import {userStore} from "./stores/UserStore";
import $ from "jquery";
import {config} from "./config";
import axios from "axios";

type SuccessCallback = (results?: any) => void;

type ErrorCallback = (results?: any) => void;

class APICaller {

    readonly caller = axios.create({
        baseURL: config.domain,
        withCredentials: true,
        responseType: 'json',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [(data) => {
            return JSON.stringify(data);
        }],
        transformResponse: [response => {
            if (response && response.data) {
                return response.data;
            }
            return response;
        }]
    });

    /**
     * Makes a post request to the nlp server.
     * @param url {string}
     * @param data {Object}
     * @param success {SuccessCallback}
     * @param error {ErrorCallback}
     */
    postNLP(url: string, data: any, success: SuccessCallback, error: ErrorCallback) {
        console.log("Request Data: ");
        console.log(data);
        return $.ajax({
            type: "POST",
            url: url,
            data: data,
            success: (results) => {
                console.log("Request Results");
                console.log(results);

                if (results !== undefined) {
                    if (results.hasOwnProperty("data")) {
                        success(results.data);
                    } else {
                        success(results);
                    }
                } else {
                    success();
                }
            },
            error: (data) => {
                console.error("Request Error");
                console.error(data);
                error(data);
            }
        });
    }

    /**
     * Makes a get request to the server.
     * @param url {string}
     */
    async get(url: string) {
        return this.caller.get(url,
            {
                headers: {
                    "Authorization": userStore.authToken
                }
            });
    }

    /**
     * Makes a put request to the server.
     * @param url {string}
     * @param data {Object}
     */
    async put(url: string, data: any) {
        return this.caller.put(url, data,
            {
                headers: {
                    "Authorization": userStore.authToken
                }
            });
    }

    /**
     * Makes a post request to the server.
     * @param url {string}
     * @param data {Object}
     */
    async post(url: string, data: any) {
        return this.caller.post(url, data,
            {
                headers: {
                    "Authorization": userStore.authToken
                }
            });
    }

    /**
     * Makes a post request to the server.
     * @param url {string}}
     */
    async delete(url: string) {
        return this.caller.delete(url,
            {
                headers: {
                    "Authorization": userStore.authToken
                }
            });
    }

    /**
     * Makes a login request to the server.
     * @param method {string} method of authentication to use
     * @param token {string} authentication token
     */
    async login(method: string, token: string) {
        return this.caller.get("/accounts/auth/token", {
            headers: {
                "Authorization": method + " " + token
            }
        });
    }

    /**
     * Makes a change password request to the server.
     * @param method {string} method of authentication to use
     * @param token {string} authentication token
     * @param data {Object}
     * @param success {SuccessCallback}
     * @param failure {ErrorCallback}
     */
    async changePassword(method: string, token: string, data: any, success: SuccessCallback = () => {
    }, failure: ErrorCallback = () => {
    }) {
        return this.caller.put("/accounts/auth/password", data,
            {
                headers: {
                    "Authorization": method + " " + token
                }
            }).then(success, failure);
    }
}

export const api = new APICaller();