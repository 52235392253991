import * as React from 'react';
import {Observer, observer} from "mobx-react";
import {domainStore} from "../../../../../../../stores/CrowdsourcingStore";
import {DetailField, DetailFieldValues} from "./DetailField";
import ServerError from "../../../../../../../../../components/ServerError";

interface RunawayDimensionsProps {
    runaway: number;
}

@observer
export class RunawayDimensions extends React.Component<RunawayDimensionsProps> {

    get runaway() {
        return domainStore.runaways[this.props.runaway];
    }

    changeAge = ({min, max, estimate}: DetailFieldValues) => {
        this.runaway.setMinAge(min);
        this.runaway.setMaxAge(max);
        this.runaway.setApproximateAge(estimate);
    };


    changeWeight = ({min, max, estimate}: DetailFieldValues) => {
        this.runaway.setMinWeight(min);
        this.runaway.setMaxWeight(max);
        this.runaway.setApproximateWeight(estimate);
    };

    changeHeight = ({min, max, estimate}: DetailFieldValues) => {
        this.runaway.setMinHeight(min);
        this.runaway.setMaxHeight(max);
        this.runaway.setApproximateHeight(estimate);
    };

    render() {
        if (!this.runaway) {
            return <ServerError/>;
        }
        const minAge = 1;
        const maxAge = 100;
        const minHeight = 36;
        const maxHeight = 96;
        const minWeight = 60;
        const maxWeight = 300;
        return (
            <div>
                <Observer>{() =>
                    <DetailField label="Age" min={minAge} max={maxAge} nullable
                                 values={[this.runaway.minAge, this.runaway.maxAge]}
                                 unit="years" estimate={this.runaway.approximateAge} onChange={this.changeAge}/>
                }</Observer>

                <Observer>{() =>
                    <DetailField label="Height" min={minHeight} max={maxHeight} type='height' nullable
                                 values={[this.runaway.minHeight, this.runaway.maxHeight]}
                                 estimate={this.runaway.approximateHeight} onChange={this.changeHeight}/>
                }</Observer>

                <Observer>{() =>
                    <DetailField label="Weight" min={minWeight} max={maxWeight} nullable
                                 values={[this.runaway.minWeight, this.runaway.maxWeight]}
                                 unit="lbs" estimate={this.runaway.approximateWeight} onChange={this.changeWeight}/>
                }</Observer>
            </div>
        );
    }
}
