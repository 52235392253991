import * as React from 'react';
import {observer} from 'mobx-react';
import {Card, Container, Divider, Header, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import Routes from "../../Routes";
import {userStore} from "../../stores/UserStore";
import {createLoader} from "../../stores/utils/Loader";
import {ErrorBoundary} from "../Crowdsourcing/components/ErrorBoundary";
import CardButton from "../../components/CardButton";
import axios from "axios";

@observer
export class CrowdsourcingSelection extends React.Component<{}, { nextAd: string }> {
    loader = createLoader();

    constructor(props: any) {
        super(props);
        this.state = {nextAd: ""};
        this.loader.load(userStore.getProfile());
    }

    componentDidMount() {
        let userID = userStore.user.id;
        let that = this;

        axios.get('https://api-next.freedomonthemove.org/crowdsource/' + userID)
            .then((response) => {
                that.setState({
                    nextAd: response.data
                })
            });
    }

    render() {
        return (
            <ErrorBoundary loader={this.loader}>
                <Container>
                    <Divider section hidden/>
                    <Segment basic padded='very' style={{paddingBottom: 0}}>
                        <Header as='h1' color='orange'>Contribute
                            <Header.Subheader>Select how you want to contribute</Header.Subheader>
                        </Header>
                    </Segment>
                    <Segment basic padded='very' className='crowdsourcing-selection'>
                        <Card.Group centered itemsPerRow={userStore.user.isMod ? 3 : 2}>
                            <CardButton as={Link} to={Routes.crowdsourcing + "/" + this.state.nextAd}>
                                <Header as='h2'>Extract Data
                                    <Header.Subheader>Fill out information about an advertisement</Header.Subheader>
                                </Header>
                            </CardButton>
                            <CardButton as={Link} to={Routes.grouping}>
                                <Header as='h2'>Group Advertisements
                                    <Header.Subheader>Link multiple instances of a single
                                        advertisement</Header.Subheader>
                                </Header>
                            </CardButton>
                            {userStore.user.isMod &&
                                <CardButton as={Link} to={Routes.moderation}>
                                    <Header as='h2'>Moderate
                                        <Header.Subheader>Review information that has been contributed by
                                            others</Header.Subheader>
                                    </Header>
                                </CardButton>
                            }
                        </Card.Group>
                    </Segment>
                </Container>
            </ErrorBoundary>
        )
    }
}

export default CrowdsourcingSelection;