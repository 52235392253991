import {action, observable, runInAction} from 'mobx';
import {userStore} from "../../../stores/UserStore";
import Collection from "./models/Collection";
import {api} from "../../../APICaller";

class CollectionStore {
    readonly collections = observable<Collection>([]);
    @observable collectionToCreate?: Collection;
    @observable collection?: Collection;
    @observable edit = false;
    @observable popupOpen = false;
    @observable shareModalOpen = false;

    @action
    async fetchCollections() {
        try {
            if (!userStore?.user?.id) {
                return;
            }
            const {data} = await api.get(`collections`);
            runInAction(() => this.collections.replace(data.map((c: any) => new Collection(c))));
        } catch {
            return;
        }
    }

    @action
    toggleEdit() {
        this.edit = !this.edit;
        if (this.edit) {
            this.collectionToCreate = new Collection({accountId: userStore.user.id});
        }
    }

    @action
    async createCollection(name?: string): Promise<Collection | undefined> {
        if (name) {
            this.collectionToCreate = new Collection({accountId: userStore.user.id, name})
        }
        const {data} = await api.post('collections', this.collectionToCreate);
        let collection;
        runInAction(() => {
            collection = new Collection(data);
            this.collections.push(collection);
        });
        this.toggleEdit();
        return collection;
    }

    isAdInCollection(id: string) {
        return !!this.collections.find(c => c.containsAdvertisement(id));
    }

    findById(id: string) {
        return this.collections.find(c => c.id === id);
    }

    async deleteColleciton(id: string) {
        try {
            await api.delete(`collections/${id}`);
            const i = this.collections.findIndex(c => c.id === id);
            runInAction(() => this.collections.splice(i, 1));
        } catch (e) {
        }
    }

    @action
    async fetchCollection(id: string) {
        const {data} = await api.get(`collections/${id}`);
        runInAction(() => this.collection = new Collection(data));
        return this.collection;
    }

    @action
    setPopupOpen(open: boolean) {
        if (open || !this.shareModalOpen) {
            this.popupOpen = open;
        }
    }

    @action
    setShareModalOpen(open: boolean) {
        this.shareModalOpen = open;
    }
}

export const collectionStore = new CollectionStore();
export default collectionStore;
