import * as React from "react";
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';
import {DateRangePicker, FocusedInputShape} from "react-dates";
import moment, {Moment} from "moment";
import {Dropdown, DropdownProps, Icon} from "semantic-ui-react";
import {uiStore} from "../scenes/Crowdsourcing/stores/UIStore";

interface IProps {
    id?: string;
    min?: Moment;
    max?: Moment;
    endDate?: Moment;
    startDate?: Moment;
    onChange: (
        arg: {
            startDate: Moment | null;
            endDate: Moment | null;
        }
    ) => void;
}

interface IState {
    focusedInput?: FocusedInputShape | null
}

export default class DateRange extends React.Component<IProps, IState> {
    id = this.props.id ? this.props.id : uiStore.getKey();
    state = {
        focusedInput: null
    };

    isOutsideRange = (date: Moment) => {
        let outside = false;
        if (this.props.min) {
            outside = outside || date.isBefore(this.props.min);
        }
        let max = moment(this.props.max);
        max = max.add(1, 'days');
        if (this.props.max) {
            outside = outside || date.isSameOrAfter(max);
        }
        return outside;
    };

    initialVisibleMonth = () => {
        return this.props.endDate ? this.props.endDate :
            this.props.startDate ? this.props.startDate :
                this.props.max ? this.props.max : moment();
    };

    info = () => {
        return (
            <div style={{padding: '10px 21px'}}>
                <Icon name='info'/>For a single date, enter the same date for min and max.
            </div>
        )
    };

    renderMonthElement = ({month, onMonthSelect, onYearSelect}: {
        month: Moment;
        onMonthSelect: (currentMonth: Moment, newMonthVal: string) => void;
        onYearSelect: (currentMonth: Moment, newYearVal: string) => void;
    }) => {
        let monthOptions = moment.months().map((label, value) => {
            return {key: value, text: label, value: value}
        });

        let minYear = this.props.min ? this.props.min.year() : 1500;
        let maxYear = this.props.max ? this.props.max.year() : moment().year();
        let yearOptions = [];
        for (let i = minYear; i < maxYear + 1; i++) {
            yearOptions.push({key: i, text: i, value: i});
        }

        return (
            <div>
                <Dropdown value={month.month()}
                          options={monthOptions}
                          onChange={({value}: DropdownProps) => onMonthSelect(month, moment().month(value as number).toString())}/>
                <Dropdown value={month.year()}
                          options={yearOptions}
                          onChange={({value}: DropdownProps) => onYearSelect(month, moment().year(value as number).toString())}/>
            </div>
        )
    };

    render() {
        const startDate = this.props.startDate ? this.props.startDate : null;
        const endDate = this.props.endDate ? this.props.endDate : null;

        return (
            <DateRangePicker
                showDefaultInputIcon
                showClearDates
                startDatePlaceholderText="m / d / yyyy"
                endDatePlaceholderText="m / d / yyyy"
                // renderMonthElement={this.renderMonthElement}
                startDateId={`startDate-${this.id}`}
                endDateId={`endDate-${this.id}`}
                startDate={startDate}
                endDate={endDate}
                initialVisibleMonth={this.initialVisibleMonth}
                onDatesChange={this.props.onChange}
                isOutsideRange={this.isOutsideRange}
                renderCalendarInfo={this.info}
                minimumNights={0}
                enableOutsideDays
                displayFormat="M/D/YYYY"
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) => {
                    this.setState({focusedInput})
                }}
            />
        )
    }
};
