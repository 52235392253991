import {Form, SemanticWIDTHS} from "semantic-ui-react";
import * as React from "react";
import {ReactElement, SyntheticEvent} from "react";

export interface NullableValues {
    value: any;
}

export type NullableClickHandler = (e: null, props: NullableValues) => void;

export type NullableHandler<T> = (e: SyntheticEvent | null, props: NullableValues | T) => void;

interface IProps {
    value?: any;
    type?: string
    widths?: SemanticWIDTHS;
    active?: boolean;
    onClick?: NullableClickHandler;
    children?: ReactElement;
    "ga-on"?: any;
    "ga-event-category"?: any;
    "ga-event-action"?: any;
}

export const Nullable = (props: IProps) => {
    const value = props.value;
    const type = props.type ? props.type : 'string';
    const widths = props.widths || props.widths === null ? props.widths : undefined;
    let active = props.active ? props.active : false;
    let nextValue: any = null;

    switch (type) {
        case 'int':
            active = active ? active : value === -9;
            nextValue = active ? null : -9;
            break;
        case 'date':
            active = active ? active : value === '1970-01-01';
            nextValue = active ? null : '1970-01-01';
            break;
        default:
            active = active ? active : value === 'NOT_PROVIDED';
            nextValue = active ? null : 'NOT_PROVIDED';
            break;
    }

    const onClick = props.onClick ? props.onClick : () => {
    };

    const width = props.children && props.children.props.width ? (16 - props.children.props.width) as SemanticWIDTHS : undefined;

    return (
        <Form.Group widths={widths} unstackable>
            {props.children}
            <Form.Button type='button' color={'blue'} inverted basic={!active} active={active}
                         label={<div style={{marginBottom: '.5rem'}}>&nbsp;</div>} width={width} size='large'
                         onClick={() => onClick(null, {value: nextValue})}
                         ga-on={props["ga-on"] ? props["ga-on"] : "click"}
                         ga-event-category={props["ga-event-category"] ? props["ga-event-category"] : "Form Field"}
                         ga-event-action={props["ga-event-action"] ? props["ga-event-action"] : "edit-nullable"}>
                Not Provided
            </Form.Button>
        </Form.Group>
    )
};

export default Nullable;
