import * as React from "react";
import {Header, Icon} from "semantic-ui-react";
import * as Sentry from "@sentry/browser";

export default function ServerError() {

    const showReportDialog = () => {
        try {
            Sentry.showReportDialog();
        } catch (e) {

        }
    };

    return (
        <div style={{textAlign: 'center', fontSize: '24px', marginTop: '20%'}}>
            <Icon name='bug' size='huge' className='error'/>

            <Header size='large'>An Unknown Error Occurred</Header>
            <div>Sorry, this one is on us. Please try again later or <a href="/#contact"> contact us</a> if the problem
                persists.
            </div>
            <button className='link-button' onClick={showReportDialog}>Report Feedback</button>
        </div>
    );
}
