import * as React from 'react';
import {Container, Form, Header, Message, Segment} from "semantic-ui-react";
import {api} from "../../APICaller";

class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      subject: '',
      email: '',
      message: '',
      isBug: false,
      complete: false
    }
  }

  submitForm = async () => {
    const {email: from, subject, message: body, isBug} = this.state;
    await api.post("/support", {from, subject, body, isBug});
    this.setState({subject: '', email: '', message: '', isBug: false, complete: true});
  };

  changeField = (e, {value, name}) => {
    this.setState({[name]: value, complete: false});
  };

  changedChecked = (e, {checked, name}) => {
    this.setState({[name]: checked, complete: false});
  };

  render() {
    return (
        <Container>
          <Segment basic padded='very'>
            <Header as='h1'>Contact Us</Header>
            <Form onSubmit={this.submitForm} success={this.state.complete}>
              <Form.Input label='Email Address' name='email' required type='email' value={this.state.email}
                          onChange={this.changeField}/>
              <Form.Input label='Subject' name='subject' required value={this.state.subject}
                          onChange={this.changeField}/>
              <Form.TextArea label='Message' name='message' required value={this.state.message}
                             onChange={this.changeField}/>
              <Form.Checkbox label='I am reporting a bug' name='isBug' checked={this.state.isBug}
                             onChange={this.changedChecked}/>
              <Form.Button primary>Submit</Form.Button>
              <Message success>Thank you for your feedback</Message>
            </Form>
          </Segment>
        </Container>
    )
  }
}

export default Contact;
