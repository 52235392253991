import * as React from 'react';
import {Button} from "semantic-ui-react";
import {RouteComponentProps, withRouter} from "react-router-dom";

export const NotFound = withRouter((props: RouteComponentProps) => {
  return (
      <div>
        <h1 style={{marginTop: '20vh', fontSize: '10em'}}>404 :(</h1>
        <div style={{margin: '10px'}}><span style={{fontSize: '2em'}}>The page you are looking for does not appear to exist.</span></div>
        <Button primary onClick={() => {props.history.push("/")}} style={{margin: '10px'}}>Continue to Home</Button>
      </div>
  )
});

export default NotFound;