import {action, observable} from "mobx";

class Page {
    @observable name!: string;
    @observable complete = false;
    @observable visible = true;

    constructor(json: any) {
        if (json.name) {
            this.name = json.name;
        }

        if (json.visible) {
            this.visible = json.visible;
        }
    }

    @action
    setName(name: string) {
        this.name = name;
    }

    @action
    setVisible(visible: boolean) {
        this.visible = visible;
    }

    @action
    setComplete(complete: boolean) {
        this.complete = complete;
    }
}

export default Page;