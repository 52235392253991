import {action, observable} from "mobx";

export class Location {
    id: string;
    @observable name?: string;
    @observable city?: string;
    @observable county?: string;
    @observable state?: string;
    @observable country?: string;
    geometries: any[];

    constructor(json: any = {}) {
        this.id = json.id;
        this.name = json.name;
        this.city = json.city;
        this.county = json.county;
        this.state = json.state;
        this.country = json.country;
        this.geometries = json.geometries;
    }

    @action
    setName(name?: string) {
        this.name = name;
    };

    @action
    setCity(city?: string) {
        this.city = city;
    };

    @action
    setCountry(country?: string) {
        this.country = country;
    };

    @action
    setCounty(county?: string) {
        this.county = county;
    };

    @action
    setState(state?: string) {
        this.state = state;
    };

    isEmpty() {
        return (!this.name || this.name.replace(/\W/g, '').length === 0)
            && (!this.city || this.city.replace(/\W/g, '').length === 0)
            && (!this.county || this.county.replace(/\W/g, '').length === 0)
            && (!this.state || this.state.replace(/\W/g, '').length === 0)
            && (!this.country || this.country.replace(/\W/g, '').length === 0)
    }
}

export default Location;
