import * as React from "react";
import {Grid, Header} from "semantic-ui-react";
import formatLocation from "../../Search/services/LocationFormat";
import moment from "moment";
import Advertisement from "../../../stores/models/Advertisement";

export const AdInfo = ({advertisement}: { advertisement: Advertisement }) => {
    const newspaper = advertisement.newspaper.name;
    const newspaperLocaiton = formatLocation(advertisement.newspaper.location);
    const publicationDate = moment(advertisement.publicationDate).format('L');

    return (
        <Grid className='description'>
            <Grid.Row>
                <Grid.Column width={5}><Header>Newspaper Name</Header></Grid.Column>
                <Grid.Column width={11}>{newspaper}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={5}><Header>Newspaper Location</Header></Grid.Column>
                <Grid.Column width={11}>{newspaperLocaiton}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={5}><Header>Publication Date</Header></Grid.Column>
                <Grid.Column width={11}>{publicationDate}</Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default AdInfo;