import Page from "./Page";
import {action, observable} from "mobx";

class Runaway {

    pages = [
        new Page({name: "description"}),
        new Page({name: "about"}),
        new Page({name: "locations"}),
        new Page({name: "rewards"})
    ];
    @observable currentPage = 0;

    getPage(name: string) {
        this.pages.forEach(p => {
            if (p.name === name) {
                return p;
            }
        })
    }

    @action
    setCurrentPage(page: number) {
        this.currentPage = page;
    }

    setPages(pages: Page[]) {
        this.pages = pages;
    }
}

export default Runaway;