import * as React from "react";
import {Responsive, Step} from 'semantic-ui-react'
import {domainStore} from "../stores/CrowdsourcingStore";
import {Link} from "react-router-dom";
import Routes from "../../../Routes";
import {uiStore} from "../stores/UIStore";

export function FormProgress({step}: {step: number}) {
    if (!domainStore.advertisement) {
        return <div/>;
    }

    const adRoute = `${Routes.crowdsourcing}/${domainStore.advertisement.id}?${uiStore.mod ? 'mod=true&' : ''}section=`;
    const adDone = domainStore.advertisement.language || domainStore.advertisement.transcription;
    const advertiserDone = adDone && domainStore.advertisement.type;
    const eventDone = adDone && domainStore.advertisement.runawayEvent && domainStore.advertisement.runawayEvent.id;
    const runawaysDone = eventDone && domainStore.runaways && domainStore.runaways.length > 0 && domainStore.runaways[0].id;

    const steps = [
        {
            key: 1,
            completed: step > 1,
            active: step >= 1,
            title: {
                content: "ADVERTISEMENT",
                as: Link,
                to: adRoute + 1
            },
            progress: 0
        },
        {
            key: 2,
            completed: step > 2,
            active: step >= 2,
            title: {
                content: "ADVERTISER",
                as: adDone ? Link : null,
                to: adDone ? adRoute + 2 : null
            },
            progress: 15
        },
        {
            key: 3,
            completed: step > 3,
            active: step >= 3,
            title: {
                content: "EVENT",
                as: advertiserDone ? Link : null,
                to: advertiserDone ? adRoute + 3 : null
            },
            progress: 30
        },
        {
            key: 4,
            completed: step > 5,
            active: step >= 4,
            title: {
                content: "RUNAWAYS",
                as: eventDone ? Link : null,
                to: eventDone ? adRoute + 4 : null
            },
            progress: 40
        },
        {
            key: 5,
            completed: step > 6,
            active: step >= 6,
            title: {
                content: "ENSLAVER",
                as: runawaysDone ? Link : null,
                to: runawaysDone ? adRoute + 6 : null
            },
            progress: 90
        }
    ];

    return (
        <div>
            <Responsive as={React.Fragment} {...Responsive.onlyComputer}>
                <Step.Group widths={5} fluid size="large" items={steps}/>
            </Responsive>
            <Responsive as={React.Fragment} maxWidth={Responsive.onlyComputer.minWidth as number - 1}>
                <Step.Group widths={5} fluid size="small" items={steps}/>
            </Responsive>
        </div>
    );
}