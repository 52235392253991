import {uiStore} from "../stores/UIStore";

export default class NLP {
  saveNLPOptions(doc){
    let sentences = doc.sentences;
    sentences.forEach((sentence) => {
      sentence.entitymentions.forEach((mention) => {
        switch (mention.ner) {
          case "NUMBER":
            uiStore.nlpOptions.addNumber(mention.text);
            break;
          case "DURATION":
            uiStore.nlpOptions.addDuration(mention.text);
            break;
          case "CITY":
          case "LOCATION":
            uiStore.nlpOptions.addLocation(mention.text);
            break;
          case "TITLE":
            uiStore.nlpOptions.addTitle(mention.text);
            break;
          case "PERSON":
            uiStore.nlpOptions.addPerson(mention.text);
            break;
          case "DATE":
            uiStore.nlpOptions.addDate(mention.text);
            break;
          default:
            break;
        }
      })
    })
  }
}

export const nlp = new NLP();