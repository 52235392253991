import * as React from "react";
import {Form, Header, InputProps} from "semantic-ui-react";
import {api} from "../../../../../APICaller";
import {SyntheticEvent} from "react";

export const SendPasswordReset = class extends React.Component {
    readonly state = {
        email: "",
        requestSuccess: false
    };

    sendPasswordReset = async () => {
        try {
            await api.put(`/accounts/password/reset?email=${this.state.email}`, {});
            this.setState({requestSuccess: true});
        } catch (e) {
            this.setState({requestSuccess: false});
        }
    };

    changeEmail = (e: SyntheticEvent, {value}: InputProps) => {
        this.setState({email: value});
    };

    render() {
        return (
            <React.Fragment>
                {!this.state.requestSuccess ?
                    <Form size='large' onSubmit={this.sendPasswordReset}>
                        <Form.Input label='Email' name='email' placeholder='Email' value={this.state.email}
                                    onChange={this.changeEmail}/>
                        <Form.Button primary type='submit'>Send Password Reset Link</Form.Button>
                    </Form>
                    :
                    <Header>Your password reset link has been sent. You have one hour to use it before it
                        expires.</Header>
                }
            </React.Fragment>

        );
    }
};