import * as React from "react";
import {Form, InputProps} from "semantic-ui-react";
import {Observer, observer} from "mobx-react";
import ServerError from "../../../../../../../../../components/ServerError";
import Reward from "../../../../../../../../../stores/models/Reward";
import {SyntheticEvent} from "react";

export const RewardField = observer(({reward}: { reward: Reward }) => {
    const changeAmount = (e: SyntheticEvent, {value}: InputProps) => {
        reward.setAmount(value);
    };

    const changeCurrency = (e: SyntheticEvent, {value}: InputProps) => {
        reward.setCurrency(value);
    };

    const changeCriteria = (e: SyntheticEvent, {value}: InputProps) => {
        reward.setCriteria(value);
    };

    if (!reward) {
        return <ServerError/>;
    }

    const amount = reward.amount ? reward.amount : '';
    const currency = reward.currency ? reward.currency : '';
    const criteria = reward.criteria ? reward.criteria : '';

    return (
        <Form.Field>
            <Form.Group>
                <Observer>{() =>
                    <Form.Input id='reward-amount' name='amount' label='Amount' value={amount} type='number' min={0}
                                width={4} onChange={changeAmount}
                                ga-on="click"
                                ga-event-category="Form Field"
                                ga-event-action="edit-reward-amount"/>
                }</Observer>
                <Observer>{() =>
                    <Form.Input id='reward-currency' name='currency' label='Currency' value={currency}
                                placeholder='ex. USD'
                                width={4} onChange={changeCurrency}
                                ga-on="click"
                                ga-event-category="Form Field"
                                ga-event-action="edit-reward-currency"/>
                }</Observer>

                <Observer>{() =>
                    <Form.Input id='reward-criteria' name='criteria' label='Criteria' value={criteria}
                                placeholder='ex. delivery of the runaway'
                                width={8} onChange={changeCriteria}
                                ga-on="click"
                                ga-event-category="Form Field"
                                ga-event-action="edit-reward-criteria"/>
                }</Observer>
            </Form.Group>
        </Form.Field>
    );
});
