import * as React from 'react';
import {EventForm} from "./components/RunawayEventForm";
import {observer} from "mobx-react";
import {domainStore} from "../../stores/CrowdsourcingStore";
import {uiStore} from "../../stores/UIStore";
import {ErrorBoundary} from "../../components/ErrorBoundary";
import {FormPage} from "../../components/FormPage";
import {Header} from "semantic-ui-react";

@observer
export class RunawayEvent extends React.Component {

    constructor(props: any) {
        super(props);
        if (!domainStore.runawayEvent) {
            domainStore.updateEventFromServer();
        } else if (!domainStore.runawayEvent.advertisementId) {
            if (domainStore.advertisement) {
                domainStore.runawayEvent.setAdvertisementId(domainStore.advertisement.id);
            }
        }
    }

    submitInfo = async () => {
        await domainStore.pushEventToServer();
        domainStore.updateAdvertisementProgress(40);
    };

    onSubmit = async () => {
        await this.submitInfo();
        uiStore.next();
    };

    previousPage = () => {
        this.submitInfo();
        uiStore.previous();
    };

    render() {
        return (
            <FormPage className='transcription-form'>
                <Header as='h1' color='orange'>RUNAWAY EVENT</Header>
                <ErrorBoundary>
                    {domainStore.runawayEvent &&
                    <EventForm onSubmit={this.onSubmit} previousPage={this.previousPage}/>
                    }
                </ErrorBoundary>
            </FormPage>
        );
    }
}