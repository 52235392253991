// @ts-nocheck
import * as React from "react";
import queryString from "query-string";
import Authenticator from "../../../../services/authentication/Authenticator";
import {Button, Container, Form, Header, InputProps, Segment} from "semantic-ui-react";
import {api} from "../../../../APICaller";
import {SendPasswordReset} from "./components/SendPasswordReset";
import {Routes} from "../../../../Routes";
import ServerError from "../../../../components/ServerError";
import {SyntheticEvent} from "react";
import {RouteComponentProps} from "react-router";

export const PasswordReset = class extends React.Component<RouteComponentProps> {
    token: string;
    tokenExpired: boolean;
    readonly state = {
        password: "",
        resetSuccess: false,
        serverError: false,
        errorMessage: ""
    };

    constructor(props: any) {
        super(props);
        let query = queryString.parse(props.location.search);
        this.token = query.token;

        this.tokenExpired = !!this.token && !Authenticator.verifyToken(this.token);
    }

    submit = async () => {
        try {
            await api.post(`/accounts/password/reset?token=${this.token}`, {password: this.state.password});
            this.setState({
                resetSuccess: true,
                serverError: false,
                errorMessage: ""
            });
        } catch ({responseJSON}) {
            this.setState({
                resetSuccess: false,
                serverError: true,
                errorMessage: responseJSON.message
            });
        }
    };

    changePassword = (e: SyntheticEvent, {value}: InputProps) => {
        this.setState({password: value});
    };

    goToLogin = () => {
        this.props.history.push(Routes.login);
    };

    render() {
        return (
            <Container text className='login-form'>
                <Segment padded='very'>
                    <Header as='h1'>Reset Your Password
                        {!this.token &&
                        <Header.Subheader>Enter your email below and we will send you a link to reset your
                          password</Header.Subheader>
                        }
                    </Header>
                    <div style={{marginTop: '2rem'}}>
                        {this.token && !this.tokenExpired && !this.state.resetSuccess && !this.state.serverError &&
                        <Form size='large' onSubmit={this.submit}>
                          <Form.Input label='New Password' name='password' placeholder='New Password' type='password'
                                      value={this.state.password} onChange={this.changePassword}/>
                          <Form.Button primary type='submit'>Change Password</Form.Button>
                        </Form>
                        }

                        {this.state.resetSuccess &&
                        <div>
                          <Header>Your password has been reset.</Header>
                          <Button primary onClick={this.goToLogin}>Return to Login</Button>
                        </div>
                        }

                        {!this.token &&
                        <SendPasswordReset/>
                        }

                        {this.tokenExpired &&
                        <div>
                          <div>This link is expired.</div>
                          <Button>Send Another Link</Button>
                        </div>
                        }

                        {this.state.serverError && this.state.errorMessage &&
                        <div>
                          <Header color='red'>{this.state.errorMessage}</Header>
                          <Button primary onClick={this.goToLogin}>Return to Login</Button>
                        </div>
                        }
                    </div>
                </Segment>

                {this.state.serverError && !this.state.errorMessage &&
                <ServerError/>
                }
            </Container>
        );
    }
};