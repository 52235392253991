import * as React from "react";
import {SyntheticEvent} from "react";
import {
    Button,
    Card,
    Container,
    Divider,
    DropdownProps,
    Form,
    Grid,
    Header,
    Image,
    Modal,
    Segment
} from "semantic-ui-react";
import {api} from "../../APICaller";
import {config} from "../../config";
import {uiStore} from "../Crowdsourcing/stores/UIStore";
import {Selection} from "../../components/Selection";
import {options} from "../../Constants";
import "./styles.css"
import Routes from "../../Routes";
import {RouteComponentProps, withRouter} from "react-router-dom";
import formatLocation from "../Search/services/LocationFormat";
import DateRange from "../../components/DateRange";
import moment, {Moment} from "moment";
import {ErrorBoundary} from "../Crowdsourcing/components/ErrorBoundary";
import {createLoader} from "../../stores/utils/Loader";
import {createComplexRule, createRule} from "../Search/services/RuleBuilder";
import {observer} from "mobx-react";
import MiddleAligned from "../../components/MiddleAligned";
import Advertisement from "../../stores/models/Advertisement";

interface IState {
    transcribed: string | boolean,
    minPubDate?: string,
    maxPubDate?: string,
    state: string,
    language: string,
    advertisements: Advertisement[]
}

export const AdSelection = observer(withRouter(class extends React.Component<RouteComponentProps, IState> {
    state: IState = {
        transcribed: 'null',
        state: 'none',
        language: 'none',
        advertisements: []
    };

    loader = createLoader(0);
    dateTimer?: NodeJS.Timeout;

    componentDidMount(): void {
        this.getAdvertisements();
    }

    getAdvertisements = async () => {
        try {
            const {data} = await this.loader.load(api.post(`/${config.adPath}/transcribe?limit=9`, this.getCriteria()));
            this.setState({advertisements: data});
        } catch (e) {
            this.setState({advertisements: []});
        }
    };

    pickRandom = async () => {
        const criteria = createComplexRule('AND', [createRule('ACCOUNT_TO_ADVERTISEMENT_ID', 'is_null', null)]);
        const {data} = await api.post(`/${config.adPath}/transcribe?limit=1`, criteria);
        const [ad] = data;
        if (ad) {
            this.props.history.push(`${Routes.crowdsourcing}/${ad.id}`);
        }
    };

    pickProofread = async () => {
        this.setState({transcribed: true});
        const criteria = createComplexRule('AND', [createRule('ACCOUNT_TO_ADVERTISEMENT_ID', 'is_not_null', null)]);
        const {data} = await api.post(`/${config.adPath}/transcribe?limit=1`, criteria);
        const [ad] = data;
        if (ad) {
            this.props.history.push(`${Routes.crowdsourcing}/${ad.id}`);
        }
    };

    getCriteria = () => {
        const rules = [];

        if (this.state.transcribed === 'yes') {
            rules.push({
                field: "ADVERTISEMENT_TRANSCRIPTION",
                operator: "is_not_null",
                value: null
            })
        } else if (this.state.transcribed === 'no') {
            rules.push({
                field: "ADVERTISEMENT_TRANSCRIPTION",
                operator: "is_null",
                value: null
            })
        }

        if (this.state.minPubDate) {
            rules.push({
                field: "ADVERTISEMENT_PUBLICATION_DATE",
                operator: "greater_or_equal",
                value: this.state.minPubDate
            })
        }

        if (this.state.maxPubDate) {
            rules.push({
                field: "ADVERTISEMENT_PUBLICATION_DATE",
                operator: "less_or_equal",
                value: this.state.maxPubDate
            })
        }

        if (this.state.state !== 'none') {
            rules.push({
                field: "NEWSPAPER_LOCATION_STATE",
                operator: "equal",
                value: this.state.state
            })
        }

        if (this.state.language && this.state.language !== 'none') {
            rules.push({
                field: "ADVERTISEMENT_LANGUAGE_ID",
                operator: "equal",
                value: this.state.language
            })
        }

        if (rules.length <= 0) {
            return {};
        }
        return createComplexRule('AND', rules);
    };

    changeTranscribed = (value: string | boolean) => {
        if (this.state.transcribed === value) {
            this.setState({transcribed: 'null'}, this.getAdvertisements);
        } else {
            this.setState({transcribed: value}, this.getAdvertisements);
        }
    };

    changePubDate = ({startDate, endDate}: { startDate: Moment | null, endDate: Moment | null }) => {
        let start = startDate ? startDate.format("YYYY-MM-DD") : undefined;
        let end = endDate ? endDate.format("YYYY-MM-DD") : undefined;
        if (this.state.minPubDate !== start || this.state.maxPubDate !== end) {
            window.ga('send', 'event', 'Form Field', 'ad-selection-date');
            this.setState({
                minPubDate: start,
                maxPubDate: end
            }, () => {
                this.dateTimer = setTimeout(() => {
                    this.getAdvertisements();
                }, 1000);
            });
        }
    };

    changeState = (e: SyntheticEvent, {value}: DropdownProps) => {
        this.setState({state: value as string}, this.getAdvertisements);
    };

    changeLanguage = (e: SyntheticEvent, {value}: DropdownProps) => {
        this.setState({language: value as string}, this.getAdvertisements);
    };

    render() {
        const languageOptions = [{key: 'none', text: 'None', value: 'none'}].concat(
            options.languages.filter(l => l.iso_639 === 'en' || l.iso_639 === 'fr')
                .map(s => {
                    return {key: s.id, text: s.name, value: s.id}
                }));
        const stateOptions = [{key: 'none', text: 'None', value: 'none'}].concat(options.newspaperStates.map(s => {
            return {key: s.iso3166_2, text: s.name, value: s.iso3166_2}
        }));

        let minDate = this.state.minPubDate ? moment(this.state.minPubDate) : undefined;
        let maxDate = this.state.maxPubDate ? moment(this.state.maxPubDate) : undefined;
        return (

            <Container id='content' className='ad-selection'>
                <Divider section hidden/>
                <Header as='h1' size='huge' color='orange'>CONTRIBUTE
                    <Header.Subheader>Help us get information from an advertisement</Header.Subheader>
                </Header>

                <Divider hidden/>

                <React.Fragment>
                    <Card.Group centered itemsPerRow={2}>
                        <Card link onClick={this.pickRandom}>
                            <MiddleAligned>
                                <Card.Content as={Grid} verticalAlign='middle' textAlign='center'>
                                    <Header as='h2' style={{paddingTop: '1rem', paddingBottom: '1rem'}}>
                                        Start from Scratch
                                        <Header.Subheader>Be the first person to contribute information about an
                                            advertisement</Header.Subheader>
                                    </Header>
                                </Card.Content>
                            </MiddleAligned>
                        </Card>
                        <Card link onClick={this.pickProofread}>
                            <MiddleAligned>
                                <Card.Content textAlign='center'>
                                    <Header as='h2' style={{paddingTop: '1rem', paddingBottom: '1rem'}}>
                                        Proofread & Correct
                                        <Header.Subheader>Look over someone else's work and make sure they didn't make
                                            any
                                            mistakes</Header.Subheader>
                                    </Header>
                                </Card.Content>
                            </MiddleAligned>
                        </Card>
                    </Card.Group>

                    <Segment padded='very' style={{minHeight: '40vh'}}>
                        <Grid centered>
                            <Form>
                                <Form.Group>
                                    <Form.Field>
                                        <label>Transcribed</label>
                                        <Selection color='blue'
                                                   options={[{key: 1, text: 'Yes', value: 'yes'}, {
                                                       key: 2,
                                                       text: 'No',
                                                       value: 'no'
                                                   }]}
                                                   value={this.state.transcribed} onChange={this.changeTranscribed}
                                                   ga-event-action="ad-selection-transcribed"/>
                                    </Form.Field>

                                    <Form.Field>
                                        <label>Publication Date</label>
                                        <DateRange max={moment('1865-12-06')}
                                                   startDate={minDate} endDate={maxDate}
                                                   onChange={this.changePubDate}
                                        />
                                    </Form.Field>

                                    <Form.Dropdown search selection style={{width: '10em'}}
                                                   label='State' options={stateOptions} value={this.state.state}
                                                   onChange={this.changeState}
                                                   ga-on="click"
                                                   ga-event-category="Form Field"
                                                   ga-event-action="ad-selection-state"/>
                                    <Form.Dropdown selection style={{width: '10em'}}
                                                   label='Language'
                                                   options={languageOptions}
                                                   value={this.state.language}
                                                   onChange={this.changeLanguage}
                                                   ga-on="click"
                                                   ga-event-category="Form Field"
                                                   ga-event-action="ad-selection-language"/>
                                </Form.Group>
                            </Form>
                        </Grid>
                        <ErrorBoundary loader={this.loader}>
                            {!this.state.advertisements.length &&
                            <div style={{textAlign: 'center', marginTop: '20%', marginBottom: '20%'}}>
                              <Header size='large'>There are no results that fit your search criteria.</Header>
                            </div>
                            }
                            <Card.Group centered itemsPerRow={3}>
                                {this.state.advertisements.map((a: Advertisement, i: number) => {
                                    let filename = a.digitalObject.filename;
                                    let locationName = formatLocation(a.newspaper.location);
                                    return (
                                        <Card key={uiStore.getKey()} color={i === 0 ? 'violet' : undefined}>
                                            <Card.Content>
                                                <Card.Header>{a.newspaper.name}</Card.Header>
                                                <Card.Meta>{locationName} | {a.publicationDate}</Card.Meta>
                                                {filename &&
                                                <Modal
                                                    trigger={<Image src={`${config.s3Domain}/${filename}`} fluid
                                                                    alt="Advertisement"/>}
                                                    className='image-modal' size='large' closeIcon>
                                                  <Modal.Content scrolling>
                                                    <Image src={`${config.s3Domain}/${filename}`} fluid
                                                           alt="Advertisement"/>
                                                  </Modal.Content>
                                                </Modal>
                                                }
                                                {a.transcription &&
                                                <Card.Description>{a.transcription}</Card.Description>}
                                            </Card.Content>
                                            <Card.Content extra>
                                                <Button primary fluid
                                                        onClick={() => this.props.history.push(`${Routes.crowdsourcing}/${a.id}`)}>CONTRIBUTE</Button>
                                            </Card.Content>
                                        </Card>
                                    )
                                })}
                            </Card.Group>
                        </ErrorBoundary>
                    </Segment>
                </React.Fragment>
            </Container>
        )
    }
}));

export default AdSelection;
