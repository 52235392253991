import * as React from "react";
import {Header, Icon} from "semantic-ui-react";

export default function ConnectionError() {

    return (
        <div style={{textAlign: 'center', fontSize: '24px', marginTop: '20%'}}>
            <Icon name='remove circle' size='huge' className='error'/>

            <Header size='large'>Failed to Connect to Server</Header>
            <div>Check your internet connection and try again. <a href="/#contact">Contact us</a> if the problem
                persists.
            </div>
        </div>
    );
}
