import * as React from "react";
import {Button, Dropdown, DropdownProps, Input, InputProps, SemanticCOLORS, SemanticSIZES} from "semantic-ui-react";
import {uiStore} from "../scenes/Crowdsourcing/stores/UIStore";
import {SyntheticEvent} from "react";

interface IProps {
    id?: any;
    options: any[];
    otherOptions?: any[];
    value?: any;
    values?: any[];
    allowNull?: boolean;
    multiple?: boolean;
    primary?: boolean;
    other?: boolean;
    compact?: boolean;
    size?: SemanticSIZES;
    color?: SemanticCOLORS;
    onChange: (value: any) => void;
    "ga-on"?: string;
    "ga-event-category"?: string;
    "ga-event-action"?: string;
}

interface IState {
    options: any[];
    otherOptions: any[];
}

export class Selection extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        let options = props.options ? props.options : [];
        const otherOptions = props.otherOptions ? props.otherOptions : [];
        const values = props.values ? props.values : [];
        values.forEach((v: any) => {
            if (!options.filter((o: any) => o.value === v).length) {
                options = options.concat(otherOptions.filter((o: any) => o.value === v));
            }
        });

        this.state = {
            options: options,
            otherOptions: otherOptions
        }
    }

    componentWillReceiveProps(props: IProps) {
        let options = props.options ? props.options : [];
        let otherOptions = props.otherOptions ? props.otherOptions : [];
        let values = props.values ? props.values : [];
        values.forEach(v => {
            if (!options.filter(o => o.value === v).length) {
                options = options.concat(otherOptions.filter(o => o.value === v));
            }
        });

        this.setState({options: options, otherOptions: otherOptions});
    }

    render() {
        const multiple = this.props.multiple;
        const color = this.props.color;
        const primary = this.props.primary;
        let other = this.props.other ? this.props.other : false;
        const size = this.props.size ? this.props.size : 'large';
        const compact = this.props.compact ? this.props.compact : false;

        if (this.state.otherOptions && this.state.otherOptions.length > 0) {
            other = true;
        }

        if (multiple) {
            let values = this.props.values ? this.props.values : [];
            let onChange = (e: SyntheticEvent | null, value: any) => {
                let active = values.includes(value);
                if (active) {
                    values.splice(values.indexOf(value), 1);
                } else {
                    values.push(value);
                }
                this.props.onChange(values);
            };

            let addOther = (value: any) => {
                if (this.state.otherOptions && !this.state.options.filter(o => o.value === value).length) {
                    let option = this.state.otherOptions.filter(o => o.value === value)[0];
                    this.setState({options: this.state.options.concat(option)})
                } else if (!this.state.options.filter(o => o.value === value).length) {
                    let option = {key: uiStore.getKey(), value: value, text: value};
                    this.setState({options: this.state.options.concat(option)})
                }
                onChange(null, value);
            };

            return (
                <Button.Group id={this.props.id} size={size} compact={compact}>
                    {this.state.options.map(o => {
                        let active = values.includes(o.value);
                        return <Button key={o.key} type='button'
                                       primary={primary && !active} color={color} basic={!active} inverted
                                       active={active}
                                       onClick={(e) => onChange(e, o.value)}
                                       ga-on={this.props["ga-on"] ? this.props["ga-on"] : "click"}
                                       ga-event-category={this.props["ga-event-category"] ? this.props["ga-event-category"] : "Form Field"}
                                       ga-event-action={this.props["ga-event-action"] ? this.props["ga-event-action"] : "edit-selection"}>
                            {o.text}
                        </Button>
                    })}
                    {other &&
                    <OtherButton color={color} onChange={addOther} options={this.state.otherOptions}/>
                    }
                </Button.Group>
            );
        } else {
            let value = this.props.value;
            let allowNull = this.props.allowNull;
            let onChange = (newValue: any) => {
                const active = value === newValue;
                if (active && allowNull) {
                    this.props.onChange(null);
                } else {
                    this.props.onChange(newValue);
                }
            };

            let addOther = (value: any) => {
                if (this.state.otherOptions && !this.state.options.filter(o => o.value === value).length) {
                    this.state.options.push(this.state.otherOptions.filter(o => o.value === value)[0]);
                } else if (!this.state.options.filter(o => o.value === value).length) {
                    this.state.options.push({key: uiStore.getKey(), value: value, text: value});
                }
                onChange(value);
            };

            return (
                <Button.Group id={this.props.id} size={size} compact={compact}>
                    {this.state.options.map(o => {
                        let active = value === o.value;
                        return <Button key={o.key} type='button'
                                       primary={primary && !active} color={color} basic={!active} inverted
                                       active={active}
                                       onClick={() => onChange(o.value)}
                                       ga-on={this.props["ga-on"] ? this.props["ga-on"] : "click"}
                                       ga-event-category={this.props["ga-event-category"] ? this.props["ga-event-category"] : "Form Field"}
                                       ga-event-action={this.props["ga-event-action"] ? this.props["ga-event-action"] : "edit-selection"}>
                            {o.text}
                        </Button>
                    })}
                    {other &&
                    <OtherButton color={color} onChange={addOther} options={this.state.otherOptions}/>
                    }
                </Button.Group>
            );
        }
    }
}

interface IOtherProps {
    options: any[];
    onChange?: (value: any) => void;
    primary?: boolean;
    color?: SemanticCOLORS;
}

interface IOtherState {
    editing: boolean;
    options: any[];
    value: any;
}

const OtherButton = class extends React.Component<IOtherProps, IOtherState> {
    constructor(props: IOtherProps) {
        super(props);
        this.state = {
            editing: false,
            options: props.options,
            value: ''
        }
    }

    toggleEdit = () => {
        this.setState({editing: !this.state.editing});
    };

    onDone = () => {
        if (this.props.onChange && this.state.value) {
            this.props.onChange(this.state.value);
            this.setState({value: ''})
        }
        this.toggleEdit();
    };

    changeValue = (e: SyntheticEvent, {value}: InputProps | DropdownProps) => {
        this.setState({value});
    };

    render() {
        return (
            this.state.editing ? (
                    this.state.options ?
                        <Button as='div' className='other-button' style={{padding: 0}} color={this.props.color}
                                primary={this.props.primary}>
                            <Dropdown search selection clearable
                                      value={this.state.value.value}
                                      options={this.state.options}
                                      onChange={this.changeValue}/>
                            <Button color={this.props.color} primary={this.props.primary}
                                    onClick={this.onDone}>OK</Button>
                        </Button>
                        :
                        <Button as='div' className='other-button' style={{padding: 0}} color={this.props.color}
                                primary={this.props.primary}>
                            <Input labelPosition='right'
                                   value={this.state.value}
                                   onChange={this.changeValue}
                                   label={<Button color={this.props.color} primary={this.props.primary} inverted
                                                  onClick={this.onDone}>OK</Button>}/>
                        </Button>
                )
                :
                <Button onClick={this.toggleEdit} color={this.props.color} primary={this.props.primary} basic inverted
                        style={{width: '8em'}}>{'Other'}</Button>
        )
    }
};
