import * as React from "react";
import {TranscriptionForm} from "./components/TranscriptionForm"
import {observer} from "mobx-react";
import {domainStore} from "../../stores/CrowdsourcingStore";
import {uiStore} from "../../stores/UIStore";
import {ErrorBoundary} from "../../components/ErrorBoundary";
import {FormPage} from "../../components/FormPage";
import {Header} from "semantic-ui-react";

@observer
export class Transcription extends React.Component {

    onSubmit = () => {
        domainStore.pushAdvertisementToServer()
            .then(() => {
                domainStore.updateAdvertisementProgress(15);
                uiStore.next();
            });

        uiStore.getNLPTranscription();
    };

    render() {
        return (
            <FormPage className='transcription-form'>
                <Header as='h1' color='orange'>ADVERTISEMENT</Header>
                <ErrorBoundary>
                    {domainStore.advertisement &&
                    <TranscriptionForm onSubmit={this.onSubmit}/>
                    }
                </ErrorBoundary>
            </FormPage>
        )
    }
}

export default Transcription;