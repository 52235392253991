// @ts-nocheck
import {action, computed, observable, runInAction} from "mobx";
import {api} from "../../../APICaller";
import {RunawayEvent} from "../../../stores/models/RunawayEvent";
import Advertisement from "../../../stores/models/Advertisement";
import Runaway from "../../../stores/models/Runaway";
import Enslaver from "../../../stores/models/Enslaver";
import {config} from "../../../config";
import Advertiser from "../../../stores/models/Advertiser";
import RunawayRelationship from "../../../stores/models/RunawayRelationship";
import {Loader, STATES} from "../../../stores/utils/Loader";
import {createComplexRule, createRule} from "../../Search/services/RuleBuilder";

class CrowdsourcingStore {
    @observable advertisement?: Advertisement;
    @observable runawayEvent: RunawayEvent;
    readonly runaways = observable<Runaway>([]);
    readonly relationships = observable<RunawayRelationship>([]);
    readonly enslavers = observable<Enslaver>([new Enslaver()]);
    @observable advertiser ? = new Advertiser();
    readonly loader = new Loader(500);

    constructor() {
        this.runawayEvent = new RunawayEvent();
        this.advertiser = new Advertiser();
    }

    @computed
    get loading() {
        return this.loader.showLoader;
    }

    @computed
    get serverError() {
        return this.loader.hasError;
    }

    @computed
    get connectionError() {
        return this.loader.failed;
    }

    @computed
    get unauthenticated() {
        return this.loader.unauthenticated;
    }

    @action
    requestSuccess = () => {
        this.loader.updateState(STATES.LOADED);
    };

    @action
    requestFailure = ({response: data}: any) => {
        if (data) {
            this.loader.updateState(STATES.ERROR);
        } else {
            this.loader.updateState(STATES.FAILED);
        }
    };

    @action
    getLoader(timeout = 500) {
        return new Loader(timeout);
    }

    @action
    async updateAll() {
        console.debug("UPDATE ALL");
        await this.updateAdvertisementFromServer();
        if (this.advertisement) {
            runInAction(() => {
                if (this.advertisement!.runawayEvent) {
                    this.runawayEvent = this.advertisement!.runawayEvent
                } else {
                    this.runawayEvent = new RunawayEvent({advertisementId: this.advertisement!.id});
                }

                if (this.runawayEvent) {
                    this.runaways.replace(this.runawayEvent.runaways)
                } else {
                    this.runaways.replace([]);
                }

                if (this.advertisement!.advertiser) {
                    this.advertiser = this.advertisement!.advertiser
                } else {
                    this.advertiser = new Advertiser()
                }
                console.debug('Ad Event', this.advertisement!.runawayEvent);
                console.debug('Event', this.runawayEvent);

            });
        }
        return this.updateEnslaversFromServer();
    }

    @action
    setAdvertisementId(advertisementId: string) {
        this.advertisement = new Advertisement({id: advertisementId});
    }

    @action
    async getAdToTranscribe() {
        try {
            let {data} = await this.loader.load(api.get(`/${config.adPath}/transcribe`));
            runInAction(() => this.advertisement = new Advertisement(data[0]));
        } catch ({response: data}) {
            runInAction(() => this.advertisement = undefined);
            if (data && data.status === 404) {
                this.requestSuccess();
            } else {
                this.requestFailure({response: data});
            }
        }
    }

    @action
    async updateAdvertisementFromServer() {
        if (this.advertisement) {
            this.loader.updateState(STATES.LOADING);
            let {data} = await this.loader.load(api.get(`/${config.adPath}/${this.advertisement.id}`));
            runInAction(() => this.advertisement = new Advertisement(data));
            return this.advertisement;
        }
    }

    async updateAdvertisementProgress(progress: number) {
        if (this.advertisement) {
            const url = `/${config.adPath}/${this.advertisement.id}/progress`;
            const data = {
                progress: progress
            };

            return api.put(url, data);
        }
    }

    @action
    async skipAdvertisement() {
        await this.pushAdvertisementToServer();
        const criteria = createComplexRule('AND', [createRule('ACCOUNT_TO_ADVERTISEMENT_ID', 'is_null', null)]);
        const {data} = await this.loader.load(api.post(`/${config.adPath}/transcribe?limit=1`, criteria));
        const [ad] = data;
        runInAction(() => this.advertisement = new Advertisement(ad));

        return this.advertisement;
    }

    @action
    async pushAdvertisementToServer() {
        if (this.advertisement) {
            let {data} = await this.loader.load(api.put(`/${config.adPath}/${this.advertisement.id}`, this.advertisement));
            runInAction(() => this.advertisement = new Advertisement(data));
            return this.advertisement;
        }
    }

    @action
    async updateEventFromServer() {
        if (this.advertisement) {
            await this.updateAdvertisementFromServer();
            if (!this.advertisement.runawayEvent) {
                runInAction(() => this.runawayEvent = new RunawayEvent({advertisementId: this.advertisement!.id}));
            }

            return this.runawayEvent;
        }
    }

    @action
    async pushEventToServer() {
        if (this.runawayEvent.id) {
            let {data} = await this.loader.load(api.put(`/runaway-events/${this.runawayEvent.id}`, this.runawayEvent));
            runInAction(() => this.runawayEvent = new RunawayEvent(data));
        } else {
            let {data} = await this.loader.load(api.post(`/runaway-events`, this.runawayEvent));
            runInAction(() => this.runawayEvent = new RunawayEvent(data));
        }

        return this.runawayEvent;
    }

    @action
    async updateRunawaysFromServer() {
        await this.updateEventFromServer();

        runInAction(() => this.runaways.replace(this.runawayEvent.runaways));
    }

    async pushAllRunawayData(i: number) {
        let runaway = this.runaways[i];
        runaway.setRelationships(this.relationships.filter(r => r.leftRunawayId === runaway.id));

        return await this.pushEnslavedPersonToServer(i)
            .then(() => {
                return this.pushRunawayToServer(i);
            });
    }

    @action
    async pushRunawayToServer(i: number) {
        let runaway = this.runaways[i];

        if (runaway.id) {
            let {data} = await this.loader.load(api.put(`/runaways/${runaway.id}`, runaway.getDataForServer()));
            runInAction(() => this.runaways[i] = new Runaway(data));
        } else {
            let {data} = await this.loader.load(api.post(`/runaways/`, runaway.getDataForServer()));
            runInAction(() => this.runaways[i] = new Runaway(data));
        }

        return this.runaways[i];
    }

    @action
    initRelationships() {
        this.relationships.clear();
        this.runaways.forEach(runaway => {
            let notUsed = runaway.relationships.filter(r1 => this.relationships.map(r2 => r2.id).indexOf(r1.id) < 0);
            this.relationships.replace(this.relationships.concat(notUsed));
        });
    }

    @action
    addRelationship(data = {}) {
        this.relationships.push(new RunawayRelationship(data))
    }

    @action
    removeRelationship(i: number) {
        this.relationships.splice(i, 1);
    }

    @action
    async pushRunawayRewardsToServer(i: number) {
        let runaway = this.runaways[i];

        let rewards = runaway.isRewardsEmpty() ? [] : runaway.rewards.filter(r => !r.isEmpty());

        let {data} = await this.loader.load(api.put(`/runaways/${runaway.id}/rewards`, rewards));
        runInAction(() => runaway.setRewards(data));
        return runaway.rewards;
    }

    @action
    async pushEnslavedPersonToServer(i: number) {
        const enslavedPerson = this.runaways[i].enslavedPerson;

        if (enslavedPerson.id) {
            const {data} = await this.loader.load(api.put(`/enslaved-persons/${enslavedPerson.id}`, enslavedPerson.getDataForServer()));
            runInAction(() => this.runaways[i].setEnslavedPerson(data));
        } else {
            const {data} = await this.loader.load(api.post(`/enslaved-persons`, enslavedPerson.getDataForServer()));
            runInAction(() => this.runaways[i].setEnslavedPerson(data));
        }

        return this.runaways[i].enslavedPerson;
    }

    @action
    async pushEnslavedPersonLanguagesToServer(i: number) {
        const enslavedPerson = this.runaways[i].enslavedPerson;
        const languages = enslavedPerson.isLanguagesEmpty() ? [] : enslavedPerson.languages;
        return this.loader.load(api.put(`/enslaved-persons/${enslavedPerson.id}/languages`, languages));
    }

    @action
    async pushEnslavedPersonNamesToServer(i: number) {
        const enslavedPerson = this.runaways[i].enslavedPerson;
        const names = enslavedPerson.isNamesEmpty() ? [] : enslavedPerson.names;
        return this.loader.load(api.put(`/enslaved-persons/${enslavedPerson.id}/names`, names));
    }

    @action
    async addRunaway() {
        this.runaways.push(new Runaway(
            {
                runawayEventId: this.runawayEvent.id
            }
        ));
        return this.pushAllRunawayData(this.runaways.length - 1);
    }

    @action
    async removeRunaway(i: number) {
        const runaway = this.runaways.splice(i, 1)[0];
        this.relationships.replace(this.relationships.filter(r => !(r.leftRunawayId === runaway.id || r.rightRunawayId === runaway.id)));

        if (runaway.id) {
            return this.loader.load(api.delete(`/runaways/${runaway.id}`));
        }
    }

    @action
    async pushRunawayLocationsToServer(i: number) {
        const runaway = this.runaways[i];

        const locations = runaway.isLocationsEmpty() ? [] : runaway.locations;
        const {data} = await this.loader.load(api.put(`/runaways/${runaway.id}/locations`, locations));
        runInAction(() => runaway.setLocations(data));
        return runaway.locations;
    }

    @action
    addEnslaver() {
        this.enslavers.push(new Enslaver());
    }

    @action
    async removeEnslaver(i: number) {
        let enslaver = this.enslavers.splice(i, 1)[0];
        if (enslaver.id) {
            return this.loader.load(api.delete(`/enslavers/${enslaver.id}`));
        }
    }

    @action
    async updateEnslaversFromServer() {
        if (!this.advertisement) {
            return;
        }
        let query = {
            condition: "AND",
            rules: [
                {
                    field: "ADVERTISEMENT_ID",
                    operator: "equal",
                    value: this.advertisement.id
                }
            ]
        };

        let {data} = await this.loader.load(api.post(`/enslavers/search`, query));
        runInAction(() => this.enslavers.replace(data.map((d: any) => new Enslaver(d))));
        return this.enslavers;
    }

    @action
    async pushEnslaversToServer() {
        return this.loader.load(Promise.all(this.enslavers.map((enslaver, i) => {
            return this.pushEnslaverToServer(i);
        })));
    }

    async pushEnslaverToServer(i: number) {
        /**@type {Enslaver}*/
        let enslaver = this.enslavers[i];
        if (enslaver && enslaver.fullname) {
            if (enslaver.id) {
                let {data} = await api.put(`/enslavers/${enslaver.id}`, enslaver.getDataForServer());
                runInAction(() => this.enslavers[i] = new Enslaver(data));
                return data;
            } else {
                let {data} = await api.post(`/enslavers`, enslaver.getDataForServer());
                runInAction(() => this.enslavers[i] = new Enslaver(data));
                return data;
            }
        }
    }

    @action
    async updateRunawayEnslavers() {
        const enslavers: { [id: string]: Enslaver[] } = {};
        this.runaways.forEach(r => enslavers[r.id] = []);

        this.enslavers.forEach(enslaver => {
            if (enslaver.fullname) {
                enslaver.runaways.forEach(runawayId => {
                    enslavers[runawayId].push(enslaver);
                });
            }
        });

        return this.loader.load(Promise.all(Object.entries(enslavers).map(([id, enslavers]) => {
            return api.put(`/runaways/${id}/enslavers`, enslavers);
        })));
    }

    @action
    async updateAdvertiserFromServer() {
        if (!this.advertisement) {
            return;
        }

        await this.updateAdvertisementFromServer();

        runInAction(() => this.advertiser = this.advertisement!.advertiser);
    }

    @action
    async pushAdvertiserToServer() {
        if (!this.advertisement) {
            return;
        }

        if (this.advertiser && this.advertiser.name && this.advertiser.name !== "") {
            if (this.advertiser.id) {
                let {data} = await this.loader.load(api.put(`/${config.advertiserPath}/${this.advertiser.id}`, this.advertiser.getDataForServer()));
                runInAction(() => this.advertiser = new Advertiser(data));
                this.advertisement.setAdvertiserId(this.advertiser.id);
                this.pushAdvertisementToServer();
                return this.advertiser;
            } else {
                let {data} = await this.loader.load(api.post(`/${config.advertiserPath}`, this.advertiser.getDataForServer()));
                runInAction(() => this.advertiser = new Advertiser(data));
                this.advertisement.setAdvertiserId(this.advertiser.id);
                this.pushAdvertisementToServer();
                return this.advertiser;
            }
        }
    }
}

export const domainStore = new CrowdsourcingStore();
