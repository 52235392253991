import * as React from 'react';

const Horizontal = (props: any) => {
    return (
        <div style={{overflow: 'hidden'}}>
            <div style={{float: 'left'}}>
                {props.children[0]}
            </div>
            <div style={{overflow: 'hidden'}}>
                {props.children[1]}
            </div>
        </div>
    )
};

export default Horizontal;