import * as React from "react";
import {SyntheticEvent} from "react";
import {Divider, DropdownProps, Form, Icon, InputProps, Popup, TextAreaProps} from "semantic-ui-react";
import {Observer, observer} from "mobx-react";
import {options} from "../../../../../../../../Constants";
import {domainStore} from "../../../../../../stores/CrowdsourcingStore";
import {uiStore} from "../../../../../../stores/UIStore";
import {RunawayDimensions} from "./componenets/RunawayDimensions";
import {Selection} from "../../../../../../../../components/Selection";
import ServerError from "../../../../../../../../components/ServerError";
import Nullable, {NullableValues} from "../../../../../../components/Nullable"
import EnslavedPerson from "../../../../../../../../stores/models/EnslavedPerson";

interface RunawayDescriptionFormProps {
    runaway: number;
}

type NullableHandler<T> = (e: SyntheticEvent | null, props: NullableValues | T) => void;

@observer
export class RunawayDescriptionForm extends React.Component<RunawayDescriptionFormProps> {
    raceOptions = options.races.map(r => {
        return {key: uiStore.getKey(), text: r, value: r}
    });
    genderOptions = options.genders.toJS();
    enslavedPerson!: EnslavedPerson;

    constructor(props: RunawayDescriptionFormProps) {
        super(props);
        if (uiStore.runaways.length > props.runaway && domainStore.runaways.length > props.runaway) {
            this.enslavedPerson = this.runaway.enslavedPerson;
        }
    };

    get runaway() {
        return domainStore.runaways[this.props.runaway];
    }

    changePossessions: NullableHandler<TextAreaProps> = (e, {value}) => {
        this.runaway.setPossessions(value);
    };

    changeEthnicDescription: NullableHandler<InputProps> = (e, {value}) => {
        this.runaway.setEthnicDescription(value);
    };

    changeInjuriesScars: NullableHandler<InputProps> = (e, {value}) => {
        this.runaway.setInjuriesScars(value);
    };

    changeSelfPresentation: NullableHandler<InputProps> = (e, {value}) => {
        this.runaway.setSelfPresentation(value);
    };

    changeOtherDescriptions: NullableHandler<InputProps> = (e, {value}) => {
        this.runaway.setOtherPhysicalDescriptions(value);
    };

    changeRacialDescription: NullableHandler<DropdownProps> = (e, {value}) => {
        const description = value instanceof Array ? value.join(', ') : value as string;
        this.runaway.setRacialDescription(description);
    };

    addRaceOption = (e: SyntheticEvent, {value}: DropdownProps) => {
        this.raceOptions.push({key: uiStore.getKey(), text: value as string, value: value as string});
    };

    changeGender = (value: string) => {
        this.enslavedPerson.setGender(value);
    };

    render() {
        if (!this.runaway || !this.enslavedPerson) {
            return <ServerError/>;
        }

        return (
            <div>
                <Form as='div' className='left aligned' size='large'>

                    <Form.Field>
                        <label>Based on the ad, do you think the runaway was male or female?</label>
                        <Observer>{() =>
                            <Selection options={this.genderOptions} value={this.enslavedPerson.gender} color='blue'
                                       onChange={this.changeGender}
                                       ga-on="click"
                                       ga-event-category="Form Field"
                                       ga-event-action="edit-runaway-gender"/>
                        }</Observer>
                    </Form.Field>

                    <Divider section/>

                    <Form.Field>
                        <RunawayDimensions runaway={this.props.runaway}/>
                    </Form.Field>

                    <Divider section/>

                    <Observer>{() => {
                        let racialDescription = this.runaway && this.runaway.racialDescription ? this.runaway.racialDescription : '';
                        if (racialDescription === 'NOT_PROVIDED') {
                            racialDescription = '';
                        }
                        const racialDescriptions = racialDescription.length > 0 ? racialDescription.split(', ') : [];

                        return (
                            <Nullable value={this.runaway.racialDescription} onClick={this.changeRacialDescription}
                                      ga-event-action="edit-runaway-race">
                                <Form.Dropdown name='racialDescription' width={12} title='Racial Category'
                                               label={
                                                   <label>What racial category is assigned to the runaway in the
                                                       ad?&nbsp;
                                                       <Popup
                                                           wide='very'
                                                           trigger={
                                                               <Icon name='info circle'/>
                                                           }
                                                           content="Enslavers invented a huge number of “race” or “color” descriptions, many of which are considered offensive because white people used those categories to deny access to freedom and other rights. However, we are gathering data about this because this information will help researchers to map changes in how racism shifted its terms across space and time."
                                                       />
                                                   </label>
                                               }
                                               search selection multiple clearable allowAdditions
                                               additionPosition='bottom'
                                               options={this.raceOptions} onAddItem={this.addRaceOption}
                                               value={racialDescriptions} onChange={this.changeRacialDescription}
                                               ga-on="click"
                                               ga-event-category="Form Field"
                                               ga-event-action="edit-runaway-race"/>
                            </Nullable>
                        )
                    }

                    }</Observer>

                    <Observer>{() => {
                        /** @type {string}*/
                        let ethnicDescription = this.runaway && this.runaway.ethnicDescription ? this.runaway.ethnicDescription : "";
                        if (ethnicDescription === 'NOT_PROVIDED') {
                            ethnicDescription = '';
                        }
                        return (
                            <Nullable value={this.runaway.ethnicDescription} onClick={this.changeEthnicDescription}
                                      ga-event-action="edit-runaway-ethnicity">
                                <Form.Input name='ethnicity' title='Ethnic Identity' width={12}
                                            label={
                                                <label>What national or ethnic identity is assigned to the runaway in
                                                    the ad? ex. American, Igbo, Creole, Native American&nbsp;
                                                    <Popup
                                                        wide='very'
                                                        trigger={
                                                            <Icon name='info circle'/>
                                                        }
                                                        content="If the self-liberating person had been brought to North America via the Atlantic slave trade, the enslaver might know or believe that they knew something about their specific African nation.  Likewise, some had been brought from the Caribbean or had Native American ancestry.  Understanding this information helps us learn more about enslaved people‘s diverse and complex backgrounds."
                                                    />
                                                </label>
                                            }
                                            value={ethnicDescription} onChange={this.changeEthnicDescription}
                                            ga-on="click"
                                            ga-event-category="Form Field"
                                            ga-event-action="edit-runaway-ethnicity"/>
                            </Nullable>
                        )
                    }

                    }</Observer>

                    <Divider section/>

                    <Observer>{() => {
                        /** @type {string}*/
                        let injuriesScars = this.runaway && this.runaway.injuriesScars ? this.runaway.injuriesScars : "";
                        if (injuriesScars === 'NOT_PROVIDED') {
                            injuriesScars = '';
                        }
                        return (
                            <Nullable value={this.runaway.injuriesScars} onClick={this.changeInjuriesScars}
                                      ga-event-action="edit-runaway-injuries-scars">
                                <Form.Input name='injuriesScars' title='Injuries or Scars' width={12}
                                            label={
                                                <label>If the runaway had any injuries or scars, list them here.&nbsp;
                                                    <Popup
                                                        wide='very'
                                                        trigger={
                                                            <Icon name='info circle'/>
                                                        }
                                                        content="Enslaved people often had many scars and sometimes the enslaver was the one who had inflicted them.  While this detail sometimes reflects incredible trauma, we think it is important to know and record the full truth about slavery’s violence."
                                                    />
                                                </label>
                                            }
                                            value={injuriesScars} onChange={this.changeInjuriesScars}
                                            ga-on="click"
                                            ga-event-category="Form Field"
                                            ga-event-action="edit-runaway-injuries-scars"/>
                            </Nullable>
                        )
                    }

                    }</Observer>

                    <Observer>{() => {
                        /** @type {string}*/
                        let selfPresentation = this.runaway && this.runaway.selfPresentation ? this.runaway.selfPresentation : "";
                        if (selfPresentation === 'NOT_PROVIDED') {
                            selfPresentation = '';
                        }
                        return (
                            <Nullable value={this.runaway.selfPresentation} onClick={this.changeSelfPresentation}
                                      ga-event-action="edit-runaway-self-presentation">
                                <Form.Input name='selfPresentation' title='Self Presentation' width={12}
                                            label={
                                                <label>How did the runaway present themselves?&nbsp;
                                                    <Popup
                                                        wide='very'
                                                        trigger={
                                                            <Icon name='info circle'/>
                                                        }
                                                        content="Sometimes the enslaver or other whites described self-liberating people’s interpersonal presentation. This gives us a chance to understand a little about how enslaved people used strategies to deceive enslavers, or to protect themselves, or to insist upon respect in the face of constant macroaggressions."
                                                    />
                                                </label>
                                            }
                                            value={selfPresentation} onChange={this.changeSelfPresentation}
                                            ga-on="click"
                                            ga-event-category="Form Field"
                                            ga-event-action="edit-runaway-self-presentation"/>
                            </Nullable>
                        )
                    }
                    }</Observer>

                    <Observer>
                        {() => {
                            return (
                                <Nullable value={this.runaway.possessions} onClick={this.changePossessions}
                                          ga-event-action="edit-runaway-possessions">
                                    <Form.TextArea title='Possessions' width={12}
                                                   label='List any possessions the runaway had including clothing:'
                                                   value={(this.runaway.possessions && this.runaway.possessions !== 'NOT_PROVIDED') ? this.runaway.possessions : ''}
                                                   onChange={this.changePossessions}
                                                   ga-on="click"
                                                   ga-event-category="Form Field"
                                                   ga-event-action="edit-runaway-possessions"/>
                                </Nullable>
                            )
                        }}
                    </Observer>

                    <Observer>{() => {
                        /** @type {string}*/
                        let otherPhysicalDescriptions = this.runaway && this.runaway.otherPhysicalDescriptions ? this.runaway.otherPhysicalDescriptions : "";
                        if (otherPhysicalDescriptions === 'NOT_PROVIDED') {
                            otherPhysicalDescriptions = '';
                        }
                        return (
                            <Nullable value={this.runaway.otherPhysicalDescriptions}
                                      onClick={this.changeOtherDescriptions}
                                      ga-event-action="edit-runaway-other-description">
                                <Form.Input title='Other Physical Descriptions' name='otherPhysicalDescriptions'
                                            width={12}
                                            label={
                                                <label>List any other physical descriptions of the runaway here.&nbsp;
                                                    <Popup
                                                        wide='very'
                                                        trigger={
                                                            <Icon name='help circle'/>
                                                        }
                                                        content="Literally anything you can glean from the ad is useful. Remember that we are recording details about people who tried to escape a system of violence and exploitation, and the more we know about the people, the more we can honor them."
                                                    />
                                                </label>
                                            }
                                            value={otherPhysicalDescriptions} onChange={this.changeOtherDescriptions}
                                            ga-on="click"
                                            ga-event-category="Form Field"
                                            ga-event-action="edit-runaway-other-description"/>
                            </Nullable>
                        )
                    }

                    }</Observer>
                </Form>
            </div>
        );
    }
}
