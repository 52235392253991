import {action, observable} from "mobx";
import Location from "./Location";
import {RunawayLocationType} from "../../types/RunawayLocationType";

class RunawayLocation {
    id: string;
    @observable runawayId: string;
    @observable type: RunawayLocationType;
    @observable reason: string;
    @observable location!: Location;

    constructor(json: any = {}) {
        this.id = json.id;
        this.runawayId = json.runawayId;
        this.type = json.type;
        this.reason = json.reason;

        if (json.location) {
            this.location = new Location(json.location);
        }
    }

    @action
    setRunawayId(runawayId: string) {
        this.runawayId = runawayId;
    }

    @action
    setType(type: RunawayLocationType) {
        this.type = type;
    }

    @action
    setReason(reason: string) {
        this.reason = reason;
    }

    @action
    setLocation(location: Object) {
        this.location = new Location(location);
    }

    isEmpty() {
        return !this.location || this.location.isEmpty()
    }

}

export default RunawayLocation;
