// @ts-nocheck
import * as React from "react";
import {SyntheticEvent} from "react";
import {observer} from "mobx-react";
import {Divider, Form, Header, InputProps, Message} from "semantic-ui-react";
import {userStore} from "../../../../stores/UserStore";
import Routes from "../../../../Routes";
import {Link, Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import ServerError from "../../../../components/ServerError";
import ConnectionError from "../../../../components/ConnectionError";
import {createLoader} from "../../../../stores/utils/Loader";
import auth from "../../../../services/authentication/Authenticator";

type IState = {
    email: string;
    password: string;
    error?: string;
}

export const LoginPage = withRouter(observer(class extends React.Component<RouteComponentProps, IState> {

    loader = createLoader();

    constructor(props: RouteComponentProps) {
        super(props);
        console.log(this.props);
        this.state = {
            email: "",
            password: ""
        };
    }

    handleEmailLogin = () => {
        let token = btoa(this.state.email + ":" + this.state.password);
        this.getToken("Basic", token);
        this.setState({password: ""});
    };

    handleGoogleLogin = (response: any) => {
        try {
            let auth2 = window.gapi.auth2.getAuthInstance();
            auth2.signOut();
        } catch (e) {
        }

        let token = response.getAuthResponse().id_token;
        this.getToken("google", token);
    };

    handleFacebookLogin = (response: any) => {
        console.debug(response);
        let token = response.authResponse.accessToken;
        this.getToken("facebook", token);
    };

    async getToken(method: 'Basic' | 'google' | 'facebook', token: string) {
        this.setState({error: ""});
        try {
            await this.loader.load(userStore.login(method, token));
        } catch (e) {
            if (e.response && e.response.data) {
                this.setState({error: e.response.data.message})
            }
        }
    }

    changeEmail = (e: SyntheticEvent, {value}: InputProps) => {
        this.setState({
            email: value
        })
    };

    changePassword = (e: SyntheticEvent, {value}: InputProps) => {
        this.setState({
            password: value
        })
    };

    render() {
        // @ts-ignore
        const {from} = this.props.location.state || {from: {pathname: Routes.home}};
        const loginSuccess = userStore.loginSuccess && auth.verifyToken(userStore.authToken);

        if (loginSuccess) {
            console.debug("Login Success");
            console.debug("FROM", from);
        }

        return (
            <div>
                {userStore.connectionError &&
                <ConnectionError/>
                }

                {userStore.serverError &&
                <ServerError/>
                }

                {!userStore.connectionError && !userStore.serverError &&
                (
                    loginSuccess ?
                        <Redirect to={from}/> :

                        <Form className='login-form' onSubmit={this.handleEmailLogin} loading={this.loader.showLoader}>
                            <Header as='h2'>Login</Header>
                            <Divider section/>

                            {/*minLength='3' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]"
            minLength='8' pattern="(?=.*\d)(?=.*[a-z]).{8,}"*/}

                            <Form.Input label='Email/Username' autoFocus required autoComplete='username'
                                        value={this.state.email} onChange={this.changeEmail}
                                        error={!!this.state.error}/>
                            <Form.Input label='Password' type='password' required autoComplete='current-password'
                                        value={this.state.password} onChange={this.changePassword}
                                        error={!!this.state.error}/>
                            <Form.Button primary fluid type='submit'>Login</Form.Button>
                            <div style={{marginBottom: 10}}>
                                <Link to={Routes.passwordReset}>Can't remember your password?</Link>
                            </div>
                            {this.state.error &&
                            <Message negative>{this.state.error}</Message>
                            }
                        </Form>
                )
                }

            </div>
        )
    }


}));
