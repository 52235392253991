import * as React from 'react';
import CopyToClipboard from "react-copy-to-clipboard";
import {Popup} from "semantic-ui-react";

type TState = {
    copied: boolean
}

export class ClickToCopy extends React.Component<any, TState> {
    state = {
        copied: false
    };

    onCopy = () => {
        this.setState({copied: true});
    };

    render() {
        const {text, label, children} = this.props;
        return (
            <Popup trigger={
                <CopyToClipboard text={text} onCopy={this.onCopy}>
                    {children}
                </CopyToClipboard>
            }>
                {this.state.copied ?
                    'Copied!'
                    :
                    label ? label : 'Copy'
                }
            </Popup>
        )
    }
}

export default ClickToCopy;