import * as React from 'react';
import {SyntheticEvent} from 'react';
import NLPText from "./NLPText";
import {observer} from "mobx-react";
import {uiStore} from "../stores/UIStore";
import {Button, Segment, TextArea, TextAreaProps} from "semantic-ui-react";
import {domainStore} from "../stores/CrowdsourcingStore";

@observer
export class TranscriptionText extends React.Component {
    state = {
        editing: false
    };

    handleEdit = () => {
        this.setState({editing: true});
    };

    handleSave = () => {
        this.setState({editing: false});
        domainStore.pushAdvertisementToServer();
        uiStore.setNLPTranscription({sentences: []});
        uiStore.getNLPTranscription();
    };

    /**
     * @param e
     * @param {string} transcription
     */
    handleTranscriptionChange = (e: SyntheticEvent, {value}: TextAreaProps) => {
        domainStore.advertisement!.setTranscription(value as string);
    };

    render() {
        const Key = (props: any) => {
            return <span style={{margin: 10}}><div className={props.className}
                                                   style={{
                                                       width: 16,
                                                       height: 16,
                                                       marginRight: 5,
                                                       display: 'inline-block'
                                                   }}/>
                {props.children}</span>;
        };

        let text = domainStore.advertisement ? domainStore.advertisement.transcription : "";
        const filename = domainStore.advertisement?.digitalObject?.filename;
        const institution = domainStore.advertisement?.digitalObject?.provenance?.institution?.name;

        return (
            <div>
                <Segment.Group>
                    <Segment>
                        {this.state.editing ?
                            <TextArea className='transcription-text' autoHeight value={text}
                                      onChange={this.handleTranscriptionChange}/>
                            :
                            <div className='transcription-text'>
                                {uiStore.nlpTranscription.sentences.length > 0 ?
                                    <div>
                                        <NLPText/>
                                        <div style={{marginTop: 20, textAlign: 'center'}}>
                                            <Key className='nlp-number'>Number</Key>
                                            <Key className='nlp-duration'>Duration</Key>
                                            <Key className='nlp-location'>Location</Key>
                                            <Key className='nlp-title'>Title</Key>
                                            <Key className='nlp-person'>Person</Key>
                                            <Key className='nlp-date'>Date</Key>
                                            <Key className='nlp-tag'>Other</Key>
                                        </div>
                                    </div>
                                    :
                                    text
                                }
                            </div>

                        }
                    </Segment>
                    {!filename && institution &&
                    <Segment>
                      SOURCE PROVIDED BY: {institution}
                    </Segment>
                    }
                </Segment.Group>
                {filename &&
                (
                    this.state.editing ?
                        <Button primary compact size='mini' floated='right' onClick={this.handleSave}>SAVE</Button>
                        :
                        <Button primary compact size='mini' floated='right' onClick={this.handleEdit}>EDIT</Button>
                )
                }
            </div>
        );
    }
}

export default TranscriptionText;