import * as React from 'react';
import {createLoader} from "../stores/utils/Loader";
import {List} from "semantic-ui-react";
import {observer} from "mobx-react";

// @ts-ignore
const ScrollLoader = observer(({as: ListComponent = List, onLoad, ...props}) => {
    const loader = createLoader();

    const onScroll = (e: any) => {
        const bottom = (e.target.scrollTop + e.target.clientHeight) / e.target.scrollHeight > 0.8;
        if (bottom && !loader.isLoading) {
            loader.load(onLoad());
        }
    };

    /**
     * Focuses the first anchor in the footer on escape
     */
    function onEscape({keyCode}: any) {
        if (keyCode === 27) {
            document.getElementsByTagName('footer')[0]?.getElementsByTagName('a')[0]?.focus();
        }
    }

    return (
        <ListComponent onScroll={onScroll} onKeyUp={onEscape} {...props}/>
    )
});

export default ScrollLoader;
