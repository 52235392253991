import {action, observable} from "mobx";

class Reward {
    id: string;
    @observable runawayId?: string;
    @observable amount?: number;
    @observable currency?: string;
    @observable criteria?: string;

    constructor(json: any = {}) {
        this.id = json.id;
        this.runawayId = json.runawayId;
        this.amount = json.amount;
        this.currency = json.currency;
        this.criteria = json.criteria;
    }

    @action
    setRunawayId(runawayId?: string) {
        this.runawayId = runawayId;
    }

    @action
    setAmount(amount?: number) {
        this.amount = amount;
    }

    @action
    setCurrency(currency?: string) {
        this.currency = currency;
    }

    @action
    setCriteria(criteria?: string) {
        this.criteria = criteria;
    }

    isEmpty() {
        return !this.amount || this.amount <= 0;
    }

}

export default Reward;
