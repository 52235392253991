import * as React from 'react';
import {observer} from "mobx-react";
import {domainStore} from "../../stores/CrowdsourcingStore";
import {uiStore} from "../../stores/UIStore";
import {ErrorBoundary} from "../../components/ErrorBoundary";
import AdvertiserForm from "./components/AdvertiserForm";
import {FormPage} from "../../components/FormPage";
import {Header} from "semantic-ui-react";

@observer
export class Advertiser extends React.Component {

  submitInfo = async () => {
    await domainStore.pushAdvertisementToServer();
    await domainStore.pushAdvertiserToServer();
    domainStore.updateAdvertisementProgress(30);
  };

  onSubmit = async () => {
    await this.submitInfo();
    uiStore.next();
  };

  previousPage = () => {
    this.submitInfo();
    uiStore.previous();
  };

  render() {
    return (
        <FormPage className='transcription-form'>
          <Header as='h1' color='orange'>ADVERTISER</Header>
          <ErrorBoundary>
            <AdvertiserForm onSubmit={this.onSubmit} previousPage={this.previousPage}/>
          </ErrorBoundary>

        </FormPage>
    );
  }
}

export default Advertiser;