import * as React from 'react';
import {CSSProperties} from 'react';
import MiddleAligned from "./MiddleAligned";
import {Card, CardProps} from "semantic-ui-react";

interface IProps extends CardProps {
    as?: any,
    style?: CSSProperties,
    children?: React.ReactNode
}

export const CardButton = ({as, style, children, ...props}: IProps) => {
    const element = as ? as : 'button';
    const cardStyle = Object.assign({alignItems: 'center'}, style);

    return (
        <Card link as={element} {...props} style={cardStyle}>
            <MiddleAligned>
                <Card.Content textAlign='center'>
                    {children}
                </Card.Content>
            </MiddleAligned>
        </Card>
    )
};

export default CardButton;