// @ts-nocheck
import {action, computed, observable, runInAction} from "mobx";
import Cookies from "js-cookie";
import auth from "../services/authentication/Authenticator";
import {api} from "../APICaller";
import User from "./models/User";
import {Loader} from "./utils/Loader";
import {config} from "../config";
import {options} from "../Constants";

class Store {
    @observable authToken?: string;
    @observable user: User;
    readonly transcriptionHistory = observable<any>([]);
    requestMoreHistory = true;
    readonly modQueue = observable<any>([]);
    requestMoreMod = true;

    @observable loginSuccess = false;
    @observable authError = false;
    @observable errorMessage = "";

    constructor() {
        this.user = new User();
    }

    loader = new Loader(500);

    @computed
    get connectionError() {
        return this.loader.failed
    }

    @computed
    get serverError() {
        return this.loader.hasError
    }

    @action
    setAuthToken(authToken: string) {
        this.authToken = authToken;
    }

    @action
    async login(method: string, token: string) {
        try {
            let {data} = await this.loader.load(auth.login(method, token));

            runInAction(() => {
                this.user = new User();
                this.user.setProfile(data.profile);
                this.user.setAuthToken(data.token);
                this.authToken = data.token;
                Cookies.set('auth_token', data.token);
                if (!this.user.id) {
                    this.user.setId(auth.parseJWT(data.token).id);
                }

                this.loginSuccess = true;
                this.authError = false;
            });

            const {data: userData} = await api.get(`/accounts/${this.user.id}`);
            runInAction(() => this.user = new User(userData));
            options.fetchAllFromServer();
        } catch (e) {
            runInAction(() => {
                if (e.response) {
                    if (e.response.status && e.response.status === 401) {
                        if (e.response.data) {
                            this.errorMessage = e.response.data.message;
                        }
                        this.authError = true;
                    }
                }
            });
            throw e;
        }
    }

    @action
    async createAccount(user: any) {
        try {
            let {data} = await this.loader.load(api.post(`/accounts`, user));
            runInAction(() => {
                this.user = new User(data);
                let token = btoa(user.emailAddress + ":" + user.password);
                this.authError = false;
                this.login('Basic', token);
            });
            return this.user;
        } catch (e) {
            if (e.response && e.response.status) {
                if (e.response.status === 400) {
                    runInAction(() => {
                        if (e.response.data) {
                            this.errorMessage = e.response.data.message;
                        }
                        this.authError = true;
                    });
                }
            }
            throw e;
        }
    }

    @action
    getUserFromToken() {
        if (this.authToken) {
            let token = auth.parseJWT(this.authToken);
            this.user = new User({
                id: token.id,
                emailAddress: token.email,
                authToken: this.authToken
            });
        }
    }

    @action
    async getProfile() {
        let {data} = await this.loader.load(api.get(`/accounts/${this.user.id}`));
        runInAction(() => this.user = new User(data));
    }

    @action
    async pushProfileToServer() {
        let {data} = await api.put(`/accounts/${this.user.id}`, userStore.user);
        runInAction(() => this.user = new User(data));
    }

    @action
    async getAdvertisements() {
        if (this.requestMoreHistory) {
            const offset = this.transcriptionHistory ? this.transcriptionHistory.length : 0;
            let {data} = await this.loader.load(api.get(`/accounts/${this.user.id}/notices?limit=10&offset=${offset}`));
            runInAction(() => {
                this.transcriptionHistory.replace(this.transcriptionHistory.concat(data));
                this.requestMoreHistory = data.length > 0;
            });
            return this.transcriptionHistory;
        }
    }

    @action
    async getModQueue() {
        if ((this.user.isMod || this.user.isAdmin) && this.requestMoreMod) {
            const offset = this.modQueue ? this.modQueue.length : 0;
            const {data} = await this.loader.load(api.get(`/${config.adPath}/mod?limit=12&offset=${offset}`));
            runInAction(() => {
                this.modQueue.replace(this.modQueue.concat(data));
                this.requestMoreMod = data.length > 0;
            });
            return this.modQueue;
        }
    }
}

export const userStore = new Store();