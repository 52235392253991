import * as React from "react";
import {Image, Menu, Responsive} from "semantic-ui-react";
import Routes from "../Routes";

export const Footer = () => {
    return (
        <footer>
            <Menu className='footer' borderless fluid stackable={false}>
                <Menu.Item><a target='_blank' rel='noopener noreferrer'
                              href='https://freedomonthemove.org#about'>About</a></Menu.Item>
                <Menu.Item><a target='_blank' rel='noopener noreferrer'
                              href='https://freedomonthemove.org#team'>Team</a></Menu.Item>
                <Menu.Item><a href={Routes.contact}>Contact</a></Menu.Item>
                <Menu.Item><a target='_blank' rel='noopener noreferrer'
                              href='https://freedomonthemove.org/privacy.html'>Privacy Policy</a></Menu.Item>
                <Menu.Item><a target='_blank' rel='noopener noreferrer' href='https://freedomonthemove.org/terms.html'>Terms
                    of Use</a></Menu.Item>

                <Responsive as={React.Fragment} minWidth={Responsive.onlyTablet.minWidth}>
                    <Menu.Item position='right'>
                        <Image className='cornell-logo'
                               alt="Cornell University"
                               title="Cornell University"
                               href="https://cornell.edu"
                               src={require("../img/cornell.png")}/>
                    </Menu.Item>

                    <Menu.Item>
                        <Image alt="University of Kentucky"
                               title="University of Kentucky"
                               href="http://www.uky.edu"
                               src={require("../img/kentucky.png")}/>
                    </Menu.Item>

                    <Menu.Item>
                        <Image alt="The University of Alabama"
                               title="The University of Alabama"
                               href="https://www.ua.edu/"
                               src={require("../img/alabama.png")}/>
                    </Menu.Item>

                    <Menu.Item>
                        <Image alt="The University of New Orleans"
                               title="The University of New Orleans"
                               href="http://new.uno.edu/"
                               src={require("../img/new_orleans.png")}/>
                    </Menu.Item>

                    <Menu.Item>
                        <Image className='osu-logo'
                               alt="The Ohio State University"
                               title="The Ohio State University"
                               href="https://www.osu.edu/"
                               src={require("../img/ohio_state.png")}/>
                    </Menu.Item>
                </Responsive>
            </Menu>
        </footer>
    );
};

export default Footer;
