import * as React from "react";
import {Component, SyntheticEvent} from "react";
import {Divider, DropdownProps, Form, InputProps} from "semantic-ui-react";
import {LocationField} from "../../../components/LocationField";
import {Observer, observer} from "mobx-react";
import {Selection} from "../../../../../components/Selection";
import {options} from "../../../../../Constants";
import {domainStore} from "../../../stores/CrowdsourcingStore";
import Enslaver from "../../../../../stores/models/Enslaver";

interface IProps {
    enslaver: Enslaver;
    onSubmit?: () => void;
}

@observer
export class EnslaverForm extends Component<IProps> {

    genderOptions = options.genders.toJS();
    typeOptions = options.enslaverTypes;

    constructor(props: IProps) {
        super(props);
        if (props.enslaver.runaways.length === 0) {
            props.enslaver.setRunaways(domainStore.runaways.map(r => r.id));
        }
    }

    changeFullname = (e: SyntheticEvent, {value}: InputProps) => {
        this.props.enslaver.setFullname(value);
    };


    changeType = (value: string) => {
        this.props.enslaver.setType(value !== this.props.enslaver.type ? value : undefined);
    };

    changeGender = (value: string) => {
        this.props.enslaver.setGender(value);
    };

    changeRunaways = (e: SyntheticEvent, {value}: DropdownProps) => {
        this.props.enslaver.setRunaways(value as string[]);
    };

    render() {
        const location = this.props.enslaver.location;
        const runawayOptions = domainStore.runaways.map((r, i) => {
            const name = r.enslavedPerson && r.enslavedPerson.name && r.enslavedPerson.name.fullname ? r.enslavedPerson.name.fullname : `Runaway #${i + 1}`;
            return {key: r.id, text: name, value: r.id}
        });
        const runaways = this.props.enslaver.runaways.toJS();

        return (
            <div>
                <Form as='div' className='left aligned' size='large' onSubmit={this.props.onSubmit}>
                    <Observer>{() => {
                        /** @type {string}*/
                        const fullname = this.props.enslaver.fullname ? this.props.enslaver.fullname : "";
                        return <Form.Input id='enslaver-fullname' label='Full Name (include titles and suffixes)'
                                           width={6} value={fullname} onChange={this.changeFullname}
                                           ga-on="click"
                                           ga-event-category="Form Field"
                                           ga-event-action="edit-enslaver-first-name"/>
                    }}</Observer>

                    <Form.Field>
                        <label>Based on the ad, do you think the enslaver was male or female?</label>
                        <Observer>{() =>
                            <Selection options={this.genderOptions} value={this.props.enslaver.gender} color='blue'
                                       onChange={this.changeGender}
                                       ga-on="click"
                                       ga-event-category="Form Field"
                                       ga-event-action="edit-enslaver-gender"/>
                        }</Observer>
                    </Form.Field>

                    <Form.Field>
                        <label>Type</label>
                        <Observer>{() => {
                            /** @type {string}*/
                            const type = this.props.enslaver.type ? this.props.enslaver.type : "";
                            return (
                                <Selection color='blue'
                                           value={type} options={this.typeOptions} onChange={this.changeType}
                                           ga-on="click"
                                           ga-event-category="Form Field"
                                           ga-event-action="edit-enslaver-type"/>
                            )
                        }}</Observer>
                    </Form.Field>

                    {domainStore.runaways.length > 1 &&
                    <Form.Dropdown id='enslaver-runaway' name='runaways'
                                   label="Which runaways are associated with this enslaver?"
                                   search selection multiple clearable options={runawayOptions}
                                   value={runaways} onChange={this.changeRunaways}/>
                    }

                    <Divider section/>

                    <Form.Field>
                        <label>Where was the enslaver from?</label>
                        <LocationField location={location}/>
                    </Form.Field>
                </Form>

            </div>
        )
    }
}

export default EnslaverForm;