import * as React from "react";
import {Button, Card} from "semantic-ui-react";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {Routes} from "../../../Routes";
import moment from "moment";
import {AdImage} from "../../Crowdsourcing/components/AdImage";
import Advertisement from "../../../stores/models/Advertisement";

interface IProps extends RouteComponentProps {
    data: Advertisement;
}

export const ModQueueItem = withRouter((props: IProps) => {
  const data = props.data;
  const advertisement = data;
  const digitalObject = advertisement.digitalObject;
  const newspaper = advertisement.newspaper;
  const location = newspaper ? newspaper.location : null;
  const state = location ? location.state : null;
  const locationName = location ? (location.city ? location.city : location.county) : undefined;

  const dateFormat = data.dateModified ? moment(data.dateModified).format('M/D/YYYY h:mm A') : "";

  return (
      <Card fluid className='advertisement'>
        <div style={{maxHeight: '120px', overflow: 'hidden'}}>
          {digitalObject.filename ?
              <AdImage fluid src={digitalObject.filename}/>
              :
              <Card.Description>
                {advertisement.transcription}
              </Card.Description>
          }
        </div>
        <Card.Content>

          {newspaper !== null ?
              <Card.Header>
                <Link to={`/advertisements/${advertisement.id}`}>
                  {newspaper.name}
                </Link>
              </Card.Header> :
              <Card.Header>No Name</Card.Header>
          }
          <Card.Meta>
            {locationName && <span>{locationName}, </span>}
            {state}
          </Card.Meta>
          <Card.Meta>ID: {advertisement.id}</Card.Meta>
        </Card.Content>

        <Card.Content extra>
          {dateFormat}
          <Button primary
                  onClick={() => props.history.push(`${Routes.crowdsourcing}/${advertisement.id}?mod=true`)}
                  style={{float: 'right'}}>REVIEW</Button>
        </Card.Content>
      </Card>
  )
});

export default ModQueueItem;