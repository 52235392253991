import * as React from "react";
import {SyntheticEvent} from "react";
import {Input, InputProps} from "semantic-ui-react"

interface HeightInputProps {
    value?: number;
    width?: number;
    onChange: (value: number) => void;
}

export const HeightInput = ({value = 0, width = 250, onChange, ...props}: HeightInputProps) => {

    const changeHeightFeet = (feet: string) => {
        const num = feet === '' ? 0 : parseInt(feet, 10);
        const height = num * 12 + value % 12;
        onChange(height);
    };

    const changeHeightInches = (inches: string) => {
        const num = inches === '' ? 0 : parseInt(inches, 10);
        const height = (value / 12 | 0) * 12 + num;
        onChange(height);
    };

    const handleFeetChange = (e: SyntheticEvent, {value}: InputProps) => changeHeightFeet(value);
    const handleInchesChange = (e: SyntheticEvent, {value}: InputProps) => changeHeightInches(value);

    return (
        <Input name='minHeight' type='number' style={{width: width, height: 40}} {...props}>
            <Input name='minHeight' value={value / 12 | 0} type='number' fluid labelPosition='right'
                   style={{width: width / 2}}
                   min={0}
                   onChange={handleFeetChange} label={{basic: true, content: 'ft.'}}/>
            <Input name='minHeight' value={value % 12} type='number' fluid labelPosition='right'
                   style={{width: width / 2}}
                   min={0}
                   onChange={handleInchesChange} label={{basic: true, content: 'in.'}}/>
        </Input>
    );
};