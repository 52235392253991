import * as React from "react";
import {LoginPage} from "../Login/LoginPage";
import {CreateAccountPage} from "../CreateAccount/CreateAccountPage";

interface IProps {
    type: 'register' | 'login'
}

export default class AuthPage extends React.Component<IProps, IProps> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            type: props.type
        }
    }

    render() {
        if (this.state.type === 'register') {

            return (
                <div style={{margin: 50, textAlign: 'center'}}>
                    <CreateAccountPage/>
                    <div style={{marginTop: '20px', fontSize: '16px'}}>
                        <button className='link-button' onClick={() => this.setState({type: 'login'})}>
                            Already have an account? Login!
                        </button>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{margin: 50, textAlign: 'center'}}>
                    <LoginPage/>
                    <div style={{marginTop: '20px', fontSize: '16px'}}>
                        <button className='link-button' onClick={() => this.setState({type: 'register'})}>
                            Don't have an account? Sign up!
                        </button>
                    </div>
                </div>
            )
        }
    }
}
