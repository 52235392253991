import * as React from 'react';
import {Button, Header, Image, Modal, ModalProps, Segment} from "semantic-ui-react";
import Cookies from "js-cookie";

class Tutorial extends React.Component<ModalProps> {
    showTutorial = !Cookies.get('help_modal');
    state = {
        open: this.showTutorial,
        page: 0
    };

    open = () => this.setState({open: true});

    close = () => {
        if (this.showTutorial) {
            Cookies.set('help_modal', 'true');
        }

        this.setState({open: false});
    };

    nextPage = () => {
        this.setState({page: this.state.page + 1});
    };

    previousPage = () => {
        this.setState({page: this.state.page - 1});
    };

    pages = [
        {
            image: require('../../../../img/transcription_tutorial.png'),
            text: 'Carefully transcribe the advertisement on the left side of the page.'
        },
        {
            image: require('../../../../img/event_tutorial.png'),
            text: 'Continue working your way through each question, filling out as much information as possible.'
        },
        {
            image: require('../../../../img/runaway_name_tutorial.png'),
            text: 'List all the runaways mentioned in the ad. Careful, in addition to listing multiple runaways, some advertisements also list multiple names for a single runaway.'
        },
        {
            image: require('../../../../img/runaway_tutorial.png'),
            text: 'If a field isn\'t provided in the advertisement, mark it as "Not Provided". If you\'re not sure or the field doesn\'t have a "Not Provided" button, just leave the field blank.'
        },
        {
            image: require('../../../../img/runaway_tutorial_2.png'),
            text: 'You may find some information to be important but not know what field it should fit into. Just do your best to figure out what seems most appropriate given the context.'
        }
    ];

    render() {
        const {image, text} = this.pages[this.state.page];
        return (
            <Modal size='fullscreen' closeIcon centered
                   open={this.state.open}
                   onOpen={this.open}
                   onClose={this.close}
                   trigger={this.props.trigger}>

                <Modal.Header as='h2' size='large'>How to Contribute</Modal.Header>
                <Modal.Content>
                    <Segment basic textAlign='center'>
                        <Image style={{maxHeight: '75vh'}} centered src={image}/>
                        <Header size='huge'>{text}</Header>
                        <Segment basic>
                            {this.state.page > 0 &&
                            <Button size='large' onClick={this.previousPage}>PREVIOUS</Button>
                            }
                            {this.state.page < this.pages.length - 1 ?
                                <Button primary size='large' onClick={this.nextPage} autoFocus>NEXT</Button>
                                :
                                <Button primary size='large' onClick={this.close}>START CONTRIBUTING</Button>
                            }
                        </Segment>
                    </Segment>
                </Modal.Content>
            </Modal>
        )
    }
}

export default Tutorial;