import * as React from "react";
import {observer} from "mobx-react";
import {Button, Container, Form, Header, Message} from "semantic-ui-react";
import Routes from "../../../../Routes";
import {withRouter} from "react-router-dom";
import {api} from "../../../../APICaller";
import {userStore} from "../../../../stores/UserStore";

export const ChangePassword = withRouter(observer(class extends React.Component {

  constructor(props) {
    super(props);
    this.history = props.history;

    this.state = {
      currentPassword: "",
      newPassword: "",
      requestSuccess: false,
      requestError: false,
      errorMessage: ""
    };

    userStore.getUserFromToken();
    userStore.getProfile();
  }

  changePassword = () => {
    let token = btoa(userStore.user.emailAddress + ":" + this.state.currentPassword);
    api.changePassword("Basic", token,
        {password: this.state.newPassword},
        () => {
          this.setState({requestSuccess: true});
          this.setState({requestError: false});
        },
        ({response}) => {
          this.setState({requestSuccess: false});
          this.setState({requestError: true});
          this.setState({errorMessage: response.status === 401 ? "Incorrect password supplied." : null});
        }
    );
    this.setState({password: ""});
  };

  render() {
    return (
        <div>
          <Container>
            {!this.state.requestSuccess ?
                <div>
                  <Form className='login-form' onSubmit={this.changePassword}>
                    <Form.Input label='Current Password' type='password' value={this.state.currentPassword}
                                onChange={(e, {value}) => this.setState({currentPassword: value})}/>
                    <Form.Input label='New Password' type='password' value={this.state.newPassword}
                                onChange={(e, {value}) => this.setState({newPassword: value})}/>
                    <Form.Button primary type='submit'>Submit</Form.Button>
                    <Form.Button primary onClick={() => this.history.push(Routes.settings)}>Back</Form.Button>
                  </Form>
                  {this.state.requestError && this.state.errorMessage &&
                    <Message negative>{this.state.errorMessage}</Message>
                  }
                  {this.state.requestError && !this.state.errorMessage &&
                  <Message negative>An unknown error occurred. Try reloading the page or contact us if the problem persists.</Message>
                  }
                </div>
                :
                <div style={{textAlign: 'center', marginTop: '20%'}}>
                  <Header>Password successfully changed!</Header>
                  <Button primary onClick={() => this.history.push(Routes.settings)}>Return to Settings</Button>
                </div>
            }
          </Container>
        </div>
    )
  }
}));