import Location from "./Location";
import {action, observable} from "mobx";

class Newspaper {
    id: string;
    @observable name?: string;
    @observable locationId?: string;
    @observable location!: Location;

    constructor(json: any = {}) {
        this.id = json.id;
        this.name = json.name;
        this.locationId = json.locationId;
        if (json.location) {
            this.location = new Location(json.location);
        }
    }

    @action
    setName(name?: string) {
        this.name = name;
    }

    @action
    setLocationId(locationId?: string) {
        this.locationId = locationId;
    }

    @action
    setLocation(location: Location) {
        this.location = new Location(location);
    }
}

export default Newspaper;
