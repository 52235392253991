// @ts-nocheck
import jwt_decode from "jwt-decode"
import Cookies from "js-cookie"
import {api} from "../../APICaller";
import {config} from "../../config";
import User from "../../stores/models/User";

type TToken = {
    id: string;
    email: string;
    exp: number;
}

export const Authenticator = {
    parseJWT: (jwt: string) => {
        return jwt_decode<TToken>(jwt);
    },

    verifyToken: (jwt?: string) => {
        if (config.fakeApi) {
            return true;
        }
        if (!jwt) {
            return false;
        }
        let data = jwt_decode<TToken>(jwt);
        let exp = data.exp;

        let date = new Date(exp * 1000);
        let now = new Date();
        return date > now;
    },

    login: async (method: string, token: string) => {
        return api.login(method, token);
    },

    logout: (user: User) => {
        if (user) {
            delete user.authToken;
        }

        try {
            const auth2 = window.gapi.auth2.getAuthInstance();
            auth2.signOut();
        } catch (e) {
        }

        Cookies.remove('auth_token');
    }
};

export default Authenticator;