import * as React from "react";
import {Button, Container, Header, Segment} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import Routes from "../../../../Routes";

export const Finish = withRouter(({history}) => {
    return (
        <Container style={{textAlign: 'center', padding: '100px'}}>
            <Segment basic size='massive'>
                <Header as='h1' style={{marginTop: '50px', paddingLeft: '2.5em', paddingRight: '2.5em'}}>
                    Thank you!
                </Header>
                <Segment basic size='massive' style={{lineHeight: 'normal'}}>
                    Thank you for participating in Freedom on the Move. Because of your efforts, and those of other
                    contributors, we’re able to make thousands of stories of self-liberating people available to the
                    public.
                </Segment>
            </Segment>
            <Segment basic padded='very'>
                <Button primary size='huge' onClick={() => {
                    history.push(Routes.crowdsourcing);
                    window.location.reload();
                }}>Start Another Transcription</Button>
                <Button primary size='huge' onClick={() => {
                    history.push(Routes.home);
                    window.location.reload();
                }}>Return to Homepage</Button>
            </Segment>
        </Container>
    );
});

export default Finish;