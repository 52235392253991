import {action, computed, observable} from "mobx";
import Runaway from "./Runaway";

class RunawayRelationship {
    id: string;
    @observable leftRunawayId: string;
    @observable rightRunawayId: string;
    @observable leftKinship: string;
    @observable rightKinship: string;
    leftRunaway?: Runaway;
    rightRunaway?: Runaway;

    constructor(json: any) {
        this.id = json.id;
        this.leftRunawayId = json.leftRunawayId;
        this.rightRunawayId = json.rightRunawayId;
        this.leftKinship = json.leftKinship;
        this.rightKinship = json.rightKinship;
    }

    @computed
    get isValid() {
        return this.leftRunawayId && this.rightRunawayId && this.leftKinship && this.rightKinship;
    }

    @action
    setLeftRunawayId(leftRunawayId: string) {
        this.leftRunawayId = leftRunawayId;
    }

    @action
    setRightRunawayId(rightRunawayId: string) {
        this.rightRunawayId = rightRunawayId;
    }

    @action
    setLeftKinship(leftKinship: string) {
        this.leftKinship = leftKinship;
    }

    @action
    setRightKinship(rightKinship: string) {
        this.rightKinship = rightKinship;
    }

    setLeftRunaway(leftRunaway?: Runaway) {
        this.leftRunaway = leftRunaway;
    }

    setRightRunaway(rightRunaway?: Runaway) {
        this.rightRunaway = rightRunaway;
    }

}

export default RunawayRelationship
