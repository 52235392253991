import {observer} from "mobx-react";
import {uiStore} from "../../../../../stores/UIStore";
import {Form, Icon, Message} from "semantic-ui-react";
import * as React from "react";
import {domainStore} from "../../../../../stores/CrowdsourcingStore";
import RunawayRelationshipModel from "../../../../../../../stores/models/RunawayRelationship";
import {Loader} from "../../../../../../../stores/utils/Loader";

interface IProps {
    relationship: RunawayRelationshipModel;
    loader: Loader;
}

export const RunawayRelationship = observer((props: IProps) => {
    let r = props.relationship;
    let loader = props.loader;
    let runawayOptions = domainStore.runaways.map((r, i) => {
        let name = r.enslavedPerson && r.enslavedPerson.names[0] ? r.enslavedPerson.names[0].fullname : '';
        name = name ? name : `Runaway #${i + 1}`;
        return {key: uiStore.getKey(), text: name, value: r.id}
    });

    const setLeft = (relationship: RunawayRelationshipModel, value: string) => {
        relationship.setLeftRunawayId(value);
        if (value && domainStore.runaways.length === 2) {
            relationship.setRightRunawayId(domainStore.runaways.filter(r => r.id !== value)[0].id)
        }
    };

    const setRight = (relationship: RunawayRelationshipModel, value: string) => {
        relationship.setRightRunawayId(value);
    };

    const setLeftKinship = (relationship: RunawayRelationshipModel, value: string) => {
        relationship.setLeftKinship(value);
    };

    const setRightKinship = (relationship: RunawayRelationshipModel, value: string) => {
        relationship.setRightKinship(value);
    };

    let reverseRelationship = (relationship: RunawayRelationshipModel) => {
        let left = relationship.leftRunawayId;
        let right = relationship.rightRunawayId;
        relationship.setLeftRunawayId(right);
        relationship.setRightRunawayId(left);
    };

    return (
        <Form error={loader.clientError && !r.isValid}>
            <Form.Group>
                <Form.Field>
                    <Form.Dropdown search selection clearable options={runawayOptions} value={r.leftRunawayId}
                                   error={loader.clientError && !r.leftRunawayId}
                                   onChange={(e, {value}) => setLeft(r, value as string)}
                                   ga-on="click"
                                   ga-event-category="Form Field"
                                   ga-event-action="edit-runaway-relationship-left"/>
                    <Form.Input placeholder='ex. Mother' value={r.leftKinship}
                                error={loader.clientError && !r.leftKinship}
                                onChange={(e, {value}) => setLeftKinship(r, value)}
                                ga-on="click"
                                ga-event-category="Form Field"
                                ga-event-action="edit-runaway-relationship-left-kinship"/>
                </Form.Field>

                <button className='link-button' onClick={() => reverseRelationship(r)}><Icon name='exchange'
                                                                                             size='large'/></button>

                <Form.Field>
                    <Form.Dropdown required search selection clearable placeholder='other'
                                   error={loader.clientError && !r.rightRunawayId}
                                   options={runawayOptions.filter(o => o.value !== r.leftRunawayId)}
                                   value={r.rightRunawayId}
                                   onChange={(e, {value}) => setRight(r, value as string)}
                                   ga-on="click"
                                   ga-event-category="Form Field"
                                   ga-event-action="edit-runaway-relationship-right"/>
                    <Form.Input required placeholder='ex. Son' value={r.rightKinship}
                                error={loader.clientError && !r.rightKinship}
                                onChange={(e, {value}) => setRightKinship(r, value)}
                                ga-on="click"
                                ga-event-category="Form Field"
                                ga-event-action="edit-runaway-relationship-right-kinship"/>
                </Form.Field>
            </Form.Group>
            <Message error content='Please complete all relationship fields.' size='mini'/>
        </Form>
    )
});

export default RunawayRelationship;