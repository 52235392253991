import * as React from 'react';
import {Grid, Header, SemanticWIDTHS} from "semantic-ui-react";

interface IProps extends React.ComponentProps<any> {
    data?: any;
    widths?: SemanticWIDTHS[];
    keyCase?: 'upper' | 'lower';
    showUndefined?: boolean;
}

export const DescriptionList = ({data = {}, widths =  [5, 11], keyCase = 'upper', showUndefined = true, ...props}: IProps) => {

    let getLabel = (key: string) => {
        let title = key.replace(/([A-Z])/g, " $1");
        if (keyCase === 'upper') {
            title = title.toUpperCase();
        } else if (keyCase === 'lower') {
            title = title.toLowerCase();
        }

        return title;
    };

    return (
        <Grid className='description' {...props}>
            {Object.keys(data).map(key => {
                    const label = getLabel(key);
                    const value = data[key] ? data[key] + '' : '';
                    if(!showUndefined && data[key] === undefined) {
                        return null;
                    }

                    return (
                        <Grid.Row key={key}>
                            <Grid.Column width={widths[0]}>
                                <Header size='small'>{label}:</Header>
                            </Grid.Column>
                            <Grid.Column width={widths[1]}>
                                {value}
                            </Grid.Column>
                        </Grid.Row>
                    )
                }
            )}
        </Grid>
    )
};