// @ts-nocheck
import {action, computed, observable} from 'mobx';
import * as Sentry from "@sentry/browser";
import {userStore} from "../UserStore";
import Authenticator from "../../services/authentication/Authenticator";

const STATES = {
    INITIAL: 'initial',
    LOADING: 'loading',
    ERROR: 'error',
    CLIENT_ERROR: 'client_error',
    UNAUTHENTICATED: 'unauthenticated',
    LOADED: 'loaded',
    FAILED: 'failed'
};

// loading object
class Loader {
    @observable state = STATES.INITIAL;
    timeout = 0;
    timer?: NodeJS.Timeout;
    @observable timeoutDone = false;
    @observable status = 0;

    constructor(timeout = 0) {
        this.timeout = timeout;
    }

    @action
    completeTimeout() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timeoutDone = false;
        if (this.timeout > 0) {
            this.timer = setTimeout(action(() => this.timeoutDone = true), this.timeout);
        } else {
            this.timeoutDone = true;
        }
    }

    @computed
    get showLoader() {
        return this.timeoutDone && this.isLoading;
    }

    @computed
    get isLoading() {
        return this.state === STATES.LOADING;
    }

    @computed
    get isLoaded() {
        return this.state === STATES.LOADED;
    }

    @computed
    get hasError() {
        return this.state === STATES.ERROR;
    }

    @computed
    get clientError() {
        return this.state === STATES.CLIENT_ERROR;
    }

    @computed
    get unauthenticated() {
        return this.state === STATES.UNAUTHENTICATED;
    }

    @computed
    get failed() {
        return this.state === STATES.FAILED;
    }

    @computed
    get complete() {
        return this.state === STATES.LOADED;
    }

    @action
    updateState(state: string) {
        this.state = state;
    }

    @action
    updateStatus(status: number) {
        this.status = status;
    }

    sendSentry(e: any, level: Sentry.Severity) {
        Sentry.withScope(scope => {
            let user = Object.assign({}, userStore.user);
            delete user.authToken;
            delete user.reputation;
            scope.setUser(user);

            scope.setLevel(level);
            let response = e.response;
            scope.setExtra("response", response);
            if (response) {
                scope.setFingerprint([response.status, response.config.method, response.config.url]);
            }
            Sentry.captureException(e);
        });
    }

    async load(promise: Promise<any>) {
        let {LOADING, ERROR, CLIENT_ERROR, UNAUTHENTICATED, LOADED, FAILED} = STATES;
        try {
            this.updateState(LOADING);
            this.completeTimeout();
            let data = await promise;
            this.updateState(LOADED);
            return data;
        } catch (e) {
            if (e.response) {
                console.debug('RESPONSE', e.response);
                if (e.response.status) {
                    this.updateStatus(e.response.status);
                }
                if (e.response.status && e.response.status === 401) {
                    console.debug('LOGOUT');
                    this.updateState(UNAUTHENTICATED);
                    Authenticator.logout(userStore.user);
                } else if (e.response.status && e.response.status < 500) {
                    this.updateState(CLIENT_ERROR);
                } else {
                    this.updateState(ERROR);
                    this.sendSentry(e, Sentry.Severity.Error);
                }
            } else {
                this.updateState(FAILED);
                this.sendSentry(e, Sentry.Severity.Critical);
            }
            throw e;
        }
    }
}

let createLoader = action((timeout: number = 500) => {
    return new Loader(timeout)
});

export {Loader, STATES, createLoader};