import * as React from 'react';
import {Button, ButtonProps, SemanticSIZES} from "semantic-ui-react";
import AdvertisementLanguageOption from "../types/AdvertisementLanguageOption";

interface IProps {
    language?: AdvertisementLanguageOption;
    size?: SemanticSIZES;
    onClick?: (e: React.MouseEvent, props: ButtonProps) => void;
}

class ForeignCharacters extends React.Component<IProps> {
    characters = {
        french: ['à', 'â', 'æ', 'ç', 'é', 'è', 'ê', 'ë', 'ï', 'î', 'ô', 'œ', 'ù', 'û', 'ü', 'ÿ'],
        spanish: ['á', 'é', 'í', 'ñ', 'ó', 'ú', 'ü', '¿', '¡'],
        english: []
    };

    state = {
        upperCase: false
    };

    toggleCase = () => {
        this.setState({upperCase: !this.state.upperCase});
    };

    render() {
        const language = this.props.language ? this.props.language.toLowerCase() as AdvertisementLanguageOption : this.props.language;
        let chSet = language && this.characters[language] ? this.characters[language] : [];
        if (this.state.upperCase) {
            chSet = chSet.map(c => c.toUpperCase());
        }

        if (chSet.length === 0) {
            return <div/>;
        }

        return (
            <div>
                <Button type='button' icon='long arrow alternate up' size='large' toggle title='Upper Case'
                        active={this.state.upperCase}
                        onClick={this.toggleCase}/>
                <Button.Group size={this.props.size ? this.props.size : 'large'}>
                    {chSet.map(c => <Button key={c} name={c} type='button' onClick={this.props.onClick}>{c}</Button>)}
                </Button.Group>
            </div>
        )
    }
}

export default ForeignCharacters;
