import {Loader} from "semantic-ui-react";
import {domainStore} from "../stores/CrowdsourcingStore";
import ServerError from "../../../components/ServerError";
import ConnectionError from "../../../components/ConnectionError";
import * as React from "react";
import {ErrorInfo} from "react";
import {observer} from "mobx-react";
import * as Sentry from "@sentry/browser";
import Routes from "../../../Routes";
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import {Loader as PromiseLoader} from "../../../stores/utils/Loader";

interface IProps extends RouteComponentProps {
    loader?: PromiseLoader;
}

interface IState {
    error?: Error;
}

export const ErrorBoundary = withRouter(observer(class extends React.Component<IProps, IState> {
    loader = this.props.loader;
    state = {
        error: undefined
    };

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({error});
        Sentry.withScope(scope => {
            Object.values(errorInfo).forEach((key, value) => {
                scope.setExtra(key, value);
            });
            Sentry.captureException(error);
        })
    }

    showLoader = () => {
        return this.loader ? this.loader.showLoader : domainStore.loading;
    };

    isLoading = () => {
        return this.loader ? this.loader.isLoading : domainStore.loading;
    };

    serverError = () => {
        return this.loader ? this.loader.hasError : domainStore.serverError;
    };

    connectionError = () => {
        return this.loader ? this.loader.failed : domainStore.connectionError;
    };

    unauthenticated = () => {
        return this.loader ? this.loader.unauthenticated : domainStore.unauthenticated;
    };

    render() {
        return (
            <React.Fragment>
                <Loader active={this.isLoading()}/>
                {(this.serverError() || this.state.error) &&
                <ServerError/>
                }

                {this.connectionError() &&
                <ConnectionError/>
                }

                {this.unauthenticated() &&
                <Redirect to={{
                    pathname: Routes.login,
                    state: {from: this.props.location}
                }}/>
                }

                {!this.isLoading() && !this.serverError() && !this.connectionError() && !this.state.error &&
                this.props.children
                }
            </React.Fragment>
        )
    }
}));