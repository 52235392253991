import {action, observable, runInAction} from 'mobx';
import Advertisement from "../../../../stores/models/Advertisement";
import {api} from "../../../../APICaller";
import {userStore} from "../../../../stores/UserStore";
import User from "../../../../stores/models/User";

class Collection {
    id: string;
    @observable name: string;
    @observable description: string;
    @observable accountId: string;
    @observable public?: boolean;
    readonly advertisements = observable<Advertisement>([]);
    account!: User;
    changed = false;

    constructor(json: any = {}) {
        this.id = json.id;
        this.name = json.name;
        this.description = json.description;
        this.accountId = json.accountId;
        this.public = json.public;
        if (json.advertisements) {
            this.advertisements.replace(json.advertisements.map((ad: any) => new Advertisement(ad)))
        }

        if (json.account) {
            this.account = new User(json.account);
        }
    }

    @action
    setName(name: string) {
        if (name !== this.name) {
            this.name = name;
            this.changed = true;
        }
    }

    @action
    setDescription(description: string) {
        if (description !== this.description) {
            this.description = description;
            this.changed = true;
        }
    }

    @action
    setPublic(_public?: boolean) {
        if (_public !== this.public) {
            this.public = _public;
            this.changed = true;
        }
    }

    async pushUpdate() {
        await api.put(`collections/${this.id}`, this);
    }

    @action
    async addAdvertisement(advertisement: Advertisement) {
        try {
            await api.put(`collections/${this.id}/${advertisement.id}`, {});
            runInAction(() => this.advertisements.push(advertisement));
        } catch (e) {
        }
    }

    async removeAdvertisement(advertisement: Advertisement) {
        try {
            await api.delete(`collections/${this.id}/${advertisement.id}`);
            const i = this.advertisements.findIndex(ad => ad.id === advertisement.id);
            runInAction(() => this.advertisements.splice(i, 1));
        } catch (e) {
        }
    }

    containsAdvertisement(id: string) {
        return !!this.advertisements.find(ad => ad.id === id);
    }

    get isOwned() {
        return this.accountId === userStore.user.id;
    }
}

export default Collection;
