import * as React from "react";
import {Component, SyntheticEvent} from "react";
import {Divider, Form, InputProps} from "semantic-ui-react";
import {LocationField} from "../../../components/LocationField";
import {domainStore} from "../../../stores/CrowdsourcingStore";
import {Observer, observer} from "mobx-react";
import {Selection} from "../../../../../components/Selection";
import {options} from "../../../../../Constants";

interface IProps {
  onSubmit: () => void;
  previousPage: () => void;
}

@observer
export class AdvertiserForm extends Component<IProps> {
    types = options.adTypes;

    changeFullName = (e: SyntheticEvent, {value}: InputProps) => {
        domainStore.advertiser!.setFullName(value);
    };

    handleTypeChange = (type: string) => {
        domainStore.advertisement!.setType(type);
    };

    render() {
        const advertiser = domainStore.advertiser!;
        const location = advertiser.location;

        return (
            <div>
                <Form className='left aligned' size='large' onSubmit={this.props.onSubmit}>
                    <Observer>{() =>
                        <Form.Field>
                            <label htmlFor="transcription-type-select">Was the ad posted by the enslaver, jailer, or
                                someone
                                else?</label>
                            <Selection id='transcription-type-select' options={this.types}
                                       value={domainStore.advertisement!.type} color='blue'
                                       onChange={this.handleTypeChange}
                                       ga-event-action="edit-advertisement-type"/>
                        </Form.Field>}
                    </Observer>

                    {domainStore.advertisement!.type && domainStore.advertisement!.type !== 'ENSLAVER' && domainStore.advertisement!.type !== 'NOT_PROVIDED' &&
                    <React.Fragment>
                      <Observer>{() =>
                          <Form.Input id='advertiser-fullname' label='Full Name' width={5} value={advertiser.name}
                                      onChange={this.changeFullName}
                                      ga-on="click"
                                      ga-event-category="Form Field"
                                      ga-event-action="edit-advertiser-name"/>
                      }</Observer>

                      <Divider section/>

                      <Form.Field>
                        <label>Where was the advertiser located?</label>
                        <LocationField location={location}/>
                      </Form.Field>
                    </React.Fragment>
                    }

                    <Divider style={{marginBottom: 20, marginTop: 40}}/>

                    <Form.Group>
                        <Form.Button className='form-button' size='large' type='button'
                                     onClick={this.props.previousPage}
                                     disabled={domainStore.loader.isLoading}
                                     ga-on="click"
                                     ga-event-category="Navigation"
                                     ga-event-action="advertiser-previous">PREVIOUS</Form.Button>
                        <Form.Button className='form-button' primary size='large' type='submit'
                                     disabled={domainStore.loader.isLoading}
                                     ga-on="click"
                                     ga-event-category="Navigation"
                                     ga-event-action="advertiser-next">NEXT</Form.Button>
                    </Form.Group>
                </Form>

            </div>
        )
    }
}

export default AdvertiserForm;