import * as React from "react";
import {Button, Card, Form, Progress} from "semantic-ui-react";
import {Link, withRouter} from "react-router-dom";
import {Routes} from "../../../../../Routes";
import moment from "moment";
import {AdImage} from "../../../../Crowdsourcing/components/AdImage";

export const TranscribeHistoryItem = withRouter((props) => {
  let data = props.data;
  let advertisement = data.advertisement;
  let digitalObject = advertisement.digitalObject;
  let newspaper = advertisement.newspaper;
  let location = newspaper !== null ? newspaper.location : null;
  let state = location !== null ? location.state : null;
  let locationName = location.city ? location.city : location.county;

  let dateFormat = moment(data.time).format('M/D/YYYY h:mm A');

  let progress = data.progress ? data.progress : 0;

  let editPage = 1;
  if (data.progress < 15) {
    editPage = 1;
  } else if (data.progress < 30) {
    editPage = 2;
  } else if (data.progress < 40) {
    editPage = 3;
  } else if (data.progress < 90) {
    editPage = 4;
  } else if (data.progress < 100) {
    editPage = 5;
  }

  let getProgressColor = (progress) => {
    if (progress < 40) {
      return "red";
    } else if (progress >= 40 && progress < 100) {
      return "orange";
    } else {
      return "green";
    }
  };

  return (

      <Card fluid className='square-border'>
        <div style={{maxHeight: '120px', overflow: 'hidden'}}>
          <AdImage fluid src={digitalObject.filename}/>
        </div>
        <Card.Content>
          {newspaper !== null ?
              <Card.Header>
                <Link to={`/advertisements/${advertisement.id}`}>
                {newspaper.name}
                </Link>
              </Card.Header> :
              <Card.Header>No Name</Card.Header>
          }
          <Card.Meta>
            {locationName && <span>{locationName}, </span>}
            {state}
          </Card.Meta>
          <Card.Meta>ID: {advertisement.id}</Card.Meta>
          <Card.Description>
            {advertisement.transcription}
          </Card.Description>

        </Card.Content>

        <Card.Content extra>
          <div style={{paddingBottom: '.75em'}}>{dateFormat}</div>
          {
            advertisement.complete ?
                <span style={{color: 'green'}}>Complete!</span>
                :
                <Form>
                  <Form.Group style={{marginBottom: 0}}>
                    <Form.Field width={16}>
                      <Progress value={progress} total='100' progress='percent'
                                color={getProgressColor(progress)}/>
                    </Form.Field>
                    <Form.Field>
                      <Button primary size='tiny'
                              onClick={() => props.history.push(Routes.crowdsourcing + "/" + advertisement.id + "?section=" + editPage)}>Edit</Button>
                    </Form.Field>
                  </Form.Group>
                </Form>
          }
        </Card.Content>
      </Card>
  )
});

export default TranscribeHistoryItem;