import React from 'react';
import {Link} from "react-router-dom";
import {Card, Container, Divider, Grid, Image, List, Message} from "semantic-ui-react";

import banner from "../../img/teachers/educator-banner.png";
import hhpLogo from "../../img/teachers/hhp.png";
import Routes from "../../Routes";

import "./styles.scss";

const cardSize = "48%";

const Teachers = () => {

    return (
        <Container id='content' className='educators'>
            <Divider hidden/>
            <Image src={banner} alt="Teaching Strategies for K-12 Educators"/>
            <Divider hidden/>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <div className="ui center aligned grid">
                                    <Image src={hhpLogo} style={{width: "400px"}} alt="In Collaboration with Hard History Project"/>
                                </div>
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <div style={{paddingTop: 0, marginTop: 0, paddingBottom: 30}} className="ui center aligned grid ">
                                    <a className="column" href="https://thehardhistoryproject.com" target="_blank" rel="noopener noreferrer">thehardhistoryproject.com</a>
                                </div>
                            </Grid.Column>
                        </Grid.Row>

                        <Message color='orange' size='large'>
                            <Message.Header><strong>Want to Learn More?</strong></Message.Header>
                            <p>We periodically host webinars for educators and other interested parties. Please fill out the <a href="https://cornell.qualtrics.com/jfe/form/SV_b4xBuzPNW7vqySp"
                                                                                                                                target="_blank" rel="noopener noreferrer">this short survey</a> to be the
                                first to know when the next webinar takes place.</p>
                            <p>Did you miss our first webinar? You can watch the recording on <a href="https://vimeo.com/437205532" target="_blank" rel="noopener noreferrer">Vimeo</a>.</p>
                        </Message>

                        <div style={{marginTop: 30}}>
                            <p>
                                <em><strong>About the Freedom on the Move Project:</strong></em><br/><br/>
                                Freedom on the Move is a searchable database of fugitives from North American slavery. On our site, students have access to thousands of stories of resistance that have
                                never been accessible in one place.
                                There are endless possibilities for using the database in your classroom, but to make things easy, we’ve created four lessons you can use to bring the ads to life and
                                introduce students to the brave people who resisted slavery by running away.
                            </p>
                            <hr/>
                            <p style={{marginTop: 16}}>
                                <em><strong>A note about our lessons:</strong></em><br/><br/>
                                Each teacher, school and district has a unique way of writing and formatting lessons. We appreciate that diversity. These lessons were written by real teachers in real
                                classrooms, and the style of each lesson reflects each teacher’s practice and expertise. Although these were written for a specific classroom and grade level, they can
                                be easily adapted for your class and grade.
                                <br/><br/>
                            </p>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Divider hidden/>

            <Grid style={{paddingBottom: "30px"}}>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <h1>Available Lessons</h1>
                        <Card.Group itemsPerRow={4}>

                            <Card style={{width: cardSize}}>
                                <div className="ui embed">
                                    <video style={{width: "100%"}} autoPlay loop muted playsInline>
                                        <source src="https://static.freedomonthemove.org/educators/previews/ahmariahjackson.mp4" type="video/mp4"/>
                                    </video>
                                </div>
                                <Card.Content style={{maxHeight: "100em"}}>
                                    <Link to={Routes.Lesson.replace(":lesson", "the-lions-side")}>
                                        <Card.Header className='center aligned' style={{marginBottom: 7}}>The Lion’s Side: Creating a Resistance Newspaper</Card.Header>
                                    </Link>
                                    <Card.Meta className='center aligned' style={{marginBottom: 14, color: "#000"}}>
                                        <span>Presented by Ahmariah Jackson</span>
                                    </Card.Meta>
                                    <hr/>
                                    <Card.Description style={{marginTop: 14, color: "#000"}}>
                                        <div className="center aligned" style={{marginBottom: 10}}><em>“Until the lion learns how to write, every story will glorify the hunter.” - J. Nozipo
                                            Maraire</em></div>
                                        In this lesson, students tell the untold stories of those who
                                        escaped slavery, highlighting the resistance and resilience of the enslaved. Students transcribe ads from the Freedom on the Move database, then create a
                                        resistance
                                        newspaper that
                                        documents stories of people who escaped slavery. Students rewrite runaway ads from the perspective of someone celebrating their escape instead of the
                                        perspective of the
                                        enslaver. The lesson culminates in a student resistance newspaper and an opportunity for students to create a piece of art, poem, narrative, song or rap
                                        that
                                        tells the
                                        story of an enslaved person they have come to know in their ad.
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra className="center aligned" style={{color: "#000"}}>
                                    History, Language Arts &amp; Creative Writing Classes<br/>Adaptable for Grades 6-12.
                                </Card.Content>
                            </Card>

                            <Card style={{width: cardSize}}>
                                <div className="ui embed">
                                    <video style={{width: "100%"}} autoPlay loop muted playsInline>
                                        <source src="https://static.freedomonthemove.org/educators/previews/heatheringram.mp4" type="video/mp4"/>
                                    </video>
                                </div>
                                <Card.Content style={{maxHeight: "100em"}}>
                                    <Link to={Routes.Lesson.replace(":lesson", "telling-their-stories")}>
                                        <Card.Header className='center aligned' style={{marginBottom: 7}}>Telling Their Stories: Using Runaway Ads to Write Narratives</Card.Header>
                                    </Link>
                                    <Card.Meta className='center aligned' style={{marginBottom: 14, color: "#000"}}>
                                        <span>Presented by Heather Ingram</span>
                                    </Card.Meta>
                                    <hr/>
                                    <Card.Description style={{marginTop: 14, color: "#000"}}>
                                        This lesson guides students through the era of enslavement through the lens of those who chose to flee slavery as a form of resistance. Students complete an
                                        image analysis
                                        of the runaway poster for Harriet Jacobs then read excerpts from her narrative, Incidents in the Life of a Slave Girl. Finally, exploring the FOTM database
                                        of runaway ads, students are tasked with writing a historical narrative that gives voice to the voiceless.
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra className="center aligned" style={{color: "#000"}}>
                                    History, Language Arts &amp; Psychology Classes<br/>Adaptable for Grades 5-12.
                                </Card.Content>
                            </Card>

                            <Card style={{width: cardSize}}>
                                <div className="ui embed">
                                    <video style={{width: "100%"}} autoPlay loop muted playsInline>
                                        <source src="https://static.freedomonthemove.org/educators/previews/coradavis.mp4" type="video/mp4"/>
                                    </video>
                                </div>
                                <Card.Content style={{maxHeight: "100em"}}>
                                    <Link to={Routes.Lesson.replace(":lesson", "traveling-back")}>
                                        <Card.Header className='center aligned' style={{marginBottom: 7}}>Traveling Back: Building a Living Timeline of Pre-Civil War Slavery</Card.Header>
                                    </Link>
                                    <Card.Meta className='center aligned' style={{marginBottom: 14, color: "#000"}}>
                                        <span>Presented by Cora Davis</span>
                                    </Card.Meta>
                                    <hr/>
                                    <Card.Description style={{marginTop: 14, color: "#000"}}>
                                        In this lesson, students read and analyze runaway ads written from 1850 to 1860. Students learn about important historical events, people and literature
                                        from
                                        those years
                                        and build a timeline in their classroom to create a fuller, more well-rounded picture of slavery and how these individuals’ lives played into the big
                                        picture.
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra className="center aligned" style={{color: "#000"}}>
                                    History &amp; Language Arts Classes<br/>Adaptable for Grades 3-12.
                                </Card.Content>
                            </Card>

                            <Card style={{width: cardSize}}>
                                <div className="ui embed">
                                    <video style={{width: "100%"}} autoPlay loop muted playsInline>
                                        <source src="https://static.freedomonthemove.org/educators/previews/willduke.mp4" type="video/mp4"/>
                                    </video>
                                </div>
                                <Card.Content style={{maxHeight: "100em"}}>
                                    <Link to={Routes.Lesson.replace(":lesson", "who-is-a-historian")}>
                                        <Card.Header className='center aligned' style={{marginBottom: 7}}>Who Is a Historian? Answering Tough Questions Using Primary Sources</Card.Header>
                                    </Link>
                                    <Card.Meta className='center aligned' style={{marginBottom: 14, color: "#000"}}>
                                        <span>Presented by Will Duke</span>
                                    </Card.Meta>
                                    <hr/>
                                    <Card.Description style={{marginTop: 14, color: "#000"}}>
                                        What does a historian do? How can we use primary documents to answer questions and give an accurate account of history? In this lesson, students answer
                                        these
                                        questions by transcribing runaway ads from Freedom on the Move. Using only ads from the database, students are tasked with answering the question, “What
                                        factors
                                        contributed to the value of the return of an enslaved person in the mid-19th century?” As they answer the question, they also draw conclusions about the
                                        lives
                                        of
                                        enslaved people and the system of slavery.
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra className="center aligned" style={{color: "#000"}}>
                                    History Classes<br/>Adaptable for Grades 3-12.
                                </Card.Content>
                            </Card>

                            <Card style={{width: cardSize}}>
                                <Card.Content style={{maxHeight: "100em"}}>
                                    <Link to={Routes.Lesson.replace(":lesson", "rewriting-the-narrative")}>
                                        <Card.Header className='center aligned' style={{marginBottom: 7}}>Rewriting the Narrative</Card.Header>
                                    </Link>
                                    <Card.Meta className='center aligned' style={{marginBottom: 14, color: "#000"}}>
                                        <span>Presented by Kristin Marconi & Christine Snivley</span>
                                    </Card.Meta>
                                    <hr/>
                                    <Card.Description style={{marginTop: 14, color: "#000"}}>
                                        Primary source documents are incredibly helpful and effective for students of all ages. Using a standard form for each primary source throughout the year allows students to become accustomed to examining documents, photographs, speeches, and political cartoons with a critical eye. It also allows students to recognize the value of these sources and readies them to use them to answer compelling and complex questions that require historical records in addition to secondary source material.
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra className="center aligned" style={{color: "#000"}}>
                                    History Classes<br/>Targeting 8th Grade
                                </Card.Content>
                            </Card>

                            <Card style={{width: cardSize}}>
                                <Card.Content style={{maxHeight: "100em"}}>
                                    <Link to={Routes.Lesson.replace(":lesson", "lord-dunmore-proclamation")}>
                                        <Card.Header className='center aligned' style={{marginBottom: 7}}>Analyzing the impact of Lord Dunmore’s Proclamation</Card.Header>
                                    </Link>
                                    <Card.Meta className='center aligned' style={{marginBottom: 14, color: "#000"}}>
                                        <span>Presented by Suzanne Perlis</span>
                                    </Card.Meta>
                                    <hr/>
                                    <Card.Description style={{marginTop: 14, color: "#000"}}>
                                        During the Revolutionary War, freedom, liberty, and equality were words frequently heard in the British colonies. When enslaved people heard the promise, “All men are created equal.” Many dreamed of a country where these Enlightenment ideals might be realized.<br /><br />
                                        Students will use primary and secondary sources to analyze the impact of Lord Dunmore’s proclamation on the enslaved communities of Colonial America. The students will analyze ads from the Freedom on the Move website to learn more about the people who self-liberated during the American Revolution, joining in the fight on both the British and American sides of the conflict.
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra className="center aligned" style={{color: "#000"}}>
                                    History Classes<br/>Adaptable for Grades 6-8.
                                </Card.Content>
                            </Card>

                            <Card style={{width: cardSize}}>
                                <Card.Content style={{maxHeight: "100em"}}>
                                    <Link to={Routes.Lesson.replace(":lesson", "the-deplorable-entanglement")}>
                                        <Card.Header className='center aligned' style={{marginBottom: 7}}>The “Deplorable Entanglement” of Slavery</Card.Header>
                                    </Link>
                                    <Card.Meta className='center aligned' style={{marginBottom: 14, color: "#000"}}>
                                        <span>Presented by Suzanne Perlis</span>
                                    </Card.Meta>
                                    <hr/>
                                    <Card.Description style={{marginTop: 14, color: "#000"}}>
                                        In this lesson, students will examine maps, photographs, newspapers articles, and documents to answer the essential question regarding the contradiction between Jefferson’s moral view of slavery and his actions towards the institution. Through a step-by-step process, students will examine the primary sources and develop a thesis statement arguing whether Jefferson was against slavery or supported slavery.
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra className="center aligned" style={{color: "#000"}}>
                                    History Classes<br/>Adaptable for Grades 6-8.
                                </Card.Content>
                            </Card>

                            <Card style={{width: cardSize}}>
                                <Card.Content style={{maxHeight: "100em"}}>
                                    <Link to={Routes.Lesson.replace(":lesson", "analyzing-primary-sources")}>
                                        <Card.Header className='center aligned' style={{marginBottom: 7}}>Analyzing Primary Sources</Card.Header>
                                    </Link>
                                    <Card.Meta className='center aligned' style={{marginBottom: 14, color: "#000"}}>
                                        <span>Presented by KB Tucker</span>
                                    </Card.Meta>
                                    <hr/>
                                    <Card.Description style={{marginTop: 14, color: "#000"}}>
                                        Primary source documents are incredibly helpful and effective for students of all ages. Using a standard form for each primary source throughout the year allows students to become accustomed to examining documents, photographs, speeches, and political cartoons with a critical eye. It also allows students to recognize the value of these sources and readies them to use them to answer compelling and complex questions that require historical records in addition to secondary source material.
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra className="center aligned" style={{color: "#000"}}>
                                    History Classes<br/>Adaptable for Grades 7-8.
                                </Card.Content>
                            </Card>

                            <Card style={{width: cardSize}}>
                                <Card.Content style={{maxHeight: "100em"}}>
                                    <Link to={Routes.Lesson.replace(":lesson", "ad-analysis")}>
                                        <Card.Header className='center aligned' style={{marginBottom: 7}}>Ad Analysis</Card.Header>
                                    </Link>
                                    <Card.Meta className='center aligned' style={{marginBottom: 14, color: "#000"}}>
                                        <span>Presented by Nicholson</span>
                                    </Card.Meta>
                                    <hr/>

                                </Card.Content>
                                <Card.Content extra className="center aligned" style={{color: "#000"}}>
                                    History Classes
                                </Card.Content>
                            </Card>

                        </Card.Group>
                    </Grid.Column>

                </Grid.Row>
            </Grid>

            <hr/>

            <Grid style={{paddingTop: "30px", paddingBottom: "30px"}}>
                <Grid.Row>
                    <h1>Alignment to Teaching Tolerance’s <a href="https://www.tolerance.org/frameworks/teaching-hard-history/american-slavery" target="_blank" rel="noopener noreferrer">Teaching Hard
                        History Framework</a></h1>
                    <Grid.Column width={16}>
                        <p>Most students leave high school without an adequate understanding of the role slavery played in the development of the United States—or how its legacies still influence us
                            today. In an effort to remedy this, Teaching Tolerance developed a comprehensive guide for teaching and learning slavery at all grade levels. Freedom on the Move’s
                            classroom strategies are each aligned to the Teaching Hard History standards. </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <hr/>

            <Grid style={{paddingTop: "30px"}}>
                <Grid.Row>
                    <h1>Additional Resources for Educators</h1>
                    <Grid.Column width={16}>
                        <List as='ul'>
                            <List.Item as='li'><a href="https://www.tolerance.org/frameworks/teaching-hard-history/american-slavery" target="_blank" rel="noopener noreferrer">Teaching Tolerance’s
                                Teaching Hard History Framework </a></List.Item>

                            <List.Item as='li'><a href="https://www.tolerance.org/podcasts/teaching-hard-history/american-slavery" target="_blank" rel="noopener noreferrer">Teaching Tolerance’s
                                Teaching Hard History Podcast</a></List.Item>

                            <List.Item as='li'><a href="https://glc.yale.edu/DigitalResources" target="_blank" rel="noopener noreferrer">The Gilder Lehrman Center for the Study of Slavery, Resistance,
                                and Abolition</a></List.Item>

                            <List.Item as='li'><a href="https://www.gilderlehrman.org/collection" target="_blank" rel="noopener noreferrer">The Gilder Lehrman Institute of American
                                History</a></List.Item>

                            <List.Item as='li'><a href="https://guides.loc.gov/slavery-in-america" target="_blank" rel="noopener noreferrer">Library of Congress, Slavery in America</a></List.Item>

                            <List.Item as='li'><a href="https://docsouth.unc.edu/neh/" target="_blank" rel="noopener noreferrer">Documenting the American South, The North American Slave Narratives</a></List.Item>

                            <List.Item as='li'><a href="https://www.tolerance.org/frameworks/teaching-hard-history/american-slavery/about-the-project/teaching-hard-history-online-archives-databases"
                                                  target="_blank" rel="noopener noreferrer">Other Online Archives and Databases</a></List.Item>
                        </List>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </Container>
    )
};

export default Teachers;