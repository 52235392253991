class Language {
    id: string;
    name: string;
    iso_639: string;

    constructor(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.iso_639 = json.iso_639;
    }
}

export default Language;
