import * as React from 'react';
import {SyntheticEvent} from 'react';
import {Form, Icon, InputProps, Popup, Search, SearchResultData, SearchResultProps} from 'semantic-ui-react'
import {Observer, observer} from "mobx-react";
import {options} from "../../../Constants";
import {uiStore} from "../stores/UIStore";
import {api} from "../../../APICaller";
import bodyBuilder from "bodybuilder";
import {observable, runInAction} from "mobx";
import Location from "../../../stores/models/Location";
import {DropdownItemProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";

interface IProps {
    location: Location;
    autoSelectCountry?: boolean;
}

@observer
export class LocationField extends React.Component<IProps> {
    nameResults = observable([]);
    cityResults = observable([]);
    countyResults = observable([]);
    id = uiStore.getKey();

    searchLocations = async (key: string, value?: string) => {
        let query = bodyBuilder().orQuery("match_phrase_prefix", key, value).orQuery("fuzzy", key, value).build();
        let {data} = await api.put('/locations/search', query);
        return data.hits.hits;
    };

    searchNames = async (value?: string) => {
        let results = await this.searchLocations("name", value);
        console.debug(results);
        runInAction(() => this.nameResults.replace(results));
    };

    searchCities = async (value?: string) => {
        let results = await this.searchLocations("city", value);
        runInAction(() => this.cityResults.replace(results));
    };

    searchCounties = async (value?: string) => {
        let results = await this.searchLocations("county", value);
        runInAction(() => this.countyResults.replace(results));
    };

    changeName = (value?: string) => {
        this.props.location.setName(value);
        this.searchNames(value);
    };

    changeCity = (value?: string) => {
        this.props.location.setCity(value);
        this.searchCities(value);
    };

    changeCounty = (value?: string) => {
        this.props.location.setCounty(value);
        this.searchCounties(value);
    };

    changeState = (value?: string) => {
        this.props.location.setState(value);
        if (value && this.props.autoSelectCountry !== false) {
            this.props.location.setCountry(value.substring(0, 2));
        }
    };

    changeCountry = (value: string) => {
        this.props.location.setCountry(value);
    };

    addNameOption = (e: SyntheticEvent, {value}: InputProps) => {
        uiStore.nlpOptions.addLocation(value);
    };

    selectName = (e: SyntheticEvent, {result}: SearchResultData) => {
        let {name, city, county, state, country} = result;
        if (name) this.props.location.setName(name);
        if (city) this.props.location.setCity(city);
        if (county) this.props.location.setCounty(county);
        if (state) this.changeState(state);
        if (country) this.props.location.setCountry(country);
    };

    selectCity = (e: SyntheticEvent, {result}: SearchResultData) => {
        let {city, county, state, country} = result;
        if (city) this.props.location.setCity(city);
        if (county) this.props.location.setCounty(county);
        if (state) this.changeState(state);
        if (country) this.props.location.setCountry(country);
    };

    selectCounty = (e: SyntheticEvent, {result}: SearchResultData) => {
        let {county, state, country} = result;
        if (county) this.props.location.setCounty(county);
        if (state) this.changeState(state);
        if (country) this.props.location.setCountry(country);
    };

    formatNameResult = ({name, city, county, state, country}: SearchResultProps) => {
        let result = name;
        if (city) result += `, ${city}`;
        if (county) result += `, ${county}`;
        if (state) result += `, ${state.substring(3)}`;
        if (country) result += `, ${country}`;
        return <div>{result}</div>
    };

    formatCityResult = ({city, county, state, country}: SearchResultProps) => {
        let result = city;
        if (county) result += `, ${county}`;
        if (state) result += `, ${state.substring(3)}`;
        if (country) result += `, ${country}`;
        return <div>{result}</div>
    };

    formatCountyResult = ({county, state, country}: SearchResultProps) => {
        let result = county;
        if (state) result += `, ${state.substring(3)}`;
        if (country) result += `, ${country}`;
        return <div>{result}</div>
    };

    searchStates(items: DropdownItemProps[], value: string): DropdownItemProps[] {
        return options.states.filter(state => {
            return state.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
                || state.iso3166_2.toLowerCase().indexOf(value.toLowerCase()) >= 0;
        }).sort((a, b) => {     // Sorts results putting us states firs and then prioritizing names that start with the value
            const aUS = a.iso3166_2.startsWith(`US-${value.toUpperCase()}`);
            const bUS = b.iso3166_2.startsWith(`US-${value.toUpperCase()}`);
            const aStart = a.name.toLowerCase().startsWith(value);
            const bStart = b.name.toLowerCase().startsWith(value);
            if (aUS && !bUS) return -1;
            if (bUS && !aUS) return 1;
            if (aStart && !bStart) return -1;
            if (bStart && !aStart) return 1;
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        })
            .map(state => {
                return {key: state.iso3166_2, value: state.iso3166_2, text: state.name}
            });
    }

    render() {
        const {location} = this.props;

        return (
            <Form.Field>
                <Form.Group>
                    <Observer>{() => {
                        let name = location && location.name ? location.name : "";
                        /*let nameOptions = uiStore.nlpOptions.locations.map(n => {
                          return {key: uiStore.getKey(), value: n, text: n}
                        });*/

                        let nameOptions = this.nameResults.map((result: any) => Object.assign({
                            key: result._source.id,
                            title: result._source.name
                        }, result._source));
                        uiStore.nlpOptions.locations.forEach(n => {
                            nameOptions.push({key: uiStore.getKey(), title: n, name: n})
                        });

                        return (
                            <Form.Field width={6}>
                                <label htmlFor={`location-name-${this.id}`}>Location Name/Address&nbsp;
                                    <Popup
                                        wide='very'
                                        trigger={
                                            <Icon name='help circle'/>
                                        }
                                        content="This could be anything from an address to the name of a local church."
                                    />
                                </label>
                                <Search id={`location-name-${this.id}`} icon={<Icon/>} showNoResults={false} fluid
                                        minCharacters={0}
                                        value={name}
                                        results={nameOptions}
                                        onSearchChange={(e, {name, value}) => this.changeName(value)}
                                        onResultSelect={this.selectName}
                                        resultRenderer={this.formatNameResult}
                                        ga-on="click"
                                        ga-event-category="Form Field"
                                        ga-event-action="edit-location-name"/>
                            </Form.Field>
                        );
                    }}</Observer>
                </Form.Group>
                <Form.Group>
                    <Observer>{() => {
                        let city = location && location.city ? location.city : "";
                        let cityOptions = this.cityResults.map((result: any) => Object.assign({
                            key: result._source.id,
                            title: result._source.city
                        }, result._source));

                        return (
                            <Form.Field width={6}>
                                <label htmlFor={`location-city-${this.id}`}>City</label>
                                <Search id={`location-city-${this.id}`} icon={<Icon/>} showNoResults={false} fluid
                                        value={city}
                                        results={cityOptions}
                                        onSearchChange={(e, {name, value}) => this.changeCity(value)}
                                        onResultSelect={this.selectCity}
                                        resultRenderer={this.formatCityResult}
                                        ga-on="click"
                                        ga-event-category="Form Field"
                                        ga-event-action="edit-location-city"/>
                            </Form.Field>
                        )
                    }}</Observer>

                    <Observer>{() => {
                        let county = location && location.county ? location.county : "";
                        let countyOptions = this.countyResults.map((result: any) => Object.assign({
                            key: result._source.id,
                            title: result._source.county
                        }, result._source));
                        return (
                            <Form.Field width={6}>
                                <label htmlFor={`location-county-${this.id}`}>County/Parish</label>
                                <Search id={`location-county-${this.id}`} icon={<Icon/>} showNoResults={false} fluid
                                        value={county}
                                        results={countyOptions}
                                        onSearchChange={(e, {name, value}) => this.changeCounty(value)}
                                        onResultSelect={this.selectCounty}
                                        resultRenderer={this.formatCountyResult}
                                        ga-on="click"
                                        ga-event-category="Form Field"
                                        ga-event-action="edit-location-county"/>
                            </Form.Field>
                        )
                    }}</Observer>
                    <Observer>{() => {
                        const state = location && location.state ? location.state : "";
                        const stateOptions = options.dropdownStates.map(state => {
                            return {key: state.iso3166_2, value: state.iso3166_2, text: state.name}
                        });
                        return <Form.Dropdown id={`location-state-${this.id}`} name='state' label='State/Colony'
                                              width={4} options={stateOptions}
                                              search={this.searchStates} selection clearable
                                              onChange={(e, {name, value}) => {
                                                  this.changeState(value as string);
                                                  options.addDropdownState(value as string);
                                              }}
                                              value={state} style={{minWidth: '0 !important'}}
                                              ga-on="click"
                                              ga-event-category="Form Field"
                                              ga-event-action="edit-location-state"/>
                    }}</Observer>
                </Form.Group>
                <Observer>{() => {
                    let country = location && location.country ? location.country : "";
                    let countryOptions = options.countries.map(country => {
                        return {key: country.iso3166_2, value: country.iso3166_2, text: country.name}
                    });
                    return <Form.Dropdown id={`location-country-${this.id}`} name='country' label='Country' width={16}
                                          options={countryOptions}
                                          search selection clearable
                                          onChange={(e, {name, value}) => this.changeCountry(value as string)}
                                          value={country} style={{minWidth: '0 !important'}}
                                          ga-on="click"
                                          ga-event-category="Form Field"
                                          ga-event-action="edit-location-country"/>
                }}</Observer>
            </Form.Field>
        );
    }
}

export default LocationField;