export interface Rule {
  field: string;
  operator: string;
  value: any;
}

export interface ComplexRule {
  condition: string;
  rules: (Rule | ComplexRule)[]
}

export let createComplexRule = (condition: string, rules: (Rule | ComplexRule)[]): ComplexRule => {
    return {
        condition: condition,
        rules: rules
    }
};

export let createRule = (field: string, operator: string, value?: any): Rule => {
    return {
        field: field,
        operator: operator,
        value: value
    }
};
