import {api} from "./APICaller";
import {action, computed, observable, runInAction} from "mobx";
import Language from "./stores/models/Language";

class Constants {

    @observable ready = false;
    readonly races = observable(['negro', 'black', 'very black', 'mulatto', 'light mulatto', 'mustee', 'yellow', 'griffe', 'quadroon', 'octoroon', 'white passing', 'unclear']);
    adTypes = [
        {key: 'ENSLAVER', text: 'Enslaver', value: 'ENSLAVER'},
        {key: 'JAILER', text: 'Jailer', value: 'JAILER'},
        {key: 'AGENT', text: 'Someone Else', value: 'AGENT'},
        {key: 'NOT_PROVIDED', text: 'Not Provided', value: 'NOT_PROVIDED'}
    ];

    readonly genders = observable([
        {key: 'MALE', text: 'Male', value: 'MALE'},
        {key: 'FEMALE', text: 'Female', value: 'FEMALE'},
        {key: 'OTHER', text: 'Other', value: 'OTHER'},
        {key: 'NOT_PROVIDED', text: 'Not Provided', value: 'NOT_PROVIDED'}
    ]);

    enslaverTypes = [
        {key: 'current', text: 'Current', value: 'current'},
        {key: 'former', text: 'Former', value: 'former'},
        {key: 'deceased', text: 'Deceased', value: 'deceased'},
        {key: 'alleged', text: 'Alleged', value: 'alleged'},
        {key: 'estate', text: 'Estate', value: 'estate'},
        {key: 'NOT_PROVIDED', text: 'Not Provided', value: 'NOT_PROVIDED'},
    ];

    runawayLocations = [
        {key: "possibleDestination", value: "possibleDestination", text: "Possible Destination"},
        {key: "ran", value: "ran", text: "Ran Away From"},
        {key: "spotted", value: "spotted", text: "Spotted At"},
        {key: "caught", value: "caught", text: "Caught In"},
        {key: "jailed", value: "jailed", text: "Jailed In"},
        {key: "placeOfOrigin", value: "placeOfOrigin", text: "Place of Origin / Birthplace"},
        {key: "returnTo", value: "returnTo", text: "If Captured, Return To"},
        {key: "stolenFrom", value: "stolenFrom", text: "Stolen from"},
        {key: "other", value: "other", text: "Other"}
    ];

    readonly languages = observable<Language>([]);

    searchTables = [
        {
            key: 1,
            name: 'Advertisement',
            fields: [
                {
                    name: 'ID',
                    value: 'ADVERTISEMENT_ID'
                },
                {
                    name: 'Transcription',
                    value: 'ADVERTISEMENT_TRANSCRIPTION'
                },
                {
                    name: 'Type',
                    value: 'ADVERTISEMENT_TYPE'
                },
                {
                    name: 'Page Number',
                    value: 'ADVERTISEMENT_PAGE_NUMBER'
                },
                {
                    name: 'Publication Date',
                    value: 'ADVERTISEMENT_PUBLICATION_DATE'
                },
                {
                    name: 'Digital Object ID',
                    value: 'ADVERTISEMENT_DIGITAL_OBJECT_ID'
                },
                {
                    name: 'Language ID',
                    value: 'ADVERTISEMENT_LANGUAGE_ID'
                },
                {
                    name: 'Advertiser ID',
                    value: 'ADVERTISEMENT_ADVERTISER_ID'
                },
                {
                    name: 'Newspaper ID',
                    value: 'ADVERTISEMENT_NEWSPAPER_ID'
                },
                {
                    name: 'Date Modified',
                    value: 'ADVERTISEMENT_DATE_MODIFIED'
                },
                {
                    name: 'Complete',
                    value: 'ADVERTISEMENT_COMPLETE'
                }
            ]
        },
        {
            key: 2,
            name: 'AccountToAdvertisement',
            fields: [
                {
                    name: 'ID',
                    value: 'ACCOUNT_TO_ADVERTISEMENT_ID'
                },
                {
                    name: 'Account ID',
                    value: 'ACCOUNT_TO_ADVERTISEMENT_ACCOUNT_ID'
                },
                {
                    name: 'Advertisement ID',
                    value: 'ACCOUNT_TO_ADVERTISEMENT_ADVERTISEMENT_ID'
                },
                {
                    name: 'Progress',
                    value: 'ACCOUNT_TO_ADVERTISEMENT_PROGRESS'
                }
            ]
        },
        {
            key: 3,
            name: 'Advertiser',
            fields: [
                {
                    name: 'ID',
                    value: 'ADVERTISER_ID'
                },
                {
                    name: 'Name',
                    value: 'ADVERTISER_NAME'
                },
                {
                    name: 'Location ID',
                    value: 'ADVERTISER_LOCATION_ID'
                },
            ]
        },
        {
            key: 4,
            name: 'Digital Object',
            fields: [
                {
                    name: 'ID',
                    value: 'DIGITAL_OBJECT_ID'
                },
                {
                    name: 'Filename',
                    value: 'DIGITAL_OBJECT_FILENAME'
                },
                {
                    name: 'Filetype',
                    value: 'DIGITAL_OBJECT_FILETYPE'
                },
                {
                    name: 'Checksum',
                    value: 'DIGITAL_OBJECT_CHECKSUM'
                },
            ]
        },
        {
            key: 5,
            name: 'Enslaved Person',
            fields: [
                {
                    name: 'ID',
                    value: 'ENSLAVED_PERSON_ID'
                },
                {
                    name: 'Gender',
                    value: 'ENSLAVED_PERSON_GENDER'
                },
                {
                    name: 'Min Birth Year',
                    value: 'ENSLAVED_PERSON_MIN_BIRTH_YEAR'
                },
                {
                    name: 'Max Birth Year',
                    value: 'ENSLAVED_PERSON_MAX_BIRTH_YEAR'
                },
                {
                    name: 'Literacy',
                    value: 'ENSLAVED_PERSON_LITERACY'
                },
                {
                    name: 'Skills',
                    value: 'ENSLAVED_PERSON_SKILLS'
                }
            ]
        },
        {
            key: 6,
            name: 'Enslaved Person Language',
            fields: [
                {
                    name: 'ID',
                    value: 'ENSLAVED_PERSON_LANGUAGE_ID'
                },
                {
                    name: 'Enslaved Person ID',
                    value: 'ENSLAVED_PERSON_LANGUAGE_ENSLAVED_PERSON_ID'
                },
                {
                    name: 'Language ID',
                    value: 'ENSLAVED_PERSON_LANGUAGE_LANGUAGE_ID'
                },
                {
                    name: 'Does Speak',
                    value: 'ENSLAVED_PERSON_LANGUAGE_DOES_SPEAK'
                },
            ]
        },
        {
            key: 7,
            name: 'Enslaved Person Name',
            fields: [
                {
                    name: 'ID',
                    value: 'ENSLAVED_PERSON_NAME_ID'
                },
                {
                    name: 'Enslaved Person ID',
                    value: 'ENSLAVED_PERSON_NAME_ENSLAVED_PERSON_ID'
                },
                {
                    name: 'Fullname',
                    value: 'ENSLAVED_PERSON_NAME_FULLNAME'
                },
            ]
        },
        {
            key: 8,
            name: 'Enslaver',
            fields: [
                {
                    name: 'ID',
                    value: 'ENSLAVER_ID'
                },
                {
                    name: 'Fullname',
                    value: 'ENSLAVER_FULLNAME'
                },
                {
                    name: 'Gender',
                    value: 'ENSLAVER_GENDER'
                },
                {
                    name: 'Type',
                    value: 'ENSLAVER_TYPE'
                },
                {
                    name: 'Location ID',
                    value: 'ENSLAVER_LOCATION_ID'
                },
            ]
        },
        {
            key: 9,
            name: 'Newspaper',
            fields: [
                {
                    name: 'ID',
                    value: 'NEWSPAPER_ID'
                },
                {
                    name: 'Name',
                    value: 'NEWSPAPER_NAME'
                },
                {
                    name: 'Location ID',
                    value: 'NEWSPAPER_LOCATION_ID'
                },
            ]
        },
        {
            key: 10,
            name: 'Runaway',
            fields: [
                {
                    name: 'ID',
                    value: 'RUNAWAY_ID'
                },
                {
                    name: 'Runaway Ran Before',
                    value: 'RUNAWAY_RAN_BEFORE'
                },
                {
                    name: 'Runaway Profess Freedom',
                    value: 'RUNAWAY_PROFESS_FREEDOM'
                },
                {
                    name: 'Runaway Approximate Age',
                    value: 'RUNAWAY_APPROXIMATE_AGE'
                },
                {
                    name: 'Runaway Min Age',
                    value: 'RUNAWAY_MIN_AGE'
                },
                {
                    name: 'Runaway Max Age',
                    value: 'RUNAWAY_MAX_AGE'
                },
                {
                    name: 'Runaway Racial Description',
                    value: 'RUNAWAY_RACIAL_DESCRIPTION'
                },
                {
                    name: 'Runaway Ethnic Description',
                    value: 'RUNAWAY_ETHNIC_DESCRIPTION'
                },
                {
                    name: 'Runaway Approximate Height',
                    value: 'RUNAWAY_APPROXIMATE_HEIGHT'
                },
                {
                    name: 'Runaway Min Height',
                    value: 'RUNAWAY_MIN_HEIGHT'
                },
                {
                    name: 'Runaway Max Height',
                    value: 'RUNAWAY_MAX_HEIGHT'
                },
                {
                    name: 'Runaway Approximate Weight',
                    value: 'RUNAWAY_APPROXIMATE_WEIGHT'
                },
                {
                    name: 'Runaway Min Weight',
                    value: 'RUNAWAY_MIN_WEIGHT'
                },
                {
                    name: 'Runaway Max Weight',
                    value: 'RUNAWAY_MAX_WEIGHT'
                },
                {
                    name: 'Runaway Injuries Scars',
                    value: 'RUNAWAY_INJURIES_SCARS'
                },
                {
                    name: 'Runaway Self Presentation',
                    value: 'RUNAWAY_SELF_PRESENTATION'
                },
                {
                    name: 'Runaway Other Physical Descriptions',
                    value: 'RUNAWAY_OTHER_PHYSICAL_DESCRIPTIONS'
                },
                {
                    name: 'Runaway Possessions',
                    value: 'RUNAWAY_POSSESSIONS'
                },
                {
                    name: 'Runaway Runaway Event ID',
                    value: 'RUNAWAY_RUNAWAY_EVENT_ID'
                },
                {
                    name: 'Runaway Enslaved Person ID',
                    value: 'RUNAWAY_ENSLAVED_PERSON_ID'
                },
            ]
        },
        {
            key: 11,
            name: 'RunawayEnslavers',
            fields: [
                {
                    name: 'ID',
                    value: 'RUNAWAY_ENSLAVERS_ID'
                },
                {
                    name: 'Runaway ID',
                    value: 'RUNAWAY_ENSLAVERS_RUNAWAY_ID'
                },
                {
                    name: 'Enslaver ID',
                    value: 'RUNAWAY_ENSLAVERS_ENSLAVER_ID'
                },
                {
                    name: 'Enslaver Status',
                    value: 'RUNAWAY_ENSLAVERS_ENSLAVER_STATUS'
                },
            ]
        },
        {
            key: 12,
            name: 'Runaway Event',
            fields: [
                {
                    name: 'ID',
                    value: 'RUNAWAY_EVENT_ID'
                },
                {
                    name: 'Type',
                    value: 'RUNAWAY_EVENT_TYPE'
                },
                {
                    name: 'Min Occurred',
                    value: 'RUNAWAY_EVENT_MIN_OCCURRED'
                },
                {
                    name: 'Max Occurred',
                    value: 'RUNAWAY_EVENT_MAX_OCCURRED'
                },
                {
                    name: 'Escapee Count',
                    value: 'RUNAWAY_EVENT_ESCAPEE_COUNT'
                },
                {
                    name: 'Outside Involvement',
                    value: 'RUNAWAY_EVENT_OUTSIDE_INVOLVEMENT'
                },
                {
                    name: 'Advertisement ID',
                    value: 'RUNAWAY_EVENT_ADVERTISEMENT_ID'
                },
            ]
        },
        {
            key: 13,
            name: 'Runaway Reward',
            fields: [
                {
                    name: 'ID',
                    value: 'RUNAWAY_REWARD_ID'
                },
                {
                    name: 'Runaway ID',
                    value: 'RUNAWAY_REWARD_RUNAWAY_ID'
                },
                {
                    name: 'Amount',
                    value: 'RUNAWAY_REWARD_AMOUNT'
                },
                {
                    name: 'Currency',
                    value: 'RUNAWAY_REWARD_CURRENCY'
                },
                {
                    name: 'Criteria',
                    value: 'RUNAWAY_REWARD_CRITERIA'
                },
            ]
        },
        {
            key: 14,
            name: 'RunawayLocation',
            fields: [
                {
                    name: 'ID',
                    value: 'RUNAWAY_LOCATION_ID'
                },
                {
                    name: 'Runaway ID',
                    value: 'RUNAWAY_LOCATION_RUNAWAY_ID'
                },
                {
                    name: 'Location ID',
                    value: 'RUNAWAY_LOCATION_LOCATION_ID'
                },
                {
                    name: 'Type',
                    value: 'RUNAWAY_LOCATION_TYPE'
                },
                {
                    name: 'Reason',
                    value: 'RUNAWAY_LOCATION_REASON'
                },
            ]
        },
        {
            key: 15,
            name: 'Runaway Relationship',
            fields: [
                {
                    name: 'ID',
                    value: 'RUNAWAY_RELATIONSHIP_ID'
                },
                {
                    name: 'Left Runaway ID',
                    value: 'RUNAWAY_RELATIONSHIP_LEFT_RUNAWAY_ID'
                },
                {
                    name: 'Right Runaway ID',
                    value: 'RUNAWAY_RELATIONSHIP_RIGHT_RUNAWAY_ID'
                },
                {
                    name: 'Left Kinship',
                    value: 'RUNAWAY_RELATIONSHIP_LEFT_KINSHIP'
                },
                {
                    name: 'Right Kinship',
                    value: 'RUNAWAY_RELATIONSHIP_RIGHT_KINSHIP'
                },
            ]
        },
        {
            key: 16,
            name: 'Location (Runaway)',
            fields: [
                {
                    name: 'Name',
                    value: 'RUNAWAY_LOCATION_NAME'
                },
                {
                    name: 'City',
                    value: 'RUNAWAY_LOCATION_CITY'
                },
                {
                    name: 'County',
                    value: 'RUNAWAY_LOCATION_COUNTY'
                },
                {
                    name: 'State',
                    value: 'RUNAWAY_LOCATION_STATE'
                },
                {
                    name: 'Country',
                    value: 'RUNAWAY_LOCATION_COUNTRY'
                },
            ]
        },
        {
            key: 17,
            name: 'Location (Newspaper)',
            fields: [
                {
                    name: 'Name',
                    value: 'NEWSPAPER_LOCATION_NAME'
                },
                {
                    name: 'City',
                    value: 'NEWSPAPER_LOCATION_CITY'
                },
                {
                    name: 'County',
                    value: 'NEWSPAPER_LOCATION_COUNTY'
                },
                {
                    name: 'State',
                    value: 'NEWSPAPER_LOCATION_STATE'
                },
                {
                    name: 'Country',
                    value: 'NEWSPAPER_LOCATION_COUNTRY'
                },
            ]
        },
        {
            key: 18,
            name: 'Location (Enslaver)',
            fields: [
                {
                    name: 'Name',
                    value: 'ENSLAVER_LOCATION_NAME'
                },
                {
                    name: 'City',
                    value: 'ENSLAVER_LOCATION_CITY'
                },
                {
                    name: 'County',
                    value: 'ENSLAVER_LOCATION_COUNTY'
                },
                {
                    name: 'State',
                    value: 'ENSLAVER_LOCATION_STATE'
                },
                {
                    name: 'Country',
                    value: 'ENSLAVER_LOCATION_COUNTRY'
                },
            ]
        },
        {
            key: 19,
            name: 'Location (Advertiser)',
            fields: [
                {
                    name: 'Name',
                    value: 'ADVERTISER_LOCATION_NAME'
                },
                {
                    name: 'City',
                    value: 'ADVERTISER_LOCATION_CITY'
                },
                {
                    name: 'County',
                    value: 'ADVERTISER_LOCATION_COUNTY'
                },
                {
                    name: 'State',
                    value: 'ADVERTISER_LOCATION_STATE'
                },
                {
                    name: 'Country',
                    value: 'ADVERTISER_LOCATION_COUNTRY'
                },
            ]
        }
    ];

    readonly states = observable<any>([
        {
            "iso3166_2": "US-AL",
            "name": "Alabama"
        },
        {
            "iso3166_2": "US-AK",
            "name": "Alaska"
        },
        {
            "iso3166_2": "US-AZ",
            "name": "Arizona"
        },
        {
            "iso3166_2": "US-AR",
            "name": "Arkansas"
        },
        {
            "iso3166_2": "US-CA",
            "name": "California"
        },
        {
            "iso3166_2": "US-CO",
            "name": "Colorado"
        },
        {
            "iso3166_2": "US-CT",
            "name": "Connecticut"
        },
        {
            "iso3166_2": "US-DE",
            "name": "Delaware"
        },
        {
            "iso3166_2": "US-DC",
            "name": "District Of Columbia"
        },
        {
            "iso3166_2": "US-FL",
            "name": "Florida"
        },
        {
            "iso3166_2": "US-GA",
            "name": "Georgia"
        },
        {
            "iso3166_2": "US-HI",
            "name": "Hawaii"
        },
        {
            "iso3166_2": "US-ID",
            "name": "Idaho"
        },
        {
            "iso3166_2": "US-IL",
            "name": "Illinois"
        },
        {
            "iso3166_2": "US-IN",
            "name": "Indiana"
        },
        {
            "iso3166_2": "US-IA",
            "name": "Iowa"
        },
        {
            "iso3166_2": "US-KS",
            "name": "Kansas"
        },
        {
            "iso3166_2": "US-KY",
            "name": "Kentucky"
        },
        {
            "iso3166_2": "US-LA",
            "name": "Louisiana"
        },
        {
            "iso3166_2": "US-ME",
            "name": "Maine"
        },
        {
            "iso3166_2": "US-MD",
            "name": "Maryland"
        },
        {
            "iso3166_2": "US-MA",
            "name": "Massachusetts"
        },
        {
            "iso3166_2": "US-MI",
            "name": "Michigan"
        },
        {
            "iso3166_2": "US-MN",
            "name": "Minnesota"
        },
        {
            "iso3166_2": "US-MS",
            "name": "Mississippi"
        },
        {
            "iso3166_2": "US-MO",
            "name": "Missouri"
        },
        {
            "iso3166_2": "US-MT",
            "name": "Montana"
        },
        {
            "iso3166_2": "US-NE",
            "name": "Nebraska"
        },
        {
            "iso3166_2": "US-NV",
            "name": "Nevada"
        },
        {
            "iso3166_2": "US-NH",
            "name": "New Hampshire"
        },
        {
            "iso3166_2": "US-NJ",
            "name": "New Jersey"
        },
        {
            "iso3166_2": "US-NM",
            "name": "New Mexico"
        },
        {
            "iso3166_2": "US-NY",
            "name": "New York"
        },
        {
            "iso3166_2": "US-NC",
            "name": "North Carolina"
        },
        {
            "iso3166_2": "US-ND",
            "name": "North Dakota"
        },
        {
            "iso3166_2": "US-OH",
            "name": "Ohio"
        },
        {
            "iso3166_2": "US-OK",
            "name": "Oklahoma"
        },
        {
            "iso3166_2": "US-OR",
            "name": "Oregon"
        },
        {
            "iso3166_2": "US-PA",
            "name": "Pennsylvania"
        },
        {
            "iso3166_2": "US-PR",
            "name": "Puerto Rico"
        },
        {
            "iso3166_2": "US-RI",
            "name": "Rhode Island"
        },
        {
            "iso3166_2": "US-SC",
            "name": "South Carolina"
        },
        {
            "iso3166_2": "US-SD",
            "name": "South Dakota"
        },
        {
            "iso3166_2": "US-TN",
            "name": "Tennessee"
        },
        {
            "iso3166_2": "US-TX",
            "name": "Texas"
        },
        {
            "iso3166_2": "US-UT",
            "name": "Utah"
        },
        {
            "iso3166_2": "US-VT",
            "name": "Vermont"
        },
        {
            "iso3166_2": "US-VA",
            "name": "Virginia"
        },
        {
            "iso3166_2": "US-WA",
            "name": "Washington"
        },
        {
            "iso3166_2": "US-WV",
            "name": "West Virginia"
        },
        {
            "iso3166_2": "US-WI",
            "name": "Wisconsin"
        },
        {
            "iso3166_2": "US-WY",
            "name": "Wyoming"
        }
    ]);

    readonly dropdownStates = observable<any>([]);

    readonly newspapers = observable<any>([]);

    countries = observable([
        {
            "iso3166_2": "US",
            "name": "United States"
        },
        {
            "iso3166_2": "CA",
            "name": "Canada"
        },
        {
            "iso3166_2": "MX",
            "name": "Mexico"
        },
        {
            "iso3166_2": "CU",
            "name": "Cuba"
        }
    ]);

    @action
    async fetchLanguages() {
        const url = `/languages`;
        const {data} = await api.get(url);
        runInAction(() => this.languages.replace(data));
        return data;
    }

    @action
    async fetchCountries() {
        const {data} = await api.get('/locations/countries');
        const usFirst = data.filter((d: any) => d.iso3166_2 === 'US').concat(data.filter((d: any) => d.iso3166_2 !== 'US'));

        runInAction(() => this.countries.replace(usFirst));
    }

    @action
    async fetchStates() {
        const {data} = await api.get('/locations/states');

        runInAction(() => {
            this.states.replace(data.sort((a: any, b: any) => {
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                return 0
            }));
            this.dropdownStates.replace(this.usStates);
        });
    }

    // @action
    // async fetchNewspapers() {
    //     let {data} = await api.put('/newspapers/search', {size: 1000});
    //     if (data && data.hits && data.hits.hits) {
    //         runInAction(() => this.newspapers.replace(data.hits.hits.map((n: any) => n._source)));
    //     }
    // }

    @computed
    get newspaperStates() {
        const states: any = {};
        this.newspapers.forEach((newspaper: any) => {
            if (newspaper.location && newspaper.location.state) {
                states[newspaper.location.state] = true
            }
        });
        return this.states.filter(state => states[state.iso3166_2]);
    }

    @computed
    get usStates() {
        return this.states.filter(state => state.iso3166_2.substr(0, 2) === 'US');
    }

    @action
    addDropdownState(iso3166_2: string) {
        if (this.dropdownStates.find(s => s.iso3166_2 === iso3166_2)) return;
        const state = this.states.find(s => s.iso3166_2 === iso3166_2);
        if (state) {
            this.dropdownStates.push(state);
        }
    }

    async fetchAllFromServer() {
        await Promise.all([
            this.fetchLanguages(),
            this.fetchStates(),
            this.fetchCountries()
        ]);
        // await this.fetchNewspapers();

        runInAction(() => this.ready = true);
    }

    findLanguage(iso_639: string) {
        return this.languages.find(l => l.iso_639 === iso_639);
    }

    findState(iso3166_2: string) {
        return this.states.find(s => s.iso3166_2 === iso3166_2);
    }
}

export const options = new Constants();