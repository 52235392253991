import * as React from 'react';
import {Component, ReactNode} from 'react';
import {RunawayDescriptionForm} from "./scenes/RunawayDescription/RunawayDescriptionForm";
import {RunawaySkillsForm} from "./scenes/RunawaySkills/RunawaySkillsForm";
import {RunawayRewardsForm} from "./scenes/RunawayRewards/RunawayRewardsForm";
import {RunawayLocationsForm} from "./scenes/RunawayLocations/RunawayLocationsForm"
import {observer} from "mobx-react";
import {uiStore} from "../../../../stores/UIStore";
import {Accordion, Divider, Form, Icon, Popup} from "semantic-ui-react";
import ServerError from "../../../../../../components/ServerError";
import {domainStore} from "../../../../stores/CrowdsourcingStore";

interface IProps {
    runaway: number;
    submitRunaway: (runaway: number) => void;
}

interface Panel {
    key: string;
    title: string | ReactNode;
    content: ReactNode;
}

@observer
export class Runaway extends Component<IProps> {
    panels: Panel[] = [];

    constructor(props: IProps) {
        super(props);

        if (uiStore.runaways.length > props.runaway) {
            this.panels = [
                {
                    key: 'description',
                    title: (
                        <span>PHYSICAL CHARACTERISTICS&nbsp;
                            <Popup
                                wide='very'
                                trigger={
                                    <Icon name='info circle'/>
                                }
                                content="Enslavers sometimes provided more personal detail about individual enslaved people in these advertisements than anywhere else.  Whether this information was accurate or simply what enslavers believed, by gathering this data we are able to learn more about the individuals than we could otherwise do. Recording these details helps insure that these individuals, who were resisting slavery, are not lost to the historical record."
                            />
                </span>
                    ),
                    content: <RunawayDescriptionForm runaway={props.runaway}/>
                },
                {
                    key: 'about',
                    title: 'SKILLS',
                    content: <RunawaySkillsForm runaway={props.runaway}/>
                },
                {
                    key: 'locations',
                    title: 'LOCATIONS',
                    content: <RunawayLocationsForm runaway={props.runaway}/>
                },
                {
                    key: 'rewards',
                    title: 'REWARDS',
                    content: <RunawayRewardsForm runaway={props.runaway}/>
                }
            ];
        }
    };

    get runaway() {
        return uiStore.runaways[this.props.runaway];
    }

    nextPage = async () => {
        await this.props.submitRunaway(this.props.runaway);
        uiStore.next()
    };

    previousPage = () => {
        uiStore.previous();
    };

    openPage = (value: number) => {
        if (this.runaway.currentPage !== value) {
            this.runaway.setCurrentPage(value);
        } else {
            this.runaway.setCurrentPage(-1);
        }
    };

    nextSection = (i: number) => {
        if (i < this.panels.length - 1) {
            this.runaway.pages[i].setComplete(true);
            domainStore.pushAllRunawayData(this.props.runaway);
            this.openPage(i + 1);
        } else {
            this.nextPage();
        }
    };

    previousSection = (i: number) => {
        domainStore.pushAllRunawayData(this.props.runaway);
        if (i > 0) {
            this.openPage(i - 1);
        } else {
            this.previousPage();
        }
    };

    render() {
        if (!this.runaway) {
            return <ServerError/>
        }

        return (
            <div>
                {
                    this.panels.map((p, i) => {
                        let active = this.runaway.currentPage >= 0 && this.runaway.pages[this.runaway.currentPage].name === p.key;
                        let done = this.runaway.pages[i].complete && !active;
                        let background = done ? 'blue segment' : '';
                        return (
                            <Accordion styled fluid key={p.key} className={background}
                                       style={{marginTop: 15, marginBottom: 15}} inverted={done}>
                                <Accordion.Title as='button' active={active} onClick={() => this.openPage(i)}>
                                    <h2 style={{padding: 10}}>{p.title}<Icon name='dropdown' style={{float: 'right'}}/>
                                    </h2>
                                </Accordion.Title>
                                <Accordion.Content className="ui" active={active}>
                                    <Divider style={{marginTop: 0}}/>
                                    {p.content}
                                    <Divider section/>
                                    <Form as='div' style={{marginTop: '20px'}}>
                                        <Form.Group>
                                            <Form.Button className='form-button' size='large'
                                                         onClick={() => this.previousSection(i)}
                                                         ga-on="click"
                                                         ga-event-category="Navigation"
                                                         ga-event-action="runaway-previous">PREVIOUS</Form.Button>
                                            <Form.Button className='form-button' primary size='large'
                                                         onClick={() => this.nextSection(i)}
                                                         ga-on="click"
                                                         ga-event-category="Navigation"
                                                         ga-event-action="runaway-next">NEXT</Form.Button>
                                        </Form.Group>
                                    </Form>
                                </Accordion.Content>
                            </Accordion>
                        )
                    })
                }
            </div>
        );

    }
}
