import React, {Component} from 'react';
import './App.css';
import NavigationBar from "./components/NavigationBar";
import {Crowdsourcing} from "./scenes/Crowdsourcing/Crowdsourcing";
import {observer} from "mobx-react";
import MainPage from "./scenes/Main/MainPage";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
import Routes from "./Routes";
import {PrivateRoute} from "./components/PrivateRoute";
import NotFound from "./scenes/NotFound/NotFound";
import Lesson from "./scenes/Teachers/Lesson";
import Teachers from "./scenes/Teachers/Teachers";
import {userStore} from "./stores/UserStore";
import Cookies from "js-cookie";
import {Settings} from "./scenes/Settings/Settings";
import {ChangePassword} from "./scenes/Settings/scenes/ChangePassword/ChangePassword"
import {configure} from "mobx";
import {PasswordReset} from "./scenes/Auth/scenes/PasswordReset/PasswordReset";
import Footer from "./components/Footer";
import AdSelection from "./scenes/AdSelection/AdSelection";
import {Message} from "semantic-ui-react";
import {options} from "./Constants";
import Contact from "./scenes/Contact/Contact";
import ModQueue from './scenes/ModQueue/ModQueue';
import AdGrouping from "./scenes/AdGrouping/AdGrouping";
import CrowdsourcingSelection from "./scenes/CrowdsourcingSelection/CrowdsourcingSelection";
import Collection from "./scenes/Collections/scenes/Collection";
import CollectionsPage from "./scenes/Collections/scenes/CollectionsPage";
import {createLoader} from "./stores/utils/Loader";

@observer
export class App extends Component<any, any> {
    loader = createLoader();

    constructor(props: any) {
        super(props);
        let jwt = Cookies.get('auth_token');
        if (jwt) {
            userStore.setAuthToken(jwt);
            userStore.getUserFromToken();
            this.loader.load(options.fetchAllFromServer());
        }

        configure({
            enforceActions: "always"
        });
    }

    render() {
        return (
            <div>
                <a className='skip' href='#content'>Skip to Content</a>
                <React.Fragment>
                    {!navigator.cookieEnabled &&
                    <Message negative>
                        <Message.Header>Cookies Required</Message.Header>
                        <Message.Content>In order for Freedom on the Move to work properly, you must have cookies enabled.
                            Please
                            enable them in your browser settings.</Message.Content>
                    </Message>
                    }

                    {(!!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g)) &&
                    <Message negative>
                        <Message.Header>Internet Explorer Not Supported</Message.Header>
                        <Message.Content>Sorry, Internet Explorer is not supported. We recommend using Google Chrome or
                            Mozilla
                            Firefox</Message.Content>
                    </Message>
                    }

                    <Router>
                        <div className="App">
                            <NavigationBar/>
                            <main>
                                <Switch>
                                    <Route exact path={Routes.home} render={() => (<MainPage/>)}/>

                                    <Route exact path={Routes.login} component={() => (<MainPage login/>)}/>

                                    <Route exact path={Routes.createAccount} component={() => (<MainPage register/>)}/>

                                    <Route exact path={Routes.passwordReset} component={PasswordReset}/>

                                    <Route exact path={Routes.contact} component={Contact}/>

                                    <Route exact path={Routes.Educators} component={Teachers}/>

                                    <Route path={Routes.Educators} component={Lesson}/>

                                    <PrivateRoute exact path={Routes.crowdsourcing}
                                                  component={CrowdsourcingSelection}/>

                                    <PrivateRoute exact path={Routes.grouping} component={AdGrouping}/>

                                    <PrivateRoute exact path={Routes.transcribing} component={AdSelection}/>

                                    <PrivateRoute exact path={Routes.moderation} component={ModQueue}/>

                                    <PrivateRoute exact path={Routes.crowdsourcing + "/:advertisementId"}
                                                  component={Crowdsourcing}/>

                                    <PrivateRoute exact path={`${Routes.collections}/:collectionId`}
                                                  component={Collection}/>

                                    <PrivateRoute exact path={Routes.collections}
                                                  component={CollectionsPage}/>

                                    <PrivateRoute exact path={Routes.settings} component={Settings}/>

                                    <PrivateRoute exact path={Routes.changePassword}
                                                  component={ChangePassword}/>

                                    {/*<PrivateRoute exact path={Object.values(Routes.educational)}*/}
                                    {/*              component={EducatorPortal}/>*/}

                                    {/*<PrivateRoute exact path={Routes.map} component={Map}/>*/}

                                    <Route component={NotFound}/>

                                </Switch>
                            </main>
                        </div>
                    </Router>

                    <Footer/>
                </React.Fragment>
            </div>
        );
    }
}

export default App;
