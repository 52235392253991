import {action, observable} from "mobx";
import {uiStore} from "../../scenes/Crowdsourcing/stores/UIStore";

class EnslavedPersonName {
    key: any;
    id: string;
    @observable enslavedPersonId: string;
    @observable fullname: string;
    @observable isAlias = false;

    constructor(json: any) {
        this.key = json.id ? json.id : uiStore.getKey();
        this.id = json.id;
        this.enslavedPersonId = json.enslavedPersonId;
        this.fullname = json.fullname;
        this.isAlias = json.isAlias;
    }

    @action
    setEnslavedPersonId(enslavedPersonId: string) {
        this.enslavedPersonId = enslavedPersonId;
    }

    @action
    setFullname(fullname: string) {
        this.fullname = fullname;
    }

    @action
    setIsAlias(isAlias: boolean) {
        this.isAlias = isAlias;
    }

    isEmpty() {
        return !this.fullname || this.fullname.replace(/\W/g, '').length === 0;
    }
}

export default EnslavedPersonName;
