import {action, observable} from "mobx";
import Enslaver from "./Enslaver";
import RunawayRelationship from "./RunawayRelationship";
import Reward from "./Reward";
import RunawayLocation from "./RunawayLocation";
import EnslavedPerson from "./EnslavedPerson";
import RunawayEvent from "./RunawayEvent";

class Runaway {
    id: string;
    @observable ranBefore?: boolean;
    @observable professFreedom?: boolean;
    @observable approximateAge?: string;
    @observable minAge?: number;
    @observable maxAge?: number;
    @observable racialDescription?: string;
    @observable ethnicDescription?: string;
    @observable approximateHeight?: string;
    @observable minHeight?: number;
    @observable maxHeight?: number;
    @observable approximateWeight?: string;
    @observable minWeight?: number;
    @observable maxWeight?: number;
    @observable injuriesScars?: string;
    @observable selfPresentation?: string;
    @observable otherPhysicalDescriptions?: string;
    @observable possessions?: string;
    readonly enslavers = observable<Enslaver>([]);
    readonly relationships = observable<RunawayRelationship>([]);
    readonly rewards = observable<Reward>([]);
    readonly locations = observable<RunawayLocation>([]);
    @observable runawayEventId: string;
    @observable enslavedPersonId: string;
    @observable runawayEvent?: RunawayEvent;
    @observable enslavedPerson: EnslavedPerson;

    constructor(json: any = {}) {
        this.id = json.id;
        this.ranBefore = json.ranBefore;
        this.professFreedom = json.professFreedom;
        this.approximateAge = json.approximateAge;
        this.minAge = json.minAge;
        this.maxAge = json.maxAge;
        this.racialDescription = json.racialDescription;
        this.ethnicDescription = json.ethnicDescription;
        this.approximateHeight = json.approximateHeight;
        this.minHeight = json.minHeight;
        this.maxHeight = json.maxHeight;
        this.approximateWeight = json.approximateWeight;
        this.minWeight = json.minWeight;
        this.maxWeight = json.maxWeight;
        this.injuriesScars = json.injuriesScars;
        this.selfPresentation = json.selfPresentation;
        this.otherPhysicalDescriptions = json.otherPhysicalDescriptions;
        this.possessions = json.possessions;
        this.runawayEventId = json.runawayEventId;
        this.enslavedPersonId = json.enslavedPersonId;

        if (json.enslavers) {
            json.enslavers.forEach((e: any) => {
                this.enslavers.push(new Enslaver(e))
            })
        }

        if (json.relationships) {
            json.relationships.forEach((r: any) => {
                this.relationships.push(new RunawayRelationship(r))
            })
        }

        if (json.rewards) {
            json.rewards.forEach((r: any) => {
                this.rewards.push(new Reward(r))
            })
        }

        if (json.locations) {
            json.locations.forEach((l: any) => {
                this.locations.push(new RunawayLocation(l))
            })
        }

        if (json.enslavedPerson) {
            this.enslavedPerson = new EnslavedPerson(json.enslavedPerson);
        } else {
            this.enslavedPerson = new EnslavedPerson({id: json.enslavedPersonId});
        }

    }

    @action
    setRanBefore(ranBefore?: boolean) {
        this.ranBefore = ranBefore;
    }

    @action
    setProfessFreedom(professFreedom?: boolean) {
        this.professFreedom = professFreedom;
    }

    @action
    setApproximateAge(approximateAge?: string) {
        this.approximateAge = approximateAge;
    }

    @action
    setMinAge(minAge?: number) {
        this.minAge = minAge;
    }

    @action
    setMaxAge(maxAge?: number) {
        this.maxAge = maxAge;
    }

    @action
    setRacialDescription(racialDescription?: string) {
        this.racialDescription = racialDescription;
    }

    @action
    setEthnicDescription(ethnicDescription?: string) {
        this.ethnicDescription = ethnicDescription;
    }

    @action
    setApproximateHeight(approximateHeight?: string) {
        this.approximateHeight = approximateHeight;
    }

    @action
    setMinHeight(minHeight?: number) {
        this.minHeight = minHeight;
    }

    @action
    setMaxHeight(maxHeight?: number) {
        this.maxHeight = maxHeight;
    }

    @action
    setApproximateWeight(approximateWeight?: string) {
        this.approximateWeight = approximateWeight;
    }

    @action
    setMinWeight(minWeight?: number) {
        this.minWeight = minWeight;
    }

    @action
    setMaxWeight(maxWeight?: number) {
        this.maxWeight = maxWeight;
    }

    @action
    setInjuriesScars(injuriesScars?: string) {
        this.injuriesScars = injuriesScars;
    }

    @action
    setSelfPresentation(selfPresentation?: string) {
        this.selfPresentation = selfPresentation;
    }

    @action
    setOtherPhysicalDescriptions(otherPhysicalDescriptions?: string) {
        this.otherPhysicalDescriptions = otherPhysicalDescriptions;
    }

    @action
    setPossessions(possessions?: string) {
        this.possessions = possessions;
    }

    @action
    setRunawayEventId(runawayEventId: string) {
        this.runawayEventId = runawayEventId;
    }

    @action
    setEnslavedPersonId(enslavedPersonId: string) {
        this.enslavedPersonId = enslavedPersonId;
    }

    @action
    setEnslavedPerson(enslavedPerson: any) {
        this.enslavedPersonId = enslavedPerson.id;
        this.enslavedPerson = new EnslavedPerson(enslavedPerson);
    }

    @action
    setEnslavers(enslavers: any[]) {
        this.enslavers.replace(enslavers.map(e => new Enslaver(e)));
    }

    @action
    addEnslaver(enslaver: any) {
        this.enslavers.push(new Enslaver(enslaver));
    }

    @action
    removeEnslaver(i: number) {
        this.enslavers.splice(i, 1);
    }

    @action
    setRelationships(relationships: any[]) {
        this.relationships.replace(relationships.map(r => new RunawayRelationship(r)));
    }

    @action
    addRelationship(relationship: any) {
        this.relationships.push(new RunawayRelationship(relationship));
    }

    @action
    removeRelationship(i: number) {
        this.relationships.splice(i, 1);
    }

    @action
    setRewards(rewards: any[]) {
        this.rewards.replace(rewards.map(r => new Reward(r)));
    }

    @action
    addReward(reward: any) {
        reward.runawayId = this.id;
        this.rewards.push(new Reward(reward));
    }

    @action
    removeReward(i: number) {
        this.rewards.splice(i, 1);
    }

    isRewardsEmpty() {
        return this.rewards.filter(r => !r.isEmpty()).length === 0;
    }

    @action
    setLocations(locations: any[]) {
        this.locations.replace(locations.map(l => new RunawayLocation(l)));
    }

    @action
    addLocation(location: any) {
        this.locations.push(new RunawayLocation(location));
    }

    @action
    removeLocation(i: number) {
        this.locations.splice(i, 1);
    }

    isLocationsEmpty() {
        return this.locations.filter(l => !l.isEmpty()).length === 0;
    }

    getDataForServer() {
        let data: any = Object.assign({}, this);

        if (this.isRewardsEmpty()) {
            data.rewards = [];
        } else {
            data.rewards = this.rewards.filter(r => !r.isEmpty());
        }

        if (this.isLocationsEmpty()) {
            data.locations = [];
        }

        return data;
    }

    get displayName() {
        return this.enslavedPerson.name && this.enslavedPerson.name.fullname;
    }

}

export default Runaway;
