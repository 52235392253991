// @ts-nocheck
import * as React from 'react';
import {observer} from 'mobx-react';
import {adGroupingStore} from "./AdGroupingStore";
import {Button, Card, CardProps, Container, Divider, Grid, Header, Image, Modal, Segment} from "semantic-ui-react";
import {config} from "../../config";
import {ErrorBoundary} from "../Crowdsourcing/components/ErrorBoundary";
import ScrollLoader from "../../components/ScrollLoader";
import formatLocation from "../Search/services/LocationFormat";
import moment from "moment";
import './styles.css';
import {AdImage} from "../Crowdsourcing/components/AdImage";
import Advertisement from "../../stores/models/Advertisement";
import {createLoader} from "../../stores/utils/Loader";

interface IProps {
    id: any;
    compact: boolean;
}

@observer
export class AdGrouping extends React.Component<IProps> {

    loader = createLoader();
    unGroupButton = React.createRef<Button>();
    groupButton = React.createRef<Button>();
    groupFocused = false;

    constructor(props: IProps) {
        super(props);
        this.findCandidates();
    }

    toggleSelect(id: any) {
        adGroupingStore.toggleSelected(id);
    }

    findCandidates = async () => {
        await adGroupingStore.findCandidates(this.props.id);
    };

    groupAds = async () => {
        await this.loader.load(adGroupingStore.groupAds(this.props.id));
    };

    markUngrouped = async () => {
        await this.loader.load(adGroupingStore.markUngrouped());
    };

    onCardFocus = () => {
        this.groupFocused = true;
    };

    onEscape = ({keyCode}: any) => {
        if (keyCode === 27) { // Escape
            if (!this.groupButton.current?.props.disabled) {
                this.groupButton.current?.focus();
            } else {
                this.unGroupButton.current?.focus();
            }
        }
    };

    render() {
        const sample = adGroupingStore.sampleAd;
        const compact = this.props.compact;

        const content = (
            <React.Fragment>
                <ScrollLoader as={Card.Group} className='shrink' itemsPerRow={!compact ? 3 : 1} doubling
                              onKeyUp={this.onEscape} onLoad={this.findCandidates}
                              style={{maxHeight: '70vh', overflowY: 'scroll'}}>
                    {adGroupingStore.candidates.map((ad: Advertisement, i) => {
                        const selected = adGroupingStore.selected.get(ad.id);

                        return (
                            <ResultCard key={ad.id} className='grouping-card' advertisement={ad} fluid autoFocus={i === 0}  //autofocus first element
                                        selected={selected} onFocus={this.onCardFocus}
                                        onClick={() => this.toggleSelect(ad.id)}/>
                        )
                    })}
                </ScrollLoader>
                <Divider section hidden/>
                {adGroupingStore.selectedIds.length > 1 &&
                <Header>{adGroupingStore.selectedIds.length - 1} advertisement{adGroupingStore.selectedIds.length - 1 > 1 && 's'} selected</Header>
                }

                <Button ref={this.unGroupButton} className='flex-none' size='large' loading={this.loader.showLoader}
                        disabled={adGroupingStore.selectedIds.length > 1}
                        onClick={this.markUngrouped}>I CAN'T FIND ANY MATCHES</Button>
                <Button ref={this.groupButton} primary className='flex-none' size='large' loading={this.loader.showLoader}
                        disabled={adGroupingStore.selectedIds.length < 2}
                        onClick={this.groupAds}>GROUP ADVERTISEMENTS</Button>
            </React.Fragment>
        );

        return (
            <ErrorBoundary>
                <Grid stackable id='content'>
                    <Grid.Row>
                        <Grid.Column className='ad-info' width={6}>
                            <Segment basic padded='very' textAlign='left'>
                                {sample &&
                                <React.Fragment>
                                  <Header as='h2'>Selecting Advertisements Like:</Header>
                                  <AdPreview ad={sample}/>
                                </React.Fragment>
                                }
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Segment basic padded='very'>
                                <Container className='flex-column'>
                                    <Header as='h1' className='flex-none' color='orange'>Find Similar Advertisements
                                        <Header.Subheader>
                                            <ul>
                                                <li>Sometimes advertisements were listed in a newspaper multiple
                                                    times.
                                                </li>
                                                <li>Select all advertisements that appear to have the same content.</li>
                                                <li>Once you've selected a group of advertisements, click the submit
                                                    button and start creating
                                                    another
                                                    group.
                                                </li>
                                            </ul>
                                        </Header.Subheader>
                                    </Header>
                                    {compact ?
                                        content
                                        :
                                        <Segment padded={'very'}>
                                            {content}
                                        </Segment>
                                    }
                                    {!compact &&
                                    <Divider section hidden/>
                                    }
                                </Container>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </ErrorBoundary>
        )
    }
}

const AdPreview = ({ad}: { ad: Advertisement }) => {
    const transcription = ad.transcription;
    const newspaper = ad.newspaper ? ad.newspaper.name : '';
    const pubDate = ad.publicationDate ? moment(ad.publicationDate).format('M/D/YYYY') : '';
    const image = ad.digitalObject ? ad.digitalObject.filename : undefined;
    return (
        <Segment.Group>
            <Segment>
                <AdImage src={image} transcription={transcription}/>
            </Segment>
            <Segment>
                <Header>{newspaper}
                    <Header.Subheader>{pubDate}</Header.Subheader>
                </Header>
            </Segment>
        </Segment.Group>
    )
};

interface ResultCardProps extends CardProps {
    advertisement: Advertisement;
    selected: boolean;
    onClick: () => void;
}

const ResultCard = ({advertisement: ad, selected, ...props}: ResultCardProps) => {
    const transcription = ad.transcription;
    const newspaper = ad.newspaper ? ad.newspaper.name : '';
    const locationName = ad.newspaper && ad.newspaper.location ? formatLocation(ad.newspaper.location) : null;
    const pubDate = ad.publicationDate ? moment(ad.publicationDate).format('M/D/YYYY') : '';
    const image = ad.digitalObject ? ad.digitalObject.filename : null;

    const cardStyle = selected ? {
        backgroundColor: '#e8f0fe',
        cursor: 'pointer'
    } : {cursor: 'pointer'};

    const imageStyle = selected ? {padding: 10, backgroundColor: '#e8f0fe',} : null;

    return (
        <Card link as='button' raised={selected} style={cardStyle} {...props}>
            {image ?
                props.onClick ?
                    <Image src={`${config.s3Domain}/${image}`} fluid alt="Advertisement" style={imageStyle}/>
                    :
                    <Modal trigger={<Image src={`${config.s3Domain}/${image}`} fluid alt="Advertisement"
                                           style={{cursor: 'pointer'}}/>}
                           className='image-modal' size='large' closeIcon>
                        <Modal.Content scrolling>
                            <Image src={`${config.s3Domain}/${image}`} fluid alt="Advertisement"/>
                        </Modal.Content>
                    </Modal>
                :
                <Card.Description>
                    {transcription}
                </Card.Description>
            }
            <Card.Content>
                <Card.Header>{newspaper}</Card.Header>
                {locationName &&
                <Card.Meta>{locationName}</Card.Meta>
                }
                <Card.Meta>{pubDate}</Card.Meta>
            </Card.Content>
        </Card>
    )
};

export default AdGrouping;
