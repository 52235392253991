import * as React from 'react';
import {Button, Form} from "semantic-ui-react";
import {observer} from "mobx-react";
import {domainStore} from "../../../../../../stores/CrowdsourcingStore";
import {uiStore} from "../../../../../../stores/UIStore";
import {RewardField} from "./components/RewardField";
import ServerError from "../../../../../../../../components/ServerError";

interface RunawayRewardsFormProps {
    runaway: number;
}

@observer
export class RunawayRewardsForm extends React.Component<RunawayRewardsFormProps> {

    get runaway() {
        return domainStore.runaways[this.props.runaway];
    }

    addReward() {
        this.runaway.addReward(
            {
                amount: 0,
                currency: "",
                criteria: ""
            }
        );
    }

    removeReward(i: number) {
        this.runaway.removeReward(i);
    }

    render() {
        if (!this.runaway) {
            return <ServerError/>;
        }

        if (!this.runaway.rewards || !this.runaway.rewards.length) {
            this.addReward();
        }

        return (
            <div>
                <Form as='div' className='left aligned' size='large'>
                    <Form.Field>
                        <label>If there were any rewards advertised for capturing the runaway, list them
                            below.</label>
                        {this.runaway.rewards.map((r, i) => {
                            return (
                                <div key={r.id ? r.id : uiStore.getKey()}>
                                    <Form.Group key={uiStore.getKey()}>
                                        <Form.Field width={12}>
                                            <RewardField reward={this.runaway.rewards[i]}/>
                                        </Form.Field>
                                        <Form.Field className='ui middle aligned grid' width={2}>
                                            <button className='sixteen wide column link-button'
                                                    onClick={() => this.removeReward(i)}>REMOVE
                                            </button>
                                        </Form.Field>
                                    </Form.Group>
                                </div>
                            )
                        })}
                    </Form.Field>

                    <Form.Field>
                        <Button type='button' color='blue' onClick={() => this.addReward()}>ADD ANOTHER
                            REWARD</Button>
                    </Form.Field>
                </Form>
            </div>
        );
    }
}
