import * as React from 'react';
import {Popup} from "semantic-ui-react";
import CopyToClipboard from "react-copy-to-clipboard"

interface IProps {
    entity: any;
}

interface IState {
    entity: any;
    spanStyle?: string;
    copied: boolean;
}

const getSpanStyle = (entity: any) => {
    switch (entity.ner) {
        case "NUMBER":
            return "nlp-number";
        case "DURATION":
            return "nlp-duration";
        case "CITY":
        case "LOCATION":
            return "nlp-location";
        case "TITLE":
            return "nlp-title";
        case "PERSON":
            return "nlp-person";
        case "DATE":
            return "nlp-date";
    }
};

export default class NLPTag extends React.Component<IProps, IState> {
    readonly state: IState = {
        entity: this.props.entity,
        spanStyle: getSpanStyle(this.props.entity),
        copied: false
    };

    getCopyText = () => {
        if (this.state.entity.ner === "DATE") {
            return this.state.entity.normalizedNER;
        } else {
            return this.state.entity.text;
        }
    };

    onCopy = () => {
        this.setState({copied: true});
    };

    onPopupClose = () => {
        this.setState({copied: false});
    };

    render() {
        return (
            <Popup
                size='mini'
                position='top center'
                trigger={
                    <CopyToClipboard text={this.getCopyText()} onCopy={this.onCopy}>
                        <span className={"nlp-tag " + this.state.spanStyle}>{this.state.entity.text}</span>
                    </CopyToClipboard>
                }
                header={this.state.copied ? "Copied!" : this.state.entity.ner}
                onClose={this.onPopupClose}
            />
        );
    }

}