import * as React from 'react';
import {Divider, Form} from "semantic-ui-react";
import {observer} from "mobx-react";
import {domainStore} from "../../../../../../stores/CrowdsourcingStore";
import {uiStore} from "../../../../../../stores/UIStore";
import ServerError from "../../../../../../../../components/ServerError";
import RunawayLocationField from "./components/RunawayLocationField";

interface RunawayLocationsFormProps {
    runaway: number;
}

@observer
export class RunawayLocationsForm extends React.Component<RunawayLocationsFormProps> {

    get runaway() {
        return domainStore.runaways[this.props.runaway];
    }

    addLocation() {
        this.runaway.addLocation(
            {
                location: {
                    name: '',
                    city: '',
                    county: '',
                    state: '',
                    country: '',
                },
                type: '',
                reason: ''
            }
        );
    }

    removeLocation(i: number) {
        this.runaway.removeLocation(i);
    }

    render() {
        if (!this.runaway) {
            return <ServerError/>;
        }

        if (!this.runaway.locations || this.runaway.locations.length === 0) {
            this.addLocation();
        }

        return (
            <div>
                <Form as='div' className='left aligned' size='large'>
                    <Form.Field>
                        <label>What locations does the advertisement associate with the runaway?</label>
                        {this.runaway.locations.map((l, i) => {
                            return (
                                <div key={l.id ? l.id : uiStore.getKey()}>
                                    {this.runaway.locations.length > 1 &&
                                    <button className='link-button' style={{position: 'absolute', right: 0}}
                                            onClick={() => this.removeLocation(i)}>REMOVE</button>
                                    }
                                    <RunawayLocationField runawayLocation={l}/>
                                    <Divider section/>
                                </div>
                            )
                        })}
                    </Form.Field>

                    <Form.Button type='button' color='blue' onClick={() => this.addLocation()}>ADD ANOTHER
                        LOCATION</Form.Button>
                </Form>
            </div>
        );
    }
}