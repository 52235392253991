import {observer} from "mobx-react";
import * as React from "react";
import {SyntheticEvent} from "react";
import collectionStore from "../stores/CollectionStore";
import {
    Accordion,
    Button,
    Card,
    Checkbox,
    CheckboxProps,
    Divider,
    Form,
    Icon,
    Input,
    InputProps,
    Modal,
    Popup,
    Segment,
    SemanticWIDTHS,
    TextAreaProps
} from "semantic-ui-react";
import AdvertisementCard from "../../../components/AdvertisementCard";
import Routes from "../../../Routes";
import CopyToClipboard from "react-copy-to-clipboard";
import Collection from "../stores/models/Collection";

interface IProps {
    collection: Collection;
    itemsPerRow?: SemanticWIDTHS;
    defaultState?: 'closed' | 'open';
}

interface IState {
    open: boolean;
    edit: boolean;
    copied: boolean;
}

@observer
class CollectionItem extends React.Component<IProps, IState> {
    readonly state = {
        open: this.props.defaultState === 'open',
        edit: false,
        copied: false
    };

    componentWillReceiveProps(nextProps: IProps) {
        const defaultState = nextProps.defaultState ? nextProps.defaultState : 'closed';
        this.setState({open: defaultState === 'open'})
    }

    toggleOpen = () => {
        this.setState({open: !this.state.open});
    };

    toggleEdit = (e: SyntheticEvent) => {
        e.stopPropagation();
        this.setState({edit: !this.state.edit, open: true});
    };

    submitChanges = (e: SyntheticEvent) => {
        e.stopPropagation();
        if (this.props.collection.changed) {
            this.props.collection.pushUpdate();
        }
        this.setState({edit: false});
    };

    changeName = (e: SyntheticEvent, {value}: InputProps) => {
        this.props.collection.setName(value);
    };

    changeDescription = (e: SyntheticEvent, {value}: TextAreaProps) => {
        this.props.collection.setDescription(value as string);
    };

    changePublic = (e: SyntheticEvent, {checked}: CheckboxProps) => {
        this.props.collection.setPublic(checked);
        this.props.collection.pushUpdate();
    };

    deleteCollection = (e: SyntheticEvent) => {
        e.stopPropagation();
        collectionStore.deleteColleciton(this.props.collection.id);
    };

    onShare = (e: SyntheticEvent) => {
        e.stopPropagation();
        collectionStore.setShareModalOpen(true);
    };

    render() {
        const collection = this.props.collection;
        const itemsPerRow = this.props.itemsPerRow ? this.props.itemsPerRow : 2;
        const name = collection.name ? collection.name : "";
        const description = collection.description ? collection.description : "";
        const url = `${window.location.origin}${Routes.collections}/${collection.id}`;

        return (
            <Accordion styled id={`collection-${collection.id}`} fluid>
                <Accordion.Title active={this.state.open} onClick={this.toggleOpen}>
                    {!this.state.edit ?
                        <h2>
                            <button className='text-button' onClick={this.toggleOpen}>{name}</button>
                            &nbsp;&nbsp;
                            <button className='link-button floated right'
                                    style={{fontSize: '1rem', lineHeight: 'inherit'}}
                                    onClick={this.toggleEdit}><Icon name='edit'/> EDIT
                            </button>
                            <Modal closeIcon
                                   trigger={
                                       <button className='link-button floated right' onClick={this.onShare}
                                               style={{fontSize: '1rem', lineHeight: 'inherit', marginRight: '10px'}}>
                                           <Icon name='share alternate'/> SHARE
                                       </button>
                                   }
                                   onClick={(e: SyntheticEvent) => e.stopPropagation()}
                                   onClose={() => collectionStore.setShareModalOpen(false)}
                            >
                                <Modal.Header>Share {collection.name}</Modal.Header>
                                <Modal.Content>
                                    <Segment basic>
                                        <Checkbox label='Allow others to view this collection'
                                                  checked={collection.public} onChange={this.changePublic}/>
                                        <Divider hidden/>
                                        {collection.public &&
                                        <Input fluid value={url}
                                               action={
                                                   <CopyToClipboard text={url}
                                                                    onCopy={() => this.setState({copied: true})}>
                                                       <Form.Button color='blue'
                                                                    basic={!this.state.copied}>{this.state.copied ? 'COPIED!' : 'COPY LINK'}</Form.Button>
                                                   </CopyToClipboard>
                                               }/>
                                        }
                                    </Segment>
                                </Modal.Content>
                            </Modal>
                        </h2>
                        :
                        <Form onSubmit={this.submitChanges}>
                            <h2 id='edit-collection'>
                                <Form.Group style={{margin: 0}}>
                                    <Form.Input size='mini' value={name} onChange={this.changeName}
                                                onClick={(e: SyntheticEvent) => e.stopPropagation()}/>
                                    <Form.Button type='button' negative
                                                 onClick={this.deleteCollection}>DELETE</Form.Button>
                                    <Form.Field style={{flexGrow: 1, paddingRight: 0}}>
                                        <button className='link-button floated right'
                                                style={{fontSize: '1rem', lineHeight: 'inherit'}}
                                                onClick={this.submitChanges}><Icon name='save'/> SAVE
                                        </button>
                                    </Form.Field>
                                </Form.Group>
                            </h2>
                        </Form>
                    }
                </Accordion.Title>
                <Accordion.Content active={this.state.open}>
                    {this.state.edit ?
                        <Form onSubmit={this.submitChanges}>
                            <Form.TextArea style={{width: '100%'}} placeholder='Description...'
                                           value={description} onChange={this.changeDescription}/>
                        </Form>
                        :
                        <span>{description}</span>
                    }

                    <Divider/>

                    {collection.advertisements.length > 0 ?
                        <Card.Group key={collection.id} itemsPerRow={itemsPerRow} doubling stackable
                                    style={{margin: 0}}>
                            {collection.advertisements.map(advertisement =>
                                (
                                    <AdvertisementCard key={advertisement.id} data={advertisement}
                                                       topAction={this.state.edit &&
                                                       <Popup trigger={<Button icon='close' compact circular negative
                                                                               size='small'
                                                                               onClick={() => collection.removeAdvertisement(advertisement)}/>}
                                                              content='Remove'/>}
                                    />
                                )
                            )}
                        </Card.Group>
                        :
                        <span>There are no advertisements in this collection.</span>
                    }
                </Accordion.Content>
            </Accordion>
        )
    }
}

export default CollectionItem;
