// @ts-nocheck
import * as React from "react";
import {ChangeEvent, FormEvent, SyntheticEvent} from "react";
import {observer} from "mobx-react";
import {CheckboxProps, Divider, DropdownProps, Form, Header, Icon, InputProps, Message, Popup} from "semantic-ui-react";
import {userStore} from "../../../../stores/UserStore";
import Routes from "../../../../Routes";
import ConnectionError from "../../../../components/ConnectionError";
import ServerError from "../../../../components/ServerError";
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import {createLoader} from "../../../../stores/utils/Loader"
import auth from "../../../../services/authentication/Authenticator";

type IState = {
    name?: string;
    email?: string;
    password?: string;
    passwordConfirmation?: string;
    acceptTerms?: boolean;
    receiveEmails?: boolean;
    public?: boolean;
    background?: string;
    error?: string;
}

export const CreateAccountPage = withRouter(observer(class extends React.Component<RouteComponentProps, IState> {
    loader = createLoader();

    backgroundOptions = [
        {key: 0, text: 'Researcher', value: 'researcher'},
        {key: 1, text: 'Genealogist', value: 'genealogist'},
        {key: 2, text: 'Student', value: 'student'},
        {key: 3, text: 'Teacher', value: 'teacher'},
        {key: 4, text: 'History Buff / Citizen Historian', value: 'historian'}
    ];

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            name: "",
            email: "",
            password: "",
            passwordConfirmation: "",
            acceptTerms: false,
            receiveEmails: false,
            public: false,
            background: "",
            error: ""
        };

    }


    handleEmailSignup = async () => {
        if (this.state.password !== this.state.passwordConfirmation) {
            this.setState({error: "Passwords must match."});
            return;
        }

        if (!this.state.acceptTerms) {
            this.setState({error: "Please accept the Terms of Use."});
            return;
        }

        this.setState({
            error: ""
        });

        let user = {
            emailAddress: this.state.email,
            fullname: this.state.name,
            password: this.state.password,
            receiveEmails: this.state.receiveEmails,
            public: this.state.public,
            background: this.state.background
        };
        try {
            await this.loader.load(userStore.createAccount(user));
        } catch (e) {
            if (e.response && e.response.data) {
                this.setState({error: e.response.data.message})
            }
        }

    };

    handleGoogleLogin = async (response: any) => {
        console.debug(response);

        if (!this.state.acceptTerms) {
            this.setState({error: "Please accept the Terms of Use."});
            try {
                const auth2 = window.gapi.auth2.getAuthInstance();
                auth2.signOut();
            } catch (e) {
            }
            return;
        }

        let token = response.getAuthResponse().id_token;
        if (!userStore.authToken) {
            await this.loader.load(userStore.login("google", token));
        }
    };

    handleFacebookLogin = async (response: any) => {
        console.debug(response);

        if (!this.state.acceptTerms) {
            this.setState({error: "Please accept the Terms of Use."});
            return;
        }

        let token = response.authResponse.accessToken;
        if (!userStore.authToken) {
            await this.loader.load(userStore.login("facebook", token));
        }
    };

    changeEmail = (e: ChangeEvent, {value}: InputProps) => {
        this.setState({
            email: value
        })
    };

    changeName = (e: ChangeEvent, {value}: InputProps) => {
        this.setState({
            name: value
        })
    };

    changePassword = (e: ChangeEvent, {value}: InputProps) => {
        this.setState({
            password: value
        })
    };

    changePasswordConfirmation = (e: ChangeEvent, {value}: InputProps) => {
        this.setState({
            passwordConfirmation: value
        })
    };

    changeAcceptTerms = (e: FormEvent, {checked: acceptTerms}: CheckboxProps) => {
        this.setState({acceptTerms});
    };

    changeReceiveEmails = (e: FormEvent, {checked}: CheckboxProps) => {
        this.setState({receiveEmails: checked});
    };

    changePublic = (e: FormEvent, {checked}: CheckboxProps) => {
        this.setState({public: checked});
    };

    changeBackground = (e: SyntheticEvent, {value}: DropdownProps) => {
        this.setState({background: value as string});
    };

    render() {
        // @ts-ignore
        const {from} = this.props.location.state || {from: {pathname: Routes.home}};
        const loginSuccess = userStore.loginSuccess && auth.verifyToken(userStore.authToken);

        return (
            <div>
                {userStore.connectionError &&
                <ConnectionError/>
                }

                {userStore.serverError &&
                <ServerError/>
                }
                {!userStore.connectionError && !userStore.serverError &&
                (
                    loginSuccess ?
                        <Redirect to={from}/>
                        :
                        <Form className='login-form' onSubmit={this.handleEmailSignup} loading={this.loader.showLoader}>
                            <Header as='h2'>Register</Header>
                            <Divider section/>

                            <Form.Input id='email' label='Email' type='email' autoFocus required minLength='3'
                                /*pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]"*/
                                        autoComplete='email'
                                        value={this.state.email}
                                        onChange={this.changeEmail}/>
                            <Form.Input id='name' label='Name' required
                                        autoComplete='name'
                                        value={this.state.name}
                                        onChange={this.changeName}/>
                            <Form.Input id='password' label='Password' type='password' required minLength='8'
                                /*pattern="(?=.*\d)(?=.*[a-z]).{8,}"*/
                                        autoComplete='new-password'
                                        value={this.state.password}
                                        onChange={this.changePassword}/>
                            <Form.Input id='password-confirmation' label='Confirm Password' type='password' required
                                        minLength='8'
                                /*pattern="(?=.*\d)(?=.*[a-z]).{8,}"*/
                                        autoComplete='new-password'
                                        value={this.state.passwordConfirmation}
                                        onChange={this.changePasswordConfirmation}/>
                            <Form.Dropdown id='background' label='Which of these roles best applies to you?'
                                           search selection options={this.backgroundOptions}
                                           value={this.state.background}
                                           onChange={this.changeBackground}/>
                            <Form.Field as='div' style={{textAlign: 'left'}}>
                                <Form.Checkbox id='accept-terms' required
                                               label={<label>I agree to the <a
                                                   href='https://freedomonthemove.org/terms.html'
                                                   target='_blank' rel='noopener noreferrer'>Terms of Use</a></label>}
                                               checked={this.state.acceptTerms}
                                               onChange={this.changeAcceptTerms}/>
                                <Form.Checkbox id='email-subscription'
                                               label='Send me occasional emails with updates regarding the project'
                                               checked={this.state.receiveEmails}
                                               onChange={this.changeReceiveEmails}/>
                                <Form.Checkbox id='public-profile' label={
                                    <label>Make my name public&nbsp;
                                        <Popup
                                            wide='very' position='top center'
                                            trigger={
                                                <Icon name='help circle'/>
                                            }
                                            content="We would like to display your name as a contributor to recognise all the work you've done. We will not share your information unless you explicitly permit us to."
                                        />
                                    </label>
                                }
                                               checked={this.state.public}
                                               onChange={this.changePublic}/>

                            </Form.Field>

                            {this.state.error &&
                            <Message negative>{this.state.error}</Message>
                            }

                            <Form.Button primary fluid type='submit'>Register</Form.Button>

                        </Form>
                )
                }
            </div>
        )
    }


}));
