import {domainStore} from "./CrowdsourcingStore";
import {action, computed, observable} from "mobx";
import NLPOptions from "../models/ui/NLPOptions";
import Runaway from "../models/ui/Runaway"
import NLP from "../services/NLP";
import queryString from "query-string";
import {History} from "history";

interface URLQuery {
    section: string;
    mod: string;
    runaway: string;
    runaway_page: string;
}

class UIStore {
    key = 0;
    section = 1;
    @observable mod = false;
    @observable currentRunaway = 0;
    @observable currentRunawayPage = 0;
    readonly runaways = observable<Runaway>([]);

    @observable nlpOptions = new NLPOptions();
    @observable nlpTranscription = {
        sentences: []
    };

    history?: History;

    @action
    saveQuery(query: URLQuery) {
        this.section = query.section ? parseInt(query.section, 10) : 1;
        this.mod = !!query.mod && query.mod === 'true';
        this.currentRunaway = query.runaway ? parseInt(query.runaway, 10) : 0;
        if (this.currentRunaway < 0) {
            this.currentRunaway = 0;
        }
        this.currentRunawayPage = query.runaway_page ? parseInt(query.runaway_page, 10) : 0;
    }

    @computed
    get query() {
        return {
            section: this.section,
            mod: this.mod
        }
    }

    @computed
    get nextPageNumber() {
        let currentPage = this.currentRunawayPage;
        let pages = this.runaways[this.currentRunaway].pages;
        if (currentPage + 1 >= pages.length) {
            return null;
        } else if (pages[currentPage + 1].visible) {
            return currentPage + 1;
        }
        return undefined;
    }

    @computed
    get previousPageNumber() {
        let currentPage = this.currentRunawayPage;
        let pages = this.runaways[this.currentRunaway].pages;
        if (currentPage <= 1) {
            return null;
        } else if (pages[currentPage - 1].visible) {
            return currentPage - 1;
        }
        return undefined;
    }

    @action
    setCurrentRunaway(value: number) {
        this.currentRunaway = value;
    }

    @action
    setCurrentRunawayPage(value: number) {
        this.currentRunawayPage = value;
    }

    @action
    next = () => {
        let history = this.history;
        if (this.section === 5 && this.currentRunaway < this.runaways.length - 1) {// Runaways
            this.currentRunaway++;
        } else {
            this.section++;
            if (history) {
                history.push("?" + queryString.stringify(this.query));
            }
        }
    };

    @action
    previous = () => {
        let history = this.history;
        if (this.section === 5 && this.currentRunaway > 0) {// Runaways
            this.currentRunaway--;
        } else {
            this.section--;
            if (history) {
                history.push("?" + queryString.stringify(this.query));
            }
        }
    };

    @action
    initRunaways() {
        this.runaways.clear();
        if (domainStore.runawayEvent) {
            for (let i = 0; i < domainStore.runaways.length; i++) {
                this.runaways.push(new Runaway());
            }
        }
    }

    @action
    addRunaway() {
        this.runaways.push(new Runaway());
    }

    @action
    removeRunaway(i: number) {
        this.runaways.splice(i, 1);
    }

    @action
    setPage(page: number) {
        this.section = page;
    }

    @action
    setRunaway(runaway: number) {
        this.currentRunaway = runaway;
    }

    @action
    nextRunaway() {
        this.currentRunaway++;
    }

    @action
    previousRunaway() {
        this.currentRunaway--;
    }

    @action
    getKey() {
        return this.key++;
    }

    @action
    setNLPTranscription(nlpTranscription: any) {
        this.nlpTranscription = nlpTranscription;
        this.nlpOptions = new NLPOptions();
        new NLP().saveNLPOptions(nlpTranscription);
    }

    getNLPTranscription() {
        /*api.postNLP(`${config.nlpDomain}/?properties=%7B%22annotators%22%3A%20%22tokenize%2Cssplit%2Cpos%2Cner%2Cdepparse%2Copenie%22%2C%20%22date%22%3A%20%222018-03-21T13%3A37%3A26%22%7D&pipelineLanguage=en`,
            domainStore.advertisement.transcription,
            (data) => {
              console.debug(data);
              this.setNLPTranscription(data);
              nlp.saveNLPOptions(data);
            },
            () => {
            }
        );*/
    }
}

export const uiStore = new UIStore();

