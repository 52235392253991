class DigitalObject {
    id: string;
    filename: string;
    provenance: any;

    constructor(json: any = {}) {
        this.id = json.id;
        this.filename = json.filename;
        this.provenance = json.provenance;
    }
}


export default DigitalObject;