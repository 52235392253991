import * as React from "react";
import {Component} from "react";
import {Divider, Form, Icon, Popup} from "semantic-ui-react";
import {Observer, observer} from "mobx-react";
import {domainStore} from "../../../stores/CrowdsourcingStore";
import {Selection} from "../../../../../components/Selection";
import Nullable from "../../../components/Nullable";
import DateRange from "../../../../../components/DateRange";
import moment, {Moment} from "moment";

interface IProps {
    onSubmit: () => void;
    previousPage: () => void;
}

@observer
export class EventForm extends Component<IProps> {
    nullDate = '1970-01-01';
    aidedOptions = [{key: 'yes', text: 'Yes', value: true}, {key: 'no', text: 'No', value: false}];

    changeOutsideInvolvement(value: boolean) {
        domainStore.runawayEvent.setOutsideInvolvement(value);
    }

    nullOccurred = () => {
        domainStore.runawayEvent.setMinOccurred(this.nullDate);
        domainStore.runawayEvent.setMaxOccurred(this.nullDate);
    };

    changeOccurred = ({startDate, endDate}: { startDate: Moment | null, endDate: Moment | null; }) => {
        window.ga('send', 'event', 'Form Field', 'edit-event-date');
        if (startDate || endDate) {
            domainStore.runawayEvent.setMinOccurred(startDate != null ? startDate.format("YYYY-MM-DD") : endDate && endDate.format("YYYY-MM-DD"));
            domainStore.runawayEvent.setMaxOccurred(endDate != null ? endDate.format("YYYY-MM-DD") : startDate && startDate.format("YYYY-MM-DD"));
        } else {
            domainStore.runawayEvent.setMinOccurred(null);
            domainStore.runawayEvent.setMaxOccurred(null);
        }
    };

    render() {
        /** @type {RunawayEvent}*/
        const runawayEvent = domainStore.runawayEvent;

        return (
            <div>
                <Form className='left aligned' size='large' onSubmit={this.props.onSubmit}>

                    <Nullable type='date' widths={undefined}
                              active={runawayEvent.minOccurred === this.nullDate && runawayEvent.maxOccurred === this.nullDate}
                              onClick={this.nullOccurred}
                              ga-event-action="edit-event-date-nullable">
                        <Form.Field>
                            <label>When did the runaway(s) escape?&nbsp;
                                <Popup
                                    wide
                                    trigger={
                                        <Icon name='question circle'/>
                                    }
                                    content='If the ad indicates a relative time such as "four weeks ago", calculate the date of escape based on the publication date.
                      If no runaway date is listed, look for other relevant dates such as a jailed date.'
                                />
                            </label>
                            <Observer>{() => {
                                let minOccurred = runawayEvent.minOccurred && runawayEvent.minOccurred !== this.nullDate ? moment(runawayEvent.minOccurred) : undefined;
                                let maxOccurred = runawayEvent.maxOccurred && runawayEvent.maxOccurred !== this.nullDate ? moment(runawayEvent.maxOccurred) : undefined;
                                return (
                                    <DateRange id='runawayEventDates'
                                               max={moment(domainStore.advertisement!.publicationDate)}
                                               startDate={minOccurred}
                                               endDate={maxOccurred}
                                               onChange={this.changeOccurred}
                                               ga-event-action="edit-event-occurred"
                                    />
                                );
                            }}</Observer>
                        </Form.Field>
                    </Nullable>

                    <Divider section/>

                    <Form.Field>
                        <label>Does the ad mention people who may have aided the runaway(s)?</label>
                        <Observer>{() =>
                            <Selection color='blue' options={this.aidedOptions}
                                       value={runawayEvent.outsideInvolvement}
                                       onChange={this.changeOutsideInvolvement}
                                       ga-event-action="edit-event-outside-involvement"/>
                        }</Observer>
                    </Form.Field>

                    <Divider section/>

                    <Form.Group>
                        <Form.Button className='form-button' size='large' type='button'
                                     onClick={this.props.previousPage}
                                     disabled={domainStore.loader.isLoading}
                                     ga-on="click"
                                     ga-event-category="Navigation"
                                     ga-event-action="event-previous">PREVIOUS</Form.Button>
                        <Form.Button className='form-button' primary size='large' type='submit'
                                     disabled={domainStore.loader.isLoading}
                                     ga-on="click"
                                     ga-event-category="Navigation"
                                     ga-event-action="event-next">NEXT</Form.Button>
                    </Form.Group>
                </Form>

            </div>
        )
    }
}