export const config = {
  version: "1.1.0",
  domain: "https://legacy-api.freedomonthemove.org",
  // domain: "http://localhost:1865/api",
  // domain: process && process.env && process.env.NODE_ENV === 'development' ? "http://localhost:1865/api" :
  //     (process && process.env && process.env.REACT_APP_API_HOST === 'production') ? "https://api.freedomonthemove.org" : "https://test-api.freedomonthemove.org",
  adPath: "notices",
  advertiserPath: "noticers",
  // s3Domain: "https://s3.amazonaws.com/fotm-ads.freedomonthemove.org",
  s3Domain: "https://source-materials.freedomonthemove.org",
  nlpDomain: "http://localhost:9000",
  fb: {
    appId: '599870943701286',
    cookie: false,
    xfbml: true,
    version: 'v3.2'
  },
  fakeApi: false,
  nlpExample: {
    "docDate": "2018-03-21T13:37:26",
    "sentences": [{
      "index": 0,
      "basicDependencies": [{
        "dep": "ROOT",
        "governor": 0,
        "governorGloss": "ROOT",
        "dependent": 1,
        "dependentGloss": "COMMITTED"
      }, {"dep": "case", "governor": 4, "governorGloss": "Jail", "dependent": 2, "dependentGloss": "To"}, {
        "dep": "det",
        "governor": 4,
        "governorGloss": "Jail",
        "dependent": 3,
        "dependentGloss": "the"
      }, {
        "dep": "nmod",
        "governor": 1,
        "governorGloss": "COMMITTED",
        "dependent": 4,
        "dependentGloss": "Jail"
      }, {
        "dep": "case",
        "governor": 7,
        "governorGloss": "County",
        "dependent": 5,
        "dependentGloss": "of"
      }, {
        "dep": "compound",
        "governor": 7,
        "governorGloss": "County",
        "dependent": 6,
        "dependentGloss": "Mobile"
      }, {
        "dep": "nmod",
        "governor": 4,
        "governorGloss": "Jail",
        "dependent": 7,
        "dependentGloss": "County"
      }, {
        "dep": "case",
        "governor": 10,
        "governorGloss": "21st",
        "dependent": 8,
        "dependentGloss": "on"
      }, {
        "dep": "det",
        "governor": 10,
        "governorGloss": "21st",
        "dependent": 9,
        "dependentGloss": "the"
      }, {
        "dep": "nmod",
        "governor": 4,
        "governorGloss": "Jail",
        "dependent": 10,
        "dependentGloss": "21st"
      }, {
        "dep": "case",
        "governor": 12,
        "governorGloss": "July",
        "dependent": 11,
        "dependentGloss": "of"
      }, {
        "dep": "nmod",
        "governor": 10,
        "governorGloss": "21st",
        "dependent": 12,
        "dependentGloss": "July"
      }, {
        "dep": "punct",
        "governor": 12,
        "governorGloss": "July",
        "dependent": 13,
        "dependentGloss": ","
      }, {
        "dep": "nummod",
        "governor": 12,
        "governorGloss": "July",
        "dependent": 14,
        "dependentGloss": "1832"
      }, {
        "dep": "punct",
        "governor": 12,
        "governorGloss": "July",
        "dependent": 15,
        "dependentGloss": ","
      }, {
        "dep": "case",
        "governor": 18,
        "governorGloss": "Wilkins",
        "dependent": 16,
        "dependentGloss": "by"
      }, {
        "dep": "compound",
        "governor": 18,
        "governorGloss": "Wilkins",
        "dependent": 17,
        "dependentGloss": "B."
      }, {
        "dep": "nmod",
        "governor": 1,
        "governorGloss": "COMMITTED",
        "dependent": 18,
        "dependentGloss": "Wilkins"
      }, {
        "dep": "punct",
        "governor": 18,
        "governorGloss": "Wilkins",
        "dependent": 19,
        "dependentGloss": ","
      }, {
        "dep": "compound",
        "governor": 22,
        "governorGloss": "Justice",
        "dependent": 20,
        "dependentGloss": "Esq"
      }, {
        "dep": "det",
        "governor": 22,
        "governorGloss": "Justice",
        "dependent": 21,
        "dependentGloss": "a"
      }, {
        "dep": "appos",
        "governor": 18,
        "governorGloss": "Wilkins",
        "dependent": 22,
        "dependentGloss": "Justice"
      }, {
        "dep": "case",
        "governor": 25,
        "governorGloss": "Peace",
        "dependent": 23,
        "dependentGloss": "of"
      }, {
        "dep": "det",
        "governor": 25,
        "governorGloss": "Peace",
        "dependent": 24,
        "dependentGloss": "the"
      }, {
        "dep": "nmod",
        "governor": 22,
        "governorGloss": "Justice",
        "dependent": 25,
        "dependentGloss": "Peace"
      }, {"dep": "dep", "governor": 29, "governorGloss": "said", "dependent": 26, "dependentGloss": "in"}, {
        "dep": "cc",
        "governor": 26,
        "governorGloss": "in",
        "dependent": 27,
        "dependentGloss": "and"
      }, {
        "dep": "conj",
        "governor": 26,
        "governorGloss": "in",
        "dependent": 28,
        "dependentGloss": "for"
      }, {
        "dep": "acl",
        "governor": 25,
        "governorGloss": "Peace",
        "dependent": 29,
        "dependentGloss": "said"
      }, {
        "dep": "nsubj",
        "governor": 29,
        "governorGloss": "said",
        "dependent": 30,
        "dependentGloss": "county"
      }, {
        "dep": "punct",
        "governor": 30,
        "governorGloss": "county",
        "dependent": 31,
        "dependentGloss": ","
      }, {
        "dep": "nummod",
        "governor": 33,
        "governorGloss": "negroes",
        "dependent": 32,
        "dependentGloss": "two"
      }, {
        "dep": "appos",
        "governor": 30,
        "governorGloss": "county",
        "dependent": 33,
        "dependentGloss": "negroes"
      }, {
        "dep": "punct",
        "governor": 30,
        "governorGloss": "county",
        "dependent": 34,
        "dependentGloss": "--"
      }, {
        "dep": "det",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 35,
        "dependentGloss": "the"
      }, {
        "dep": "dep",
        "governor": 30,
        "governorGloss": "county",
        "dependent": 36,
        "dependentGloss": "Man"
      }, {
        "dep": "case",
        "governor": 41,
        "governorGloss": "years",
        "dependent": 37,
        "dependentGloss": "about"
      }, {
        "dep": "nummod",
        "governor": 41,
        "governorGloss": "years",
        "dependent": 38,
        "dependentGloss": "23"
      }, {"dep": "cc", "governor": 38, "governorGloss": "23", "dependent": 39, "dependentGloss": "or"}, {
        "dep": "conj",
        "governor": 38,
        "governorGloss": "23",
        "dependent": 40,
        "dependentGloss": "24"
      }, {
        "dep": "nmod",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 41,
        "dependentGloss": "years"
      }, {
        "dep": "case",
        "governor": 43,
        "governorGloss": "age",
        "dependent": 42,
        "dependentGloss": "of"
      }, {
        "dep": "nmod",
        "governor": 41,
        "governorGloss": "years",
        "dependent": 43,
        "dependentGloss": "age"
      }, {
        "dep": "punct",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 44,
        "dependentGloss": ","
      }, {
        "dep": "nummod",
        "governor": 46,
        "governorGloss": "feet",
        "dependent": 45,
        "dependentGloss": "5"
      }, {
        "dep": "appos",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 46,
        "dependentGloss": "feet"
      }, {
        "dep": "nummod",
        "governor": 48,
        "governorGloss": "inches",
        "dependent": 47,
        "dependentGloss": "5"
      }, {
        "dep": "nmod:npmod",
        "governor": 49,
        "governorGloss": "high",
        "dependent": 48,
        "dependentGloss": "inches"
      }, {
        "dep": "amod",
        "governor": 46,
        "governorGloss": "feet",
        "dependent": 49,
        "dependentGloss": "high"
      }, {
        "dep": "punct",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 50,
        "dependentGloss": ","
      }, {
        "dep": "amod",
        "governor": 52,
        "governorGloss": "complexion",
        "dependent": 51,
        "dependentGloss": "dark"
      }, {
        "dep": "appos",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 52,
        "dependentGloss": "complexion"
      }, {
        "dep": "punct",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 53,
        "dependentGloss": ","
      }, {
        "dep": "case",
        "governor": 58,
        "governorGloss": "head",
        "dependent": 54,
        "dependentGloss": "with"
      }, {
        "dep": "det",
        "governor": 58,
        "governorGloss": "head",
        "dependent": 55,
        "dependentGloss": "a"
      }, {
        "dep": "amod",
        "governor": 58,
        "governorGloss": "head",
        "dependent": 56,
        "dependentGloss": "thick"
      }, {
        "dep": "amod",
        "governor": 58,
        "governorGloss": "head",
        "dependent": 57,
        "dependentGloss": "bushy"
      }, {
        "dep": "nmod",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 58,
        "dependentGloss": "head"
      }, {
        "dep": "case",
        "governor": 60,
        "governorGloss": "hair",
        "dependent": 59,
        "dependentGloss": "of"
      }, {
        "dep": "nmod",
        "governor": 58,
        "governorGloss": "head",
        "dependent": 60,
        "dependentGloss": "hair"
      }, {"dep": "punct", "governor": 36, "governorGloss": "Man", "dependent": 61, "dependentGloss": ","}, {
        "dep": "cc",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 62,
        "dependentGloss": "&"
      }, {
        "dep": "conj",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 63,
        "dependentGloss": "calls"
      }, {
        "dep": "compound",
        "governor": 66,
        "governorGloss": "EDWARDS",
        "dependent": 64,
        "dependentGloss": "himself"
      }, {
        "dep": "compound",
        "governor": 66,
        "governorGloss": "EDWARDS",
        "dependent": 65,
        "dependentGloss": "STEPHEN"
      }, {
        "dep": "xcomp",
        "governor": 63,
        "governorGloss": "calls",
        "dependent": 66,
        "dependentGloss": "EDWARDS"
      }, {"dep": "punct", "governor": 36, "governorGloss": "Man", "dependent": 67, "dependentGloss": ","}, {
        "dep": "cc",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 68,
        "dependentGloss": "and"
      }, {
        "dep": "conj",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 69,
        "dependentGloss": "professes"
      }, {
        "dep": "mark",
        "governor": 72,
        "governorGloss": "free",
        "dependent": 70,
        "dependentGloss": "to"
      }, {
        "dep": "cop",
        "governor": 72,
        "governorGloss": "free",
        "dependent": 71,
        "dependentGloss": "be"
      }, {
        "dep": "xcomp",
        "governor": 69,
        "governorGloss": "professes",
        "dependent": 72,
        "dependentGloss": "free"
      }, {"dep": "punct", "governor": 1, "governorGloss": "COMMITTED", "dependent": 73, "dependentGloss": "."}],
      "enhancedDependencies": [{
        "dep": "ROOT",
        "governor": 0,
        "governorGloss": "ROOT",
        "dependent": 1,
        "dependentGloss": "COMMITTED"
      }, {"dep": "case", "governor": 4, "governorGloss": "Jail", "dependent": 2, "dependentGloss": "To"}, {
        "dep": "det",
        "governor": 4,
        "governorGloss": "Jail",
        "dependent": 3,
        "dependentGloss": "the"
      }, {
        "dep": "nmod:to",
        "governor": 1,
        "governorGloss": "COMMITTED",
        "dependent": 4,
        "dependentGloss": "Jail"
      }, {
        "dep": "case",
        "governor": 7,
        "governorGloss": "County",
        "dependent": 5,
        "dependentGloss": "of"
      }, {
        "dep": "compound",
        "governor": 7,
        "governorGloss": "County",
        "dependent": 6,
        "dependentGloss": "Mobile"
      }, {
        "dep": "nmod:of",
        "governor": 4,
        "governorGloss": "Jail",
        "dependent": 7,
        "dependentGloss": "County"
      }, {
        "dep": "case",
        "governor": 10,
        "governorGloss": "21st",
        "dependent": 8,
        "dependentGloss": "on"
      }, {
        "dep": "det",
        "governor": 10,
        "governorGloss": "21st",
        "dependent": 9,
        "dependentGloss": "the"
      }, {
        "dep": "nmod:on",
        "governor": 4,
        "governorGloss": "Jail",
        "dependent": 10,
        "dependentGloss": "21st"
      }, {
        "dep": "case",
        "governor": 12,
        "governorGloss": "July",
        "dependent": 11,
        "dependentGloss": "of"
      }, {
        "dep": "nmod:of",
        "governor": 10,
        "governorGloss": "21st",
        "dependent": 12,
        "dependentGloss": "July"
      }, {
        "dep": "punct",
        "governor": 12,
        "governorGloss": "July",
        "dependent": 13,
        "dependentGloss": ","
      }, {
        "dep": "nummod",
        "governor": 12,
        "governorGloss": "July",
        "dependent": 14,
        "dependentGloss": "1832"
      }, {
        "dep": "punct",
        "governor": 12,
        "governorGloss": "July",
        "dependent": 15,
        "dependentGloss": ","
      }, {
        "dep": "case",
        "governor": 18,
        "governorGloss": "Wilkins",
        "dependent": 16,
        "dependentGloss": "by"
      }, {
        "dep": "compound",
        "governor": 18,
        "governorGloss": "Wilkins",
        "dependent": 17,
        "dependentGloss": "B."
      }, {
        "dep": "nmod:by",
        "governor": 1,
        "governorGloss": "COMMITTED",
        "dependent": 18,
        "dependentGloss": "Wilkins"
      }, {
        "dep": "punct",
        "governor": 18,
        "governorGloss": "Wilkins",
        "dependent": 19,
        "dependentGloss": ","
      }, {
        "dep": "compound",
        "governor": 22,
        "governorGloss": "Justice",
        "dependent": 20,
        "dependentGloss": "Esq"
      }, {
        "dep": "det",
        "governor": 22,
        "governorGloss": "Justice",
        "dependent": 21,
        "dependentGloss": "a"
      }, {
        "dep": "appos",
        "governor": 18,
        "governorGloss": "Wilkins",
        "dependent": 22,
        "dependentGloss": "Justice"
      }, {
        "dep": "case",
        "governor": 25,
        "governorGloss": "Peace",
        "dependent": 23,
        "dependentGloss": "of"
      }, {
        "dep": "det",
        "governor": 25,
        "governorGloss": "Peace",
        "dependent": 24,
        "dependentGloss": "the"
      }, {
        "dep": "nmod:of",
        "governor": 22,
        "governorGloss": "Justice",
        "dependent": 25,
        "dependentGloss": "Peace"
      }, {"dep": "dep", "governor": 29, "governorGloss": "said", "dependent": 26, "dependentGloss": "in"}, {
        "dep": "cc",
        "governor": 26,
        "governorGloss": "in",
        "dependent": 27,
        "dependentGloss": "and"
      }, {
        "dep": "conj:and",
        "governor": 26,
        "governorGloss": "in",
        "dependent": 28,
        "dependentGloss": "for"
      }, {
        "dep": "dep",
        "governor": 29,
        "governorGloss": "said",
        "dependent": 28,
        "dependentGloss": "for"
      }, {
        "dep": "acl",
        "governor": 25,
        "governorGloss": "Peace",
        "dependent": 29,
        "dependentGloss": "said"
      }, {
        "dep": "nsubj",
        "governor": 29,
        "governorGloss": "said",
        "dependent": 30,
        "dependentGloss": "county"
      }, {
        "dep": "punct",
        "governor": 30,
        "governorGloss": "county",
        "dependent": 31,
        "dependentGloss": ","
      }, {
        "dep": "nummod",
        "governor": 33,
        "governorGloss": "negroes",
        "dependent": 32,
        "dependentGloss": "two"
      }, {
        "dep": "appos",
        "governor": 30,
        "governorGloss": "county",
        "dependent": 33,
        "dependentGloss": "negroes"
      }, {
        "dep": "punct",
        "governor": 30,
        "governorGloss": "county",
        "dependent": 34,
        "dependentGloss": "--"
      }, {
        "dep": "det",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 35,
        "dependentGloss": "the"
      }, {
        "dep": "dep",
        "governor": 30,
        "governorGloss": "county",
        "dependent": 36,
        "dependentGloss": "Man"
      }, {
        "dep": "case",
        "governor": 41,
        "governorGloss": "years",
        "dependent": 37,
        "dependentGloss": "about"
      }, {
        "dep": "nummod",
        "governor": 41,
        "governorGloss": "years",
        "dependent": 38,
        "dependentGloss": "23"
      }, {
        "dep": "cc",
        "governor": 38,
        "governorGloss": "23",
        "dependent": 39,
        "dependentGloss": "or"
      }, {
        "dep": "conj:or",
        "governor": 38,
        "governorGloss": "23",
        "dependent": 40,
        "dependentGloss": "24"
      }, {
        "dep": "nummod",
        "governor": 41,
        "governorGloss": "years",
        "dependent": 40,
        "dependentGloss": "24"
      }, {
        "dep": "nmod:about",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 41,
        "dependentGloss": "years"
      }, {
        "dep": "case",
        "governor": 43,
        "governorGloss": "age",
        "dependent": 42,
        "dependentGloss": "of"
      }, {
        "dep": "nmod:of",
        "governor": 41,
        "governorGloss": "years",
        "dependent": 43,
        "dependentGloss": "age"
      }, {
        "dep": "punct",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 44,
        "dependentGloss": ","
      }, {
        "dep": "nummod",
        "governor": 46,
        "governorGloss": "feet",
        "dependent": 45,
        "dependentGloss": "5"
      }, {
        "dep": "appos",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 46,
        "dependentGloss": "feet"
      }, {
        "dep": "nummod",
        "governor": 48,
        "governorGloss": "inches",
        "dependent": 47,
        "dependentGloss": "5"
      }, {
        "dep": "nmod:npmod",
        "governor": 49,
        "governorGloss": "high",
        "dependent": 48,
        "dependentGloss": "inches"
      }, {
        "dep": "amod",
        "governor": 46,
        "governorGloss": "feet",
        "dependent": 49,
        "dependentGloss": "high"
      }, {
        "dep": "punct",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 50,
        "dependentGloss": ","
      }, {
        "dep": "amod",
        "governor": 52,
        "governorGloss": "complexion",
        "dependent": 51,
        "dependentGloss": "dark"
      }, {
        "dep": "appos",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 52,
        "dependentGloss": "complexion"
      }, {
        "dep": "punct",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 53,
        "dependentGloss": ","
      }, {
        "dep": "case",
        "governor": 58,
        "governorGloss": "head",
        "dependent": 54,
        "dependentGloss": "with"
      }, {
        "dep": "det",
        "governor": 58,
        "governorGloss": "head",
        "dependent": 55,
        "dependentGloss": "a"
      }, {
        "dep": "amod",
        "governor": 58,
        "governorGloss": "head",
        "dependent": 56,
        "dependentGloss": "thick"
      }, {
        "dep": "amod",
        "governor": 58,
        "governorGloss": "head",
        "dependent": 57,
        "dependentGloss": "bushy"
      }, {
        "dep": "nmod:with",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 58,
        "dependentGloss": "head"
      }, {
        "dep": "case",
        "governor": 60,
        "governorGloss": "hair",
        "dependent": 59,
        "dependentGloss": "of"
      }, {
        "dep": "nmod:of",
        "governor": 58,
        "governorGloss": "head",
        "dependent": 60,
        "dependentGloss": "hair"
      }, {"dep": "punct", "governor": 36, "governorGloss": "Man", "dependent": 61, "dependentGloss": ","}, {
        "dep": "cc",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 62,
        "dependentGloss": "&"
      }, {
        "dep": "dep",
        "governor": 30,
        "governorGloss": "county",
        "dependent": 63,
        "dependentGloss": "calls"
      }, {
        "dep": "conj:&",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 63,
        "dependentGloss": "calls"
      }, {
        "dep": "compound",
        "governor": 66,
        "governorGloss": "EDWARDS",
        "dependent": 64,
        "dependentGloss": "himself"
      }, {
        "dep": "compound",
        "governor": 66,
        "governorGloss": "EDWARDS",
        "dependent": 65,
        "dependentGloss": "STEPHEN"
      }, {
        "dep": "xcomp",
        "governor": 63,
        "governorGloss": "calls",
        "dependent": 66,
        "dependentGloss": "EDWARDS"
      }, {"dep": "punct", "governor": 36, "governorGloss": "Man", "dependent": 67, "dependentGloss": ","}, {
        "dep": "cc",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 68,
        "dependentGloss": "and"
      }, {
        "dep": "dep",
        "governor": 30,
        "governorGloss": "county",
        "dependent": 69,
        "dependentGloss": "professes"
      }, {
        "dep": "conj:&",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 69,
        "dependentGloss": "professes"
      }, {
        "dep": "mark",
        "governor": 72,
        "governorGloss": "free",
        "dependent": 70,
        "dependentGloss": "to"
      }, {
        "dep": "cop",
        "governor": 72,
        "governorGloss": "free",
        "dependent": 71,
        "dependentGloss": "be"
      }, {
        "dep": "xcomp",
        "governor": 69,
        "governorGloss": "professes",
        "dependent": 72,
        "dependentGloss": "free"
      }, {"dep": "punct", "governor": 1, "governorGloss": "COMMITTED", "dependent": 73, "dependentGloss": "."}],
      "enhancedPlusPlusDependencies": [{
        "dep": "ROOT",
        "governor": 0,
        "governorGloss": "ROOT",
        "dependent": 1,
        "dependentGloss": "COMMITTED"
      }, {"dep": "case", "governor": 4, "governorGloss": "Jail", "dependent": 2, "dependentGloss": "To"}, {
        "dep": "det",
        "governor": 4,
        "governorGloss": "Jail",
        "dependent": 3,
        "dependentGloss": "the"
      }, {
        "dep": "nmod:to",
        "governor": 1,
        "governorGloss": "COMMITTED",
        "dependent": 4,
        "dependentGloss": "Jail"
      }, {
        "dep": "case",
        "governor": 7,
        "governorGloss": "County",
        "dependent": 5,
        "dependentGloss": "of"
      }, {
        "dep": "compound",
        "governor": 7,
        "governorGloss": "County",
        "dependent": 6,
        "dependentGloss": "Mobile"
      }, {
        "dep": "nmod:of",
        "governor": 4,
        "governorGloss": "Jail",
        "dependent": 7,
        "dependentGloss": "County"
      }, {
        "dep": "case",
        "governor": 10,
        "governorGloss": "21st",
        "dependent": 8,
        "dependentGloss": "on"
      }, {
        "dep": "det",
        "governor": 10,
        "governorGloss": "21st",
        "dependent": 9,
        "dependentGloss": "the"
      }, {
        "dep": "nmod:on",
        "governor": 4,
        "governorGloss": "Jail",
        "dependent": 10,
        "dependentGloss": "21st"
      }, {
        "dep": "case",
        "governor": 12,
        "governorGloss": "July",
        "dependent": 11,
        "dependentGloss": "of"
      }, {
        "dep": "nmod:of",
        "governor": 10,
        "governorGloss": "21st",
        "dependent": 12,
        "dependentGloss": "July"
      }, {
        "dep": "punct",
        "governor": 12,
        "governorGloss": "July",
        "dependent": 13,
        "dependentGloss": ","
      }, {
        "dep": "nummod",
        "governor": 12,
        "governorGloss": "July",
        "dependent": 14,
        "dependentGloss": "1832"
      }, {
        "dep": "punct",
        "governor": 12,
        "governorGloss": "July",
        "dependent": 15,
        "dependentGloss": ","
      }, {
        "dep": "case",
        "governor": 18,
        "governorGloss": "Wilkins",
        "dependent": 16,
        "dependentGloss": "by"
      }, {
        "dep": "compound",
        "governor": 18,
        "governorGloss": "Wilkins",
        "dependent": 17,
        "dependentGloss": "B."
      }, {
        "dep": "nmod:by",
        "governor": 1,
        "governorGloss": "COMMITTED",
        "dependent": 18,
        "dependentGloss": "Wilkins"
      }, {
        "dep": "punct",
        "governor": 18,
        "governorGloss": "Wilkins",
        "dependent": 19,
        "dependentGloss": ","
      }, {
        "dep": "compound",
        "governor": 22,
        "governorGloss": "Justice",
        "dependent": 20,
        "dependentGloss": "Esq"
      }, {
        "dep": "det",
        "governor": 22,
        "governorGloss": "Justice",
        "dependent": 21,
        "dependentGloss": "a"
      }, {
        "dep": "appos",
        "governor": 18,
        "governorGloss": "Wilkins",
        "dependent": 22,
        "dependentGloss": "Justice"
      }, {
        "dep": "case",
        "governor": 25,
        "governorGloss": "Peace",
        "dependent": 23,
        "dependentGloss": "of"
      }, {
        "dep": "det",
        "governor": 25,
        "governorGloss": "Peace",
        "dependent": 24,
        "dependentGloss": "the"
      }, {
        "dep": "nmod:of",
        "governor": 22,
        "governorGloss": "Justice",
        "dependent": 25,
        "dependentGloss": "Peace"
      }, {"dep": "dep", "governor": 29, "governorGloss": "said", "dependent": 26, "dependentGloss": "in"}, {
        "dep": "cc",
        "governor": 26,
        "governorGloss": "in",
        "dependent": 27,
        "dependentGloss": "and"
      }, {
        "dep": "conj:and",
        "governor": 26,
        "governorGloss": "in",
        "dependent": 28,
        "dependentGloss": "for"
      }, {
        "dep": "dep",
        "governor": 29,
        "governorGloss": "said",
        "dependent": 28,
        "dependentGloss": "for"
      }, {
        "dep": "acl",
        "governor": 25,
        "governorGloss": "Peace",
        "dependent": 29,
        "dependentGloss": "said"
      }, {
        "dep": "nsubj",
        "governor": 29,
        "governorGloss": "said",
        "dependent": 30,
        "dependentGloss": "county"
      }, {
        "dep": "punct",
        "governor": 30,
        "governorGloss": "county",
        "dependent": 31,
        "dependentGloss": ","
      }, {
        "dep": "nummod",
        "governor": 33,
        "governorGloss": "negroes",
        "dependent": 32,
        "dependentGloss": "two"
      }, {
        "dep": "appos",
        "governor": 30,
        "governorGloss": "county",
        "dependent": 33,
        "dependentGloss": "negroes"
      }, {
        "dep": "punct",
        "governor": 30,
        "governorGloss": "county",
        "dependent": 34,
        "dependentGloss": "--"
      }, {
        "dep": "det",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 35,
        "dependentGloss": "the"
      }, {
        "dep": "dep",
        "governor": 30,
        "governorGloss": "county",
        "dependent": 36,
        "dependentGloss": "Man"
      }, {
        "dep": "case",
        "governor": 41,
        "governorGloss": "years",
        "dependent": 37,
        "dependentGloss": "about"
      }, {
        "dep": "nummod",
        "governor": 41,
        "governorGloss": "years",
        "dependent": 38,
        "dependentGloss": "23"
      }, {
        "dep": "cc",
        "governor": 38,
        "governorGloss": "23",
        "dependent": 39,
        "dependentGloss": "or"
      }, {
        "dep": "conj:or",
        "governor": 38,
        "governorGloss": "23",
        "dependent": 40,
        "dependentGloss": "24"
      }, {
        "dep": "nummod",
        "governor": 41,
        "governorGloss": "years",
        "dependent": 40,
        "dependentGloss": "24"
      }, {
        "dep": "nmod:about",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 41,
        "dependentGloss": "years"
      }, {
        "dep": "case",
        "governor": 43,
        "governorGloss": "age",
        "dependent": 42,
        "dependentGloss": "of"
      }, {
        "dep": "nmod:of",
        "governor": 41,
        "governorGloss": "years",
        "dependent": 43,
        "dependentGloss": "age"
      }, {
        "dep": "punct",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 44,
        "dependentGloss": ","
      }, {
        "dep": "nummod",
        "governor": 46,
        "governorGloss": "feet",
        "dependent": 45,
        "dependentGloss": "5"
      }, {
        "dep": "appos",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 46,
        "dependentGloss": "feet"
      }, {
        "dep": "nummod",
        "governor": 48,
        "governorGloss": "inches",
        "dependent": 47,
        "dependentGloss": "5"
      }, {
        "dep": "nmod:npmod",
        "governor": 49,
        "governorGloss": "high",
        "dependent": 48,
        "dependentGloss": "inches"
      }, {
        "dep": "amod",
        "governor": 46,
        "governorGloss": "feet",
        "dependent": 49,
        "dependentGloss": "high"
      }, {
        "dep": "punct",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 50,
        "dependentGloss": ","
      }, {
        "dep": "amod",
        "governor": 52,
        "governorGloss": "complexion",
        "dependent": 51,
        "dependentGloss": "dark"
      }, {
        "dep": "appos",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 52,
        "dependentGloss": "complexion"
      }, {
        "dep": "punct",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 53,
        "dependentGloss": ","
      }, {
        "dep": "case",
        "governor": 58,
        "governorGloss": "head",
        "dependent": 54,
        "dependentGloss": "with"
      }, {
        "dep": "det",
        "governor": 58,
        "governorGloss": "head",
        "dependent": 55,
        "dependentGloss": "a"
      }, {
        "dep": "amod",
        "governor": 58,
        "governorGloss": "head",
        "dependent": 56,
        "dependentGloss": "thick"
      }, {
        "dep": "amod",
        "governor": 58,
        "governorGloss": "head",
        "dependent": 57,
        "dependentGloss": "bushy"
      }, {
        "dep": "nmod:with",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 58,
        "dependentGloss": "head"
      }, {
        "dep": "case",
        "governor": 60,
        "governorGloss": "hair",
        "dependent": 59,
        "dependentGloss": "of"
      }, {
        "dep": "nmod:of",
        "governor": 58,
        "governorGloss": "head",
        "dependent": 60,
        "dependentGloss": "hair"
      }, {"dep": "punct", "governor": 36, "governorGloss": "Man", "dependent": 61, "dependentGloss": ","}, {
        "dep": "cc",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 62,
        "dependentGloss": "&"
      }, {
        "dep": "dep",
        "governor": 30,
        "governorGloss": "county",
        "dependent": 63,
        "dependentGloss": "calls"
      }, {
        "dep": "conj:&",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 63,
        "dependentGloss": "calls"
      }, {
        "dep": "compound",
        "governor": 66,
        "governorGloss": "EDWARDS",
        "dependent": 64,
        "dependentGloss": "himself"
      }, {
        "dep": "compound",
        "governor": 66,
        "governorGloss": "EDWARDS",
        "dependent": 65,
        "dependentGloss": "STEPHEN"
      }, {
        "dep": "xcomp",
        "governor": 63,
        "governorGloss": "calls",
        "dependent": 66,
        "dependentGloss": "EDWARDS"
      }, {"dep": "punct", "governor": 36, "governorGloss": "Man", "dependent": 67, "dependentGloss": ","}, {
        "dep": "cc",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 68,
        "dependentGloss": "and"
      }, {
        "dep": "dep",
        "governor": 30,
        "governorGloss": "county",
        "dependent": 69,
        "dependentGloss": "professes"
      }, {
        "dep": "conj:&",
        "governor": 36,
        "governorGloss": "Man",
        "dependent": 69,
        "dependentGloss": "professes"
      }, {
        "dep": "mark",
        "governor": 72,
        "governorGloss": "free",
        "dependent": 70,
        "dependentGloss": "to"
      }, {
        "dep": "cop",
        "governor": 72,
        "governorGloss": "free",
        "dependent": 71,
        "dependentGloss": "be"
      }, {
        "dep": "xcomp",
        "governor": 69,
        "governorGloss": "professes",
        "dependent": 72,
        "dependentGloss": "free"
      }, {"dep": "punct", "governor": 1, "governorGloss": "COMMITTED", "dependent": 73, "dependentGloss": "."}],
      "openie": [{
        "subject": "24 years",
        "subjectSpan": [39, 41],
        "relation": "about Man is",
        "relationSpan": [36, 37],
        "object": "5 feet 5 inches high",
        "objectSpan": [44, 49]
      }, {
        "subject": "Man",
        "subjectSpan": [35, 36],
        "relation": "is with",
        "relationSpan": [53, 54],
        "object": "thick bushy head of hair",
        "objectSpan": [55, 60]
      }, {
        "subject": "24 years",
        "subjectSpan": [39, 41],
        "relation": "about Man is",
        "relationSpan": [36, 37],
        "object": "dark complexion",
        "objectSpan": [50, 52]
      }, {
        "subject": "B. Wilkins",
        "subjectSpan": [16, 18],
        "relation": "Justice of",
        "relationSpan": [21, 23],
        "object": "Peace said county",
        "objectSpan": [24, 30]
      }, {
        "subject": "thick bushy head",
        "subjectSpan": [55, 58],
        "relation": "with Man is",
        "relationSpan": [53, 54],
        "object": "dark complexion",
        "objectSpan": [50, 52]
      }, {
        "subject": "County",
        "subjectSpan": [6, 7],
        "relation": "is of",
        "relationSpan": [4, 5],
        "object": "Mobile",
        "objectSpan": [5, 6]
      }, {
        "subject": "thick bushy head",
        "subjectSpan": [55, 58],
        "relation": "with Man is",
        "relationSpan": [53, 54],
        "object": "5 feet 5 inches high",
        "objectSpan": [44, 49]
      }],
      "entitymentions": [{
        "docTokenBegin": 5,
        "docTokenEnd": 6,
        "tokenBegin": 5,
        "tokenEnd": 6,
        "text": "Mobile",
        "characterOffsetBegin": 25,
        "characterOffsetEnd": 31,
        "ner": "TITLE"
      }, {
        "docTokenBegin": 6,
        "docTokenEnd": 7,
        "tokenBegin": 6,
        "tokenEnd": 7,
        "text": "County",
        "characterOffsetBegin": 32,
        "characterOffsetEnd": 38,
        "ner": "LOCATION"
      }, {
        "docTokenBegin": 8,
        "docTokenEnd": 14,
        "tokenBegin": 8,
        "tokenEnd": 14,
        "text": "the 21st of July, 1832",
        "characterOffsetBegin": 42,
        "characterOffsetEnd": 64,
        "ner": "DATE",
        "normalizedNER": "1832-07-21",
        "timex": {"tid": "t1", "type": "DATE", "value": "1832-07-21"}
      }, {
        "docTokenBegin": 16,
        "docTokenEnd": 18,
        "tokenBegin": 16,
        "tokenEnd": 18,
        "text": "B. Wilkins",
        "characterOffsetBegin": 69,
        "characterOffsetEnd": 79,
        "ner": "PERSON"
      }, {
        "docTokenBegin": 31,
        "docTokenEnd": 32,
        "tokenBegin": 31,
        "tokenEnd": 32,
        "text": "two",
        "characterOffsetBegin": 132,
        "characterOffsetEnd": 135,
        "ner": "NUMBER",
        "normalizedNER": "2.0"
      }, {
        "docTokenBegin": 37,
        "docTokenEnd": 38,
        "tokenBegin": 37,
        "tokenEnd": 38,
        "text": "23",
        "characterOffsetBegin": 158,
        "characterOffsetEnd": 160,
        "ner": "NUMBER",
        "normalizedNER": "~23.0"
      }, {
        "docTokenBegin": 39,
        "docTokenEnd": 41,
        "tokenBegin": 39,
        "tokenEnd": 41,
        "text": "24 years",
        "characterOffsetBegin": 164,
        "characterOffsetEnd": 172,
        "ner": "DURATION",
        "normalizedNER": "P24Y",
        "timex": {"tid": "t2", "type": "DURATION", "value": "P24Y"}
      }, {
        "docTokenBegin": 44,
        "docTokenEnd": 45,
        "tokenBegin": 44,
        "tokenEnd": 45,
        "text": "5",
        "characterOffsetBegin": 181,
        "characterOffsetEnd": 182,
        "ner": "NUMBER",
        "normalizedNER": "5.0"
      }, {
        "docTokenBegin": 46,
        "docTokenEnd": 47,
        "tokenBegin": 46,
        "tokenEnd": 47,
        "text": "5",
        "characterOffsetBegin": 188,
        "characterOffsetEnd": 189,
        "ner": "NUMBER",
        "normalizedNER": "5.0"
      }, {
        "docTokenBegin": 64,
        "docTokenEnd": 66,
        "tokenBegin": 64,
        "tokenEnd": 66,
        "text": "STEPHEN EDWARDS",
        "characterOffsetBegin": 269,
        "characterOffsetEnd": 284,
        "ner": "PERSON"
      }],
      "tokens": [{
        "index": 1,
        "word": "COMMITTED",
        "originalText": "COMMITTED",
        "lemma": "commit",
        "characterOffsetBegin": 0,
        "characterOffsetEnd": 9,
        "pos": "VBN",
        "ner": "O",
        "before": "",
        "after": "\n"
      }, {
        "index": 2,
        "word": "To",
        "originalText": "To",
        "lemma": "to",
        "characterOffsetBegin": 10,
        "characterOffsetEnd": 12,
        "pos": "TO",
        "ner": "O",
        "before": "\n",
        "after": " "
      }, {
        "index": 3,
        "word": "the",
        "originalText": "the",
        "lemma": "the",
        "characterOffsetBegin": 13,
        "characterOffsetEnd": 16,
        "pos": "DT",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 4,
        "word": "Jail",
        "originalText": "Jail",
        "lemma": "jail",
        "characterOffsetBegin": 17,
        "characterOffsetEnd": 21,
        "pos": "NN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 5,
        "word": "of",
        "originalText": "of",
        "lemma": "of",
        "characterOffsetBegin": 22,
        "characterOffsetEnd": 24,
        "pos": "IN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 6,
        "word": "Mobile",
        "originalText": "Mobile",
        "lemma": "Mobile",
        "characterOffsetBegin": 25,
        "characterOffsetEnd": 31,
        "pos": "NNP",
        "ner": "TITLE",
        "before": " ",
        "after": " "
      }, {
        "index": 7,
        "word": "County",
        "originalText": "County",
        "lemma": "County",
        "characterOffsetBegin": 32,
        "characterOffsetEnd": 38,
        "pos": "NNP",
        "ner": "LOCATION",
        "before": " ",
        "after": " "
      }, {
        "index": 8,
        "word": "on",
        "originalText": "on",
        "lemma": "on",
        "characterOffsetBegin": 39,
        "characterOffsetEnd": 41,
        "pos": "IN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 9,
        "word": "the",
        "originalText": "the",
        "lemma": "the",
        "characterOffsetBegin": 42,
        "characterOffsetEnd": 45,
        "pos": "DT",
        "ner": "DATE",
        "normalizedNER": "1832-07-21",
        "before": " ",
        "after": " ",
        "timex": {"tid": "t1", "type": "DATE", "value": "1832-07-21"}
      }, {
        "index": 10,
        "word": "21st",
        "originalText": "21st",
        "lemma": "21st",
        "characterOffsetBegin": 46,
        "characterOffsetEnd": 50,
        "pos": "CD",
        "ner": "DATE",
        "normalizedNER": "1832-07-21",
        "before": " ",
        "after": " ",
        "timex": {"tid": "t1", "type": "DATE", "value": "1832-07-21"}
      }, {
        "index": 11,
        "word": "of",
        "originalText": "of",
        "lemma": "of",
        "characterOffsetBegin": 51,
        "characterOffsetEnd": 53,
        "pos": "IN",
        "ner": "DATE",
        "normalizedNER": "1832-07-21",
        "before": " ",
        "after": " ",
        "timex": {"tid": "t1", "type": "DATE", "value": "1832-07-21"}
      }, {
        "index": 12,
        "word": "July",
        "originalText": "July",
        "lemma": "July",
        "characterOffsetBegin": 54,
        "characterOffsetEnd": 58,
        "pos": "NNP",
        "ner": "DATE",
        "normalizedNER": "1832-07-21",
        "before": " ",
        "after": "",
        "timex": {"tid": "t1", "type": "DATE", "value": "1832-07-21"}
      }, {
        "index": 13,
        "word": ",",
        "originalText": ",",
        "lemma": ",",
        "characterOffsetBegin": 58,
        "characterOffsetEnd": 59,
        "pos": ",",
        "ner": "DATE",
        "normalizedNER": "1832-07-21",
        "before": "",
        "after": " ",
        "timex": {"tid": "t1", "type": "DATE", "value": "1832-07-21"}
      }, {
        "index": 14,
        "word": "1832",
        "originalText": "1832",
        "lemma": "1832",
        "characterOffsetBegin": 60,
        "characterOffsetEnd": 64,
        "pos": "CD",
        "ner": "DATE",
        "normalizedNER": "1832-07-21",
        "before": " ",
        "after": "",
        "timex": {"tid": "t1", "type": "DATE", "value": "1832-07-21"}
      }, {
        "index": 15,
        "word": ",",
        "originalText": ",",
        "lemma": ",",
        "characterOffsetBegin": 64,
        "characterOffsetEnd": 65,
        "pos": ",",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 16,
        "word": "by",
        "originalText": "by",
        "lemma": "by",
        "characterOffsetBegin": 66,
        "characterOffsetEnd": 68,
        "pos": "IN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 17,
        "word": "B.",
        "originalText": "B.",
        "lemma": "B.",
        "characterOffsetBegin": 69,
        "characterOffsetEnd": 71,
        "pos": "NNP",
        "ner": "PERSON",
        "before": " ",
        "after": " "
      }, {
        "index": 18,
        "word": "Wilkins",
        "originalText": "Wilkins",
        "lemma": "Wilkins",
        "characterOffsetBegin": 72,
        "characterOffsetEnd": 79,
        "pos": "NNP",
        "ner": "PERSON",
        "before": " ",
        "after": ""
      }, {
        "index": 19,
        "word": ",",
        "originalText": ",",
        "lemma": ",",
        "characterOffsetBegin": 79,
        "characterOffsetEnd": 80,
        "pos": ",",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 20,
        "word": "Esq",
        "originalText": "Esq",
        "lemma": "Esq",
        "characterOffsetBegin": 81,
        "characterOffsetEnd": 84,
        "pos": "NNP",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 21,
        "word": "a",
        "originalText": "a",
        "lemma": "a",
        "characterOffsetBegin": 85,
        "characterOffsetEnd": 86,
        "pos": "DT",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 22,
        "word": "Justice",
        "originalText": "Justice",
        "lemma": "justice",
        "characterOffsetBegin": 87,
        "characterOffsetEnd": 94,
        "pos": "NN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 23,
        "word": "of",
        "originalText": "of",
        "lemma": "of",
        "characterOffsetBegin": 95,
        "characterOffsetEnd": 97,
        "pos": "IN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 24,
        "word": "the",
        "originalText": "the",
        "lemma": "the",
        "characterOffsetBegin": 98,
        "characterOffsetEnd": 101,
        "pos": "DT",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 25,
        "word": "Peace",
        "originalText": "Peace",
        "lemma": "peace",
        "characterOffsetBegin": 102,
        "characterOffsetEnd": 107,
        "pos": "NN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 26,
        "word": "in",
        "originalText": "in",
        "lemma": "in",
        "characterOffsetBegin": 108,
        "characterOffsetEnd": 110,
        "pos": "IN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 27,
        "word": "and",
        "originalText": "and",
        "lemma": "and",
        "characterOffsetBegin": 111,
        "characterOffsetEnd": 114,
        "pos": "CC",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 28,
        "word": "for",
        "originalText": "for",
        "lemma": "for",
        "characterOffsetBegin": 115,
        "characterOffsetEnd": 118,
        "pos": "IN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 29,
        "word": "said",
        "originalText": "said",
        "lemma": "say",
        "characterOffsetBegin": 119,
        "characterOffsetEnd": 123,
        "pos": "VBD",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 30,
        "word": "county",
        "originalText": "county",
        "lemma": "county",
        "characterOffsetBegin": 124,
        "characterOffsetEnd": 130,
        "pos": "NN",
        "ner": "O",
        "before": " ",
        "after": ""
      }, {
        "index": 31,
        "word": ",",
        "originalText": ",",
        "lemma": ",",
        "characterOffsetBegin": 130,
        "characterOffsetEnd": 131,
        "pos": ",",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 32,
        "word": "two",
        "originalText": "two",
        "lemma": "two",
        "characterOffsetBegin": 132,
        "characterOffsetEnd": 135,
        "pos": "CD",
        "ner": "NUMBER",
        "normalizedNER": "2.0",
        "before": " ",
        "after": " "
      }, {
        "index": 33,
        "word": "negroes",
        "originalText": "negroes",
        "lemma": "negro",
        "characterOffsetBegin": 136,
        "characterOffsetEnd": 143,
        "pos": "NNS",
        "ner": "O",
        "before": " ",
        "after": ""
      }, {
        "index": 34,
        "word": "--",
        "originalText": "—",
        "lemma": "--",
        "characterOffsetBegin": 143,
        "characterOffsetEnd": 144,
        "pos": ":",
        "ner": "O",
        "before": "",
        "after": ""
      }, {
        "index": 35,
        "word": "the",
        "originalText": "the",
        "lemma": "the",
        "characterOffsetBegin": 144,
        "characterOffsetEnd": 147,
        "pos": "DT",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 36,
        "word": "Man",
        "originalText": "Man",
        "lemma": "man",
        "characterOffsetBegin": 148,
        "characterOffsetEnd": 151,
        "pos": "NN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 37,
        "word": "about",
        "originalText": "about",
        "lemma": "about",
        "characterOffsetBegin": 152,
        "characterOffsetEnd": 157,
        "pos": "IN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 38,
        "word": "23",
        "originalText": "23",
        "lemma": "23",
        "characterOffsetBegin": 158,
        "characterOffsetEnd": 160,
        "pos": "CD",
        "ner": "NUMBER",
        "normalizedNER": "~23.0",
        "before": " ",
        "after": " "
      }, {
        "index": 39,
        "word": "or",
        "originalText": "or",
        "lemma": "or",
        "characterOffsetBegin": 161,
        "characterOffsetEnd": 163,
        "pos": "CC",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 40,
        "word": "24",
        "originalText": "24",
        "lemma": "24",
        "characterOffsetBegin": 164,
        "characterOffsetEnd": 166,
        "pos": "CD",
        "ner": "DURATION",
        "normalizedNER": "P24Y",
        "before": " ",
        "after": " ",
        "timex": {"tid": "t2", "type": "DURATION", "value": "P24Y"}
      }, {
        "index": 41,
        "word": "years",
        "originalText": "years",
        "lemma": "year",
        "characterOffsetBegin": 167,
        "characterOffsetEnd": 172,
        "pos": "NNS",
        "ner": "DURATION",
        "normalizedNER": "P24Y",
        "before": " ",
        "after": " ",
        "timex": {"tid": "t2", "type": "DURATION", "value": "P24Y"}
      }, {
        "index": 42,
        "word": "of",
        "originalText": "of",
        "lemma": "of",
        "characterOffsetBegin": 173,
        "characterOffsetEnd": 175,
        "pos": "IN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 43,
        "word": "age",
        "originalText": "age",
        "lemma": "age",
        "characterOffsetBegin": 176,
        "characterOffsetEnd": 179,
        "pos": "NN",
        "ner": "O",
        "before": " ",
        "after": ""
      }, {
        "index": 44,
        "word": ",",
        "originalText": ",",
        "lemma": ",",
        "characterOffsetBegin": 179,
        "characterOffsetEnd": 180,
        "pos": ",",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 45,
        "word": "5",
        "originalText": "5",
        "lemma": "5",
        "characterOffsetBegin": 181,
        "characterOffsetEnd": 182,
        "pos": "CD",
        "ner": "NUMBER",
        "normalizedNER": "5.0",
        "before": " ",
        "after": " "
      }, {
        "index": 46,
        "word": "feet",
        "originalText": "feet",
        "lemma": "foot",
        "characterOffsetBegin": 183,
        "characterOffsetEnd": 187,
        "pos": "NNS",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 47,
        "word": "5",
        "originalText": "5",
        "lemma": "5",
        "characterOffsetBegin": 188,
        "characterOffsetEnd": 189,
        "pos": "CD",
        "ner": "NUMBER",
        "normalizedNER": "5.0",
        "before": " ",
        "after": " "
      }, {
        "index": 48,
        "word": "inches",
        "originalText": "inches",
        "lemma": "inch",
        "characterOffsetBegin": 190,
        "characterOffsetEnd": 196,
        "pos": "NNS",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 49,
        "word": "high",
        "originalText": "high",
        "lemma": "high",
        "characterOffsetBegin": 197,
        "characterOffsetEnd": 201,
        "pos": "JJ",
        "ner": "O",
        "before": " ",
        "after": ""
      }, {
        "index": 50,
        "word": ",",
        "originalText": ",",
        "lemma": ",",
        "characterOffsetBegin": 201,
        "characterOffsetEnd": 202,
        "pos": ",",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 51,
        "word": "dark",
        "originalText": "dark",
        "lemma": "dark",
        "characterOffsetBegin": 203,
        "characterOffsetEnd": 207,
        "pos": "JJ",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 52,
        "word": "complexion",
        "originalText": "complexion",
        "lemma": "complexion",
        "characterOffsetBegin": 208,
        "characterOffsetEnd": 218,
        "pos": "NN",
        "ner": "O",
        "before": " ",
        "after": ""
      }, {
        "index": 53,
        "word": ",",
        "originalText": ",",
        "lemma": ",",
        "characterOffsetBegin": 218,
        "characterOffsetEnd": 219,
        "pos": ",",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 54,
        "word": "with",
        "originalText": "with",
        "lemma": "with",
        "characterOffsetBegin": 220,
        "characterOffsetEnd": 224,
        "pos": "IN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 55,
        "word": "a",
        "originalText": "a",
        "lemma": "a",
        "characterOffsetBegin": 225,
        "characterOffsetEnd": 226,
        "pos": "DT",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 56,
        "word": "thick",
        "originalText": "thick",
        "lemma": "thick",
        "characterOffsetBegin": 227,
        "characterOffsetEnd": 232,
        "pos": "JJ",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 57,
        "word": "bushy",
        "originalText": "bushy",
        "lemma": "bushy",
        "characterOffsetBegin": 233,
        "characterOffsetEnd": 238,
        "pos": "JJ",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 58,
        "word": "head",
        "originalText": "head",
        "lemma": "head",
        "characterOffsetBegin": 239,
        "characterOffsetEnd": 243,
        "pos": "NN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 59,
        "word": "of",
        "originalText": "of",
        "lemma": "of",
        "characterOffsetBegin": 244,
        "characterOffsetEnd": 246,
        "pos": "IN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 60,
        "word": "hair",
        "originalText": "hair",
        "lemma": "hair",
        "characterOffsetBegin": 247,
        "characterOffsetEnd": 251,
        "pos": "NN",
        "ner": "O",
        "before": " ",
        "after": ""
      }, {
        "index": 61,
        "word": ",",
        "originalText": ",",
        "lemma": ",",
        "characterOffsetBegin": 251,
        "characterOffsetEnd": 252,
        "pos": ",",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 62,
        "word": "&",
        "originalText": "&",
        "lemma": "&",
        "characterOffsetBegin": 253,
        "characterOffsetEnd": 254,
        "pos": "CC",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 63,
        "word": "calls",
        "originalText": "calls",
        "lemma": "call",
        "characterOffsetBegin": 255,
        "characterOffsetEnd": 260,
        "pos": "VBZ",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 64,
        "word": "himself",
        "originalText": "himself",
        "lemma": "himself",
        "characterOffsetBegin": 261,
        "characterOffsetEnd": 268,
        "pos": "PRP",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 65,
        "word": "STEPHEN",
        "originalText": "STEPHEN",
        "lemma": "STEPHEN",
        "characterOffsetBegin": 269,
        "characterOffsetEnd": 276,
        "pos": "NNP",
        "ner": "PERSON",
        "before": " ",
        "after": " "
      }, {
        "index": 66,
        "word": "EDWARDS",
        "originalText": "EDWARDS",
        "lemma": "EDWARDS",
        "characterOffsetBegin": 277,
        "characterOffsetEnd": 284,
        "pos": "NNP",
        "ner": "PERSON",
        "before": " ",
        "after": ""
      }, {
        "index": 67,
        "word": ",",
        "originalText": ",",
        "lemma": ",",
        "characterOffsetBegin": 284,
        "characterOffsetEnd": 285,
        "pos": ",",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 68,
        "word": "and",
        "originalText": "and",
        "lemma": "and",
        "characterOffsetBegin": 286,
        "characterOffsetEnd": 289,
        "pos": "CC",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 69,
        "word": "professes",
        "originalText": "professes",
        "lemma": "profess",
        "characterOffsetBegin": 290,
        "characterOffsetEnd": 299,
        "pos": "VBZ",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 70,
        "word": "to",
        "originalText": "to",
        "lemma": "to",
        "characterOffsetBegin": 300,
        "characterOffsetEnd": 302,
        "pos": "TO",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 71,
        "word": "be",
        "originalText": "be",
        "lemma": "be",
        "characterOffsetBegin": 303,
        "characterOffsetEnd": 305,
        "pos": "VB",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 72,
        "word": "free",
        "originalText": "free",
        "lemma": "free",
        "characterOffsetBegin": 306,
        "characterOffsetEnd": 310,
        "pos": "JJ",
        "ner": "O",
        "before": " ",
        "after": ""
      }, {
        "index": 73,
        "word": ".",
        "originalText": ".",
        "lemma": ".",
        "characterOffsetBegin": 310,
        "characterOffsetEnd": 311,
        "pos": ".",
        "ner": "O",
        "before": "",
        "after": " "
      }]
    }, {
      "index": 1,
      "basicDependencies": [{
        "dep": "ROOT",
        "governor": 0,
        "governorGloss": "ROOT",
        "dependent": 6,
        "dependentGloss": "years"
      }, {
        "dep": "det",
        "governor": 2,
        "governorGloss": "woman",
        "dependent": 1,
        "dependentGloss": "The"
      }, {
        "dep": "nsubj",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 2,
        "dependentGloss": "woman"
      }, {
        "dep": "cop",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 3,
        "dependentGloss": "is"
      }, {
        "dep": "advmod",
        "governor": 5,
        "governorGloss": "20",
        "dependent": 4,
        "dependentGloss": "about"
      }, {
        "dep": "nummod",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 5,
        "dependentGloss": "20"
      }, {"dep": "case", "governor": 8, "governorGloss": "age", "dependent": 7, "dependentGloss": "of"}, {
        "dep": "nmod",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 8,
        "dependentGloss": "age"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 9,
        "dependentGloss": ","
      }, {
        "dep": "nummod",
        "governor": 11,
        "governorGloss": "feet",
        "dependent": 10,
        "dependentGloss": "4"
      }, {
        "dep": "nmod:npmod",
        "governor": 14,
        "governorGloss": "high",
        "dependent": 11,
        "dependentGloss": "feet"
      }, {
        "dep": "nummod",
        "governor": 13,
        "governorGloss": "inches",
        "dependent": 12,
        "dependentGloss": "91/2"
      }, {
        "dep": "nmod:npmod",
        "governor": 14,
        "governorGloss": "high",
        "dependent": 13,
        "dependentGloss": "inches"
      }, {
        "dep": "amod",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 14,
        "dependentGloss": "high"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 15,
        "dependentGloss": ","
      }, {
        "dep": "amod",
        "governor": 17,
        "governorGloss": "complex-ion",
        "dependent": 16,
        "dependentGloss": "dark"
      }, {
        "dep": "appos",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 17,
        "dependentGloss": "complex-ion"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 18,
        "dependentGloss": ","
      }, {
        "dep": "case",
        "governor": 23,
        "governorGloss": "head",
        "dependent": 19,
        "dependentGloss": "with"
      }, {
        "dep": "det",
        "governor": 23,
        "governorGloss": "head",
        "dependent": 20,
        "dependentGloss": "a"
      }, {
        "dep": "amod",
        "governor": 23,
        "governorGloss": "head",
        "dependent": 21,
        "dependentGloss": "thick"
      }, {
        "dep": "amod",
        "governor": 23,
        "governorGloss": "head",
        "dependent": 22,
        "dependentGloss": "bushy"
      }, {
        "dep": "nmod",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 23,
        "dependentGloss": "head"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 24,
        "dependentGloss": ","
      }, {
        "dep": "cc",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 25,
        "dependentGloss": "and"
      }, {
        "dep": "conj",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 26,
        "dependentGloss": "says"
      }, {
        "dep": "nsubj",
        "governor": 30,
        "governorGloss": "wife",
        "dependent": 27,
        "dependentGloss": "she"
      }, {
        "dep": "cop",
        "governor": 30,
        "governorGloss": "wife",
        "dependent": 28,
        "dependentGloss": "is"
      }, {
        "dep": "det",
        "governor": 30,
        "governorGloss": "wife",
        "dependent": 29,
        "dependentGloss": "the"
      }, {
        "dep": "ccomp",
        "governor": 26,
        "governorGloss": "says",
        "dependent": 30,
        "dependentGloss": "wife"
      }, {
        "dep": "mark",
        "governor": 32,
        "governorGloss": "said",
        "dependent": 31,
        "dependentGloss": "of"
      }, {
        "dep": "acl",
        "governor": 30,
        "governorGloss": "wife",
        "dependent": 32,
        "dependentGloss": "said"
      }, {
        "dep": "dobj",
        "governor": 32,
        "governorGloss": "said",
        "dependent": 33,
        "dependentGloss": "Stephen"
      }, {
        "dep": "punct",
        "governor": 33,
        "governorGloss": "Stephen",
        "dependent": 34,
        "dependentGloss": ","
      }, {
        "dep": "cc",
        "governor": 33,
        "governorGloss": "Stephen",
        "dependent": 35,
        "dependentGloss": "and"
      }, {
        "dep": "conj",
        "governor": 33,
        "governorGloss": "Stephen",
        "dependent": 36,
        "dependentGloss": "professes"
      }, {
        "dep": "advmod",
        "governor": 36,
        "governorGloss": "professes",
        "dependent": 37,
        "dependentGloss": "also"
      }, {
        "dep": "mark",
        "governor": 40,
        "governorGloss": "free",
        "dependent": 38,
        "dependentGloss": "to"
      }, {
        "dep": "cop",
        "governor": 40,
        "governorGloss": "free",
        "dependent": 39,
        "dependentGloss": "be"
      }, {
        "dep": "xcomp",
        "governor": 36,
        "governorGloss": "professes",
        "dependent": 40,
        "dependentGloss": "free"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 41,
        "dependentGloss": ";"
      }, {
        "dep": "cc",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 42,
        "dependentGloss": "and"
      }, {
        "dep": "nsubj",
        "governor": 44,
        "governorGloss": "say",
        "dependent": 43,
        "dependentGloss": "they"
      }, {
        "dep": "conj",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 44,
        "dependentGloss": "say"
      }, {
        "dep": "nsubj",
        "governor": 50,
        "governorGloss": "Orleans",
        "dependent": 45,
        "dependentGloss": "they"
      }, {
        "dep": "cop",
        "governor": 50,
        "governorGloss": "Orleans",
        "dependent": 46,
        "dependentGloss": "are"
      }, {
        "dep": "advmod",
        "governor": 50,
        "governorGloss": "Orleans",
        "dependent": 47,
        "dependentGloss": "late"
      }, {
        "dep": "case",
        "governor": 50,
        "governorGloss": "Orleans",
        "dependent": 48,
        "dependentGloss": "from"
      }, {
        "dep": "compound",
        "governor": 50,
        "governorGloss": "Orleans",
        "dependent": 49,
        "dependentGloss": "New"
      }, {
        "dep": "ccomp",
        "governor": 44,
        "governorGloss": "say",
        "dependent": 50,
        "dependentGloss": "Orleans"
      }, {"dep": "punct", "governor": 6, "governorGloss": "years", "dependent": 51, "dependentGloss": "."}],
      "enhancedDependencies": [{
        "dep": "ROOT",
        "governor": 0,
        "governorGloss": "ROOT",
        "dependent": 6,
        "dependentGloss": "years"
      }, {
        "dep": "det",
        "governor": 2,
        "governorGloss": "woman",
        "dependent": 1,
        "dependentGloss": "The"
      }, {
        "dep": "nsubj",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 2,
        "dependentGloss": "woman"
      }, {
        "dep": "nsubj",
        "governor": 26,
        "governorGloss": "says",
        "dependent": 2,
        "dependentGloss": "woman"
      }, {
        "dep": "cop",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 3,
        "dependentGloss": "is"
      }, {
        "dep": "advmod",
        "governor": 5,
        "governorGloss": "20",
        "dependent": 4,
        "dependentGloss": "about"
      }, {
        "dep": "nummod",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 5,
        "dependentGloss": "20"
      }, {
        "dep": "case",
        "governor": 8,
        "governorGloss": "age",
        "dependent": 7,
        "dependentGloss": "of"
      }, {
        "dep": "nmod:of",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 8,
        "dependentGloss": "age"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 9,
        "dependentGloss": ","
      }, {
        "dep": "nummod",
        "governor": 11,
        "governorGloss": "feet",
        "dependent": 10,
        "dependentGloss": "4"
      }, {
        "dep": "nmod:npmod",
        "governor": 14,
        "governorGloss": "high",
        "dependent": 11,
        "dependentGloss": "feet"
      }, {
        "dep": "nummod",
        "governor": 13,
        "governorGloss": "inches",
        "dependent": 12,
        "dependentGloss": "91/2"
      }, {
        "dep": "nmod:npmod",
        "governor": 14,
        "governorGloss": "high",
        "dependent": 13,
        "dependentGloss": "inches"
      }, {
        "dep": "amod",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 14,
        "dependentGloss": "high"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 15,
        "dependentGloss": ","
      }, {
        "dep": "amod",
        "governor": 17,
        "governorGloss": "complex-ion",
        "dependent": 16,
        "dependentGloss": "dark"
      }, {
        "dep": "appos",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 17,
        "dependentGloss": "complex-ion"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 18,
        "dependentGloss": ","
      }, {
        "dep": "case",
        "governor": 23,
        "governorGloss": "head",
        "dependent": 19,
        "dependentGloss": "with"
      }, {
        "dep": "det",
        "governor": 23,
        "governorGloss": "head",
        "dependent": 20,
        "dependentGloss": "a"
      }, {
        "dep": "amod",
        "governor": 23,
        "governorGloss": "head",
        "dependent": 21,
        "dependentGloss": "thick"
      }, {
        "dep": "amod",
        "governor": 23,
        "governorGloss": "head",
        "dependent": 22,
        "dependentGloss": "bushy"
      }, {
        "dep": "nmod:with",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 23,
        "dependentGloss": "head"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 24,
        "dependentGloss": ","
      }, {
        "dep": "cc",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 25,
        "dependentGloss": "and"
      }, {
        "dep": "conj:and",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 26,
        "dependentGloss": "says"
      }, {
        "dep": "nsubj",
        "governor": 30,
        "governorGloss": "wife",
        "dependent": 27,
        "dependentGloss": "she"
      }, {
        "dep": "cop",
        "governor": 30,
        "governorGloss": "wife",
        "dependent": 28,
        "dependentGloss": "is"
      }, {
        "dep": "det",
        "governor": 30,
        "governorGloss": "wife",
        "dependent": 29,
        "dependentGloss": "the"
      }, {
        "dep": "ccomp",
        "governor": 26,
        "governorGloss": "says",
        "dependent": 30,
        "dependentGloss": "wife"
      }, {
        "dep": "mark",
        "governor": 32,
        "governorGloss": "said",
        "dependent": 31,
        "dependentGloss": "of"
      }, {
        "dep": "acl:of",
        "governor": 30,
        "governorGloss": "wife",
        "dependent": 32,
        "dependentGloss": "said"
      }, {
        "dep": "dobj",
        "governor": 32,
        "governorGloss": "said",
        "dependent": 33,
        "dependentGloss": "Stephen"
      }, {
        "dep": "punct",
        "governor": 33,
        "governorGloss": "Stephen",
        "dependent": 34,
        "dependentGloss": ","
      }, {
        "dep": "cc",
        "governor": 33,
        "governorGloss": "Stephen",
        "dependent": 35,
        "dependentGloss": "and"
      }, {
        "dep": "dobj",
        "governor": 32,
        "governorGloss": "said",
        "dependent": 36,
        "dependentGloss": "professes"
      }, {
        "dep": "conj:and",
        "governor": 33,
        "governorGloss": "Stephen",
        "dependent": 36,
        "dependentGloss": "professes"
      }, {
        "dep": "advmod",
        "governor": 36,
        "governorGloss": "professes",
        "dependent": 37,
        "dependentGloss": "also"
      }, {
        "dep": "mark",
        "governor": 40,
        "governorGloss": "free",
        "dependent": 38,
        "dependentGloss": "to"
      }, {
        "dep": "cop",
        "governor": 40,
        "governorGloss": "free",
        "dependent": 39,
        "dependentGloss": "be"
      }, {
        "dep": "xcomp",
        "governor": 36,
        "governorGloss": "professes",
        "dependent": 40,
        "dependentGloss": "free"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 41,
        "dependentGloss": ";"
      }, {
        "dep": "cc",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 42,
        "dependentGloss": "and"
      }, {
        "dep": "nsubj",
        "governor": 44,
        "governorGloss": "say",
        "dependent": 43,
        "dependentGloss": "they"
      }, {
        "dep": "conj:and",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 44,
        "dependentGloss": "say"
      }, {
        "dep": "nsubj",
        "governor": 50,
        "governorGloss": "Orleans",
        "dependent": 45,
        "dependentGloss": "they"
      }, {
        "dep": "cop",
        "governor": 50,
        "governorGloss": "Orleans",
        "dependent": 46,
        "dependentGloss": "are"
      }, {
        "dep": "advmod",
        "governor": 50,
        "governorGloss": "Orleans",
        "dependent": 47,
        "dependentGloss": "late"
      }, {
        "dep": "case",
        "governor": 50,
        "governorGloss": "Orleans",
        "dependent": 48,
        "dependentGloss": "from"
      }, {
        "dep": "compound",
        "governor": 50,
        "governorGloss": "Orleans",
        "dependent": 49,
        "dependentGloss": "New"
      }, {
        "dep": "ccomp",
        "governor": 44,
        "governorGloss": "say",
        "dependent": 50,
        "dependentGloss": "Orleans"
      }, {"dep": "punct", "governor": 6, "governorGloss": "years", "dependent": 51, "dependentGloss": "."}],
      "enhancedPlusPlusDependencies": [{
        "dep": "ROOT",
        "governor": 0,
        "governorGloss": "ROOT",
        "dependent": 6,
        "dependentGloss": "years"
      }, {
        "dep": "det",
        "governor": 2,
        "governorGloss": "woman",
        "dependent": 1,
        "dependentGloss": "The"
      }, {
        "dep": "nsubj",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 2,
        "dependentGloss": "woman"
      }, {
        "dep": "nsubj",
        "governor": 26,
        "governorGloss": "says",
        "dependent": 2,
        "dependentGloss": "woman"
      }, {
        "dep": "cop",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 3,
        "dependentGloss": "is"
      }, {
        "dep": "advmod",
        "governor": 5,
        "governorGloss": "20",
        "dependent": 4,
        "dependentGloss": "about"
      }, {
        "dep": "nummod",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 5,
        "dependentGloss": "20"
      }, {
        "dep": "case",
        "governor": 8,
        "governorGloss": "age",
        "dependent": 7,
        "dependentGloss": "of"
      }, {
        "dep": "nmod:of",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 8,
        "dependentGloss": "age"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 9,
        "dependentGloss": ","
      }, {
        "dep": "nummod",
        "governor": 11,
        "governorGloss": "feet",
        "dependent": 10,
        "dependentGloss": "4"
      }, {
        "dep": "nmod:npmod",
        "governor": 14,
        "governorGloss": "high",
        "dependent": 11,
        "dependentGloss": "feet"
      }, {
        "dep": "nummod",
        "governor": 13,
        "governorGloss": "inches",
        "dependent": 12,
        "dependentGloss": "91/2"
      }, {
        "dep": "nmod:npmod",
        "governor": 14,
        "governorGloss": "high",
        "dependent": 13,
        "dependentGloss": "inches"
      }, {
        "dep": "amod",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 14,
        "dependentGloss": "high"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 15,
        "dependentGloss": ","
      }, {
        "dep": "amod",
        "governor": 17,
        "governorGloss": "complex-ion",
        "dependent": 16,
        "dependentGloss": "dark"
      }, {
        "dep": "appos",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 17,
        "dependentGloss": "complex-ion"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 18,
        "dependentGloss": ","
      }, {
        "dep": "case",
        "governor": 23,
        "governorGloss": "head",
        "dependent": 19,
        "dependentGloss": "with"
      }, {
        "dep": "det",
        "governor": 23,
        "governorGloss": "head",
        "dependent": 20,
        "dependentGloss": "a"
      }, {
        "dep": "amod",
        "governor": 23,
        "governorGloss": "head",
        "dependent": 21,
        "dependentGloss": "thick"
      }, {
        "dep": "amod",
        "governor": 23,
        "governorGloss": "head",
        "dependent": 22,
        "dependentGloss": "bushy"
      }, {
        "dep": "nmod:with",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 23,
        "dependentGloss": "head"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 24,
        "dependentGloss": ","
      }, {
        "dep": "cc",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 25,
        "dependentGloss": "and"
      }, {
        "dep": "conj:and",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 26,
        "dependentGloss": "says"
      }, {
        "dep": "nsubj",
        "governor": 30,
        "governorGloss": "wife",
        "dependent": 27,
        "dependentGloss": "she"
      }, {
        "dep": "cop",
        "governor": 30,
        "governorGloss": "wife",
        "dependent": 28,
        "dependentGloss": "is"
      }, {
        "dep": "det",
        "governor": 30,
        "governorGloss": "wife",
        "dependent": 29,
        "dependentGloss": "the"
      }, {
        "dep": "ccomp",
        "governor": 26,
        "governorGloss": "says",
        "dependent": 30,
        "dependentGloss": "wife"
      }, {
        "dep": "mark",
        "governor": 32,
        "governorGloss": "said",
        "dependent": 31,
        "dependentGloss": "of"
      }, {
        "dep": "acl:of",
        "governor": 30,
        "governorGloss": "wife",
        "dependent": 32,
        "dependentGloss": "said"
      }, {
        "dep": "dobj",
        "governor": 32,
        "governorGloss": "said",
        "dependent": 33,
        "dependentGloss": "Stephen"
      }, {
        "dep": "punct",
        "governor": 33,
        "governorGloss": "Stephen",
        "dependent": 34,
        "dependentGloss": ","
      }, {
        "dep": "cc",
        "governor": 33,
        "governorGloss": "Stephen",
        "dependent": 35,
        "dependentGloss": "and"
      }, {
        "dep": "dobj",
        "governor": 32,
        "governorGloss": "said",
        "dependent": 36,
        "dependentGloss": "professes"
      }, {
        "dep": "conj:and",
        "governor": 33,
        "governorGloss": "Stephen",
        "dependent": 36,
        "dependentGloss": "professes"
      }, {
        "dep": "advmod",
        "governor": 36,
        "governorGloss": "professes",
        "dependent": 37,
        "dependentGloss": "also"
      }, {
        "dep": "mark",
        "governor": 40,
        "governorGloss": "free",
        "dependent": 38,
        "dependentGloss": "to"
      }, {
        "dep": "cop",
        "governor": 40,
        "governorGloss": "free",
        "dependent": 39,
        "dependentGloss": "be"
      }, {
        "dep": "xcomp",
        "governor": 36,
        "governorGloss": "professes",
        "dependent": 40,
        "dependentGloss": "free"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 41,
        "dependentGloss": ";"
      }, {
        "dep": "cc",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 42,
        "dependentGloss": "and"
      }, {
        "dep": "nsubj",
        "governor": 44,
        "governorGloss": "say",
        "dependent": 43,
        "dependentGloss": "they"
      }, {
        "dep": "conj:and",
        "governor": 6,
        "governorGloss": "years",
        "dependent": 44,
        "dependentGloss": "say"
      }, {
        "dep": "nsubj",
        "governor": 50,
        "governorGloss": "Orleans",
        "dependent": 45,
        "dependentGloss": "they"
      }, {
        "dep": "cop",
        "governor": 50,
        "governorGloss": "Orleans",
        "dependent": 46,
        "dependentGloss": "are"
      }, {
        "dep": "advmod",
        "governor": 50,
        "governorGloss": "Orleans",
        "dependent": 47,
        "dependentGloss": "late"
      }, {
        "dep": "case",
        "governor": 50,
        "governorGloss": "Orleans",
        "dependent": 48,
        "dependentGloss": "from"
      }, {
        "dep": "compound",
        "governor": 50,
        "governorGloss": "Orleans",
        "dependent": 49,
        "dependentGloss": "New"
      }, {
        "dep": "ccomp",
        "governor": 44,
        "governorGloss": "say",
        "dependent": 50,
        "dependentGloss": "Orleans"
      }, {"dep": "punct", "governor": 6, "governorGloss": "years", "dependent": 51, "dependentGloss": "."}],
      "openie": [{
        "subject": "woman",
        "subjectSpan": [1, 2],
        "relation": "is",
        "relationSpan": [2, 3],
        "object": "about 20 years with thick head",
        "objectSpan": [3, 23]
      }, {
        "subject": "woman",
        "subjectSpan": [1, 2],
        "relation": "is",
        "relationSpan": [2, 3],
        "object": "about 20 years of age with thick head",
        "objectSpan": [3, 23]
      }, {
        "subject": "woman",
        "subjectSpan": [1, 2],
        "relation": "is",
        "relationSpan": [2, 3],
        "object": "about 20 years of age with thick bushy head",
        "objectSpan": [3, 23]
      }, {
        "subject": "woman",
        "subjectSpan": [1, 2],
        "relation": "is",
        "relationSpan": [2, 3],
        "object": "about 20 years of age",
        "objectSpan": [3, 8]
      }, {
        "subject": "woman",
        "subjectSpan": [1, 2],
        "relation": "is",
        "relationSpan": [2, 3],
        "object": "about 20 years with thick bushy head",
        "objectSpan": [3, 23]
      }, {
        "subject": "woman",
        "subjectSpan": [1, 2],
        "relation": "is",
        "relationSpan": [2, 3],
        "object": "about 20 years of age with head",
        "objectSpan": [3, 23]
      }, {
        "subject": "age",
        "subjectSpan": [7, 8],
        "relation": "of years is",
        "relationSpan": [6, 7],
        "object": "dark complex-ion",
        "objectSpan": [15, 17]
      }, {
        "subject": "thick bushy head",
        "subjectSpan": [20, 23],
        "relation": "with years is",
        "relationSpan": [18, 19],
        "object": "dark complex-ion",
        "objectSpan": [15, 17]
      }, {
        "subject": "woman",
        "subjectSpan": [1, 2],
        "relation": "is",
        "relationSpan": [2, 3],
        "object": "about 20 years with head",
        "objectSpan": [3, 23]
      }, {
        "subject": "woman",
        "subjectSpan": [1, 2],
        "relation": "is",
        "relationSpan": [2, 3],
        "object": "about 20 years of age with bushy head",
        "objectSpan": [3, 23]
      }, {
        "subject": "woman",
        "subjectSpan": [1, 2],
        "relation": "is",
        "relationSpan": [2, 3],
        "object": "about 20 years with bushy head",
        "objectSpan": [3, 23]
      }, {
        "subject": "about 20 years",
        "subjectSpan": [3, 6],
        "relation": "is with",
        "relationSpan": [18, 19],
        "object": "thick bushy head",
        "objectSpan": [20, 23]
      }, {
        "subject": "woman",
        "subjectSpan": [1, 2],
        "relation": "is",
        "relationSpan": [2, 3],
        "object": "about 20 years",
        "objectSpan": [3, 6]
      }],
      "entitymentions": [{
        "docTokenBegin": 76,
        "docTokenEnd": 79,
        "tokenBegin": 3,
        "tokenEnd": 6,
        "text": "about 20 years",
        "characterOffsetBegin": 325,
        "characterOffsetEnd": 339,
        "ner": "DURATION",
        "normalizedNER": "P20Y",
        "timex": {"tid": "t3", "type": "DURATION", "value": "P20Y"}
      }, {
        "docTokenBegin": 82,
        "docTokenEnd": 83,
        "tokenBegin": 9,
        "tokenEnd": 10,
        "text": "4",
        "characterOffsetBegin": 348,
        "characterOffsetEnd": 349,
        "ner": "NUMBER",
        "normalizedNER": "4.0"
      }, {
        "docTokenBegin": 84,
        "docTokenEnd": 85,
        "tokenBegin": 11,
        "tokenEnd": 12,
        "text": "91/2",
        "characterOffsetBegin": 355,
        "characterOffsetEnd": 359,
        "ner": "NUMBER"
      }, {
        "docTokenBegin": 105,
        "docTokenEnd": 106,
        "tokenBegin": 32,
        "tokenEnd": 33,
        "text": "Stephen",
        "characterOffsetBegin": 449,
        "characterOffsetEnd": 456,
        "ner": "PERSON"
      }, {
        "docTokenBegin": 121,
        "docTokenEnd": 123,
        "tokenBegin": 48,
        "tokenEnd": 50,
        "text": "New Orleans",
        "characterOffsetBegin": 521,
        "characterOffsetEnd": 532,
        "ner": "CITY"
      }, {
        "docTokenBegin": 99,
        "docTokenEnd": 100,
        "tokenBegin": 26,
        "tokenEnd": 27,
        "text": "she",
        "characterOffsetBegin": 425,
        "characterOffsetEnd": 428,
        "ner": "PERSON"
      }],
      "tokens": [{
        "index": 1,
        "word": "The",
        "originalText": "The",
        "lemma": "the",
        "characterOffsetBegin": 312,
        "characterOffsetEnd": 315,
        "pos": "DT",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 2,
        "word": "woman",
        "originalText": "woman",
        "lemma": "woman",
        "characterOffsetBegin": 316,
        "characterOffsetEnd": 321,
        "pos": "NN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 3,
        "word": "is",
        "originalText": "is",
        "lemma": "be",
        "characterOffsetBegin": 322,
        "characterOffsetEnd": 324,
        "pos": "VBZ",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 4,
        "word": "about",
        "originalText": "about",
        "lemma": "about",
        "characterOffsetBegin": 325,
        "characterOffsetEnd": 330,
        "pos": "IN",
        "ner": "DURATION",
        "normalizedNER": "P20Y",
        "before": " ",
        "after": " ",
        "timex": {"tid": "t3", "type": "DURATION", "value": "P20Y"}
      }, {
        "index": 5,
        "word": "20",
        "originalText": "20",
        "lemma": "20",
        "characterOffsetBegin": 331,
        "characterOffsetEnd": 333,
        "pos": "CD",
        "ner": "DURATION",
        "normalizedNER": "P20Y",
        "before": " ",
        "after": " ",
        "timex": {"tid": "t3", "type": "DURATION", "value": "P20Y"}
      }, {
        "index": 6,
        "word": "years",
        "originalText": "years",
        "lemma": "year",
        "characterOffsetBegin": 334,
        "characterOffsetEnd": 339,
        "pos": "NNS",
        "ner": "DURATION",
        "normalizedNER": "P20Y",
        "before": " ",
        "after": " ",
        "timex": {"tid": "t3", "type": "DURATION", "value": "P20Y"}
      }, {
        "index": 7,
        "word": "of",
        "originalText": "of",
        "lemma": "of",
        "characterOffsetBegin": 340,
        "characterOffsetEnd": 342,
        "pos": "IN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 8,
        "word": "age",
        "originalText": "age",
        "lemma": "age",
        "characterOffsetBegin": 343,
        "characterOffsetEnd": 346,
        "pos": "NN",
        "ner": "O",
        "before": " ",
        "after": ""
      }, {
        "index": 9,
        "word": ",",
        "originalText": ",",
        "lemma": ",",
        "characterOffsetBegin": 346,
        "characterOffsetEnd": 347,
        "pos": ",",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 10,
        "word": "4",
        "originalText": "4",
        "lemma": "4",
        "characterOffsetBegin": 348,
        "characterOffsetEnd": 349,
        "pos": "CD",
        "ner": "NUMBER",
        "normalizedNER": "4.0",
        "before": " ",
        "after": " "
      }, {
        "index": 11,
        "word": "feet",
        "originalText": "feet",
        "lemma": "foot",
        "characterOffsetBegin": 350,
        "characterOffsetEnd": 354,
        "pos": "NNS",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 12,
        "word": "91/2",
        "originalText": "91/2",
        "lemma": "91/2",
        "characterOffsetBegin": 355,
        "characterOffsetEnd": 359,
        "pos": "CD",
        "ner": "NUMBER",
        "before": " ",
        "after": " "
      }, {
        "index": 13,
        "word": "inches",
        "originalText": "inches",
        "lemma": "inch",
        "characterOffsetBegin": 360,
        "characterOffsetEnd": 366,
        "pos": "NNS",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 14,
        "word": "high",
        "originalText": "high",
        "lemma": "high",
        "characterOffsetBegin": 367,
        "characterOffsetEnd": 371,
        "pos": "JJ",
        "ner": "O",
        "before": " ",
        "after": ""
      }, {
        "index": 15,
        "word": ",",
        "originalText": ",",
        "lemma": ",",
        "characterOffsetBegin": 371,
        "characterOffsetEnd": 372,
        "pos": ",",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 16,
        "word": "dark",
        "originalText": "dark",
        "lemma": "dark",
        "characterOffsetBegin": 373,
        "characterOffsetEnd": 377,
        "pos": "JJ",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 17,
        "word": "complex-ion",
        "originalText": "complex-ion",
        "lemma": "complex-ion",
        "characterOffsetBegin": 378,
        "characterOffsetEnd": 389,
        "pos": "NN",
        "ner": "O",
        "before": " ",
        "after": ""
      }, {
        "index": 18,
        "word": ",",
        "originalText": ",",
        "lemma": ",",
        "characterOffsetBegin": 389,
        "characterOffsetEnd": 390,
        "pos": ",",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 19,
        "word": "with",
        "originalText": "with",
        "lemma": "with",
        "characterOffsetBegin": 391,
        "characterOffsetEnd": 395,
        "pos": "IN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 20,
        "word": "a",
        "originalText": "a",
        "lemma": "a",
        "characterOffsetBegin": 396,
        "characterOffsetEnd": 397,
        "pos": "DT",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 21,
        "word": "thick",
        "originalText": "thick",
        "lemma": "thick",
        "characterOffsetBegin": 398,
        "characterOffsetEnd": 403,
        "pos": "JJ",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 22,
        "word": "bushy",
        "originalText": "bushy",
        "lemma": "bushy",
        "characterOffsetBegin": 404,
        "characterOffsetEnd": 409,
        "pos": "JJ",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 23,
        "word": "head",
        "originalText": "head",
        "lemma": "head",
        "characterOffsetBegin": 410,
        "characterOffsetEnd": 414,
        "pos": "NN",
        "ner": "O",
        "before": " ",
        "after": ""
      }, {
        "index": 24,
        "word": ",",
        "originalText": ",",
        "lemma": ",",
        "characterOffsetBegin": 414,
        "characterOffsetEnd": 415,
        "pos": ",",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 25,
        "word": "and",
        "originalText": "and",
        "lemma": "and",
        "characterOffsetBegin": 416,
        "characterOffsetEnd": 419,
        "pos": "CC",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 26,
        "word": "says",
        "originalText": "says",
        "lemma": "say",
        "characterOffsetBegin": 420,
        "characterOffsetEnd": 424,
        "pos": "VBZ",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 27,
        "word": "she",
        "originalText": "she",
        "lemma": "she",
        "characterOffsetBegin": 425,
        "characterOffsetEnd": 428,
        "pos": "PRP",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 28,
        "word": "is",
        "originalText": "is",
        "lemma": "be",
        "characterOffsetBegin": 429,
        "characterOffsetEnd": 431,
        "pos": "VBZ",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 29,
        "word": "the",
        "originalText": "the",
        "lemma": "the",
        "characterOffsetBegin": 432,
        "characterOffsetEnd": 435,
        "pos": "DT",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 30,
        "word": "wife",
        "originalText": "wife",
        "lemma": "wife",
        "characterOffsetBegin": 436,
        "characterOffsetEnd": 440,
        "pos": "NN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 31,
        "word": "of",
        "originalText": "of",
        "lemma": "of",
        "characterOffsetBegin": 441,
        "characterOffsetEnd": 443,
        "pos": "IN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 32,
        "word": "said",
        "originalText": "said",
        "lemma": "say",
        "characterOffsetBegin": 444,
        "characterOffsetEnd": 448,
        "pos": "VBD",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 33,
        "word": "Stephen",
        "originalText": "Stephen",
        "lemma": "Stephen",
        "characterOffsetBegin": 449,
        "characterOffsetEnd": 456,
        "pos": "NNP",
        "ner": "PERSON",
        "before": " ",
        "after": ""
      }, {
        "index": 34,
        "word": ",",
        "originalText": ",",
        "lemma": ",",
        "characterOffsetBegin": 456,
        "characterOffsetEnd": 457,
        "pos": ",",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 35,
        "word": "and",
        "originalText": "and",
        "lemma": "and",
        "characterOffsetBegin": 458,
        "characterOffsetEnd": 461,
        "pos": "CC",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 36,
        "word": "professes",
        "originalText": "professes",
        "lemma": "profess",
        "characterOffsetBegin": 462,
        "characterOffsetEnd": 471,
        "pos": "VBZ",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 37,
        "word": "also",
        "originalText": "also",
        "lemma": "also",
        "characterOffsetBegin": 472,
        "characterOffsetEnd": 476,
        "pos": "RB",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 38,
        "word": "to",
        "originalText": "to",
        "lemma": "to",
        "characterOffsetBegin": 477,
        "characterOffsetEnd": 479,
        "pos": "TO",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 39,
        "word": "be",
        "originalText": "be",
        "lemma": "be",
        "characterOffsetBegin": 480,
        "characterOffsetEnd": 482,
        "pos": "VB",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 40,
        "word": "free",
        "originalText": "free",
        "lemma": "free",
        "characterOffsetBegin": 483,
        "characterOffsetEnd": 487,
        "pos": "JJ",
        "ner": "O",
        "before": " ",
        "after": ""
      }, {
        "index": 41,
        "word": ";",
        "originalText": ";",
        "lemma": ";",
        "characterOffsetBegin": 487,
        "characterOffsetEnd": 488,
        "pos": ":",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 42,
        "word": "and",
        "originalText": "and",
        "lemma": "and",
        "characterOffsetBegin": 489,
        "characterOffsetEnd": 492,
        "pos": "CC",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 43,
        "word": "they",
        "originalText": "they",
        "lemma": "they",
        "characterOffsetBegin": 493,
        "characterOffsetEnd": 497,
        "pos": "PRP",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 44,
        "word": "say",
        "originalText": "say",
        "lemma": "say",
        "characterOffsetBegin": 498,
        "characterOffsetEnd": 501,
        "pos": "VBP",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 45,
        "word": "they",
        "originalText": "they",
        "lemma": "they",
        "characterOffsetBegin": 502,
        "characterOffsetEnd": 506,
        "pos": "PRP",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 46,
        "word": "are",
        "originalText": "are",
        "lemma": "be",
        "characterOffsetBegin": 507,
        "characterOffsetEnd": 510,
        "pos": "VBP",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 47,
        "word": "late",
        "originalText": "late",
        "lemma": "late",
        "characterOffsetBegin": 511,
        "characterOffsetEnd": 515,
        "pos": "RB",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 48,
        "word": "from",
        "originalText": "from",
        "lemma": "from",
        "characterOffsetBegin": 516,
        "characterOffsetEnd": 520,
        "pos": "IN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 49,
        "word": "New",
        "originalText": "New",
        "lemma": "New",
        "characterOffsetBegin": 521,
        "characterOffsetEnd": 524,
        "pos": "NNP",
        "ner": "CITY",
        "before": " ",
        "after": " "
      }, {
        "index": 50,
        "word": "Orleans",
        "originalText": "Orleans",
        "lemma": "Orleans",
        "characterOffsetBegin": 525,
        "characterOffsetEnd": 532,
        "pos": "NNP",
        "ner": "CITY",
        "before": " ",
        "after": ""
      }, {
        "index": 51,
        "word": ".",
        "originalText": ".",
        "lemma": ".",
        "characterOffsetBegin": 532,
        "characterOffsetEnd": 533,
        "pos": ".",
        "ner": "O",
        "before": "",
        "after": ""
      }]
    }, {
      "index": 2,
      "basicDependencies": [{
        "dep": "ROOT",
        "governor": 0,
        "governorGloss": "ROOT",
        "dependent": 6,
        "dependentGloss": "requested"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 1,
        "dependentGloss": "--"
      }, {
        "dep": "det",
        "governor": 3,
        "governorGloss": "owner",
        "dependent": 2,
        "dependentGloss": "The"
      }, {
        "dep": "nsubjpass",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 3,
        "dependentGloss": "owner"
      }, {
        "dep": "auxpass",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 4,
        "dependentGloss": "is"
      }, {
        "dep": "advmod",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 5,
        "dependentGloss": "hereby"
      }, {
        "dep": "mark",
        "governor": 8,
        "governorGloss": "come",
        "dependent": 7,
        "dependentGloss": "to"
      }, {
        "dep": "xcomp",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 8,
        "dependentGloss": "come"
      }, {
        "dep": "advmod",
        "governor": 8,
        "governorGloss": "come",
        "dependent": 9,
        "dependentGloss": "forward"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 10,
        "dependentGloss": ","
      }, {
        "dep": "conj",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 11,
        "dependentGloss": "prove"
      }, {
        "dep": "compound",
        "governor": 15,
        "governorGloss": "charges",
        "dependent": 12,
        "dependentGloss": "property"
      }, {
        "dep": "cc",
        "governor": 12,
        "governorGloss": "property",
        "dependent": 13,
        "dependentGloss": "and"
      }, {
        "dep": "conj",
        "governor": 12,
        "governorGloss": "property",
        "dependent": 14,
        "dependentGloss": "pay"
      }, {
        "dep": "dobj",
        "governor": 11,
        "governorGloss": "prove",
        "dependent": 15,
        "dependentGloss": "charges"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 16,
        "dependentGloss": ","
      }, {
        "dep": "cc",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 17,
        "dependentGloss": "or"
      }, {
        "dep": "nsubjpass",
        "governor": 21,
        "governorGloss": "sold",
        "dependent": 18,
        "dependentGloss": "they"
      }, {
        "dep": "aux",
        "governor": 21,
        "governorGloss": "sold",
        "dependent": 19,
        "dependentGloss": "will"
      }, {
        "dep": "auxpass",
        "governor": 21,
        "governorGloss": "sold",
        "dependent": 20,
        "dependentGloss": "be"
      }, {
        "dep": "conj",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 21,
        "dependentGloss": "sold"
      }, {
        "dep": "case",
        "governor": 24,
        "governorGloss": "same",
        "dependent": 22,
        "dependentGloss": "for"
      }, {
        "dep": "det",
        "governor": 24,
        "governorGloss": "same",
        "dependent": 23,
        "dependentGloss": "the"
      }, {
        "dep": "nmod",
        "governor": 21,
        "governorGloss": "sold",
        "dependent": 24,
        "dependentGloss": "same"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 25,
        "dependentGloss": ","
      }, {
        "dep": "case",
        "governor": 29,
        "governorGloss": "statute",
        "dependent": 26,
        "dependentGloss": "according"
      }, {
        "dep": "mwe",
        "governor": 26,
        "governorGloss": "according",
        "dependent": 27,
        "dependentGloss": "to"
      }, {
        "dep": "det",
        "governor": 29,
        "governorGloss": "statute",
        "dependent": 28,
        "dependentGloss": "the"
      }, {
        "dep": "nmod",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 29,
        "dependentGloss": "statute"
      }, {
        "dep": "case",
        "governor": 32,
        "governorGloss": "cases",
        "dependent": 30,
        "dependentGloss": "in"
      }, {
        "dep": "amod",
        "governor": 32,
        "governorGloss": "cases",
        "dependent": 31,
        "dependentGloss": "such"
      }, {
        "dep": "nmod",
        "governor": 29,
        "governorGloss": "statute",
        "dependent": 32,
        "dependentGloss": "cases"
      }, {
        "dep": "dep",
        "governor": 32,
        "governorGloss": "cases",
        "dependent": 33,
        "dependentGloss": "made"
      }, {
        "dep": "cc",
        "governor": 33,
        "governorGloss": "made",
        "dependent": 34,
        "dependentGloss": "and"
      }, {
        "dep": "conj",
        "governor": 33,
        "governorGloss": "made",
        "dependent": 35,
        "dependentGloss": "provided"
      }, {"dep": "punct", "governor": 6, "governorGloss": "requested", "dependent": 36, "dependentGloss": "."}],
      "enhancedDependencies": [{
        "dep": "ROOT",
        "governor": 0,
        "governorGloss": "ROOT",
        "dependent": 6,
        "dependentGloss": "requested"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 1,
        "dependentGloss": "--"
      }, {
        "dep": "det",
        "governor": 3,
        "governorGloss": "owner",
        "dependent": 2,
        "dependentGloss": "The"
      }, {
        "dep": "nsubjpass",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 3,
        "dependentGloss": "owner"
      }, {
        "dep": "nsubj:xsubj",
        "governor": 8,
        "governorGloss": "come",
        "dependent": 3,
        "dependentGloss": "owner"
      }, {
        "dep": "nsubj",
        "governor": 11,
        "governorGloss": "prove",
        "dependent": 3,
        "dependentGloss": "owner"
      }, {
        "dep": "auxpass",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 4,
        "dependentGloss": "is"
      }, {
        "dep": "advmod",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 5,
        "dependentGloss": "hereby"
      }, {
        "dep": "mark",
        "governor": 8,
        "governorGloss": "come",
        "dependent": 7,
        "dependentGloss": "to"
      }, {
        "dep": "xcomp",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 8,
        "dependentGloss": "come"
      }, {
        "dep": "advmod",
        "governor": 8,
        "governorGloss": "come",
        "dependent": 9,
        "dependentGloss": "forward"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 10,
        "dependentGloss": ","
      }, {
        "dep": "conj:or",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 11,
        "dependentGloss": "prove"
      }, {
        "dep": "compound",
        "governor": 15,
        "governorGloss": "charges",
        "dependent": 12,
        "dependentGloss": "property"
      }, {
        "dep": "cc",
        "governor": 12,
        "governorGloss": "property",
        "dependent": 13,
        "dependentGloss": "and"
      }, {
        "dep": "conj:and",
        "governor": 12,
        "governorGloss": "property",
        "dependent": 14,
        "dependentGloss": "pay"
      }, {
        "dep": "compound",
        "governor": 15,
        "governorGloss": "charges",
        "dependent": 14,
        "dependentGloss": "pay"
      }, {
        "dep": "dobj",
        "governor": 11,
        "governorGloss": "prove",
        "dependent": 15,
        "dependentGloss": "charges"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 16,
        "dependentGloss": ","
      }, {
        "dep": "cc",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 17,
        "dependentGloss": "or"
      }, {
        "dep": "nsubjpass",
        "governor": 21,
        "governorGloss": "sold",
        "dependent": 18,
        "dependentGloss": "they"
      }, {
        "dep": "aux",
        "governor": 21,
        "governorGloss": "sold",
        "dependent": 19,
        "dependentGloss": "will"
      }, {
        "dep": "auxpass",
        "governor": 21,
        "governorGloss": "sold",
        "dependent": 20,
        "dependentGloss": "be"
      }, {
        "dep": "conj:or",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 21,
        "dependentGloss": "sold"
      }, {
        "dep": "case",
        "governor": 24,
        "governorGloss": "same",
        "dependent": 22,
        "dependentGloss": "for"
      }, {
        "dep": "det",
        "governor": 24,
        "governorGloss": "same",
        "dependent": 23,
        "dependentGloss": "the"
      }, {
        "dep": "nmod:for",
        "governor": 21,
        "governorGloss": "sold",
        "dependent": 24,
        "dependentGloss": "same"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 25,
        "dependentGloss": ","
      }, {
        "dep": "case",
        "governor": 29,
        "governorGloss": "statute",
        "dependent": 26,
        "dependentGloss": "according"
      }, {
        "dep": "mwe",
        "governor": 26,
        "governorGloss": "according",
        "dependent": 27,
        "dependentGloss": "to"
      }, {
        "dep": "det",
        "governor": 29,
        "governorGloss": "statute",
        "dependent": 28,
        "dependentGloss": "the"
      }, {
        "dep": "nmod:according_to",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 29,
        "dependentGloss": "statute"
      }, {
        "dep": "case",
        "governor": 32,
        "governorGloss": "cases",
        "dependent": 30,
        "dependentGloss": "in"
      }, {
        "dep": "amod",
        "governor": 32,
        "governorGloss": "cases",
        "dependent": 31,
        "dependentGloss": "such"
      }, {
        "dep": "nmod:in",
        "governor": 29,
        "governorGloss": "statute",
        "dependent": 32,
        "dependentGloss": "cases"
      }, {
        "dep": "dep",
        "governor": 32,
        "governorGloss": "cases",
        "dependent": 33,
        "dependentGloss": "made"
      }, {
        "dep": "cc",
        "governor": 33,
        "governorGloss": "made",
        "dependent": 34,
        "dependentGloss": "and"
      }, {
        "dep": "dep",
        "governor": 32,
        "governorGloss": "cases",
        "dependent": 35,
        "dependentGloss": "provided"
      }, {
        "dep": "conj:and",
        "governor": 33,
        "governorGloss": "made",
        "dependent": 35,
        "dependentGloss": "provided"
      }, {"dep": "punct", "governor": 6, "governorGloss": "requested", "dependent": 36, "dependentGloss": "."}],
      "enhancedPlusPlusDependencies": [{
        "dep": "ROOT",
        "governor": 0,
        "governorGloss": "ROOT",
        "dependent": 6,
        "dependentGloss": "requested"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 1,
        "dependentGloss": "--"
      }, {
        "dep": "det",
        "governor": 3,
        "governorGloss": "owner",
        "dependent": 2,
        "dependentGloss": "The"
      }, {
        "dep": "nsubjpass",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 3,
        "dependentGloss": "owner"
      }, {
        "dep": "nsubj:xsubj",
        "governor": 8,
        "governorGloss": "come",
        "dependent": 3,
        "dependentGloss": "owner"
      }, {
        "dep": "nsubj",
        "governor": 11,
        "governorGloss": "prove",
        "dependent": 3,
        "dependentGloss": "owner"
      }, {
        "dep": "auxpass",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 4,
        "dependentGloss": "is"
      }, {
        "dep": "advmod",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 5,
        "dependentGloss": "hereby"
      }, {
        "dep": "mark",
        "governor": 8,
        "governorGloss": "come",
        "dependent": 7,
        "dependentGloss": "to"
      }, {
        "dep": "xcomp",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 8,
        "dependentGloss": "come"
      }, {
        "dep": "advmod",
        "governor": 8,
        "governorGloss": "come",
        "dependent": 9,
        "dependentGloss": "forward"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 10,
        "dependentGloss": ","
      }, {
        "dep": "conj:or",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 11,
        "dependentGloss": "prove"
      }, {
        "dep": "compound",
        "governor": 15,
        "governorGloss": "charges",
        "dependent": 12,
        "dependentGloss": "property"
      }, {
        "dep": "cc",
        "governor": 12,
        "governorGloss": "property",
        "dependent": 13,
        "dependentGloss": "and"
      }, {
        "dep": "conj:and",
        "governor": 12,
        "governorGloss": "property",
        "dependent": 14,
        "dependentGloss": "pay"
      }, {
        "dep": "compound",
        "governor": 15,
        "governorGloss": "charges",
        "dependent": 14,
        "dependentGloss": "pay"
      }, {
        "dep": "dobj",
        "governor": 11,
        "governorGloss": "prove",
        "dependent": 15,
        "dependentGloss": "charges"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 16,
        "dependentGloss": ","
      }, {
        "dep": "cc",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 17,
        "dependentGloss": "or"
      }, {
        "dep": "nsubjpass",
        "governor": 21,
        "governorGloss": "sold",
        "dependent": 18,
        "dependentGloss": "they"
      }, {
        "dep": "aux",
        "governor": 21,
        "governorGloss": "sold",
        "dependent": 19,
        "dependentGloss": "will"
      }, {
        "dep": "auxpass",
        "governor": 21,
        "governorGloss": "sold",
        "dependent": 20,
        "dependentGloss": "be"
      }, {
        "dep": "conj:or",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 21,
        "dependentGloss": "sold"
      }, {
        "dep": "case",
        "governor": 24,
        "governorGloss": "same",
        "dependent": 22,
        "dependentGloss": "for"
      }, {
        "dep": "det",
        "governor": 24,
        "governorGloss": "same",
        "dependent": 23,
        "dependentGloss": "the"
      }, {
        "dep": "nmod:for",
        "governor": 21,
        "governorGloss": "sold",
        "dependent": 24,
        "dependentGloss": "same"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 25,
        "dependentGloss": ","
      }, {
        "dep": "case",
        "governor": 29,
        "governorGloss": "statute",
        "dependent": 26,
        "dependentGloss": "according"
      }, {
        "dep": "mwe",
        "governor": 26,
        "governorGloss": "according",
        "dependent": 27,
        "dependentGloss": "to"
      }, {
        "dep": "det",
        "governor": 29,
        "governorGloss": "statute",
        "dependent": 28,
        "dependentGloss": "the"
      }, {
        "dep": "nmod:according_to",
        "governor": 6,
        "governorGloss": "requested",
        "dependent": 29,
        "dependentGloss": "statute"
      }, {
        "dep": "case",
        "governor": 32,
        "governorGloss": "cases",
        "dependent": 30,
        "dependentGloss": "in"
      }, {
        "dep": "amod",
        "governor": 32,
        "governorGloss": "cases",
        "dependent": 31,
        "dependentGloss": "such"
      }, {
        "dep": "nmod:in",
        "governor": 29,
        "governorGloss": "statute",
        "dependent": 32,
        "dependentGloss": "cases"
      }, {
        "dep": "dep",
        "governor": 32,
        "governorGloss": "cases",
        "dependent": 33,
        "dependentGloss": "made"
      }, {
        "dep": "cc",
        "governor": 33,
        "governorGloss": "made",
        "dependent": 34,
        "dependentGloss": "and"
      }, {
        "dep": "dep",
        "governor": 32,
        "governorGloss": "cases",
        "dependent": 35,
        "dependentGloss": "provided"
      }, {
        "dep": "conj:and",
        "governor": 33,
        "governorGloss": "made",
        "dependent": 35,
        "dependentGloss": "provided"
      }, {"dep": "punct", "governor": 6, "governorGloss": "requested", "dependent": 36, "dependentGloss": "."}],
      "openie": [{
        "subject": "owner",
        "subjectSpan": [2, 3],
        "relation": "is requested",
        "relationSpan": [3, 6],
        "object": "come",
        "objectSpan": [7, 8]
      }, {
        "subject": "statute",
        "subjectSpan": [28, 29],
        "relation": "is in",
        "relationSpan": [29, 30],
        "object": "such cases",
        "objectSpan": [30, 32]
      }, {
        "subject": "owner",
        "subjectSpan": [2, 3],
        "relation": "is",
        "relationSpan": [3, 4],
        "object": "requested",
        "objectSpan": [5, 6]
      }, {
        "subject": "owner",
        "subjectSpan": [2, 3],
        "relation": "prove",
        "relationSpan": [10, 11],
        "object": "property charges",
        "objectSpan": [11, 15]
      }, {
        "subject": "owner",
        "subjectSpan": [2, 3],
        "relation": "is requested",
        "relationSpan": [3, 6],
        "object": "come forward",
        "objectSpan": [7, 9]
      }, {
        "subject": "owner",
        "subjectSpan": [2, 3],
        "relation": "is requested according to",
        "relationSpan": [3, 6],
        "object": "statute",
        "objectSpan": [28, 29]
      }, {
        "subject": "they",
        "subjectSpan": [17, 18],
        "relation": "will",
        "relationSpan": [18, 19],
        "object": "will sold",
        "objectSpan": [18, 21]
      }, {
        "subject": "they",
        "subjectSpan": [17, 18],
        "relation": "will",
        "relationSpan": [18, 19],
        "object": "will sold for same",
        "objectSpan": [18, 24]
      }, {
        "subject": "owner",
        "subjectSpan": [2, 3],
        "relation": "is requested according to",
        "relationSpan": [3, 6],
        "object": "statute in cases",
        "objectSpan": [28, 32]
      }, {
        "subject": "owner",
        "subjectSpan": [2, 3],
        "relation": "is requested according to",
        "relationSpan": [3, 6],
        "object": "statute in such cases",
        "objectSpan": [28, 32]
      }],
      "entitymentions": [{
        "docTokenBegin": 126,
        "docTokenEnd": 127,
        "tokenBegin": 2,
        "tokenEnd": 3,
        "text": "owner",
        "characterOffsetBegin": 539,
        "characterOffsetEnd": 544,
        "ner": "TITLE"
      }],
      "tokens": [{
        "index": 1,
        "word": "--",
        "originalText": "—",
        "lemma": "--",
        "characterOffsetBegin": 533,
        "characterOffsetEnd": 534,
        "pos": ":",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 2,
        "word": "The",
        "originalText": "The",
        "lemma": "the",
        "characterOffsetBegin": 535,
        "characterOffsetEnd": 538,
        "pos": "DT",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 3,
        "word": "owner",
        "originalText": "owner",
        "lemma": "owner",
        "characterOffsetBegin": 539,
        "characterOffsetEnd": 544,
        "pos": "NN",
        "ner": "TITLE",
        "before": " ",
        "after": " "
      }, {
        "index": 4,
        "word": "is",
        "originalText": "is",
        "lemma": "be",
        "characterOffsetBegin": 545,
        "characterOffsetEnd": 547,
        "pos": "VBZ",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 5,
        "word": "hereby",
        "originalText": "hereby",
        "lemma": "hereby",
        "characterOffsetBegin": 548,
        "characterOffsetEnd": 554,
        "pos": "RB",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 6,
        "word": "requested",
        "originalText": "requested",
        "lemma": "request",
        "characterOffsetBegin": 555,
        "characterOffsetEnd": 564,
        "pos": "VBN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 7,
        "word": "to",
        "originalText": "to",
        "lemma": "to",
        "characterOffsetBegin": 565,
        "characterOffsetEnd": 567,
        "pos": "TO",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 8,
        "word": "come",
        "originalText": "come",
        "lemma": "come",
        "characterOffsetBegin": 568,
        "characterOffsetEnd": 572,
        "pos": "VB",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 9,
        "word": "forward",
        "originalText": "forward",
        "lemma": "forward",
        "characterOffsetBegin": 573,
        "characterOffsetEnd": 580,
        "pos": "RB",
        "ner": "O",
        "before": " ",
        "after": ""
      }, {
        "index": 10,
        "word": ",",
        "originalText": ",",
        "lemma": ",",
        "characterOffsetBegin": 580,
        "characterOffsetEnd": 581,
        "pos": ",",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 11,
        "word": "prove",
        "originalText": "prove",
        "lemma": "prove",
        "characterOffsetBegin": 582,
        "characterOffsetEnd": 587,
        "pos": "VBP",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 12,
        "word": "property",
        "originalText": "property",
        "lemma": "property",
        "characterOffsetBegin": 588,
        "characterOffsetEnd": 596,
        "pos": "NN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 13,
        "word": "and",
        "originalText": "and",
        "lemma": "and",
        "characterOffsetBegin": 597,
        "characterOffsetEnd": 600,
        "pos": "CC",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 14,
        "word": "pay",
        "originalText": "pay",
        "lemma": "pay",
        "characterOffsetBegin": 601,
        "characterOffsetEnd": 604,
        "pos": "NN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 15,
        "word": "charges",
        "originalText": "charges",
        "lemma": "charge",
        "characterOffsetBegin": 605,
        "characterOffsetEnd": 612,
        "pos": "NNS",
        "ner": "O",
        "before": " ",
        "after": ""
      }, {
        "index": 16,
        "word": ",",
        "originalText": ",",
        "lemma": ",",
        "characterOffsetBegin": 612,
        "characterOffsetEnd": 613,
        "pos": ",",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 17,
        "word": "or",
        "originalText": "or",
        "lemma": "or",
        "characterOffsetBegin": 614,
        "characterOffsetEnd": 616,
        "pos": "CC",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 18,
        "word": "they",
        "originalText": "they",
        "lemma": "they",
        "characterOffsetBegin": 617,
        "characterOffsetEnd": 621,
        "pos": "PRP",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 19,
        "word": "will",
        "originalText": "will",
        "lemma": "will",
        "characterOffsetBegin": 622,
        "characterOffsetEnd": 626,
        "pos": "MD",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 20,
        "word": "be",
        "originalText": "be",
        "lemma": "be",
        "characterOffsetBegin": 627,
        "characterOffsetEnd": 629,
        "pos": "VB",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 21,
        "word": "sold",
        "originalText": "sold",
        "lemma": "sell",
        "characterOffsetBegin": 630,
        "characterOffsetEnd": 634,
        "pos": "VBN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 22,
        "word": "for",
        "originalText": "for",
        "lemma": "for",
        "characterOffsetBegin": 635,
        "characterOffsetEnd": 638,
        "pos": "IN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 23,
        "word": "the",
        "originalText": "the",
        "lemma": "the",
        "characterOffsetBegin": 639,
        "characterOffsetEnd": 642,
        "pos": "DT",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 24,
        "word": "same",
        "originalText": "same",
        "lemma": "same",
        "characterOffsetBegin": 643,
        "characterOffsetEnd": 647,
        "pos": "JJ",
        "ner": "O",
        "before": " ",
        "after": ""
      }, {
        "index": 25,
        "word": ",",
        "originalText": ",",
        "lemma": ",",
        "characterOffsetBegin": 647,
        "characterOffsetEnd": 648,
        "pos": ",",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 26,
        "word": "according",
        "originalText": "according",
        "lemma": "accord",
        "characterOffsetBegin": 649,
        "characterOffsetEnd": 658,
        "pos": "VBG",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 27,
        "word": "to",
        "originalText": "to",
        "lemma": "to",
        "characterOffsetBegin": 659,
        "characterOffsetEnd": 661,
        "pos": "TO",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 28,
        "word": "the",
        "originalText": "the",
        "lemma": "the",
        "characterOffsetBegin": 662,
        "characterOffsetEnd": 665,
        "pos": "DT",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 29,
        "word": "statute",
        "originalText": "statute",
        "lemma": "statute",
        "characterOffsetBegin": 666,
        "characterOffsetEnd": 673,
        "pos": "NN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 30,
        "word": "in",
        "originalText": "in",
        "lemma": "in",
        "characterOffsetBegin": 674,
        "characterOffsetEnd": 676,
        "pos": "IN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 31,
        "word": "such",
        "originalText": "such",
        "lemma": "such",
        "characterOffsetBegin": 677,
        "characterOffsetEnd": 681,
        "pos": "JJ",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 32,
        "word": "cases",
        "originalText": "cases",
        "lemma": "case",
        "characterOffsetBegin": 682,
        "characterOffsetEnd": 687,
        "pos": "NNS",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 33,
        "word": "made",
        "originalText": "made",
        "lemma": "make",
        "characterOffsetBegin": 688,
        "characterOffsetEnd": 692,
        "pos": "VBN",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 34,
        "word": "and",
        "originalText": "and",
        "lemma": "and",
        "characterOffsetBegin": 693,
        "characterOffsetEnd": 696,
        "pos": "CC",
        "ner": "O",
        "before": " ",
        "after": " "
      }, {
        "index": 35,
        "word": "provided",
        "originalText": "provided",
        "lemma": "provide",
        "characterOffsetBegin": 697,
        "characterOffsetEnd": 705,
        "pos": "VBN",
        "ner": "O",
        "before": " ",
        "after": ""
      }, {
        "index": 36,
        "word": ".",
        "originalText": ".",
        "lemma": ".",
        "characterOffsetBegin": 705,
        "characterOffsetEnd": 706,
        "pos": ".",
        "ner": "O",
        "before": "",
        "after": "\n"
      }]
    }, {
      "index": 3,
      "basicDependencies": [{
        "dep": "ROOT",
        "governor": 0,
        "governorGloss": "ROOT",
        "dependent": 1,
        "dependentGloss": "July"
      }, {
        "dep": "nummod",
        "governor": 1,
        "governorGloss": "July",
        "dependent": 2,
        "dependentGloss": "23"
      }, {
        "dep": "punct",
        "governor": 1,
        "governorGloss": "July",
        "dependent": 3,
        "dependentGloss": "--"
      }, {
        "dep": "nummod",
        "governor": 6,
        "governorGloss": "BATES",
        "dependent": 4,
        "dependentGloss": "23tf"
      }, {
        "dep": "compound",
        "governor": 6,
        "governorGloss": "BATES",
        "dependent": 5,
        "dependentGloss": "J."
      }, {
        "dep": "dep",
        "governor": 1,
        "governorGloss": "July",
        "dependent": 6,
        "dependentGloss": "BATES"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "BATES",
        "dependent": 7,
        "dependentGloss": ","
      }, {
        "dep": "appos",
        "governor": 6,
        "governorGloss": "BATES",
        "dependent": 8,
        "dependentGloss": "Jr."
      }, {"dep": "punct", "governor": 1, "governorGloss": "July", "dependent": 9, "dependentGloss": "."}],
      "enhancedDependencies": [{
        "dep": "ROOT",
        "governor": 0,
        "governorGloss": "ROOT",
        "dependent": 1,
        "dependentGloss": "July"
      }, {
        "dep": "nummod",
        "governor": 1,
        "governorGloss": "July",
        "dependent": 2,
        "dependentGloss": "23"
      }, {
        "dep": "punct",
        "governor": 1,
        "governorGloss": "July",
        "dependent": 3,
        "dependentGloss": "--"
      }, {
        "dep": "nummod",
        "governor": 6,
        "governorGloss": "BATES",
        "dependent": 4,
        "dependentGloss": "23tf"
      }, {
        "dep": "compound",
        "governor": 6,
        "governorGloss": "BATES",
        "dependent": 5,
        "dependentGloss": "J."
      }, {
        "dep": "dep",
        "governor": 1,
        "governorGloss": "July",
        "dependent": 6,
        "dependentGloss": "BATES"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "BATES",
        "dependent": 7,
        "dependentGloss": ","
      }, {
        "dep": "appos",
        "governor": 6,
        "governorGloss": "BATES",
        "dependent": 8,
        "dependentGloss": "Jr."
      }, {"dep": "punct", "governor": 1, "governorGloss": "July", "dependent": 9, "dependentGloss": "."}],
      "enhancedPlusPlusDependencies": [{
        "dep": "ROOT",
        "governor": 0,
        "governorGloss": "ROOT",
        "dependent": 1,
        "dependentGloss": "July"
      }, {
        "dep": "nummod",
        "governor": 1,
        "governorGloss": "July",
        "dependent": 2,
        "dependentGloss": "23"
      }, {
        "dep": "punct",
        "governor": 1,
        "governorGloss": "July",
        "dependent": 3,
        "dependentGloss": "--"
      }, {
        "dep": "nummod",
        "governor": 6,
        "governorGloss": "BATES",
        "dependent": 4,
        "dependentGloss": "23tf"
      }, {
        "dep": "compound",
        "governor": 6,
        "governorGloss": "BATES",
        "dependent": 5,
        "dependentGloss": "J."
      }, {
        "dep": "dep",
        "governor": 1,
        "governorGloss": "July",
        "dependent": 6,
        "dependentGloss": "BATES"
      }, {
        "dep": "punct",
        "governor": 6,
        "governorGloss": "BATES",
        "dependent": 7,
        "dependentGloss": ","
      }, {
        "dep": "appos",
        "governor": 6,
        "governorGloss": "BATES",
        "dependent": 8,
        "dependentGloss": "Jr."
      }, {"dep": "punct", "governor": 1, "governorGloss": "July", "dependent": 9, "dependentGloss": "."}],
      "openie": [],
      "entitymentions": [{
        "docTokenBegin": 160,
        "docTokenEnd": 162,
        "tokenBegin": 0,
        "tokenEnd": 2,
        "text": "July 23",
        "characterOffsetBegin": 707,
        "characterOffsetEnd": 714,
        "ner": "DATE",
        "normalizedNER": "2018-07-23",
        "timex": {"tid": "t4", "type": "DATE", "value": "2018-07-23"}
      }, {
        "docTokenBegin": 163,
        "docTokenEnd": 164,
        "tokenBegin": 3,
        "tokenEnd": 4,
        "text": "23tf",
        "characterOffsetBegin": 715,
        "characterOffsetEnd": 719,
        "ner": "NUMBER",
        "normalizedNER": "23.0"
      }],
      "tokens": [{
        "index": 1,
        "word": "July",
        "originalText": "July",
        "lemma": "July",
        "characterOffsetBegin": 707,
        "characterOffsetEnd": 711,
        "pos": "NNP",
        "ner": "DATE",
        "normalizedNER": "2018-07-23",
        "before": "\n",
        "after": " ",
        "timex": {"tid": "t4", "type": "DATE", "value": "2018-07-23"}
      }, {
        "index": 2,
        "word": "23",
        "originalText": "23",
        "lemma": "23",
        "characterOffsetBegin": 712,
        "characterOffsetEnd": 714,
        "pos": "CD",
        "ner": "DATE",
        "normalizedNER": "2018-07-23",
        "before": " ",
        "after": "",
        "timex": {"tid": "t4", "type": "DATE", "value": "2018-07-23"}
      }, {
        "index": 3,
        "word": "--",
        "originalText": "—",
        "lemma": "--",
        "characterOffsetBegin": 714,
        "characterOffsetEnd": 715,
        "pos": ":",
        "ner": "O",
        "before": "",
        "after": ""
      }, {
        "index": 4,
        "word": "23tf",
        "originalText": "23tf",
        "lemma": "23tf",
        "characterOffsetBegin": 715,
        "characterOffsetEnd": 719,
        "pos": "CD",
        "ner": "NUMBER",
        "normalizedNER": "23.0",
        "before": "",
        "after": "\t"
      }, {
        "index": 5,
        "word": "J.",
        "originalText": "J.",
        "lemma": "J.",
        "characterOffsetBegin": 720,
        "characterOffsetEnd": 722,
        "pos": "NNP",
        "ner": "O",
        "before": "\t",
        "after": " "
      }, {
        "index": 6,
        "word": "BATES",
        "originalText": "BATES",
        "lemma": "BATES",
        "characterOffsetBegin": 723,
        "characterOffsetEnd": 728,
        "pos": "NNP",
        "ner": "O",
        "before": " ",
        "after": ""
      }, {
        "index": 7,
        "word": ",",
        "originalText": ",",
        "lemma": ",",
        "characterOffsetBegin": 728,
        "characterOffsetEnd": 729,
        "pos": ",",
        "ner": "O",
        "before": "",
        "after": " "
      }, {
        "index": 8,
        "word": "Jr.",
        "originalText": "Jr",
        "lemma": "Jr.",
        "characterOffsetBegin": 730,
        "characterOffsetEnd": 732,
        "pos": "NNP",
        "ner": "O",
        "before": " ",
        "after": ""
      }, {
        "index": 9,
        "word": ".",
        "originalText": ".",
        "lemma": ".",
        "characterOffsetBegin": 732,
        "characterOffsetEnd": 733,
        "pos": ".",
        "ner": "O",
        "before": "",
        "after": " "
      }]
    }, {
      "index": 4,
      "basicDependencies": [{
        "dep": "ROOT",
        "governor": 0,
        "governorGloss": "ROOT",
        "dependent": 1,
        "dependentGloss": "Shff"
      }, {"dep": "punct", "governor": 1, "governorGloss": "Shff", "dependent": 2, "dependentGloss": "."}],
      "enhancedDependencies": [{
        "dep": "ROOT",
        "governor": 0,
        "governorGloss": "ROOT",
        "dependent": 1,
        "dependentGloss": "Shff"
      }, {"dep": "punct", "governor": 1, "governorGloss": "Shff", "dependent": 2, "dependentGloss": "."}],
      "enhancedPlusPlusDependencies": [{
        "dep": "ROOT",
        "governor": 0,
        "governorGloss": "ROOT",
        "dependent": 1,
        "dependentGloss": "Shff"
      }, {"dep": "punct", "governor": 1, "governorGloss": "Shff", "dependent": 2, "dependentGloss": "."}],
      "openie": [],
      "entitymentions": [],
      "tokens": [{
        "index": 1,
        "word": "Shff",
        "originalText": "Shff",
        "lemma": "Shff",
        "characterOffsetBegin": 734,
        "characterOffsetEnd": 738,
        "pos": "NNP",
        "ner": "O",
        "before": " ",
        "after": ""
      }, {
        "index": 2,
        "word": ".",
        "originalText": ".",
        "lemma": ".",
        "characterOffsetBegin": 738,
        "characterOffsetEnd": 739,
        "pos": ".",
        "ner": "O",
        "before": "",
        "after": ""
      }]
    }]
  }
};