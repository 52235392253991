import * as React from "react";
import {Card, Header, List, Placeholder} from "semantic-ui-react";
import TranscribeHistoryItem from "./components/TranscribeHistoryItem";
import Routes from "../../../../Routes";
import ScrollLoader from "../../../../components/ScrollLoader";

export default function TranscribeHistory(props) {
  const items = props.data ? props.data : [];
  const onScroll = props.onScroll;
  const loading = props.loading;

  return (
      <div>
        <Header as={"h2"} style={{textAlign: 'left', padding: '5px'}}>History</Header>
        {loading || (items && items.length) ?
            <ScrollLoader as={List} style={{maxHeight: '60vh', overflow: 'hidden', overflowY: 'scroll', padding: 5}} onLoad={onScroll}>
              {items.map(i => {
                return <List.Item key={i.id}><TranscribeHistoryItem data={i}/></List.Item>
              })}

              {loading &&
              <List.Item key={1}>
                <Card fluid>
                  <Card.Content>
                    <Placeholder fluid>
                      <Placeholder.Image/>
                      <Placeholder.Paragraph>
                        <Placeholder.Line/>
                        <Placeholder.Line/>
                        <Placeholder.Line/>
                        <Placeholder.Line/>
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </Card.Content>
                </Card>
              </List.Item>
              }
            </ScrollLoader>
            :
            <div>You don't appear to have history. <a href={Routes.crowdsourcing}>Start contributing!</a></div>
        }
      </div>
  )
}