
export const Routes = {
  home: "/",
  crowdsourcing: '/crowdsourcing',
  transcribing: '/crowdsourcing/transcribing',
  moderation: '/crowdsourcing/moderation',
  grouping: '/crowdsourcing/grouping',
  search: '/search',
  collections: '/collections',
  advertisementDetails: '/advertisements',
  runawayEventDetails: '/runaway-events',
  runawayDetails: '/runaways',
  createAccount: '/account/new',
  login: '/account/login',
  settings: '/account',
  changePassword: '/account/password',
  passwordReset: '/account/password/reset',
  contact: '/contact',
  map: '/map',

  Educators: "/educators",
  Lesson: "/educators/:lesson",

  educational: {
    home: '/classroom',
    join: '/classroom/join/:courseCode',
    course: '/classroom/course/:course',
    roster: '/classroom/course/:course/roster',
    student: '/classroom/course/:course/roster/:student',
    assignments: '/classroom/course/:course/assignments',
    assignment: '/classroom/course/:course/assignments/:assignment',
    courseLesson: '/classroom/course/:course/lesson/:lessonPlanId',
    lesson: '/classroom/lesson/:lessonPlanId',
  }
};

export default Routes;