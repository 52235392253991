import * as React from "react";
import {Form, Icon, Popup} from "semantic-ui-react";
import {observer} from "mobx-react";
import {domainStore} from "../../../../stores/CrowdsourcingStore";
import {uiStore} from "../../../../stores/UIStore";
import ServerError from "../../../../../../components/ServerError";
import {RunawayNameField} from "./components/RunawayNameField";
import {Selection} from "../../../../../../components/Selection";
import EnslavedPerson from "../../../../../../stores/models/EnslavedPerson";
import EnslavedPersonName from "../../../../../../stores/models/EnslavedPersonName";

interface IProps {
    runaway: number;
}

@observer
export class RunawayNameForm extends React.Component<IProps> {
    id = uiStore.getKey();
    enslavedPerson?: EnslavedPerson;

    constructor(props: IProps) {
        super(props);
        if (domainStore.runaways.length > props.runaway) {
            this.enslavedPerson = domainStore.runaways[props.runaway].enslavedPerson;
            this.enslavedPerson.names.forEach(n => {
                uiStore.nlpOptions.addPerson(n.fullname);
            });

            if (!this.enslavedPerson.names || this.enslavedPerson.names.length === 0) {
                this.addName();
            }
        }
    };

    addName = (isAlias = false) => {
        this.enslavedPerson!.addName({
            fullname: "",
            isAlias
        });
    };

    addAlias = () => {
        this.addName(true);
    };

    removeName(name: EnslavedPersonName) {
        this.enslavedPerson!.removeName(name);
    }

    changeHasAliases = (hasAliases: boolean) => {
        if (hasAliases) {
            if (this.enslavedPerson!.aliases.length === 0) {
                this.addAlias();
            }
        } else {
            this.enslavedPerson!.removeAliases();
        }
    };

    render() {
        if (!this.enslavedPerson) {
            return <ServerError/>;
        }

        const hasAliases = this.enslavedPerson.aliases.length > 0;

        return (
            <Form as='div' className='left aligned' size='large'>
                <Form.Field>
                    <label>What is the primary name given for this runaway?</label>
                    <RunawayNameField name={this.enslavedPerson.name!}/>
                    <Form.Field>
                        <label htmlFor={`has-aliases-${this.id}`}>Does the advertisement specify multiple names for the
                            runaway? <Popup
                                wide
                                trigger={
                                    <Icon name='info circle'/>
                                }
                                content='Enslavers sometimes assigned names to enslaved people, but Africans and African Americans often had their own names which they used with others, especially among themselves.'
                            /></label>
                        <Selection id={`has-aliases-${this.id}`}
                                   options={[{key: 'no', text: 'NO', value: false}, {
                                       key: 'yes',
                                       text: 'YES',
                                       value: true
                                   }]}
                                   color={'blue'} value={hasAliases} onChange={this.changeHasAliases}/>
                    </Form.Field>

                    {hasAliases &&
                    <React.Fragment>
                      <label>List any other names associated with this runaway.</label>
                        {this.enslavedPerson.aliases.map((name, i) => {
                            return (
                                <Form.Field key={name.key}>
                                    <Form.Group unstackable>
                                        <RunawayNameField id={name.key} name={name}/>
                                        <Form.Field width={8}>
                                            <div className='label'>&nbsp;</div>
                                            <div style={{padding: '0.67857143em'}}>
                                                {this.enslavedPerson!.aliases.length > 1 &&
                                                <button className='link-button'
                                                        onClick={() => this.removeName(name)}>REMOVE</button>
                                                }
                                                {i === this.enslavedPerson!.aliases.length - 1 &&
                                                <React.Fragment>
                                                    {i > 0 &&
                                                    <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                                    }
                                                  <button className='link-button' onClick={this.addAlias}>ADD NAME
                                                  </button>
                                                </React.Fragment>
                                                }
                                            </div>
                                        </Form.Field>
                                    </Form.Group>
                                </Form.Field>
                            )
                        })}
                    </React.Fragment>
                    }
                </Form.Field>
            </Form>
        );
    }
}
