import 'react-app-polyfill/ie9';
import * as React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './semantic/dist/semantic.min.css';
import 'autotrack';

declare global {
  interface Window {
    ga: any;
    gapi: any;
  }
}

if (process && process.env && process.env.NODE_ENV !== 'development') {
  window.ga('create', 'UA-117803226-1', 'auto');
// Only require the plugins you've imported above.
  window.ga('require', 'eventTracker');
  window.ga('require', 'impressionTracker');
  window.ga('require', 'maxScrollTracker');
  window.ga('require', 'mediaQueryTracker');
  window.ga('require', 'outboundFormTracker');
  window.ga('require', 'outboundLinkTracker');
  window.ga('require', 'pageVisibilityTracker');
  window.ga('require', 'socialWidgetTracker');
  window.ga('require', 'urlChangeTracker');

  window.ga('send', 'pageview');

  console.debug = () => {};
  console.log = () => {};
}

ReactDOM.render(<App/>, document.getElementById('root'));
