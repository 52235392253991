import * as React from "react";
import {observer} from "mobx-react";
import {Button, Container, Divider, Form, Grid, Icon, Loader, Popup, Segment} from "semantic-ui-react";
import {userStore} from "../../stores/UserStore";
import Routes from "../../Routes";
import {withRouter} from "react-router-dom";
import ServerError from "../../components/ServerError";
import TranscribeHistory from "./scenes/TranscribeHistory/TranscribeHistory";
import ConnectionError from "../../components/ConnectionError";
import {createLoader} from "../../stores/utils/Loader";

export const Settings = withRouter(observer(class extends React.Component {
  backgroundOptions = [
    {key: 0, text: 'Researcher', value: 'researcher'},
    {key: 1, text: 'Genealogist', value: 'genealogist'},
    {key: 2, text: 'Student', value: 'student'},
    {key: 3, text: 'Teacher', value: 'teacher'},
    {key: 4, text: 'History Buff / Citizen Historian', value: 'historian'}
  ];

  constructor(props) {
    super(props);
    this.history = props.history;

    this.state = {
      serverError: false,
      editName: false,
      editEmail: false
    };
    this.historyLoader = createLoader();
    this.init();
  }

  async init() {
    this.setState({loading: true});
    try {
      await userStore.getUserFromToken();
      await userStore.getProfile()
          .then(() => this.setState({loading: false}));
      this.historyLoader.load(userStore.getAdvertisements());
    } catch (e) {
      console.error(e);
      this.setState({serverError: true});
    }
  }

  scrollHistory = (e) => {
    this.historyLoader.load(userStore.getAdvertisements());
  };

  updateProfile = () => {
    this.setState({editName: false, editEmail: false});
    userStore.pushProfileToServer();
  };

  setName = (e, {value}) => {
    userStore.user.setFullname(value);
  };

  setEmail = (e, {value}) => {
    userStore.user.setEmailAddress(value);
  };

  changeReceiveEmails = (e, {checked}) => {
    userStore.user.setReceiveEmails(checked);
    userStore.pushProfileToServer();
  };

  changePublic = (e, {checked}) => {
    userStore.user.setPublic(checked);
    userStore.pushProfileToServer();
  };

  changeBackground = (e, {value}) => {
    userStore.user.setBackground(value);
    userStore.pushProfileToServer();
  };

  render() {
    return (
        <div className="settings">
          <Loader active={userStore.loading || this.state.loading}/>

          {userStore.connectionError &&
          <ConnectionError/>
          }

          {this.state.serverError &&
          <ServerError/>
          }

          {!userStore.loading && !userStore.connectionError && !userStore.serverError && !this.state.serverError &&
          <Grid centered>
            <Grid.Row>
              <Grid.Column computer={6} mobile={16} tablet={16}>
                <Segment padded='very' style={{maxWidth: '600px', margin: 'auto'}}>
                  <Container className='center aligned'>

                    <Icon name='user circle outline' size='massive'/>
                    <Form onSubmit={this.updateProfile} style={{margin: '10px'}}>
                      {this.state.editName ?
                          <Form.Input label='Name' value={userStore.user.fullname} onChange={this.setName}/>
                          :
                          <div style={{margin: '10px'}}>
                            <span style={{fontSize: '18px'}}>{userStore.user.fullname}</span>
                            <button className='link-button' style={{padding: '5px', cursor: 'pointer'}}
                                    onClick={() => this.setState({editName: true})}>edit
                            </button>
                          </div>
                      }
                      {this.state.editEmail ?
                          <Form.Input label='Email' value={userStore.user.emailAddress} onChange={this.setEmail}/>
                          :
                          <div style={{margin: '10px'}}>
                            <span style={{fontSize: '18px'}}>{userStore.user.emailAddress}</span>
                            <button className='link-button' style={{padding: '5px', cursor: 'pointer'}}
                                    onClick={() => this.setState({editEmail: true})}>edit
                            </button>
                          </div>
                      }
                      {(this.state.editName || this.state.editEmail) &&
                      <Form.Button primary>Submit</Form.Button>
                      }

                      <Button primary onClick={() => this.history.push(Routes.changePassword)}>Change
                        Password</Button>

                      {!userStore.user.restricted &&
                      <React.Fragment>
                        <Divider section/>

                        <Form.Field style={{textAlign: 'left'}}>
                          <Form.Dropdown label='Which of these roles best applies to you?'
                                         search selection options={this.backgroundOptions}
                                         value={userStore.user.background}
                                         onChange={this.changeBackground}/>
                          <Form.Checkbox label='Send me occasional emails with updates regarding the project'
                                         checked={userStore.user.receiveEmails}
                                         onChange={this.changeReceiveEmails}/>
                          <Form.Checkbox label={
                            <label>Make my name public&nbsp;
                              <Popup
                                  wide='very' position='top center'
                                  trigger={
                                    <Icon name='help circle'/>
                                  }
                                  content="We would like to display your name as a contributor to recognise all the work you've done. We will not share your information unless you explicitly permit us to."
                              />
                            </label>
                          }
                                         checked={userStore.user.public}
                                         onChange={this.changePublic}/>

                        </Form.Field>
                      </React.Fragment>
                      }
                    </Form>
                  </Container>
                </Segment>
              </Grid.Column>


              <Grid.Column computer={6} mobile={16} tablet={16}>
                <Segment padded='very' className='center aligned'>
                  {/*<Stats/>*/}
                  <TranscribeHistory data={userStore.transcriptionHistory} onScroll={this.scrollHistory}
                                     loading={this.historyLoader.isLoading}/>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          }
        </div>
    )
  }
}));
