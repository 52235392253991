import './styles.css'
import * as React from "react";
import {Button, Container, Divider, Grid, Header, Image, Modal, Responsive, Statistic} from "semantic-ui-react";
import Routes from "../../Routes";
import {RouteComponentProps, withRouter} from "react-router-dom";
import AuthPage from "../Auth/scenes/AuthPage/AuthPage";
import {api} from "../../APICaller";

type Stats = {
    advertisements?: number;
    transcriptions?: number;
    accounts?: number;
}

interface IProps extends RouteComponentProps {
    login?: boolean;
    register?: boolean;
}

export const MainPage = withRouter(class extends React.Component<IProps, { stats: Stats }> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            stats: {}
        };

        this.getStats();
    }

    getStats = async () => {
        const {data: stats} = await api.get('stats');
        this.setState({stats});
    };

    render() {
        let {history, login, register} = this.props;

        const MOBILE_SIZE = 580;

        let navButtons = (
            <>
                <div style={{marginTop: 40}}>
                    <Button primary size='huge' style={{margin: 0}} onClick={() => history.push(Routes.crowdsourcing)}
                            ga-on="click"
                            ga-event-category="Navigation"
                            ga-event-action="main-page-crowdsource">
                        CONTRIBUTE
                    </Button>
                    <span style={{padding: '1rem'}}/>
                    <a className="ui huge primary button" href="https://database.freedomonthemove.org">SEARCH</a>
                    <span style={{padding: '1rem'}}/>
                    <a className="ui huge primary button" href="https://freedomonthemove.org/educators/">EDUCATOR PORTAL</a>
                </div>
            </>
        );

        let navButtonsMobile = (
            <>
                <div style={{marginTop: 40}}>
                    <Button primary size='huge' style={{margin: 0}} fluid
                            onClick={() => history.push(Routes.crowdsourcing)}
                            ga-on="click"
                            ga-event-category="Navigation"
                            ga-event-action="main-page-crowdsource">
                        CONTRIBUTE
                    </Button>
                    <Divider section horizontal/>
                    <a className="ui huge primary button" href="https://database.freedomonthemove.org">SEARCH</a>
                    <Divider section horizontal/>
                    <a className="ui huge primary button" href="https://freedomonthemove.org/educators/">EDUCATOR
                        PORTAL</a>
                </div>
            </>
        );

        return (
            <div>
                <Container id='content'>
                    <div className='page-content'>
                        <Grid>
                            <Responsive as={React.Fragment} maxWidth={MOBILE_SIZE}>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Header as='h1' color='violet' className='welcome-header'>WELCOME TO <br/>FREEDOM ON THE
                                            MOVE</Header>
                                    </Grid.Column>
                                </Grid.Row>
                            </Responsive>
                            <Grid.Row>
                                <Grid.Column width={10}>
                                    <Responsive as={React.Fragment} minWidth={MOBILE_SIZE + 1}>
                                        <Header as='h1' color='violet' className='welcome-header'>WELCOME TO <br/>FREEDOM ON THE
                                            MOVE<br />CROWDSOURCING</Header>
                                    </Responsive>
                                    <div style={{marginTop: 40}}>
                                        <p>
                                            Created to control the movement of enslaved people, runaway ads ultimately preserved the details
                                            of
                                            individual lives--their personality, appearance, and life story. Taken collectively, these ads
                                            constitute a detailed, concise, and rare source of information about the experiences of enslaved
                                            people.
                                        </p>
                                        <p>
                                            With your help, Freedom on the Move will serve as a research aid, pedagogical tool, and a
                                            resource
                                            for genealogists. Scholars, students, and citizen historians will be able to use the data
                                            produced
                                            by you in new
                                            and creative ways.
                                        </p>
                                    </div>
                                    <Responsive as={React.Fragment} minWidth={MOBILE_SIZE + 1}>
                                        {navButtons}
                                    </Responsive>
                                </Grid.Column>
                                <Grid.Column width={6} verticalAlign='middle'>
                                    <Image centered size='medium' alt='Runaway slave holding a bindle stick'
                                           src='https://freedomonthemove.org/content/images/2023/09/bindle-male.png'/>
                                </Grid.Column>
                            </Grid.Row>
                            <Responsive as={React.Fragment} maxWidth={MOBILE_SIZE}>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        {navButtonsMobile}
                                    </Grid.Column>
                                </Grid.Row>
                            </Responsive>
                            {this.state.stats &&
                            <Grid.Row style={{marginTop: '10em'}}>
                                <Grid.Column width={16}>
                                    <Header as='h2' dividing style={{fontSize: '3em', marginBottom: '2rem'}}>SOME STATS</Header>
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <Statistic size='large' color='purple' label='Advertisements' value={numberWithCommas(this.state.stats.advertisements)}/>
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <Statistic size='large' color='purple' label='Contributions' value={numberWithCommas(this.state.stats.transcriptions)}/>
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <Statistic size='large' color='purple' label='CONTRIBUTORS' value={numberWithCommas(this.state.stats.accounts)}/>
                                </Grid.Column>
                            </Grid.Row>
                            }
                        </Grid>
                    </div>
                </Container>
                <Modal open={login || register} content={<AuthPage type={register ? 'register' : 'login'}/>} closeIcon
                       onClose={() => history.push(Routes.home)}/>
            </div>
        )
    }
});

function numberWithCommas(x?: number) {
    if (!x) return '';
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default MainPage;
