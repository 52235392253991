import {DropdownProps, Form, InputProps} from "semantic-ui-react";
import {LocationField} from "../../../../../../../components/LocationField";
import * as React from "react";
import {SyntheticEvent} from "react";
import {observer} from "mobx-react";


// @ts-ignore
export const RunawayLocationField = observer((props) => {
    const typeOptions = [
        {key: "possibleDestination", value: "possibleDestination", text: "Possible Destination"},
        {key: "ran", value: "ran", text: "Ran Away From"},
        {key: "spotted", value: "spotted", text: "Spotted At"},
        {key: "caught", value: "caught", text: "Caught In"},
        {key: "jailed", value: "jailed", text: "Jailed In"},
        {key: "placeOfOrigin", value: "placeOfOrigin", text: "Place of Origin / Birthplace"},
        {key: "returnTo", value: "returnTo", text: "If Captured, Return To"},
        {key: "stolenFrom", value: "stolenFrom", text: "Stolen from"},
        {key: "harbouredAt", value: "harbouredAt", text: "Harboured at"},
        {key: "other", value: "other", text: "Other"}
    ];

    const l = props.runawayLocation;

    const changeType = (e: SyntheticEvent, {value}: DropdownProps) => {
        l.setType(value);
    };

    const changeReason = (e: SyntheticEvent, {value}: InputProps) => {
        l.setReason(value);
    };

    return (
        <div>
            <Form.Group>
                <Form.Dropdown id='runaway-location-type' label='Type' options={typeOptions} width={6}
                               search selection clearable
                               onChange={changeType}
                               value={l.type}
                               ga-on="click"
                               ga-event-category="Form Field"
                               ga-event-action="edit-runaway-location-type"/>
                {l.type === "possibleDestination" &&
                <Form.Input id='runaway-location-reason' label='Reason'
                            onChange={changeReason}
                            value={l.reason}
                            ga-on="click"
                            ga-event-category="Form Field"
                            ga-event-action="edit-runaway-location-reason"/>
                }
            </Form.Group>
            <LocationField location={l.location}/>
        </div>
    )
});

export default RunawayLocationField;
