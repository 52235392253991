import * as React from 'react';
import {SyntheticEvent} from 'react';
import {observer} from 'mobx-react';
import {Button, Container, Divider, Form, Header, InputProps, Segment} from "semantic-ui-react";
import collectionStore from "../stores/CollectionStore";
import CollectionItem from "../components/CollectionItem";
import Collection from "../stores/models/Collection";

type IState = {
    expanded: boolean;
}

@observer
export class CollectionsPage extends React.Component<any, IState> {
    state = {
        expanded: false
    }

    constructor(props: any) {
        super(props);
        collectionStore.fetchCollections();
    }

    addCollection = () => {
        collectionStore.toggleEdit();
    };

    createCollection = () => {
        collectionStore.createCollection();
    };

    changeCollectionName = (e: SyntheticEvent, {value: name}: InputProps) => {
        collectionStore.collectionToCreate!.setName(name);
    };

    toggleExpanded = () => {
        this.setState({expanded: !this.state.expanded});
    };

    render() {
        return (
            <Segment id='content' className='collections' basic as={Container} style={{maxHeight: 'none'}}>
                <Divider hidden/>

                <Header as='h1'>Collections</Header>
                <div className='text-right'>
                    <button className='link-button'
                            onClick={this.toggleExpanded}>{this.state.expanded ? 'COLLAPSE ALL' : 'EXPAND ALL'}</button>
                </div>
                <div>
                    {collectionStore.collections.map((collection: Collection) => (
                        <CollectionItem key={collection.id} collection={collection} itemsPerRow={3}
                                        defaultState={this.state.expanded ? 'open' : 'closed'}/>
                    ))}
                </div>
                {collectionStore.collections.length === 0 &&
                <div>You don't currently have any collections</div>
                }
                <Divider/>
                {collectionStore.edit ?
                    <Form onSubmit={this.createCollection}>
                        <Form.Group style={{margin: 0}}>
                            <Form.Input placeholder='My Collection' value={collectionStore.collectionToCreate!.name}
                                        onChange={this.changeCollectionName}/>
                            <Form.Button primary>Create</Form.Button>
                        </Form.Group>
                    </Form>
                    :
                    <Button primary onClick={this.addCollection}>Add Collection</Button>
                }
            </Segment>
        )
    }
}

export default CollectionsPage;
