import React, {Component} from 'react';
import ReactMarkdown from "react-markdown";
import {FormProgress} from './FormProgress'
import {observer} from "mobx-react";
import {Container, Divider, Grid, Header, Image, Label, Popup, Responsive, Segment, SemanticCOLORS, Sticky} from "semantic-ui-react";
import TranscriptionText from "./TranscriptionText";
import {AdImage} from "./AdImage";
import {domainStore} from "../stores/CrowdsourcingStore";
import {uiStore} from "../stores/UIStore";
import AdInfo from "./AdInfo";
import {userStore} from "../../../stores/UserStore";
import Tutorial from "../scenes/Tutorial/Tutorial";
import Glossary from "../scenes/Tutorial/Glossary";

interface IState {
    minHeight: number;
    stickyContext?: HTMLDivElement;
}

@observer
export class FormPage extends Component<any, IState> {
    state: IState = {
        minHeight: 0
    };

    handleStickyRef = (stickyContext: HTMLDivElement) => {
        this.setState({stickyContext});
        if (stickyContext) {
            this.setState({minHeight: stickyContext.clientHeight})
        }
    };

    onImageLoad = () => {
        this.setState({minHeight: this.state.stickyContext!.clientHeight})
    };

    adInfo = () => {
        if (!domainStore.advertisement) {
            return "";
        }
        const imageSrc = domainStore.advertisement?.digitalObject?.filename;
        const transcription = domainStore.advertisement?.transcription;
        const institution = domainStore.advertisement?.digitalObject?.provenance?.institution?.name;
        let preTranscribed = !domainStore.advertisement.digitalObject.filename;
        let contributorCount = domainStore.advertisement.contributors.length;

        const provenance = domainStore.advertisement.digitalObject?.provenance;

        if (contributorCount === 1 && (!domainStore.advertisement.contributors[0].id || domainStore.advertisement.contributors[0].id !== userStore.user.id)) {
            contributorCount++;
        }

        const contributedBy = () => {
            return (
                <Segment>
                    <Header textAlign='center'>This advertisement was generously contributed by {institution}.</Header>
                    {provenance?.image && provenance.image !== '' &&
                    <>
                        <br/><br/>
                        {provenance?.url ?
                            <a href={provenance.url} target="_blank" rel="noopener noreferrer">
                                <Image padded='very' size='medium' centered src={provenance.image} fluid alt={institution}/>
                            </a>
                            :
                            <Image padded='very' size='medium' centered src={provenance.image} fluid alt={institution}/>
                        }
                        <br/><br/>
                    </>
                    }
                    <Container textAlign='center'>
                        <ReactMarkdown source={provenance.blurb}
                                       renderers={{link: props => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>}}/>
                    </Container>
                </Segment>
            )
        }

        return (
            <React.Fragment>
                {(preTranscribed || (uiStore.section > 1 && domainStore.advertisement)) &&
                <div style={{marginTop: '0rem'}}>
                    <Header as='h2' color='orange'>TRANSCRIPTION
                        {preTranscribed &&
                        <Header.Subheader>The source material for this advertisement is the transcription itself.
                            There is no source image.</Header.Subheader>
                        }
                    </Header>
                    <TranscriptionText/>
                </div>
                }
                {!preTranscribed &&
                <div style={{marginTop: preTranscribed || uiStore.section > 1 ? '3rem' : undefined}}>
                    <Header as='h2' color='orange'>PRINT AD</Header>
                    <Segment.Group>
                        <Segment>
                            <AdImage src={imageSrc} transcription={transcription} onLoad={this.onImageLoad}/>
                        </Segment>
                        {institution &&
                        <Segment>
                            {contributedBy()}
                        </Segment>
                        }
                    </Segment.Group>
                </div>
                }
                {contributorCount > 1 &&
                <Popup
                    wide='very'
                    size='large'
                    content="Other contributors have already filled in some information. Be sure to check their work and make sure they didn't miss anything!"
                    trigger={
                        <Header color={'darkgrey' as SemanticCOLORS} className='thin' style={{paddingLeft: '14px'}}>YOU
                            ARE ONE OF
                            &nbsp;<Label color='orange' circular size='large'
                                         style={{marginRight: '.5rem'}}>{contributorCount}</Label>&nbsp;CONTRIBUTORS
                        </Header>
                    }
                />
                }
                <div style={{marginBottom: '4rem'}}>
                    <AdInfo advertisement={domainStore.advertisement}/>
                </div>
            </React.Fragment>
        )
    };

    render() {

        return (
            <div className='crowdsourcing-form'>
                <div ref={this.handleStickyRef}>
                    <Grid stackable>
                        <Grid.Row only='mobile'>
                            <Grid.Column width={16}>
                                <FormProgress step={uiStore.section}/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='ad-info' width={7} only='tablet computer'
                                         style={{minHeight: this.state.minHeight}}>
                                <div>
                                    <Sticky offset={20} context={this.state.stickyContext}>
                                        {this.adInfo()}
                                    </Sticky>
                                </div>
                            </Grid.Column>
                            <Grid.Column className='ad-info' width={7} only='mobile'>
                                {this.adInfo()}
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <Responsive as={React.Fragment} minWidth={Responsive.onlyMobile.maxWidth as number + 1}>
                                    <FormProgress step={uiStore.section}/>
                                    <Divider style={{marginBottom: 20, marginTop: 40}}/>
                                </Responsive>
                                <div style={{float: 'right', fontSize: '1.2em'}}>
                                    <Glossary trigger={<button className='link-button'>Glossary</button>}/>
                                    &nbsp;|&nbsp;
                                    <Tutorial trigger={<button className='link-button'>Need Help?</button>}/>
                                </div>
                                <div id='content'>
                                    {this.props.children}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
        );
    }
}
