import * as React from 'react';
import {SyntheticEvent} from 'react';
import {Container, Input, InputProps, Modal, ModalProps, Segment} from "semantic-ui-react";
import {DescriptionList} from "../../../../components/DescriptionList";
import glossary from "../../../../json/glossary.json";

class Glossary extends React.Component<ModalProps> {

    state = {
        open: false,
        query: ''
    };

    open = () => this.setState({open: true});

    close = () => {

        this.setState({open: false});
    };

    changeQuery = (e: SyntheticEvent, {value: query}: InputProps) => {
        this.setState({query});
    };

    get filteredGlossary() {
        if (this.state.query.length === 0) {
            return glossary;
        }

        const result: { [x: string]: string } = {};
        Object.entries(glossary).forEach(([key, value]) => {
            const k = key.toLowerCase();
            const q = this.state.query.toLowerCase();
            const v = value.toLowerCase();
            if (k.indexOf(q) >= 0 || v.indexOf(q) >= 0) {
                result[key] = value;
            }
        });

        return result;
    }

    render() {
        return (
            <Modal size='large' closeIcon centered
                   open={this.state.open}
                   onOpen={this.open}
                   onClose={this.close}
                   trigger={this.props.trigger}>

                <Modal.Header as='h2' size='large'>Glossary</Modal.Header>
                <Modal.Content scrolling style={{minHeight: 'calc(80vh - 10em)'}}>
                    <Container>
                        <Segment basic textAlign='left'>
                            <Input icon='search' placeholder='Search...' autoFocus
                                   value={this.state.query} onChange={this.changeQuery}/>
                            <DescriptionList data={this.filteredGlossary} keyCase={undefined}/>
                        </Segment>
                    </Container>
                </Modal.Content>
            </Modal>
        )
    }
}

export default Glossary;