// @ts-nocheck
import * as React from "react";
import {Card, Container, Divider, Header, Segment} from "semantic-ui-react";
import {ModQueueItem} from "./components/ModQueueItem";
import {createLoader} from "../../stores/utils/Loader";
import {ErrorBoundary} from "../Crowdsourcing/components/ErrorBoundary";
import {userStore} from "../../stores/UserStore";
import {observer} from "mobx-react";
import ScrollLoader from "../../components/ScrollLoader";

@observer
export class ModQueue extends React.Component {
    loader = createLoader();

    constructor(props: any) {
        super(props);
        this.init();
    }

    async init() {
        await userStore.getProfile();
        this.getModQueue();
    }

    getModQueue = async () => {
        await this.loader.load(userStore.getModQueue());
    };

    render() {
        let items = userStore.modQueue;
        const loading = this.loader.isLoading;

        return (
            <Container>
                <Divider section hidden/>
                <Header as="h1" color='orange'>Review
                    <Header.Subheader>Check for mistakes, fix any errors, and mark the information as
                        complete.</Header.Subheader>
                </Header>
                <Segment padded='very'>
                    <ErrorBoundary>
                        {loading || (items && items.length) ?
                            <ScrollLoader as={Card.Group} itemsPerRow={3}
                                          style={{
                                              maxHeight: '70vh',
                                              overflow: 'hidden',
                                              overflowY: 'scroll',
                                              padding: 5
                                          }}
                                          onLoad={this.getModQueue}>
                                {items && items.map(i => {
                                    return <ModQueueItem key={i.id} data={i}/>
                                })}
                            </ScrollLoader>
                            :
                            <Segment basic placeholder textAlign='center'>
                                <Header>There are currently no advertisements for you to review.</Header>
                            </Segment>
                        }
                    </ErrorBoundary>
                </Segment>
            </Container>
        )
    }
}

export default ModQueue;