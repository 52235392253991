import {action, computed, observable, runInAction} from "mobx";
import User from "./User";
import DigitalObject from "./DigitalObject";
import Language from "./Language";
import Newspaper from "./Newspaper"
import {RunawayEvent} from "./RunawayEvent";
import Advertiser from "./Advertiser";
import AdvertisementGroup from "./AdvertisementGroup";
import {config} from "../../config";
import {api} from "../../APICaller";

const ILLEGIBLE = "[illegible]";

class Advertisement {
    id: string;
    @observable transcription?: string;
    @observable type?: string;
    @observable pageNumber?: number;
    @observable publicationDate?: string;
    @observable dateModified?: string;
    @observable complete?: boolean;
    @observable digitalObjectId?: string;
    @observable languageId?: string;
    @observable advertiserId?: string;
    @observable newspaperId?: string;
    @observable digitalObject: DigitalObject;
    @observable language?: Language;
    @observable newspaper!: Newspaper;
    advertiser?: Advertiser;
    runawayEvent?: RunawayEvent;
    readonly contributors = observable<User>([]);
    readonly advertisementGroups = observable<AdvertisementGroup>([]);
    @observable illegible: boolean = false;

    /**
     *
     * @param json {Object}
     */
    constructor(json: any = {}) {
        this.id = json.id;
        this.transcription = json.transcription;
        this.type = json.type;
        this.pageNumber = json.pageNumber;
        this.publicationDate = json.publicationDate;
        this.dateModified = json.dateModified;
        this.complete = json.complete;
        this.digitalObjectId = json.digitalObjectId;
        this.languageId = json.languageId;
        this.advertiserId = json.advertiserId;
        this.newspaperId = json.newspaperId;
        if (json.digitalObject) {
            this.digitalObject = new DigitalObject(json.digitalObject);
        } else {
            this.digitalObject = new DigitalObject();
        }
        if (json.language) {
            this.language = new Language(json.language);
        }

        if (json.newspaper) {
            this.newspaper = new Newspaper(json.newspaper);
        }

        if (json.advertiser) {
            this.advertiser = new Advertiser(json.advertiser);
        }
        if (json.runawayEvent) {
            this.runawayEvent = new RunawayEvent(json.runawayEvent);
        }
        if (json.contributors && json.contributors instanceof Array) {
            json.contributors.forEach((c: any) => {
                this.contributors.push(new User(c));
            })
        }
        if (json.advertisementGroups && json.advertisementGroups instanceof Array) {
            this.advertisementGroups.replace(json.advertisementGroups.map((group: any) => new AdvertisementGroup(group)));
        }

        if (this.transcription === ILLEGIBLE) {
            this.illegible = true;
        }
    }

    @action
    setTranscription(transcription: string) {
        this.transcription = transcription;
    }

    @action
    setType(type?: string) {
        this.type = type;
    }

    @action
    setPageNumber(pageNumber?: number) {
        this.pageNumber = pageNumber;
    }

    @action
    setDateModified(dateModified?: string) {
        this.dateModified = dateModified;
    }

    @action
    setComplete(complete?: boolean) {
        this.complete = complete;
    }

    @action
    setDigitalObjectId(digitalObjectId?: string) {
        this.digitalObjectId = digitalObjectId;
    }

    @action
    setLanguageId(languageId?: string) {
        this.languageId = languageId;
    }

    @action
    setAdvertiserId(advertiserId?: string) {
        this.advertiserId = advertiserId;
    }

    @action
    setNewspaperId(newspaperId?: string) {
        this.newspaperId = newspaperId;
    }

    @action
    setDigitalObject(digitalObject: object) {
        this.digitalObject = new DigitalObject(digitalObject);
    }

    @action
    setLanguage(language: object) {
        this.language = new Language(language);
        if (this.language.id) {
            this.setLanguageId(this.language.id);
        }
    }

    @action
    setNewspaper(newspaper: object) {
        this.newspaper = new Newspaper(newspaper);
    }

    @action
    setContributors(contributors: object[]) {
        this.contributors.replace(contributors.map(c => new User(c)));
    }

    @computed
    get groupAdvertisements() {
        let groupAds: Advertisement[] = [];
        this.advertisementGroups.forEach(group => groupAds = groupAds.concat(group.advertisements.filter((ad: Advertisement) => ad.id !== this.id)));
        return groupAds;
    }

    @action
    async removeGroupAd(advertisement: Advertisement) {
        let groups = this.advertisementGroups.filter(group => group.advertisements.findIndex((ad: Advertisement) => ad.id === advertisement.id));
        groups.forEach(async group => {
            await api.delete(`${config.adPath}/groups/${group.id}/${config.adPath}/${advertisement.id}`)
            runInAction(() => group.advertisements.remove(advertisement));
        })
    }

    @action
    setIllegible(illegible: boolean) {
        this.illegible = illegible;
        this.transcription = ILLEGIBLE;
    }

    get hasLocationGeom() {
        if (this.newspaper.location && this.newspaper.location.geometries && this.newspaper.location.geometries.length > 0) {
            return true;
        }

        if (this.runawayEvent && this.runawayEvent.runaways) {
            this.runawayEvent.runaways.forEach(runaway => {
                runaway.locations.forEach(location => {
                    if (location.location && location.location.geometries && location.location.geometries.length > 0) {
                        return true;
                    }
                });

                runaway.enslavers.forEach(enslaver => {
                    if (enslaver.location && enslaver.location.geometries && enslaver.location.geometries.length > 0) {
                        return true;
                    }
                })
            });
        }

        return false;
    }
}

export default Advertisement;
