import * as React from 'react';
import {observer} from 'mobx-react';
import {Card, Image, Modal} from "semantic-ui-react";
import {config} from "../config";
import {Link} from "react-router-dom";
import formatLocation from "../scenes/Search/services/LocationFormat";
import moment from "moment";

type IProps = {
    data: any;
    topAction?: any;
    extra?: any;
}

const AdvertisementCard = observer(({data, topAction, extra}: IProps) => {

    const ad = data;
    const transcription = ad.transcription;
    const newspaper = ad.newspaper ? ad.newspaper.name : '';
    const locationName = ad.newspaper && ad.newspaper.location ? formatLocation(ad.newspaper.location) : null;
    const pubDate = ad.publicationDate ? moment(ad.publicationDate).format('M/D/YYYY') : '';
    const id = ad.id;
    const image = ad.digitalObject ? ad.digitalObject.filename : null;

    return (
        <Card fluid className='advertisement'>
            {topAction &&
            <div className='top-right'>
                {topAction}
            </div>
            }
            {image ?
                <Card.Description className='image'>
                    <Modal trigger={<Image src={`${config.s3Domain}/${image}`} fluid alt="Advertisement"
                                           style={{cursor: 'pointer'}}/>}
                           className='image-modal' size='large' closeIcon>
                        <Modal.Content scrolling>
                            <Image src={`${config.s3Domain}/${image}`} fluid alt="Advertisement"/>
                        </Modal.Content>
                    </Modal>
                </Card.Description>
                :
                <Card.Description>
                    {transcription}
                </Card.Description>
            }
            <Card.Content>
                {locationName &&
                <Card.Meta>{locationName}</Card.Meta>
                }
                <Card.Meta>{pubDate}</Card.Meta>
            </Card.Content>
            {extra &&
            <Card.Content extra>
                {extra}
            </Card.Content>
            }
        </Card>
    )
});

export default AdvertisementCard;