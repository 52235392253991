import {action, observable} from 'mobx';
import Advertisement from "./Advertisement";
import {api} from "../../APICaller";
import {config} from "../../config";

class AdvertisementGroup {
    @observable id!: string;
    @observable transcription!: string;
    readonly advertisements = observable<Advertisement>([]);

    constructor(json: object) {
        this.update(json);
    }

    @action
    update(json: any) {
        this.id = json.id;
        this.transcription = json.transcription;
        if (json.advertisements && json.advertisements instanceof Array) {
            this.advertisements.replace(json.advertisements.map((ad: any) => new Advertisement(ad)))
        }
    }

    @action
    async fetchAdvertisements() {
        const {data} = await api.get(`${config.adPath}/groups/${this.id}`);
        this.update(data);
    }
}

export default AdvertisementGroup;
