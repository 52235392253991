import {action, computed, observable} from "mobx";

class User {
    _key: any;
    id: string;
    @observable authToken: string;
    @observable emailAddress: string;
    @observable fullname: string;
    @observable password?: string;
    @observable reputation: number;
    completeContributions: number;
    isMod: boolean;
    isAdmin: boolean;
    @observable receiveEmails: boolean;
    @observable public: boolean;
    @observable background: string;
    @observable restricted: boolean;

    constructor(json: any = {}) {
        this._key = json.key;
        this.id = json.id;
        this.authToken = json.authToken;
        this.emailAddress = json.emailAddress;
        this.fullname = json.fullname;
        this.reputation = json.reputation;
        this.completeContributions = json.completeContributions;
        this.isMod = json.isMod;
        this.isAdmin = json.isAdmin;
        this.receiveEmails = json.receiveEmails;
        this.public = json.public;
        this.background = json.background;
        this.restricted = json.restricted;
    }

    @action
    setProfile(profile: any) {
        this.id = profile.uuid;
        this.emailAddress = profile.email;
        this.fullname = profile.name;
    }

    @action
    setId(id: string) {
        this.id = id;
    }

    @action
    setAuthToken(authToken: string) {
        this.authToken = authToken;
    }

    @action
    setEmailAddress(emailAddress: string) {
        this.emailAddress = emailAddress
    }

    @action
    setFullname(fullname: string) {
        this.fullname = fullname
    }

    @action
    setPassword(password: string) {
        this.password = password
    }

    @action
    setReputation(reputation: number) {
        this.reputation = reputation
    }

    @action
    setReceiveEmails(receiveEmails: boolean) {
        this.receiveEmails = receiveEmails
    }

    @action
    setPublic(isPublic: boolean) {
        this.public = isPublic
    }

    @action
    setBackground(background: string) {
        this.background = background
    }

    @computed
    get initials() {
        let initials = this.fullname.charAt(0).toUpperCase();
        const lastIndex = this.fullname.lastIndexOf(' ') + 1;
        if (lastIndex > 0) {
            initials += this.fullname.charAt(lastIndex).toUpperCase();
        } else if (this.fullname.length > 1) {
            initials += this.fullname.charAt(1).toLowerCase();
        }
        return initials;
    }

    get key() {
        if (this.id) {
            return this.id;
        }
        return this._key;
    }
}

export default User;
