import React, {useEffect, useState} from 'react';
import ReactMarkdown from "react-markdown";
import {Link, RouteComponentProps} from "react-router-dom";
import {Container, Divider, Grid, Image, Message} from "semantic-ui-react";
import Routes from "../../Routes";
import {LessonData} from "./models/LessonData";

import "./styles.scss";

const lionsSide: LessonData = {
    directory: 'TheLionsSide',
    banner: "lions-side.png",
    title: "The Lion's Side:",
    subtitle: "Creating a Resistance Newspaper",
    video: "https://player.vimeo.com/video/418486433",
    author: "Ahmariah Jackson",
    subjectAreas: "History, Language Arts & Creative Writing Classes",
    gradeLevels: "Adaptable for Grades 6-12",
    sections: [
        {
            heading: "Background",
            markdownFile: require("./content/TheLionsSide/background.md")
        },
        {
            heading: "Objectives / Student Goals",
            markdownFile: require("./content/TheLionsSide/objective.md")
        },
        {
            heading: "Resources",
            markdownFile: require("./content/TheLionsSide/resources.md")
        },
        {
            heading: "Focus Question",
            markdownFile: require("./content/TheLionsSide/focus-question.md")
        },
        {
            heading: "Essential Vocabulary",
            markdownFile: require("./content/TheLionsSide/essential-vocab.md")
        },
        {
            heading: "Opening Activity (Annotation and Analysis)",
            markdownFile: require("./content/TheLionsSide/opening-activity.md")
        },
        {
            heading: "Guided Instruction",
            markdownFile: require("./content/TheLionsSide/guided-instruction.md")
        },
        {
            heading: "Independent Practice",
            markdownFile: require("./content/TheLionsSide/independent-practice.md")
        },
        {
            heading: "Collaborative Practice",
            markdownFile: require("./content/TheLionsSide/collaborative-practice.md")
        },
        {
            heading: "Closing Activity",
            markdownFile: require("./content/TheLionsSide/closing-activity.md")
        },
        {
            heading: "Extension Activity",
            markdownFile: require("./content/TheLionsSide/extension-activity.md")
        },
        {
            heading: "Teachers Note",
            markdownFile: require("./content/TheLionsSide/teachers-note.md")
        }
    ],
};

const travelingBack: LessonData = {
    directory: 'TravelingBack',
    banner: "traveling-back.png",
    title: "Traveling Back:",
    subtitle: "Building a Living Timeline of Pre-Civil War Slavery",
    video: "https://player.vimeo.com/video/418495817",
    author: "Cora Davis",
    subjectAreas: "History & Language Arts Classes",
    gradeLevels: "Adaptable for Grades 3-12",
    sections: [
        {
            heading: "Background",
            markdownFile: require("./content/TravelingBack/background.md")
        },
        {
            heading: "Objectives / Student Tasks",
            markdownFile: require("./content/TravelingBack/objective.md")
        },
        {
            heading: "Pre-lesson (optional)",
            markdownFile: require("./content/TravelingBack/pre-lesson.md")
        },
        {
            heading: "Warm-up: Don’t Judge a Book by Its Cover",
            markdownFile: require("./content/TravelingBack/warm-up.md")
        },
        {
            heading: "Part 1: Explore the Ads",
            markdownFile: require("./content/TravelingBack/explore.md")
        },
        {
            heading: "Part 2: Construct the Timeline",
            markdownFile: require("./content/TravelingBack/construct.md")
        },
        {
            heading: "Part 3: Discuss as a Group",
            markdownFile: require("./content/TravelingBack/discuss.md")
        },
        {
            heading: "Extension Activities",
            markdownFile: require("./content/TravelingBack/extension.md")
        },
    ],
};

const whoIsAHistorian: LessonData = {
    directory: 'WhoIsAHistorian',
    banner: "who-is-a-historian.png",
    title: "Who Is a Historian?",
    subtitle: "Answering Tough Questions Using Primary Sources",
    video: "https://player.vimeo.com/video/418563528",
    author: "Will Duke",
    subjectAreas: "History Classes",
    gradeLevels: "Adaptable for Grades 3-12",
    sections: [
        {
            heading: "Student Objectives",
            markdownFile: require("./content/WhoIsAHistorian/objectives.md")
        },
        {
            heading: "Resources",
            markdownFile: require("./content/WhoIsAHistorian/resources.md")
        },
        {
            heading: "Warm-Up",
            markdownFile: require("./content/WhoIsAHistorian/warm-up.md")
        },
        {
            heading: "Instruction",
            markdownFile: require("./content/WhoIsAHistorian/instruction.md")
        },
        {
            heading: "Return to Warm Up",
            markdownFile: require("./content/WhoIsAHistorian/return.md")
        },
        {
            heading: "Extension Activity",
            markdownFile: require("./content/WhoIsAHistorian/extension.md")
        },
    ],
};

const tellingTheirStories: LessonData = {
    directory: 'TellingTheirStories',
    banner: "telling-their-stories.png",
    title: "Telling Their Stories:",
    subtitle: "Using Runaway Ads to Write Narratives",
    video: "https://player.vimeo.com/video/418502765",
    author: "Heather Ingram",
    subjectAreas: "History, Language Arts & Psychology Classes",
    gradeLevels: "Adaptable for Grades 5-12",
    sections: [
        {
            heading: "Background",
            markdownFile: require("./content/TellingTheirStories/background.md")
        },
        {
            heading: "Tangible Objectives",
            markdownFile: require("./content/TellingTheirStories/objectives.md")
        },
        {
            heading: "Terms to Know",
            markdownFile: require("./content/TellingTheirStories/terms.md")
        },
        {
            heading: "Required Reading",
            markdownFile: require("./content/TellingTheirStories/required-reading.md")
        },
        {
            heading: "Supplemental Sources",
            markdownFile: require("./content/TellingTheirStories/supplemental-sources.md")
        },
        {
            heading: "Instructional Handouts",
            markdownFile: require("./content/TellingTheirStories/handouts.md")
        },
        {
            heading: "In The Classroom",
            markdownFile: require("./content/TellingTheirStories/in-the-classroom.md")
        },
        {
            heading: "Supported Standards, Concepts & Objectives",
            markdownFile: require("./content/TellingTheirStories/supported-standards.md")
        },
    ],
};

const rewritingTheNarrative: LessonData = {
    author: "Kristin Marconi & Christine Snivley",
    banner: "rewriting-the-narrative.png",
    directory: "RewritingTheNarrative",
    gradeLevels: "8th Grade",
    sections: [
        {
            heading: "Lesson Overview",
            markdownFile: require("./content/RewritingTheNarrative/overview.md")
        },
        {
            heading: "Objectives",
            markdownFile: require("./content/RewritingTheNarrative/objectives.md")
        },
        {
            heading: "Number of Class Periods",
            markdownFile: require("./content/RewritingTheNarrative/time.md")
        },
        {
            heading: "Grade Levels",
            markdownFile: require("./content/RewritingTheNarrative/grade-levels.md")
        },
        {
            heading: "Materials",
            markdownFile: require("./content/RewritingTheNarrative/materials.md")
        },
        {
            heading: "Pre-teaching Thoughts",
            markdownFile: require("./content/RewritingTheNarrative/pre-teaching.md")
        },
        {
            heading: "Procedures",
            markdownFile: require("./content/RewritingTheNarrative/procedures.md")
        },
        {
            heading: "Assessment",
            markdownFile: require("./content/RewritingTheNarrative/assessment.md")
        }
    ],
    subjectAreas: "History",
    title: "Rewriting the Narrative",
    video: ""
}

const lordDunmoreProclamation: LessonData = {
    author: "Suzanne Perlis",
    directory: "LordDunmoreProclamation",
    gradeLevels: "6th - 8th Grade",
    sections: [
        {
            heading: "Lesson Overview",
            markdownFile: require("./content/LordDunmoreProclamation/overview.md")
        },
        {
            heading: "Objectives",
            markdownFile: require("./content/LordDunmoreProclamation/objectives.md")
        },
        {
            heading: "Number of Class Periods",
            markdownFile: require("./content/LordDunmoreProclamation/time.md")
        },
        {
            heading: "Grade Levels",
            markdownFile: require("./content/LordDunmoreProclamation/grade-levels.md")
        },
        {
            heading: "Materials",
            markdownFile: require("./content/LordDunmoreProclamation/materials.md")
        },
        {
            heading: "Teacher Background",
            markdownFile: require("./content/LordDunmoreProclamation/background.md")
        },
        {
            heading: "Procedures",
            markdownFile: require("./content/LordDunmoreProclamation/procedures.md")
        },
        {
            heading: "Assessments",
            markdownFile: require("./content/LordDunmoreProclamation/assessments.md")
        },
    ],
    subjectAreas: "History",
    title: "Analyzing the impact of Lord Dunmore’s Proclamation"
}

const theDeplorableEntanglement: LessonData = {
    author: "Suzanne Perlis",
    directory: "TheDeplorableEntanglement",
    gradeLevels: "6th - 8th Grade",
    sections: [
        {
            heading: "Lesson Overview",
            markdownFile: require("./content/TheDeplorableEntanglement/overview.md")
        },
        {
            heading: "Essential Questions",
            markdownFile: require("./content/TheDeplorableEntanglement/essential-questions.md")
        },
        {
            heading: "Objectives",
            markdownFile: require("./content/TheDeplorableEntanglement/objectives.md")
        },
        {
            heading: "Number of Class Periods",
            markdownFile: require("./content/TheDeplorableEntanglement/time.md")
        },
        {
            heading: "Grade Levels",
            markdownFile: require("./content/TheDeplorableEntanglement/grade-levels.md")
        },
        {
            heading: "Historical Context",
            markdownFile: require("./content/TheDeplorableEntanglement/context.md")
        },
        {
            heading: "Materials",
            markdownFile: require("./content/TheDeplorableEntanglement/materials.md")
        },
        {
            heading: "Procedures",
            markdownFile: require("./content/TheDeplorableEntanglement/procedures.md")
        },
        {
            heading: "History Lab Activity",
            markdownFile: require("./content/TheDeplorableEntanglement/lab.md")
        },
        {
            heading: "Assessment and Summary",
            markdownFile: require("./content/TheDeplorableEntanglement/summary.md")
        },
    ],
    subjectAreas: "History",
    title: "The “Deplorable Entanglement” of Slavery",
    subtitle: "The Complexity of Thomas Jefferson and Slavery"
}

const analyzingPrimarySources: LessonData = {
    author: "KB Tucker",
    directory: "AnalyzingPrimarySources",
    gradeLevels: "7th - 8th Grade",
    sections: [
        {
            heading: "Lesson Overview",
            markdownFile: require("./content/AnalyzingPrimarySources/overview.md")
        },
        {
            heading: "Objectives",
            markdownFile: require("./content/AnalyzingPrimarySources/objectives.md")
        },
        {
            heading: "Grade Levels & Standards",
            markdownFile: require("./content/AnalyzingPrimarySources/grade-levels.md")
        },
        {
            heading: "Materials",
            markdownFile: require("./content/AnalyzingPrimarySources/materials.md")
        },
        {
            heading: "Procedures",
            markdownFile: require("./content/AnalyzingPrimarySources/procedures.md")
        },
    ],
    subjectAreas: "History",
    title: "Analyzing Primary Sources",
}

const adAnalysis: LessonData = {
    author: "Nicholson",
    directory: "AdAnalysis",
    gradeLevels: "",
    sections: [
        {
            heading: "Materials",
            markdownFile: require("./content/AdAnalysis/materials.md")
        }
    ],
    subjectAreas: "History",
    title: "Ad Analysis",
}

const lessonData: Map<string, LessonData> = new Map([
    ["the-lions-side", lionsSide],
    ["traveling-back", travelingBack],
    ["who-is-a-historian", whoIsAHistorian],
    ["telling-their-stories", tellingTheirStories],
    ["rewriting-the-narrative", rewritingTheNarrative],
    ["lord-dunmore-proclamation", lordDunmoreProclamation],
    ["the-deplorable-entanglement", theDeplorableEntanglement],
    ["analyzing-primary-sources", analyzingPrimarySources],
    ["ad-analysis", adAnalysis],
]);

const Lesson = (props: RouteComponentProps) => {

    const lessonKey = props.location.pathname.replace('educators/', '').replace('/', '');
    const selectedLesson: LessonData = (lessonData.has(lessonKey) && lessonData.get(lessonKey)) || lionsSide;
    const [markdown, setMarkdown] = useState<Map<string, string>>(new Map());

    useEffect(() => {
        const load = async (lesson: LessonData) => {
            let temp = new Map();

            for (let i = 0; i < lesson.sections.length; i++) {
                let res = await fetch(lesson.sections[i].markdownFile);
                let md = await res.text();

                temp.set(lesson.sections[i].heading, md);
            }

            setMarkdown(temp);
            window?.scrollTo(0, 0);
        };

        if (markdown.size === 0) {
            load(selectedLesson);
        }

    }, [markdown, selectedLesson]);

    return (
        <Container id='content' className='educators' style={{fontSize: "16px"}}>
            <Divider hidden/>
            <Link to={Routes.Educators}>Return to Educator Homepage</Link>
            <Divider hidden/>
            {selectedLesson.banner &&
            <Image src={require(`./content/${selectedLesson.directory}/${selectedLesson.banner}`)} alt={selectedLesson.title + (selectedLesson?.subtitle ? ' ' + selectedLesson?.subtitle : '')}/>}
            <Divider hidden/>

            {selectedLesson.video &&
            <div className="ui embed">
                <iframe title={selectedLesson.title} src={selectedLesson.video} width="100%" height="auto" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen/>
            </div>}

            <div style={{textAlign: "center", marginTop: 10, marginBottom: 40}}>
                <Message floating compact className="grey" style={{textAlign: "center"}}>
                    <em><strong>{selectedLesson.title + (selectedLesson?.subtitle ? ' ' + selectedLesson?.subtitle : '')}</strong></em><br/>
                    <div className="black">
                        presented by {selectedLesson.author}<br/><br/>
                        Suitable for {selectedLesson.subjectAreas}<br/>
                        {selectedLesson.gradeLevels}
                    </div>
                </Message>
            </div>

            <hr/>

            {selectedLesson.sections.map((s, i) =>
                <div style={{marginBottom: 35}} key={s.heading}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <div style={{marginTop: 10}}>
                                    <h1>{s.heading}</h1>
                                    <ReactMarkdown source={markdown?.get(s.heading) || ''}
                                                   renderers={{link: props => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>}}/>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {selectedLesson.sections.length - 1 !== i ? <Divider/> : <Divider hidden/>}
                </div>
            )}

            <Divider/>

            <div style={{marginBottom: 35, fontSize: 14}}>
                <ReactMarkdown renderers={{link: props => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>}}
                               source="This lesson aligns to key concepts [4](https://www.tolerance.org/frameworks/teaching-hard-history/american-slavery/key-concept-videos#keyconcept4),
                [5](https://www.tolerance.org/frameworks/teaching-hard-history/american-slavery/key-concept-videos#keyconcept5),
                [6](https://www.tolerance.org/frameworks/teaching-hard-history/american-slavery/key-concept-videos#keyconcept6) and
                [10](https://www.tolerance.org/frameworks/teaching-hard-history/american-slavery/key-concept-videos#keyconcept10) of Teaching Tolerance’s [Teaching Hard History Framework](https://www.tolerance.org/frameworks/teaching-hard-history/american-slavery)."/>
            </div>

            <Divider hidden/>

        </Container>
    )
}

export default Lesson;