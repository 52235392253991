import {action, computed, observable, runInAction} from 'mobx';
import {config} from "../../config";
import Advertisement from "../../stores/models/Advertisement";
import {api} from "../../APICaller";
import {createLoader} from "../../stores/utils/Loader";

class AdGroupingStore {
    readonly advertisements = observable<Advertisement>([]);
    @observable selected = new Map();
    loader = createLoader();

    @action
    async findCandidates(id?: string) {
        const withID = id ? `/${id}` : '';
        const {data} = await this.loader.load(api.get(`${config.adPath}/grouping${withID}?offset=${this.advertisements.length}&limit=18`));
        runInAction(() => {
            this.advertisements.replace(this.advertisements.concat(data.map((ad: any) => new Advertisement(ad))));
            if (this.advertisements.length > 0) {
                this.selected.set(this.advertisements[0].id, true);
            }
        });
    }

    async markUngrouped() {
        const id = this.selectedIds[0];
        if (id) {
            await api.put(`${config.adPath}/grouping/${id}`, {});
            runInAction(() => {
                this.advertisements.clear();
                this.selected = new Map();
            });
        }
        this.findCandidates();
    }

    @action
    async groupAds(id?: string) {
        const ids = this.selectedIds;
        if (id) {
            ids.push(id);
        }
        if (ids.length > 1) {
            await this.loader.load(api.post(`${config.adPath}/grouping`, ids));
            runInAction(() => {
                this.advertisements.clear();
                this.selected = new Map();
            });
            this.findCandidates();
        }
    }

    @computed
    get selectedIds() {
        const ids: string[] = [];
        this.selected.forEach((v, k) => {
            if (v && k) {
                ids.push(k);
            }
        });
        return ids;
    }

    @computed
    get sampleAd() {
        if (this.advertisements.length > 0) {
            return this.advertisements[0];
        }
        return null;
    }

    @computed
    get candidates() {
        return this.advertisements.slice(1);
    }

    @action
    toggleSelected(id: string) {
        this.selected.set(id, !this.selected.get(id));
    }
}


export const adGroupingStore = new AdGroupingStore();
