import * as React from 'react';
import {observer} from "mobx-react"
import {Runaway} from "./scenes/Runaway/Runaway";
import {Accordion, Divider, Header, Icon} from "semantic-ui-react";
import {domainStore} from "../../stores/CrowdsourcingStore";
import {uiStore} from "../../stores/UIStore";
import {ErrorBoundary} from "../../components/ErrorBoundary";
import queryString from "query-string";
import {FormPage} from "../../components/FormPage";

type RunawayOption = {
    key: number | string;
    text: string;
    value: any
}

@observer
export class Runaways extends React.Component {
    runawayOptions: RunawayOption[] = [];
    loader = domainStore.getLoader(500);

    constructor(props: any) {
        super(props);

        this.getRunaways();
        uiStore.initRunaways();
    }

    getRunaways() {
        if (domainStore.runaways.length <= 0) {
            domainStore.addRunaway();
        }
    }

    changeRunaway = (value: number) => {
        uiStore.setRunaway(value);
    };

    submitRunaway = (i: number) => {
        return this.loader.load(domainStore.pushAllRunawayData(i)
            .then(() => {
                if (i >= domainStore.runaways.length - 1) {
                    domainStore.updateAdvertisementProgress(90)
                }
            })
        )
    };

    previousPage = () => {
        if (uiStore.currentRunaway > 0) {
            this.changeRunaway(uiStore.currentRunaway - 1);
        } else {
            uiStore.previous();
        }
    };

    openPage = (value: number) => {
        if (uiStore.currentRunaway !== value) {
            uiStore.setCurrentRunaway(value);
            let query = Object.assign(uiStore.query, {runaway: value});
            uiStore.history!.push("?" + queryString.stringify(query));
        }
    };

    removeRunaway = (i: number) => {
        if (i === uiStore.currentRunaway) {
            this.openPage(i > 0 ? i - 1 : 0);
        }
        domainStore.removeRunaway(i);
        uiStore.removeRunaway(i);
    };

    render() {
        this.runawayOptions = domainStore.runaways.map((r, i) => {
            return {
                key: r.id ? r.id : uiStore.getKey(),
                text: r.enslavedPerson.names.length > 0 && r.enslavedPerson.names[0].fullname !== "" ? r.enslavedPerson.names[0].fullname.toUpperCase() : `RUNAWAY #${i + 1}`,
                value: i
            }
        });

        return (
            <FormPage className='transcription-form'>
                <ErrorBoundary loader={this.loader}>
                    <div>
                        {this.runawayOptions.map((o, i) => {
                            return (
                                <React.Fragment key={o.key}>
                                    <Accordion fluid>
                                        <Accordion.Title as='button' active={i === uiStore.currentRunaway}
                                                         onClick={() => this.openPage(i)}>
                                            <Header as='h1'><span
                                                className='ui orange header'>RUNAWAY:&nbsp;</span>{o.text}
                                                <Icon name='dropdown' style={{float: 'right'}}/></Header>
                                        </Accordion.Title>

                                        <Accordion.Content active={i === uiStore.currentRunaway}>
                                            <Runaway runaway={i} submitRunaway={this.submitRunaway}/>
                                        </Accordion.Content>
                                    </Accordion>
                                    {i < this.runawayOptions.length - 1 && <Divider section/>}
                                </React.Fragment>
                            )
                        })}
                    </div>
                </ErrorBoundary>

            </FormPage>
        );
    }
}
