import * as React from "react";
import {observer} from "mobx-react";
import {Button, Header} from "semantic-ui-react";
import {domainStore} from "../../../../stores/CrowdsourcingStore";
import {uiStore} from "../../../../stores/UIStore";
import RunawayRelationship from "./components/RunawayRelationship";
import Horizontal from "../../../../components/Horizontal";
import {Loader} from "../../../../../../stores/utils/Loader";

interface IProps {
    loader: Loader;
}

@observer
export class RunawayRelationships extends React.Component<IProps> {
    loader = this.props.loader;
    runaways = domainStore.runaways;

    componentDidMount() {
        domainStore.initRelationships();
    }

    addRelationship = () => {
        const relationship: any = {};
        domainStore.runaways.forEach((r, i) => {
            if (!r.id) {
                domainStore.pushAllRunawayData(i);
            }
        });

        if (this.runaways.length === 2) {
            relationship.leftRunawayId = this.runaways[0].id;
            relationship.rightRunawayId = this.runaways[1].id;
        }
        domainStore.addRelationship(relationship);
    };

    removeRelationship = (i: number) => {
        domainStore.removeRelationship(i);
    };

    render() {
        let n = this.runaways.length;
        let limit = n * (n - 1) / 2;

        return (
            <div style={{marginTop: 40}}>
                <Header as='h2'>Relationships
                    <Header.Subheader>If the advertisement mentions any relationships (family or other) between
                        runaways, list them here.</Header.Subheader>
                </Header>
                {domainStore.relationships.map((r, i) => {
                    return (
                        <Horizontal key={uiStore.getKey()}>
                            <RunawayRelationship loader={this.loader} relationship={r}/>
                            <button className='link-button' style={{marginLeft: 20}}
                                    onClick={() => this.removeRelationship(i)}>REMOVE
                            </button>
                        </Horizontal>
                    )
                })}

                {domainStore.relationships.length < limit &&
                <Button color='blue' onClick={this.addRelationship}>ADD A RELATIONSHIP</Button>
                }
            </div>
        );
    }
}
