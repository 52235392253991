import './styles.css'
import React, {Component} from 'react';
import Finish from './scenes/Finish/Finish';
import {observer} from "mobx-react";
import {FormPage} from "./components/FormPage";
import queryString from "query-string";
import {Button, Header} from "semantic-ui-react";
import {domainStore} from "./stores/CrowdsourcingStore";
import {uiStore} from "./stores/UIStore";
import {options} from "../../Constants";
import {ErrorBoundary} from "./components/ErrorBoundary";
import {Transcription} from "./scenes/Transcription/Transcription";
import {RunawayEvent} from "./scenes/RunawayEvent/RunawayEvent";
import {Runaways} from "./scenes/Runaways/Runaways";
import Enslaver from "./scenes/Enslaver/Enslaver";
import Advertiser from "./scenes/Advertiser/Advertiser";
import RunawayNames from "./scenes/RunawayNames/RunawayNames";
import NotFound from "../NotFound/NotFound";
import {Redirect, RouteComponentProps} from "react-router-dom";
import Routes from "../../Routes";

type IProps = RouteComponentProps<{ advertisementId: string }>;

@observer
export class Crowdsourcing extends Component<IProps> {
    loader = domainStore.getLoader(0);

    constructor(props: IProps) {
        super(props);
        this.componentWillReceiveProps(props);
        options.fetchLanguages();
        options.fetchStates();
    }

    componentWillReceiveProps(nextProps: IProps) {
        const advertisementId = nextProps.match && nextProps.match.params ? nextProps.match.params.advertisementId : null;
        const adChanged = !domainStore.advertisement || advertisementId !== domainStore.advertisement.id;
        if (advertisementId && adChanged) {
            domainStore.setAdvertisementId(advertisementId);
        }

        let pageChanged = adChanged;
        if (this.props.location) {
            const prevSection = uiStore.section;
            let query = queryString.parse(nextProps.location.search);
            uiStore.saveQuery(query);
            pageChanged = pageChanged || prevSection !== uiStore.section;
        }

        if (pageChanged) {
            uiStore.history = nextProps.history;
            this.getAdvertisement();
        }
    }

    componentDidUpdate() {
        if (this.props.location) {
            let query = queryString.parse(this.props.location.search);
            uiStore.saveQuery(query);
        }
    }

    getAdvertisement() {
        if (domainStore.advertisement && domainStore.advertisement.id) {
            if (this.props.location.pathname.endsWith("transcriber")) {
                this.props.history.push(`${this.props.location.pathname}/${domainStore.advertisement.id}`);
            }
            this.loader.load(domainStore.updateAll()
                .then(() => uiStore.getNLPTranscription));
        } else {
            this.loader.load(domainStore.getAdToTranscribe()
                .then(() => this.props.history.push(`${this.props.location.pathname}/${domainStore.advertisement!.id}`)));
        }
    }

    render() {
        let page;
        // noinspection FallThroughInSwitchStatementJS
        switch (uiStore.section) {
            case 1:
                page = <Transcription/>;
                break;
            case 2:
                page = <Advertiser/>;
                break;
            case 3:
                page = <RunawayEvent/>;
                break;
            case 4:
                page = <RunawayNames/>;
                break;
            case 5:
                page = <Runaways/>;
                break;
            case 6:
                page = <Enslaver/>;
                break;
            case 7:
                page = <Finish/>;
                break;
            default:
                page = <FormPage>
                    <div/>
                </FormPage>;
                break;
        }

        if (!domainStore.advertisement) {
            page =
                <div style={{textAlign: 'center', fontSize: '24px', marginTop: '20%'}}>
                    <Header size='large'>There are currently no more advertisements to transcribe.</Header>
                    <Button primary size='large' onClick={() => this.props.history.push("/")}>Return to Home</Button>
                </div>
        }

        if (this.loader.clientError) {
            return <NotFound/>;
        }

        if (this.loader.unauthenticated) {
            return (
                <Redirect to={{
                    pathname: Routes.login,
                    state: {from: this.props.location}
                }}/>
            )
        }

        return (
            <div>
                <ErrorBoundary loader={this.loader}>
                    {page}
                </ErrorBoundary>
            </div>
        );
    }
}
