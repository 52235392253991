import Language from "./Language";

class EnslavedPersonLanguage {
    enslavedPersonId: string;
    languageId: string;
    language?: Language;
    doesSpeak = true;

    constructor(json: any) {
        this.enslavedPersonId = json.enslavedPersonId;
        this.languageId = json.languageId;
        this.doesSpeak = json.doesSpeak;
        if (json.language) {
            this.language = new Language(json.language);
        }
    }
}

export default EnslavedPersonLanguage;