import {Grid} from "semantic-ui-react";
import * as React from "react";
import {CSSProperties} from "react";

interface IProps {
    children?: React.ReactNode,
    style?: CSSProperties
}

export const MiddleAligned = ({children, style, ...props}: IProps) => {
    const gridStyle = Object.assign({height: '100%', flexGrow: 1, margin: 0}, style);
    return (
        <Grid verticalAlign='middle' style={gridStyle} {...props}>
            <Grid.Column>
                {children}
            </Grid.Column>
        </Grid>
    )
};

export default MiddleAligned;