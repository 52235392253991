import {action, computed, observable, runInAction} from "mobx";
import EnslavedPersonName from "./EnslavedPersonName";
import EnslavedPersonLanguage from "./EnslavedPersonLanguage";
import {options} from "../../Constants";

class EnslavedPerson {
    id: string;
    @observable gender?: string;
    @observable minBirthYear?: string;
    @observable maxBirthYear?: string;
    @observable literacy?: string;
    @observable skills?: string;
    readonly languages = observable<EnslavedPersonLanguage>([]);
    readonly names = observable<EnslavedPersonName>([]);

    constructor(json: any = {}) {
        this.id = json.id;
        this.gender = json.gender;
        this.minBirthYear = json.minBirthYear;
        this.maxBirthYear = json.maxBirthYear;
        this.literacy = json.literacy;
        this.skills = json.skills;

        if (json.languages) {
            json.languages.forEach((l: any) => {
                this.languages.push(new EnslavedPersonLanguage(l));
            });
        }

        if (json.names) {
            let hasName = false;
            json.names.forEach((n: any) => {
                n.isAlias = n.isAlias || hasName;
                hasName = hasName || !n.isAlias;

                this.names.push(new EnslavedPersonName(n));
            });
            if (!hasName && this.names.length > 0) {
                this.names[0].isAlias = false;
            }
        }
    }

    @action
    setGender(gender?: string) {
        this.gender = gender;
    }

    @action
    setMinBirthYear(minBirthYear?: string) {
        this.minBirthYear = minBirthYear;
    }

    @action
    setMaxBirthYear(maxBirthYear?: string) {
        this.maxBirthYear = maxBirthYear;
    }

    @action
    setLiteracy(literacy?: string) {
        this.literacy = literacy;
    }

    @action
    setSkills(skills?: string) {
        this.skills = skills;
    }

    @action
    setLanguages(languages: any[]) {
        this.languages.replace(languages.map((l: any) => new EnslavedPersonLanguage(l)));
    }

    @action
    addLanguage(json: any) {
        runInAction(() => this.languages.push(new EnslavedPersonLanguage(json)));
    }

    @action
    removeLanguage(i: number) {
        this.languages.splice(i, 1);
    }

    @computed
    get knownLanguages() {
        return this.languages.filter(l => l.doesSpeak);
    }

    @computed
    get unknownLanguages() {
        return this.languages.filter(l => !l.doesSpeak);
    }

    isLanguagesEmpty() {
        return this.languages.length === 0;
    }

    @action
    changeLanguages(languages: any[], doesSpeak: boolean) {
        let known = this.knownLanguages;
        let unknown = this.unknownLanguages;
        let changed = languages.map(l => {
                let language = options.languages.find(l2 => l === l2.id);
                return new EnslavedPersonLanguage({
                    enslavedPersonId: this.id,
                    languageId: language!.id,
                    language: language,
                    doesSpeak: doesSpeak
                })
            }
        );
        if (doesSpeak) {
            known = changed;
            let ids = known.map(k => k.languageId);
            unknown = unknown.filter(l => {
                return ids.indexOf(l.languageId) < 0;
            })
        } else {
            unknown = changed;
            let ids = unknown.map(k => k.languageId);
            known = known.filter(l => {
                return ids.indexOf(l.languageId) < 0;
            })
        }

        this.languages.replace(known.concat(unknown));
    };

    @action
    setNames(names: any[]) {
        this.names.replace(names.map(n => new EnslavedPersonName(n)));
    }

    @action
    addName(json: any) {
        json.enslavedPersonId = this.id;
        this.names.push(new EnslavedPersonName(json));
    }

    @action
    removeName(name: EnslavedPersonName) {
        const i = this.names.indexOf(name);
        this.names.splice(i, 1);
    }

    isNamesEmpty() {
        return this.names.filter(n => !n.isEmpty()).length === 0;
    }

    getDataForServer() {
        let data: any = Object.assign({}, this);

        if (this.isNamesEmpty()) {
            data.names = [];
        }

        if (this.isLanguagesEmpty()) {
            data.languages = [];
        }

        return data;
    }

    @computed
    get aliases() {
        return this.names.filter(name => name.isAlias);
    }

    @action
    removeAliases() {
        for (let i = this.names.length - 1; i >= 0; i--) {
            if (this.names[i].isAlias) {
                this.names.splice(i, 1);
            }
        }
    }

    @computed
    get name() {
        return this.names.find(name => !name.isAlias);
    }
}

export default EnslavedPerson;
