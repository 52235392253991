import * as React from "react";
import {SyntheticEvent} from "react";
import {Button, Form, Icon, Input, InputProps} from "semantic-ui-react";
import {HeightInput} from "./HeightInput";
import Nullable from "../../../../../../../../../types/Nullable";

enum Selection {
    exact = "exact",
    range = "range",
    approximate = "approximate",
    unknown = "unknown",
    unset = "",
}

export interface DetailFieldValues {
    min?: number;
    max?: number;
    estimate?: string;
}

interface DetailFieldProps extends DetailFieldValues {
    values: (Nullable<number>)[];
    clearable?: boolean;
    nullable?: boolean;
    label: string;
    unit?: string;
    type?: string;
    onChange: (values: DetailFieldValues) => void;
}

interface DetailFieldState {
    selection: Selection
}

export class DetailField extends React.Component<DetailFieldProps, DetailFieldState> {
    clearable?: boolean;
    nullable?: boolean;
    NULL_NUM?: -9;
    NULL_STRING?: 'NOT_PROVIDED';
    min!: number;
    max!: number;
    label!: string;
    unit!: string;
    type?: string;
    estimate!: string;
    values!: (Nullable<number>)[];
    minValue!: number;
    maxValue!: number;

    state: DetailFieldState = {
        selection: Selection.unset
    };

    constructor(props: DetailFieldProps) {
        super(props);
        this.componentWillReceiveProps(props);

        this.clearable = props.clearable;
        this.nullable = props.nullable;
        this.NULL_NUM = props.nullable ? -9 : undefined;
        this.NULL_STRING = this.nullable ? 'NOT_PROVIDED' : undefined;

        if (props.values && props.values[0] && props.values[1]) {
            if (props.estimate && props.estimate === this.NULL_STRING
                && props.values[0] === this.NULL_NUM && props.values[1] === this.NULL_NUM) {
                this.state.selection = Selection.unknown;
            } else if (props.values[0] === props.values[1]) {
                if (props.values[0] !== this.NULL_NUM) {
                    this.state.selection = Selection.exact;
                } else {
                    this.state.selection = Selection.approximate;
                }
            } else {
                this.state.selection = Selection.range;
            }
        } else if (props.estimate) {
            this.state.selection = Selection.approximate;
        }
    }

    componentWillReceiveProps(props: DetailFieldProps) {
        this.min = props.min ? props.min : 0;
        this.max = props.max ? props.max : 100;
        this.label = props.label ? props.label : "";
        this.unit = props.unit ? props.unit : "";
        this.type = props.type;

        this.estimate = props.estimate ? props.estimate : "";
        this.values = props.values ? props.values : [0, 0];
        this.minValue = this.values.length > 0 && this.values[0] ? this.values[0] : 0;
        this.maxValue = this.values.length > 1 && this.values[1] ? this.values[1] : 0;
    }

    changeMin = (e: SyntheticEvent | null, {value}: InputProps) => {
        let n = parseInt(value, 10);
        // if (n >= min && n <= maxValue) {
        this.props.onChange({min: n, max: this.maxValue, estimate: this.NULL_STRING});
        // }
    };

    changeMax = (e: SyntheticEvent | null, {value}: InputProps) => {
        let n = parseInt(value, 0);
        // if (n <= max && n >= minValue) {
        this.props.onChange({min: this.minValue, max: n, estimate: this.NULL_STRING});
        // }
    };

    changeExact = (e: SyntheticEvent | null, {value}: InputProps) => {
        let n = parseInt(value, 10);
        // if (n <= max && n >= minValue) {
        this.props.onChange({min: n, max: n, estimate: this.NULL_STRING});
        // }
    };

    changeEstimate = (e: SyntheticEvent, {value}: InputProps) => {
        this.props.onChange({estimate: value, min: this.NULL_NUM, max: this.NULL_NUM});
    };

    changeSelection = (value: Selection) => {
        if (this.state.selection !== value) {
            this.setState({selection: value});
        } else {
            this.setState({selection: Selection.unknown});
        }

        switch (value) {
            case Selection.exact:
                this.props.onChange({min: this.min, max: this.min, estimate: this.NULL_STRING});
                break;
            case Selection.range:
                this.props.onChange({min: this.min, max: this.max, estimate: this.NULL_STRING});
                break;
            case Selection.approximate:
                this.props.onChange({min: this.NULL_NUM, max: this.NULL_NUM, estimate: ''});
                break;
            case Selection.unknown:
                this.props.onChange({estimate: this.NULL_STRING, min: this.NULL_NUM, max: this.NULL_NUM});
                break;
            default:
                this.props.onChange({estimate: undefined, min: undefined, max: undefined});
                break;
        }
    };

    handleUnknown = () => {
        this.changeSelection(this.clearable && this.state.selection === Selection.unknown ? Selection.unset : Selection.unknown);
    };

    ExactButton = () => {
        const active = this.state.selection === 'exact';
        return (
            <Button size='large' type='button' color={'blue'} inverted basic={!active} active={active}
                    onClick={() => this.changeSelection(Selection.exact)}
                    ga-on="click"
                    ga-event-category="Form Field"
                    ga-event-action="edit-runaway-dimension-exact">Numeric</Button>
        )
    };

    RangeButton = () => {
        const active = this.state.selection === 'range';
        return (
            <Button size='large' type='button' color={'blue'} inverted basic={!active} active={active}
                    onClick={() => this.changeSelection(Selection.range)}
                    ga-on="click"
                    ga-event-category="Form Field"
                    ga-event-action="edit-runaway-dimension-range">Range</Button>
        )
    };

    EstimateButton = () => {
        const active = this.state.selection === 'approximate';
        return (
            <Button size='large' type='button' color={'blue'} inverted basic={!active} active={active}
                    onClick={() => this.changeSelection(Selection.approximate)}
                    ga-on="click"
                    ga-event-category="Form Field"
                    ga-event-action="edit-runaway-dimension-approximate">Non-Numeric</Button>
        )
    };

    UnknownButton = () => {
        const active = this.state.selection === 'unknown';
        return (
            <Button size='large' type='button' color={'blue'} inverted basic={!active} active={active}
                    onClick={this.handleUnknown}
                    ga-on="click"
                    ga-event-category="Form Field"
                    ga-event-action="edit-runaway-dimension-unknown">Not Provided</Button>
        )
    };

    render() {

        return (
            <Form.Field className='form-section'>
                <label>{this.label}</label>
                {(this.state.selection === '' || this.state.selection === 'unknown') &&
                <Button.Group>
                  <this.ExactButton/>
                  <this.RangeButton/>
                  <this.EstimateButton/>
                  <this.UnknownButton/>
                </Button.Group>
                }

                <Form.Group>
                    {(this.state.selection === Selection.exact) &&
                    <Form.Field>
                      <Form.Group>
                        <Form.Field>
                          <this.ExactButton/>
                        </Form.Field>
                          {this.state.selection === Selection.exact &&
                          <Form.Group>
                              {this.type === 'height' ?
                                  <Form.Field>
                                      <HeightInput value={this.minValue}
                                                   onChange={(value: number) => this.changeExact(null, {value: value})}/>
                                  </Form.Field>
                                  :
                                  <Form.Field>
                                      <Input value={this.minValue} type='number' min={this.min} max={this.max}
                                             label={{basic: true, content: this.unit}} labelPosition='right'
                                             onChange={this.changeExact}/>
                                  </Form.Field>
                              }
                            <Form.Button secondary icon
                                         onClick={() => this.changeSelection(this.clearable ? Selection.unset : Selection.unknown)}
                                         ga-on="click"
                                         ga-event-category="Form Field"
                                         ga-event-action="edit-runaway-dimension-exact-close">
                              <Icon name='close'/>
                            </Form.Button>
                          </Form.Group>

                          }
                      </Form.Group>
                    </Form.Field>
                    }

                    {(this.state.selection === Selection.range) &&
                    <Form.Field>
                      <Form.Group>
                        <Form.Field>
                          <this.RangeButton/>
                        </Form.Field>
                          {this.state.selection === Selection.range &&
                          <Form.Group>
                              {this.type === 'height' ?
                                  <Form.Field>
                                      <HeightInput value={this.minValue}
                                                   onChange={(value: number) => this.changeMin(null, {value: value})}/>
                                  </Form.Field>
                                  :
                                  <Form.Field>
                                      <Input name={'min' + this.label} value={this.minValue} type='number'
                                             min={this.min}
                                             max={this.max} label={{basic: true, content: this.unit}}
                                             labelPosition='right'
                                             onChange={this.changeMin}/>
                                  </Form.Field>
                              }

                            <label style={{margin: '10px 5px 5px 5px'}}>-</label>

                              {this.type === 'height' ?
                                  <Form.Field>
                                      <HeightInput value={this.maxValue}
                                                   onChange={(value: number) => this.changeMax(null, {value: value})}/>
                                  </Form.Field>
                                  :
                                  <Form.Field>
                                      <Input name={'max' + this.label} value={this.maxValue} type='number'
                                             min={this.min}
                                             max={this.max} label={{basic: true, content: this.unit}}
                                             labelPosition='right'
                                             onChange={this.changeMax}/>
                                  </Form.Field>
                              }
                            <Form.Button secondary icon
                                         onClick={() => this.changeSelection(this.clearable ? Selection.unset : Selection.unknown)}
                                         ga-on="click"
                                         ga-event-category="Form Field"
                                         ga-event-action="edit-runaway-dimension-range-close">
                              <Icon name='close'/></Form.Button>
                          </Form.Group>
                          }
                      </Form.Group>
                    </Form.Field>
                    }

                    {(this.state.selection === Selection.approximate) &&
                    <Form.Field>
                      <Form.Group>
                        <Form.Field>
                          <this.EstimateButton/>
                        </Form.Field>
                          {this.state.selection === Selection.approximate &&
                          <Form.Group>
                            <Form.Input name={'approximate' + this.label} value={this.estimate}
                                        onChange={(this.changeEstimate)}/>
                            <Form.Button secondary icon
                                         onClick={() => this.changeSelection(this.clearable ? Selection.unset : Selection.unknown)}
                                         ga-on="click"
                                         ga-event-category="Form Field"
                                         ga-event-action="edit-runaway-dimension-approximate-close">
                              <Icon name='close'/></Form.Button>
                          </Form.Group>
                          }
                      </Form.Group>
                    </Form.Field>
                    }

                </Form.Group>
            </Form.Field>
        );
    }

}