import * as React from "react";
import {SyntheticEvent} from "react";
import {Dropdown, DropdownProps, Icon, Image, Menu} from "semantic-ui-react";
import {userStore} from "../stores/UserStore";
import {Link, withRouter} from "react-router-dom";
import Routes from "../Routes";
import Authenticator from "../services/authentication/Authenticator";

export const NavigationBar = withRouter(({history}) => {
    const options = [
        {
            key: 'user', content: 'Account', value: 'account', icon: 'user'
        },
        {
            key: 'collections', content: 'Collections', value: 'collections', icon: 'bookmark'
        },
        {
            key: 'sign-out', text: 'Sign Out', value: 'signout', icon: 'sign out'
        },
    ];

    const onProfileChange = (e: SyntheticEvent, {value}: DropdownProps) => {
        e.stopPropagation();
        if (value === 'account') {
            history.push(Routes.settings);
        } else if (value === 'collections') {
            history.push(Routes.collections);
        } else if (value === 'signout') {
            Authenticator.logout(userStore.user);
            history.push(Routes.home);
            window.location.reload();
        }
    };

    return (
        <Menu as='header' className='navigation-bar' style={{marginBottom: 0}} color='purple' inverted fluid
              stackable={false}>
            <Menu.Item style={{marginLeft: '20px'}}>
                <Link to={Routes.home} className="brand"
                      ga-on="click"
                      ga-event-category="Navigation"
                      ga-event-action="nav-bar-home">
                    <Image src={require('../img/logos/FOTM_LOGO_DARK.png')} alt='Freedom on the Move Logo'
                           style={{maxHeight: 45}}/>
                </Link>
            </Menu.Item>

            {!Authenticator.verifyToken(userStore.authToken) ?
                <Menu.Menu position='right' style={{marginRight: '20px'}}>
                    <Menu.Item as='span' link onClick={() => history.push(Routes.login)}><Link
                        to={Routes.login}>Login</Link></Menu.Item>
                    <Menu.Item as='span' link onClick={() => history.push(Routes.createAccount)}><Link
                        to={Routes.createAccount}>Register</Link></Menu.Item>
                </Menu.Menu>
                :
                <Menu.Menu position='right' style={{marginRight: '20px'}}>
                    <Menu.Item as={Link} to={Routes.crowdsourcing}>Contribute</Menu.Item>
                    <Menu.Item as='span' link
                               onClick={() => window.location.href = "https://database.freedomonthemove.org"}><Link
                        to={Routes.createAccount}>Search</Link></Menu.Item>
                    <Menu.Item as='span' link
                               onClick={() => window.location.href = "https://freedomonthemove.org/educators/"}><Link
                        to={Routes.createAccount}>K-12 Educators</Link></Menu.Item>
                    <Menu.Item>
                        <Dropdown title='Profile' pointing='top right' options={options} value={undefined}
                                  onChange={onProfileChange} selectOnNavigation={false} selectOnBlur={false}
                                  icon={null}
                                  trigger={
                                      <Icon title='Profile' name='user' circular inverted size='large'
                                            color='yellow'/>
                                  }
                                  ga-on="click"
                                  ga-event-category="Navigation"
                                  ga-event-action="nav-bar-profile"/>
                    </Menu.Item>
                </Menu.Menu>
            }

        </Menu>
    );
});

export default NavigationBar;
