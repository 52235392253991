import Location from "../../../stores/models/Location";

export default function formatLocation(location: Location) {
    let name = location.name;
    let city = location.city;
    let county = location.county;
    let state = location.state;
    let country = location.country;

    let display = "";
    if (name) {
        display = display + name;
        if (city || county || state || country) {
            display = display + ", "
        }
    }

    if (city) {
        display = display + city;
        if (county || state || country) {
            display = display + ", "
        }
    }

    if (county) {
        display = display + county;
        if (state || country) {
            display = display + ", "
        }
    }

    if (state) {
        let name = state.substr(state.indexOf('-') + 1, state.length);
        display = display + name;
        if (country) {
            display = display + ", "
        }
    }

    if (country) {
        display = display + country;
    }

    return display;
}