import * as React from "react";
import {Divider, Form, TextAreaProps} from "semantic-ui-react";
import {Observer, observer} from "mobx-react";
import {domainStore} from "../../../../../../stores/CrowdsourcingStore";
import {options} from "../../../../../../../../Constants";
import {Selection} from "../../../../../../../../components/Selection";
import ServerError from "../../../../../../../../components/ServerError";
import Nullable, {NullableHandler} from "../../../../../../components/Nullable";
import Language from "../../../../../../../../stores/models/Language";

interface IProps {
    runaway: number;
}

@observer
export class RunawaySkillsForm extends React.Component<IProps> {
    shownLangs = ['en', 'es', 'fr'];
    languages = options.languages.filter(l => this.shownLangs.indexOf(l.iso_639) > -1).map((language) => {
        return {key: language.id, text: language.name, value: language.id}
    });
    otherLanguages = options.languages.filter(l => this.shownLangs.indexOf(l.iso_639) < 0).map((language) => {
        return {key: language.id, text: language.name, value: language.id}
    });

    literacyOptions = [
        {key: 'both', text: 'Read & Write', value: 'both'},
        {key: 'read', text: 'Read', value: 'read'},
        {key: 'write', text: 'Write', value: 'write'},
        {key: 'none', text: 'Neither', value: 'none'},
        {key: 'null', text: 'Not Provided', value: 'NOT_PROVIDED'}
    ];

    get enslavedPerson() {
        return domainStore.runaways[this.props.runaway].enslavedPerson;
    }

    changeLiterate = (value: string) => {
        this.enslavedPerson.setLiteracy(value);
    };

    changeLanguages = (languages: Language[], doesSpeak: boolean) => {
        this.enslavedPerson.changeLanguages(languages, doesSpeak);
    };

    changeSkills: NullableHandler<TextAreaProps> = (e, {value}) => {
        this.enslavedPerson.setSkills(value as string)
    };

    render() {
        if (!this.enslavedPerson) {
            return <ServerError/>;
        }

        return (
            <div>
                <Form as='div' className='left aligned' size='large'>

                    <Form.Field>
                        <label>Could the runaway read, write, or both?</label>
                        <Observer>{() => {
                            /** @type {string}*/
                            const literacy = this.enslavedPerson.literacy;
                            return <Selection options={this.literacyOptions} value={literacy} color='blue'
                                              onChange={this.changeLiterate}
                                              ga-event-action="edit-runaway-literacy"/>
                        }}</Observer>
                    </Form.Field>

                    <Divider section/>

                    <Form.Field>
                        <label>If the ad specifically mentions languages that the runaway could speak, list
                            them below.</label>
                        <Observer>{() => {
                            /** @type {string[]}*/
                            const knownLanguages = this.enslavedPerson.knownLanguages.map((language) => language.languageId);
                            return <Selection multiple options={this.languages} values={knownLanguages} color='blue'
                                              otherOptions={this.otherLanguages}
                                              onChange={languages => this.changeLanguages(languages, true)}
                                              ga-event-action="edit-runaway-languages-known"/>
                        }}</Observer>
                    </Form.Field>

                    <Form.Field>
                        <label>If the ad specifically mentions languages that the runaway could not speak,
                            list them below.</label>
                        <Observer>{() => {
                            /** @type {string[]}*/
                            const unknownLanguages = this.enslavedPerson.unknownLanguages.map((language) => language.languageId);
                            return <Selection multiple options={this.languages} values={unknownLanguages} color='blue'
                                              otherOptions={this.otherLanguages}
                                              onChange={languages => this.changeLanguages(languages, false)}
                                              ga-event-action="edit-runaway-languages-unknown"/>
                        }}</Observer>
                    </Form.Field>

                    <Divider section/>

                    <Observer>{() =>
                        <Nullable value={this.enslavedPerson.skills} onClick={this.changeSkills}
                                  ga-event-action="edit-runaway-skills">
                            <Form.TextArea id='runaway-skills' width={12}
                                           label='List any other skills or occupations the runaway had.'
                                           value={this.enslavedPerson.skills !== 'NOT_PROVIDED' ? this.enslavedPerson.skills : ''}
                                           onChange={this.changeSkills}
                                           ga-on="click"
                                           ga-event-category="Form Field"
                                           ga-event-action="edit-runaway-skills"/>
                        </Nullable>
                    }</Observer>
                </Form>
            </div>
        );
    }
}
