import * as React from 'react';
import {observer} from 'mobx-react';
import collectionStore from "../stores/CollectionStore";
import {
    Button,
    Card,
    Container,
    Form,
    Header,
    Icon,
    InputProps,
    Message,
    Popup,
    Segment,
    TextAreaProps
} from "semantic-ui-react";
import AdvertisementCard from "../../../components/AdvertisementCard";
import {createLoader} from "../../../stores/utils/Loader";
import {ErrorBoundary} from "../../Crowdsourcing/components/ErrorBoundary";
import NotFound from "../../NotFound/NotFound";
import Routes from "../../../Routes";
import ClickToCopy from "../../../components/ClickToCopy";
import {Link, Redirect, RouteComponentProps} from "react-router-dom";
import CollectionModel from "../stores/models/Collection";
import {SyntheticEvent} from "react";
import Advertisement from "../../../stores/models/Advertisement";

interface IProps extends RouteComponentProps<any> {
    collection: CollectionModel;
}

type IState = {
    open: boolean;
    edit: boolean;
    deleted: boolean;
}

@observer
export class Collection extends React.Component<IProps, IState> {
    loader = createLoader(0);
    id!: string;
    collection?: CollectionModel;
    state = {
        open: false,
        edit: false,
        deleted: false
    };

    constructor(props: IProps) {
        super(props);
        this.id = props.match.params.collectionId;
        this.collection = props.collection;

        this.loader.load(this.fetchCollection());
    }

    async fetchCollection() {
        this.collection = await collectionStore.fetchCollection(this.id);
    }

    toggleEdit = (e: SyntheticEvent) => {
        e.stopPropagation();
        this.setState({edit: !this.state.edit, open: true});
    };

    submitChanges = (e: SyntheticEvent) => {
        e.stopPropagation();
        if (this.collection && this.collection.changed) {
            this.collection.pushUpdate();
        }
        this.setState({edit: false});
    };

    changeName = (e: SyntheticEvent, {value}: InputProps) => {
        if (this.collection) {
            this.collection.setName(value);
        }
    };

    changeDescription = (e: SyntheticEvent, {value}: TextAreaProps) => {
        if (this.collection) {
            this.collection.setDescription(value as string);
        }
    };

    deleteCollection = async (e: SyntheticEvent) => {
        e.stopPropagation();
        if (this.collection) {
            await collectionStore.deleteColleciton(this.collection.id);
            this.setState({deleted: true});
        }
    };

    render() {
        const collection = collectionStore.collection;
        const name = collection && collection.name ? collection.name : "";
        const description = collection && collection.description ? collection.description : "";
        const owner = collection && collection.account ? collection.account.fullname : "";

        if (this.state.deleted) {
            return (<Redirect to={Routes.collections}/>);
        }

        if (this.loader.status === 404) {
            return (<NotFound/>);
        }

        if (this.loader.status === 403) {
            return (
                <Segment basic as={Container}>
                    <Message size='massive' warning style={{marginTop: '20vh'}}>
                        <Message.Header>Collection is Private</Message.Header>
                        <Message.Content>Sorry, this collection is private. The owner must make it public for you to
                            view it.</Message.Content>
                        <Button as={Link} primary to={Routes.collections} size='large'>Go to My Collections</Button>
                    </Message>
                </Segment>
            );
        }

        return (
            <ErrorBoundary loader={this.loader}>
                <Segment basic as={Container}>
                    {collection && collection.isOwned &&
                    <div style={{textAlign: "right"}}>
                      <ClickToCopy
                          text={`${window.location.origin}${Routes.collections}/${collection.id}`}
                          label='Copy Link'>
                        <button className='link-button' style={{marginRight: '10px'}}><Icon
                            name='share alternate'/> SHARE
                        </button>
                      </ClickToCopy>
                        {this.state.edit ?
                            <button className='link-button' onClick={this.submitChanges}><Icon name='save'/> SAVE
                            </button>
                            :
                            <button className='link-button' onClick={this.toggleEdit}><Icon name='edit'/> EDIT</button>
                        }
                    </div>
                    }

                    {this.state.edit ?
                        <Form size='large' onSubmit={this.submitChanges}>
                            <Form.Group>
                                <Form.Input placeholder='Name...' value={name} onChange={this.changeName}/>
                                <Form.Button type='button' negative
                                             onClick={this.deleteCollection}>DELETE</Form.Button>
                            </Form.Group>
                            <Form.TextArea style={{width: '100%'}} placeholder='Description...'
                                           value={description} onChange={this.changeDescription}/>
                        </Form>
                        :
                        <Header as='h1' style={{marginTop: 0}}>{name}
                            {owner &&
                            <Header.Subheader title='Owner'><Icon name='user outline'/>{owner}</Header.Subheader>
                            }
                            <Header.Subheader title='Description'><Icon name='file alternate outline'/>{description}
                            </Header.Subheader>
                        </Header>
                    }


                    {collection && collection.advertisements.length > 0 ?
                        <Card.Group key={collection.id} itemsPerRow={3} doubling stackable style={{margin: 0}}>
                            {collection.advertisements.map((advertisement: Advertisement) =>
                                (
                                    <AdvertisementCard key={advertisement.id} data={advertisement}
                                                       topAction={this.state.edit &&
                                                       <Popup
                                                           trigger={<Button icon='close' compact circular negative
                                                                            size='small'
                                                                            onClick={() => collection.removeAdvertisement(advertisement)}/>}
                                                           content='Remove'/>}
                                    />
                                )
                            )}
                        </Card.Group>
                        :
                        <span>There are no advertisements in this collection.</span>
                    }
                </Segment>
            </ErrorBoundary>
        )
    }
}

export default Collection;