import * as React from "react";
import {CSSProperties} from "react";
import {Image, Modal} from 'semantic-ui-react'
import {config} from "../../../config";

interface IProps {
    src?: string;
    transcription?: string;
    onLoad?: () => void;
    style?: CSSProperties;

    [x: string]: any;
}

export function AdImage({src, transcription = undefined, onLoad = undefined, style = undefined, ...props}: IProps) {
    if (!src) {
        return <React.Fragment/>;
    }
    const imgSrc = `${config.s3Domain}/${src}`;
    const alt = transcription ? `Advertisement: ${transcription}` : 'Advertisement';
    const imgStyle = Object.assign({cursor: 'pointer'}, style);

    return (
        <Modal trigger={
            <Image src={imgSrc} fluid alt={alt} onLoad={onLoad} style={imgStyle} {...props}/>
        }
               className='image-modal' size='large' closeIcon>
            <Modal.Content scrolling>
                <Image src={imgSrc} fluid alt="Advertisement"/>
            </Modal.Content>
        </Modal>
    );
}
