import * as React from 'react';
import NLPTag from "./NLPTag";
import {observer} from "mobx-react";
import {uiStore} from "../stores/UIStore";

export const NLPText = observer(class extends React.Component {

    isPunctuation(text: string) {
        return text === "." || text === "," || text === ";" || text === "?";
    }

    render() {
        return (
            <div>
                {
                    uiStore.nlpTranscription.sentences.map((sentence: any) => {
                        let nextIndex = 0;
                        return sentence.tokens.map((token: any, index: number) => {
                            if (index < nextIndex) {
                                return <span key={uiStore.getKey()}/>;
                            }

                            let entity = sentence.entitymentions.find((mention: any) => {
                                return mention.tokenBegin <= index && mention.tokenEnd > index;
                            });

                            nextIndex = entity ? entity.tokenEnd : 0;

                            return (
                                entity ?
                                    <span key={uiStore.getKey()}>
                          &nbsp;<NLPTag entity={entity}/>
                        </span>
                                    :
                                    !this.isPunctuation(token.originalText) ?
                                        <span key={uiStore.getKey()}> {token.originalText}</span>
                                        :
                                        <span key={uiStore.getKey()}>{token.originalText}</span>
                            );
                        })

                    })
                }
            </div>
        );
    }
});

export default NLPText;