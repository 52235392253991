import * as React from "react";
import {observer} from "mobx-react";
import {domainStore} from "../../stores/CrowdsourcingStore";
import {RunawayNameForm} from "./scenes/RunawayName/RunawayNameForm";
import {Button, Divider, Form, Grid, Header, Label} from "semantic-ui-react";
import {uiStore} from "../../stores/UIStore";
import {FormPage} from "../../components/FormPage";
import {ErrorBoundary} from "../../components/ErrorBoundary";
import {RunawayRelationships} from "./scenes/RunawayRelationships/RunawayRelationships";

@observer
export class RunawayNames extends React.Component {
    loader = domainStore.getLoader(500);

    constructor(props: any) {
        super(props);
        if (domainStore.runaways.length <= 0) {
            domainStore.addRunaway();
        }
        uiStore.initRunaways();
    }

    submitInfo = async () => {
        domainStore.runawayEvent.setEscapeeCount(domainStore.runaways.length);
        let promises: Promise<any>[] = [];
        promises.push(domainStore.pushEventToServer());
        for (let i = 0; i < domainStore.runaways.length; i++) {
            promises.push(domainStore.pushAllRunawayData(i));
        }

        return Promise.all(promises);
    };

    onPrevious = () => {
        try {
            this.submitInfo();
            uiStore.previous();
        } catch (e) {
        }
    };

    onSubmit = async () => {
        try {
            await this.loader.load(this.submitInfo());
            uiStore.next();
        } catch (e) {
            console.error(e);
        }
    };

    changeRanBefore = (i: number, value?: boolean) => {
        domainStore.runaways[i].setRanBefore(value);
    };

    changeProfessFree = (i: number, value?: boolean) => {
        domainStore.runaways[i].setProfessFreedom(value);
    };

    removeRunaway = (i: number) => {
        domainStore.removeRunaway(i);
        uiStore.removeRunaway(i);
    };

    addRunaway = () => {
        domainStore.addRunaway();
        uiStore.addRunaway();
    };

    render() {
        let runaways = domainStore.runaways;

        return (
            <FormPage>
                <ErrorBoundary loader={this.loader}>
                    <Header as='h1' color='orange'>RUNAWAYS
                        <Header.Subheader>Add as many runaways as the advertisement listed, even if they are just listed
                            as
                            “infant” or “child.” You’ll be able to record additional information about each runaway as
                            you go
                            along.</Header.Subheader>
                    </Header>
                    <Grid style={{margin: 0}}>
                        {runaways.map((r, i) => {
                            return (
                                <Grid.Row key={r.id}>
                                    <Grid.Column width={1}>
                                        <Label circular color='orange' size='big'>{i + 1}</Label>
                                    </Grid.Column>
                                    <Grid.Column width={14}>
                                        <RunawayNameForm runaway={i}/>
                                        <Form as='div' style={{marginTop: '30px'}}>
                                            <Form.Group>
                                                <Form.Checkbox id={`runaway-ran-before-${r.id}`} name='Ran Before'
                                                               label='Has run before' checked={r.ranBefore}
                                                               onChange={(e, {checked}) => this.changeRanBefore(i, checked)}
                                                               ga-on="click"
                                                               ga-event-category="Form Field"
                                                               ga-event-action="edit-runaway-ran-before"/>
                                                <Form.Checkbox id={`runaway-profess-freedom-${r.id}`}
                                                               name='Rrofess Freedom' label='Claims to be free'
                                                               checked={r.professFreedom}
                                                               onChange={(e, {checked}) => this.changeProfessFree(i, checked)}
                                                               ga-on="click"
                                                               ga-event-category="Form Field"
                                                               ga-event-action="edit-runaway-claims-free"/>
                                            </Form.Group>
                                        </Form>
                                    </Grid.Column>
                                    <Grid.Column width={1}>
                                        {runaways.length > 1 &&
                                        <button className='link-button'
                                                onClick={() => this.removeRunaway(i)}>REMOVE</button>
                                        }
                                    </Grid.Column>
                                    {i < runaways.length - 1 &&
                                    <Grid.Column width={16}>
                                      <Divider section/>
                                    </Grid.Column>
                                    }
                                </Grid.Row>
                            )
                        })}
                        <Button color='blue' onClick={this.addRunaway}>ADD ANOTHER RUNAWAY</Button>
                    </Grid>

                    {runaways.length > 1 &&
                    <RunawayRelationships loader={this.loader}/>
                    }

                    <Divider section/>
                    <div style={{marginTop: 20}}>
                        <Form as='div'>
                            <Form.Group>
                                <Form.Button className='form-button' size='large' type='button'
                                             onClick={this.onPrevious}
                                             ga-on="click"
                                             ga-event-category="Navigation"
                                             ga-event-action="runaway-names-previous">PREVIOUS</Form.Button>
                                <Form.Button className='form-button' primary size='large' type='button'
                                             onClick={this.onSubmit}
                                             ga-on="click"
                                             ga-event-category="Navigation"
                                             ga-event-action="runaway-names-next">NEXT</Form.Button>
                            </Form.Group>
                        </Form>
                    </div>
                </ErrorBoundary>
            </FormPage>
        );
    }
}

export default RunawayNames;