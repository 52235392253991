import {action, observable} from "mobx";

class NLPOptions {
    readonly numbers = observable<string>([]);
    readonly durations = observable<string>([]);
    readonly locations = observable<string>([]);
    readonly titles = observable<string>([]);
    readonly persons = observable<string>([]);
    readonly dates = observable<string>([]);

    @action
    setNumber(numbers: string[]) {
        this.numbers.replace(numbers);
    }

    @action
    addNumber(number: string) {
        this.numbers.push(number);
    }

    @action
    setDuration(durations: string[]) {
        this.durations.replace(durations);
    }

    @action
    addDuration(duration: string) {
        this.durations.push(duration);
    }

    @action
    setLocation(locations: string[]) {
        this.locations.replace(locations);
    }

    @action
    addLocation(location: string) {
        if (this.locations.indexOf(location) < 0) {
            this.locations.push(location);
        }
    }

    @action
    setTitle(titles: string[]) {
        this.titles.replace(titles);
    }

    @action
    addTitle(title: string) {
        this.titles.push(title);
    }

    @action
    setPerson(persons: string[]) {
        this.persons.replace(persons);
    }

    @action
    addPerson(person: string) {
        if (this.persons.indexOf(person) < 0) {
            this.persons.push(person);
        }
    }

    @action
    setDate(dates: string[]) {
        this.dates.replace(dates);
    }

    @action
    addDate(date: string) {
        this.dates.push(date);
    }

}

export default NLPOptions;