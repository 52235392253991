import * as React from "react";
import {SyntheticEvent} from "react";
import {ButtonProps, CheckboxProps, Divider, Form, Icon, Popup, TextArea, TextAreaProps} from "semantic-ui-react";
import {Observer, observer} from "mobx-react";
import {domainStore} from "../../../stores/CrowdsourcingStore";
import {options} from "../../../../../Constants";
import {Selection} from "../../../../../components/Selection";
import ForeignCharacters from "../../../../../components/ForeignCharacters";
import Routes from "../../../../../Routes";
import {RouteComponentProps, withRouter} from "react-router-dom";
import AdvertisementLanguageOption from "../../../../../types/AdvertisementLanguageOption";

interface IProps extends RouteComponentProps {
    onSubmit: () => void;
}

export const TranscriptionForm = withRouter(observer((props: IProps) => {
    const advertisement = domainStore.advertisement!;
    const transcriptionInput = React.createRef<TextArea>();

    const shownLangs = ['en', 'es', 'fr'];
    const languages = options.languages.filter(l => shownLangs.indexOf(l.iso_639) > -1).map((language) => {
        return {key: language.id, text: language.name, value: language.name}
    });

    const nextAd = async () => {
        await domainStore.loader.load(skipAd());
    };

    const skipAd = async () => {
        const ad = await domainStore.skipAdvertisement();
        domainStore.updateAdvertisementProgress(15);
        if (ad) {
            props.history.push(`${Routes.crowdsourcing}/${ad.id}`);
        }
    };

    const handleTranscriptionChange = (e: SyntheticEvent, {value}: TextAreaProps) => {
        advertisement.setTranscription(value as string);
    };

    const handleSpecialCharacter = (e: React.MouseEvent, {name}: ButtonProps) => {
        let transcription = advertisement.transcription ? advertisement.transcription : '';
        advertisement.setTranscription(transcription + name);
        transcriptionInput.current!.focus();
    };

    let handleLanguageChange = (language: string) => {
        options.languages.forEach((l) => {
            if (l.name === language) {
                advertisement.setLanguage(l);
                advertisement.setLanguageId(l.id);
            }
        });
    };

    const changeIllegible = (e: SyntheticEvent, {checked}: CheckboxProps) => {
        advertisement.setIllegible(checked as boolean);
    };

    let showTranscription = advertisement.digitalObject.filename;

    return (
        <div>
            <Form className='left aligned' size='large' onSubmit={() => props.onSubmit()}>
                <Observer>{() =>
                    <Form.Field>
                        <label htmlFor="transcription-language-select">In what language was the ad printed?</label>
                        <Selection id='transcription-language-select' options={languages}
                                   value={advertisement.language ? advertisement.language.name : null}
                                   color='blue' onChange={handleLanguageChange}
                                   ga-event-action="edit-advertisement-language"/>
                    </Form.Field>}
                </Observer>

                {showTranscription &&
                <React.Fragment>
                  <div style={{marginBottom: '10px', whiteSpace: 'pre-wrap'}}>
                    <label
                        htmlFor='transcription-input'>{"Write a transcription of the ad exactly as it is written in the image to the left."}
                      <ul>
                        <li>Maintain punctuation, capitalization, any non-words such as print marks&nbsp;
                          <Popup
                              position='top center'
                              trigger={
                                  <Icon name='question circle'/>
                              }
                              content="Print marks are marks such as '11tf' that indicate to the newspaper how long to print the ad for."/>
                        </li>
                        <li>Keep line breaks where there is clearly meant to be a new line but do not add them in place
                          of
                          text wrapping
                        </li>
                        <li>If a word is illegible, write [illegible] starting at the part of the word that is
                          illegible
                        </li>
                        <li>Do not fix any spelling mistakes</li>
                        <li>Do not include hyphens that separate a single word due to text wrapping</li>
                        <li>Do not include indentation</li>
                      </ul>
                    </label>
                  </div>
                  <Observer>{() =>
                      <React.Fragment>
                          <ForeignCharacters
                              language={advertisement.language ? advertisement.language.name as AdvertisementLanguageOption : undefined}
                              onClick={handleSpecialCharacter}/>
                          <Form.Field>
                              <TextArea id="transcription-input" style={{height: '20vw'}} ref={transcriptionInput}
                                        placeholder='Transcription...' value={advertisement.illegible ? "" : advertisement.transcription}
                                        disabled={advertisement.illegible}
                                        onChange={handleTranscriptionChange}
                                        ga-on="click"
                                        ga-event-category="Form Field"
                                        ga-event-action="edit-transcription"
                              />
                          </Form.Field>
                      </React.Fragment>
                  }</Observer>
                  <Form.Checkbox id='advertisment-illegible'
                                 label='The advertisement is completely illegible'
                                 checked={advertisement.illegible} onChange={changeIllegible}/>
                </React.Fragment>
                }

                <Divider section/>
                <Form.Group>
                    <Form.Button className='form-button' size='large' type='button'
                                 onClick={nextAd}
                                 ga-on="click"
                                 ga-event-category="Navigation"
                                 ga-event-action="skip-advertisement">SKIP TO NEXT ADVERTISEMENT</Form.Button>
                    <Form.Button className='form-button' primary size='large' type='submit'
                                 disabled={domainStore.loader.isLoading}
                                 ga-on="click"
                                 ga-event-category="Navigation"
                                 ga-event-action="transcription-next">NEXT</Form.Button>
                </Form.Group>
            </Form>
        </div>
    )
}));

export default TranscriptionForm;