// @ts-nocheck
import {action, observable} from "mobx";
import Location from "./Location";

class Advertiser {
    id: string;
    @observable name = "";
    @observable location = new Location();

    constructor(json: any = {}) {
        this.id = json.id;
        this.name = json.name;
        if (json.location) {
            this.location = new Location(json.location);
        }
    }

    @action
    setFullName(fullName: string) {
        this.name = fullName;
    }

    @action
    setLocation(location: any) {
        this.location = new Location(location);
    }

    getDataForServer() {
        let data = Object.assign({}, this);

        if (this.location.isEmpty()) {
            delete data.location;
        }

        return data;
    }

}

export default Advertiser;