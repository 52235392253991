import * as React from "react";
import {ComponentType} from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import auth from "../services/authentication/Authenticator";
import {userStore} from "../stores/UserStore";
import Routes from "../Routes";

interface IProps extends RouteProps {
    component: ComponentType<any>
}

export const PrivateRoute = ({component: Component, ...rest}: IProps) => (
    <Route {...rest} render={(props) => (
        auth.verifyToken(userStore.authToken)
            ? <Component {...props} />
            : <Redirect to={{
                pathname: Routes.login,
                state: {from: props.location}
            }}/>
    )}/>
);

export default PrivateRoute;
