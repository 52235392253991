import {action, observable} from "mobx";
import Runaway from "./Runaway";
import RunawayRelationship from "./RunawayRelationship";

export class RunawayEvent {
    id: string;
    @observable minOccurred?: string | null;
    @observable maxOccurred?: string | null;
    @observable escapeeCount? = 1;
    @observable outsideInvolvement?: boolean;
    advertisementId?: string;
    readonly runaways = observable<Runaway>([]);

    constructor(json: any = {}) {
        this.id = json.id;
        this.minOccurred = json.minOccurred;
        this.maxOccurred = json.maxOccurred;
        this.escapeeCount = json.hasOwnProperty("escapeeCount") ? json.escapeeCount : 1;
        this.advertisementId = json.advertisementId;
        this.outsideInvolvement = json.outsideInvolvement;
        if (json.runaways) {
            this.runaways.replace(json.runaways.map((r: any) => new Runaway(r)));
        }
    }

    @action
    setMinOccurred(minOccurred?: string | null) {
        this.minOccurred = minOccurred;
    }

    @action
    setMaxOccurred(maxOccurred?: string | null) {
        this.maxOccurred = maxOccurred;
    }

    @action
    setEscapeeCount(escapeeCount?: number) {
        this.escapeeCount = escapeeCount;
    }

    @action
    setOutsideInvolvement(outsideInvolvement?: boolean) {
        this.outsideInvolvement = outsideInvolvement;
    }

    @action
    setAdvertisementId(advertisementId?: string) {
        this.advertisementId = advertisementId;
    }

    get runawayRelationships() {
        const relationshipMap: {[k: string]: string} = {};
        const relationships: RunawayRelationship[] = [];
        this.runaways.forEach(runaway => {
            runaway.relationships.forEach(relationship => {
                if (!(relationshipMap[relationship.leftRunawayId] === relationship.rightRunawayId
                || relationshipMap[relationship.rightRunawayId] === relationship.leftRunawayId)) {
                    const leftRunaway = this.runaways.find(r => r.id === relationship.leftRunawayId);
                    const rightRunaway = this.runaways.find(r => r.id === relationship.rightRunawayId);
                    relationship.setLeftRunaway(leftRunaway);
                    relationship.setRightRunaway(rightRunaway);
                    relationships.push(relationship);
                    relationshipMap[relationship.leftRunawayId] = relationship.rightRunawayId;
                }
            })
        });
        return relationships;
    }
}

export default RunawayEvent;
