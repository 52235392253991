// @ts-nocheck
import {action, observable} from "mobx";
import Location from "./Location";

class Enslaver {
    id: string;
    @observable fullname?: string;
    @observable gender?: string;
    @observable type?: string;
    @observable location = new Location();
    readonly runaways = observable<string>([]);

    constructor(json: any = {}) {
        this.id = json.id;
        this.fullname = json.fullname;
        this.gender = json.gender;
        this.type = json.type;
        if (json.location) {
            this.location = new Location(json.location);
        }
    }

    @action
    setFullname(fullname?: string) {
        this.fullname = fullname;
    }

    @action
    setGender(gender?: string) {
        this.gender = gender;
    }

    @action
    setType(type?: string) {
        this.type = type;
    }

    @action
    setLocation(location: any) {
        this.location = new Location(location);
    }

    @action
    setRunaways(runaways: string[]) {
        this.runaways.replace(runaways);
    }

    getDataForServer() {
        let data = Object.assign({}, this);

        if (this.location.isEmpty()) {
            delete data.location;
        }

        return data;
    }

}

export default Enslaver;
