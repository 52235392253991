import {observer} from "mobx-react";
import * as React from "react";
import {SyntheticEvent} from "react";
import {Form, Icon, Search, SearchProps} from "semantic-ui-react";
import EnslavedPersonName from "../../../../../../../stores/models/EnslavedPersonName";
import {uiStore} from "../../../../../stores/UIStore";

interface IProps {
    id?: string | number;
    name: EnslavedPersonName;
}

export const RunawayNameField = observer(({id = uiStore.getKey(), name}: IProps) => {

    const changeName = (e: SyntheticEvent, {value}: SearchProps) => {
        name.setFullname(value as string);
    };

    const selectName = (e: SyntheticEvent, {result}: SearchProps) => {
        name.setFullname(result.value);
    };

    const nameOptions = uiStore.nlpOptions.persons.map(p => {
        return {key: uiStore.getKey(), value: p, title: p}
    });

    return (
        <Form.Field width={8}>
            <label htmlFor={`runaway-fullname-${id}`}>Full Name</label>
            <div className='input-checkbox-container'>
                <Search id={`runaway-fullname-${id}`} icon={<Icon/>} showNoResults={false} fluid minCharacters={0}
                        value={name.fullname}
                        results={nameOptions}
                        onSearchChange={changeName}
                        onResultSelect={selectName}
                        resultRenderer={({value}) => <div>{value}</div>}
                        ga-on="click"
                        ga-event-category="Form Field"
                        ga-event-action="edit-runaway-name"/>
            </div>
        </Form.Field>
    )
});

export default RunawayNameField;
