import * as React from 'react';
import EnslaverForm from "./components/EnslaverForm";
import {observer} from "mobx-react";
import {uiStore} from "../../stores/UIStore";
import {domainStore} from "../../stores/CrowdsourcingStore";
import {ErrorBoundary} from "../../components/ErrorBoundary";
import {FormPage} from "../../components/FormPage";
import {Button, Divider, Form, Grid, Header, Label} from "semantic-ui-react";

@observer
export class Enslaver extends React.Component {

    constructor(props: any) {
        super(props);
        if (domainStore.enslavers.length <= 0) {
            domainStore.addEnslaver();
        }
    }

    addEnslaver = () => {
        domainStore.addEnslaver();
    };

    onSubmit = async () => {
        try {
            await domainStore.pushEnslaversToServer();
            await domainStore.updateRunawayEnslavers();
            domainStore.updateAdvertisementProgress(100);
            if (uiStore.mod) {
                domainStore.advertisement!.setComplete(true);
                domainStore.pushAdvertisementToServer()
                    .then(() => uiStore.next());
            } else {
                uiStore.next();
            }
        } catch (e) {
        }
    };

    previousPage = async () => {
        await domainStore.pushEnslaversToServer();
        await domainStore.updateRunawayEnslavers();
        uiStore.previous();
    };

    render() {
        return (
            <FormPage className='transcription-form'>
                <Header as='h1' color='orange'>ENSLAVERS</Header>
                <ErrorBoundary>
                    <Grid style={{margin: 0}}>
                        {domainStore.enslavers.map((enslaver, i) => {
                            return (
                                <Grid.Row key={uiStore.getKey()}>
                                    <Grid.Column width={1}>
                                        <Label circular color='orange' size='big'>{i + 1}</Label>
                                    </Grid.Column>
                                    <Grid.Column width={14}>
                                        <EnslaverForm enslaver={enslaver}/>
                                        <Divider style={{marginBottom: 20, marginTop: 40}}/>
                                    </Grid.Column>
                                    <Grid.Column width={1}>
                                        {domainStore.enslavers.length > 1 &&
                                        <button className='link-button'
                                                onClick={() => domainStore.removeEnslaver(i)}>REMOVE</button>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            )
                        })}
                    </Grid>

                    <Button color='blue' onClick={this.addEnslaver}>ADD ANOTHER ENSLAVER</Button>

                    <Divider style={{marginBottom: 20, marginTop: 40}}/>

                    <Form onSubmit={this.onSubmit}>
                        <Form.Group>
                            <Form.Button className='form-button' size='large' type='button'
                                         onClick={this.previousPage}
                                         disabled={domainStore.loader.isLoading}
                                         ga-on="click"
                                         ga-event-category="Navigation"
                                         ga-event-action="enslaver-previous">PREVIOUS</Form.Button>
                            {uiStore.mod ?
                                <Form.Button className='form-button' positive size='large' type='submit'
                                             disabled={domainStore.loader.isLoading}
                                             ga-on="click"
                                             ga-event-category="Navigation"
                                             ga-event-action="enslaver-submit-mod">MARK AS COMPLETE!</Form.Button>
                                :
                                <Form.Button className='form-button' primary size='large' type='submit'
                                             disabled={domainStore.loader.isLoading}
                                             ga-on="click"
                                             ga-event-category="Navigation"
                                             ga-event-action="enslaver-submit">SUBMIT</Form.Button>
                            }

                        </Form.Group>
                    </Form>
                </ErrorBoundary>

            </FormPage>
        );
    }
}

export default Enslaver;